import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Button, Spinner } from 'react-bootstrap'
import { subscriptionUpdateStatusAction, subscriptionDetailAction } from '../../../../actions/subscriptionActions'
import { nextWeekSubscriptionOrderDetailAction } from '../../../../actions/orderActions'
import { SUBSCRIPTION_UPDATE_STATUS_RESET } from '../../../../constants/subscriptionConstants'
import Message from '../../../Message'
import { userMessageAction } from '../../../../actions/userActions'


export const ReactivateSubscriptionForm = () => {

    const dispatch = useDispatch()

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    const updateStatusSubscription = useSelector(state => state.subscriptionUpdateStatus)
    const { error: updateStatusError, loading: updateStatusLoading, success } = updateStatusSubscription


    const reactivateSubscriptionHandler = (e) => {
        e.preventDefault()
        // 4 is the pk of the Active subscription status on development. Check the 
        // production pk
        dispatch(subscriptionUpdateStatusAction(subscription.id, 4))
    }

    useEffect(() => {
        if(success) {
            dispatch(subscriptionDetailAction())
            dispatch(nextWeekSubscriptionOrderDetailAction())
            dispatch({
                type: SUBSCRIPTION_UPDATE_STATUS_RESET
            })
            dispatch(userMessageAction('Subscripción reactivada'))
        }
    }, [success, dispatch])

    return (
        <Form onSubmit={reactivateSubscriptionHandler} className='my-3'>
            {updateStatusError && (<Message variant='danger'>{updateStatusError}</Message>)}
            <Button variant="success" disabled={updateStatusLoading} type="submit" className='btn-block btn-lg'>
            {updateStatusLoading ? (
                <Spinner
                animation='border'
                role='status'
                style={{
                    height:'25px',
                    width:'25px',
                    margin:'auto',
                    display:'block'
                }}
                >
                    <span className='sr-only'>
                        Loading...
                    </span>
                </Spinner>
            ) : (
                <span>
                    Reactivar subscripción
                </span>
            )}
            </Button>
        </Form>
    )
}
