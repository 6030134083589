import React from 'react'
import { useSelector } from 'react-redux'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const { REACT_APP_GMAPS_API_KEY } = process.env

const containerStyle = {
  width: '100%',
  height: '325px'
};


function UserAddressMap(props) {

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const MapCenter = () => {
        if (props.latLng) {
            const center = {
                lat: parseFloat(props.latLng[0]),
                lng: parseFloat(props.latLng[1])
              }
            return center
        } else {
            const center = {
                lat: parseFloat(user.latitude),
                lng: parseFloat(user.longitude)
              }
            return center
        }
    }
      
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: REACT_APP_GMAPS_API_KEY
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(MapCenter());
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const MarkerDragEnd = (coord) => {
    props.onMarkerDragEnd(coord)
  }

  return isLoaded ? (
    <GoogleMap
        mapContainerStyle={containerStyle}
        center={MapCenter()}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{streetViewControl: false, fullscreenControl: false, mapTypeControl: false, minZoom: 14, maxZoom: 15}}
      >
        {props.latLng ? (
            <Marker
                position={{ lat: parseFloat(props.latLng[0]), lng: parseFloat(props.latLng[1]) }}
                draggable={props.draggableMarker}
                onDragEnd={(coord) => MarkerDragEnd(coord)}
            />
        ) : (
            <Marker
                position={{ lat: parseFloat(user.latitude), lng: parseFloat(user.longitude) }}
                draggable={props.draggableMarker}
                onDragEnd={(coord) => MarkerDragEnd(coord)}
            />
        )}
    </GoogleMap>
  ) : <></>
}

export default UserAddressMap