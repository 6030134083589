import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
const { 
    REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE,
    REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT,
    REACT_APP_MINIMUN_PURCHASE_NO_TRANSPORT,
    REACT_APP_TRANSPORT_CHARGE } = process.env


export const LocalActiveSubscriptionOrderResume = () => {

    const orderNextWeekActiveSubscription = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
    const { loading: orderLoading, nextWeekActiveOrder  } = orderNextWeekActiveSubscription

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const subscriptionLocal = useSelector(state => state.localSubscription)
    const { localProductsItems: localSubscriptionProductsItems } = subscriptionLocal

    const[platformBalanceUser, setPlatformBalanceUser] = useState(0)

    const[amountToPay, setAmountToPay] = useState(0)

    const[remainingPlatformBalanceUser, setRemainingPlatformBalanceUser] = useState(0)

    const updateSubscription = useSelector(state => state.subscriptionUpdate)
    const { loading: subscriptionUpdateLoading } = updateSubscription

    useEffect(() => {
        if (user && localSubscriptionProductsItems) {
            orderAmountToPay(localSubscriptionProductsItems)
        }
    }, [
        localSubscriptionProductsItems,
        nextWeekActiveOrder,
        subscriptionUpdateLoading,
        user])

    const orderAmountToPay = (productsValue) => {
        const minAmountToPay = 10000
        const itemsValue = SubscriptionCartItemsValue(localSubscriptionProductsItems)
        const discount = subscriptionDiscoutValue(localSubscriptionProductsItems)
        const transport = transportCost(localSubscriptionProductsItems)
        const userBalance = user.user_platform_balance
        if (itemsValue - discount - transport - userBalance > minAmountToPay) {
            setAmountToPay(itemsValue - discount - transport - userBalance)
            if (userBalance > 0) {
                setPlatformBalanceUser(userBalance)
                setRemainingPlatformBalanceUser(0)    
            }
        } else {
            const balanceToUse = (itemsValue - discount - transport) - minAmountToPay
            setAmountToPay(itemsValue - discount - transport - balanceToUse)
            if (userBalance > 0) {
                setPlatformBalanceUser(balanceToUse)
                setRemainingPlatformBalanceUser(userBalance - balanceToUse)    
            }
        }
    }

    function transportCost(cartItemsArray) {
        const itemsCost = SubscriptionCartItemsValue(cartItemsArray)
        if (itemsCost > REACT_APP_MINIMUN_PURCHASE_NO_TRANSPORT) {
            return 0
        }
        return Math.round(Number(REACT_APP_TRANSPORT_CHARGE) * (1 - (Number(itemsCost)/REACT_APP_MINIMUN_PURCHASE_NO_TRANSPORT)))
    }


    function SubscriptionCartItemsValue(cartItemsArray) {
        const initialValue = 0;
        const itemsCost = cartItemsArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total_value,
        initialValue,
        );
        return itemsCost
    }

    function subscriptionDiscoutValue(cartItemsArray) {
        const itemsCost = SubscriptionCartItemsValue(cartItemsArray)
        const productsDiscount = itemsCost * REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE
        if (productsDiscount > REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT) {
            return REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT
        }
        return productsDiscount
    }


    return (
        <div>
            <ul style={{ paddingLeft: '1.2em'}} className='mx-auto'>
                <li>
                    <span style={{ float: 'left'}}>
                        Valor de los productos
                    </span>
                    <span style={{ float: 'right'}}>
                        {nextWeekActiveOrder &&
                        orderLoading === false &&
                        nextWeekActiveOrder.date !== null &&
                        subscriptionUpdateLoading === false ? (<span>${new Intl.NumberFormat('co-CO').format(Number(nextWeekActiveOrder.products_value))}</span>) : (<span>Actualizando......</span>)}
                    </span>
                </li>
                <li>
                    <span style={{ float: 'left', color: '#29BC5A'}}>
                        Descuento
                    </span>
                    <span style={{ float: 'right'}}>
                        {nextWeekActiveOrder &&
                        orderLoading === false &&
                        nextWeekActiveOrder.date !== null &&
                        subscriptionUpdateLoading === false ? (
                        <span style={{ color: '#29BC5A' }}>
                            {(Number(nextWeekActiveOrder.products_value) - Number(nextWeekActiveOrder.products_amont_to_pay)) > 0 ? (
                                <span>
                                    -${new Intl.NumberFormat('co-CO').format(Number(nextWeekActiveOrder.products_value) - Number(nextWeekActiveOrder.products_amont_to_pay))}
                                </span>
                            ) : (
                                <span>0</span>
                            )}
                        </span>) : (
                        <span style={{ color: '#29BC5A' }}>
                            Actualizando......
                        </span>)}
                    </span>
                </li>
                {nextWeekActiveOrder && orderLoading === false && nextWeekActiveOrder.date !== null && subscriptionUpdateLoading === false && (
                    <>
                        {(Number(nextWeekActiveOrder.products_value) - Number(nextWeekActiveOrder.products_amont_to_pay)) >= REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT && (
                            <li style={{ listStyleType: 'none', marginLeft: '10px', color: '#29BC5A' }}>
                                <small style={{ marginLeft: 'auto' }}>
                                    Descuento máximo por mercado para los 2 primeros mercados
                                </small>
                            </li>
                        )}
                    </>
                )}
                {user && user.user_platform_balance > 0 && (
                <li style={{ color: '#29BC5A' }}>
                    <span style={{ float: 'left'}}>
                        Balance en Verdemi a usar
                    </span>
                    <span style={{ float: 'right'}}>
                    {nextWeekActiveOrder &&
                    orderLoading === false &&
                    nextWeekActiveOrder.date !== null &&
                    subscriptionUpdateLoading === false ? (
                        <span style={{ color: '#29BC5A' }}>
                            {((platformBalanceUser)) > 0 ? (
                                <span>
                                    -${new Intl.NumberFormat('co-CO').format(Number(platformBalanceUser))}
                                </span>
                            ) : (
                                <span>0</span>
                            )}
                        </span>) : (
                        <span style={{ color: '#29BC5A' }}>
                            Actualizando......
                        </span>)}
                    </span>
                </li>
                )}
                {remainingPlatformBalanceUser > 0 && (
                    <li style={{ color: '#29BC5A' }}>
                        <span style={{ float: 'left'}}>
                            <small>Saldo en Verdemi para futuros mercados</small>
                        </span>
                        <span style={{ float: 'right'}}>
                            <small>
                                {nextWeekActiveOrder &&
                                orderLoading === false &&
                                nextWeekActiveOrder.date !== null &&
                                subscriptionUpdateLoading === false ? (
                                <span style={{ color: '#29BC5A' }}>
                                    {(Number(nextWeekActiveOrder.products_value) - Number(nextWeekActiveOrder.products_amont_to_pay)) > 0 ? (
                                        <span>
                                            ${new Intl.NumberFormat('co-CO').format(Number(remainingPlatformBalanceUser))}
                                        </span>
                                    ) : (
                                        <span>0</span>
                                    )}
                                </span>) : (
                                <span style={{ color: '#29BC5A' }}>
                                    Actualizando......
                                </span>)}
                            </small>
                        </span>
                    </li>
                )}
                <li>
                    <span style={{ float: 'left'}}>
                        Transporte
                    </span>
                    <span style={{ float: 'right'}}>
                        {nextWeekActiveOrder &&
                        orderLoading === false &&
                        nextWeekActiveOrder.date !== null &&
                        subscriptionUpdateLoading === false ? (<span>${new Intl.NumberFormat('co-CO').format(Number(nextWeekActiveOrder.transport_charge))}</span>) : (<span>Actualizando......</span>)}
                    </span>
                </li>
                <li>
                    <span style={{ float: 'left'}}>
                        <b>Total a pagar</b>
                    </span>
                    <span style={{ float: 'right'}}>
                        <b>
                            {nextWeekActiveOrder &&
                            orderLoading === false &&
                            nextWeekActiveOrder.date !== null &&
                            subscriptionUpdateLoading === false ? (<span>${new Intl.NumberFormat('co-CO').format(Math.round(Number(Number(nextWeekActiveOrder.products_amont_to_pay) + nextWeekActiveOrder.transport_charge - platformBalanceUser)))}</span>) : (<span>Actualizando......</span>)}                                                                                                                    
                        </b>
                    </span>
                </li>

                <hr style={{ backgroundColor: '#29bc5a' }}></hr>
                <li>Donación de Verdemi a la Fundación Fiambre {nextWeekActiveOrder &&
                                                                orderLoading === false &&
                                                                nextWeekActiveOrder.date !== null &&
                                                                subscriptionUpdateLoading === false ? (<span>${new Intl.NumberFormat('co-CO').format(Number(nextWeekActiveOrder.platform_donation))}</span>) : (<span>Actualizando......</span>)}</li>
            </ul>
        </div>    
    )
}
