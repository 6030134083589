export const SUBSCRIPTION_ORDER_DETAIL_REQUEST = 'SUBSCRIPTION_ORDER_DETAIL_REQUEST'
export const SUBSCRIPTION_ORDER_DETAIL_SUCCESS = 'SUBSCRIPTION_ORDER_DETAIL_SUCCESS'
export const SUBSCRIPTION_ORDER_DETAIL_FAILS = 'SUBSCRIPTION_ORDER_DETAIL_FAILS'
export const SUBSCRIPTION_ORDER_DETAIL_RESET = 'SUBSCRIPTION_ORDER_DETAIL_RESET'

export const SUBSCRIPTION_ORDER_ID_REQUEST = 'SUBSCRIPTION_ORDER_ID_REQUEST'
export const SUBSCRIPTION_ORDER_ID_SUCCESS = 'SUBSCRIPTION_ORDER_ID_SUCCESS'
export const SUBSCRIPTION_ORDER_ID_FAILS = 'SUBSCRIPTION_ORDER_ID_FAILS'
export const SUBSCRIPTION_ORDER_ID_RESET = 'SUBSCRIPTION_ORDER_ID_RESET'

export const SUBSCRIPTION_ORDER_UPDATE_PRODUCT_REQUEST = 'SUBSCRIPTION_ORDER_UPDATE_PRODUCT_REQUEST'
export const SUBSCRIPTION_ORDER_UPDATE_PRODUCT_SUCCESS = 'SUBSCRIPTION_ORDER_UPDATE_PRODUCT_SUCCESS'
export const SUBSCRIPTION_ORDER_UPDATE_PRODUCT_FAILS = 'SUBSCRIPTION_ORDER_UPDATE_PRODUCT_FAILS'
export const SUBSCRIPTION_ORDER_UPDATE_PRODUCT_RESET = 'SUBSCRIPTION_ORDER_UPDATE_PRODUCT_RESET'

export const SUBSCRIPTION_ORDER_UPDATE_STATUS_REQUEST = 'SUBSCRIPTION_ORDER_UPDATE_STATUS_REQUEST'
export const SUBSCRIPTION_ORDER_UPDATE_STATUS_SUCCESS = 'SUBSCRIPTION_ORDER_UPDATE_STATUS_SUCCESS'
export const SUBSCRIPTION_ORDER_UPDATE_STATUS_FAILS = 'SUBSCRIPTION_ORDER_UPDATE_STATUS_FAILS'
export const SUBSCRIPTION_ORDER_UPDATE_STATUS_RESET = 'SUBSCRIPTION_ORDER_UPDATE_STATUS_RESET'

export const SUBSCRIPTION_ORDER_UPDATE_DATE_REQUEST = 'SUBSCRIPTION_ORDER_UPDATE_DATE_REQUEST'
export const SUBSCRIPTION_ORDER_UPDATE_DATE_SUCCESS = 'SUBSCRIPTION_ORDER_UPDATE_DATE_SUCCESS'
export const SUBSCRIPTION_ORDER_UPDATE_DATE_FAILS = 'SUBSCRIPTION_ORDER_UPDATE_DATE_FAILS'
export const SUBSCRIPTION_ORDER_UPDATE_DATE_RESET = 'SUBSCRIPTION_ORDER_UPDATE_DATE_RESET'

export const NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_REQUEST = 'NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_REQUEST'
export const NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS = 'NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS'
export const NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_FAILS = 'NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_FAILS'
export const NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_RESET = 'NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_RESET'

export const CREATE_DELIVERY_ISSUE_REQUEST = 'CREATE_DELIVERY_ISSUE_REQUEST'
export const CREATE_DELIVERY_ISSUE_SUCCESS = 'CREATE_DELIVERY_ISSUE_SUCCESS'
export const CREATE_DELIVERY_ISSUE_FAILS = 'CREATE_DELIVERY_ISSUE_FAILS'
export const CREATE_DELIVERY_ISSUE_RESET = 'CREATE_DELIVERY_ISSUE_RESET'

export const COMPLETE_SUBSCRIPTION_ORDER_REQUEST = 'COMPLETE_SUBSCRIPTION_ORDER_REQUEST'
export const COMPLETE_SUBSCRIPTION_ORDER_SUCCESS = 'COMPLETE_SUBSCRIPTION_ORDER_SUCCESS'
export const COMPLETE_SUBSCRIPTION_ORDER_FAILS = 'COMPLETE_SUBSCRIPTION_ORDER_FAILS'
export const COMPLETE_SUBSCRIPTION_ORDER_RESET = 'COMPLETE_SUBSCRIPTION_ORDER_RESET'

export const CREATE_CLIENT_ISSUE_REQUEST = 'CREATE_CLIENT_ISSUE_REQUEST'
export const CREATE_CLIENT_ISSUE_SUCCESS = 'CREATE_CLIENT_ISSUE_SUCCESS'
export const CREATE_CLIENT_ISSUE_FAILS = 'CREATE_CLIENT_ISSUE_FAILS'
export const CREATE_CLIENT_ISSUE_RESET = 'CREATE_CLIENT_ISSUE_RESET'

export const CREATE_DELIVERY_ISSUE_RESPONSE_REQUEST = 'CREATE_DELIVERY_ISSUE_RESPONSE_REQUEST'
export const CREATE_DELIVERY_ISSUE_RESPONSE_SUCCESS = 'CREATE_DELIVERY_ISSUE_RESPONSE_SUCCESS'
export const CREATE_DELIVERY_ISSUE_RESPONSE_FAILS = 'CREATE_DELIVERY_ISSUE_RESPONSE_FAILS'
export const CREATE_DELIVERY_ISSUE_RESPONSE_RESET = 'CREATE_DELIVERY_ISSUE_RESPONSE_RESET'

export const CREATE_CLIENT_ISSUE_RESPONSE_REQUEST = 'CREATE_CLIENT_ISSUE_RESPONSE_REQUEST'
export const CREATE_CLIENT_ISSUE_RESPONSE_SUCCESS = 'CREATE_CLIENT_ISSUE_RESPONSE_SUCCESS'
export const CREATE_CLIENT_ISSUE_RESPONSE_FAILS = 'CREATE_CLIENT_ISSUE_RESPONSE_FAILS'
export const CREATE_CLIENT_ISSUE_RESPONSE_RESET = 'CREATE_CLIENT_ISSUE_RESPONSE_RESET'

export const UPDATE_PAST_ORDERS_STATUS_REQUEST = 'UPDATE_PAST_ORDERS_STATUS_REQUEST'
export const UPDATE_PAST_ORDERS_STATUS_SUCCESS = 'UPDATE_PAST_ORDERS_STATUS_SUCCESS'
export const UPDATE_PAST_ORDERS_STATUS_FAILS = 'UPDATE_PAST_ORDERS_STATUS_FAILS'
export const UPDATE_PAST_ORDERS_STATUS_RESET = 'UPDATE_PAST_ORDERS_STATUS_RESET'

export const GET_PAST_ACTIVE_ORDERS_REQUEST = 'GET_PAST_ACTIVE_ORDERS_REQUEST'
export const GET_PAST_ACTIVE_ORDERS_SUCCESS = 'GET_PAST_ACTIVE_ORDERS_SUCCESS'
export const GET_PAST_ACTIVE_ORDERS_FAILS = 'GET_PAST_ACTIVE_ORDERS_FAILS'
export const GET_PAST_ACTIVE_ORDERS_RESET = 'GET_PAST_ACTIVE_ORDERS_RESET'

export const SEND_ORDERS_NOTIFICATIONS_REQUEST = 'SEND_ORDERS_NOTIFICATIONS_REQUEST'
export const SEND_ORDERS_NOTIFICATIONS_SUCCESS = 'SEND_ORDERS_NOTIFICATIONS_SUCCESS'
export const SEND_ORDERS_NOTIFICATIONS_FAILS = 'SEND_ORDERS_NOTIFICATIONS_FAILS'
export const SEND_ORDERS_NOTIFICATIONS_RESET = 'SEND_ORDERS_NOTIFICATIONS_RESET'

export const LOCAL_ORDER_CHANGE_AMOUNT_ITEM = 'LOCAL_ORDER_CHANGE_AMOUNT_ITEM'

export const LOCAL_ORDER_FROM_LOCAL_SUBSCRIPTION = 'LOCAL_ORDER_FROM_LOCAL_SUBSCRIPTION'

export const REMOVE_LOCAL_ORDER = 'REMOVE_LOCAL_ORDER'

export const CHECK_LOCAL_ORDER_VALUE = 'CHECK_LOCAL_ORDER_VALUE'

export const SUBSCRIPTION_ORDER_PAYMENT_DETAIL_REQUEST = 'SUBSCRIPTION_ORDER_PAYMENT_DETAIL_REQUEST'
export const SUBSCRIPTION_ORDER_PAYMENT_DETAIL_SUCCESS = 'SUBSCRIPTION_ORDER_PAYMENT_DETAIL_SUCCESS'
export const SUBSCRIPTION_ORDER_PAYMENT_DETAIL_FAILS = 'SUBSCRIPTION_ORDER_PAYMENT_DETAIL_FAILS'
export const SUBSCRIPTION_ORDER_PAYMENT_DETAIL_RESET = 'SUBSCRIPTION_ORDER_PAYMENT_DETAIL_RESET'

export const MARK_DELIVERED_TODAY_ORDERS_REQUEST = 'MARK_DELIVERED_TODAY_ORDERS_REQUEST'
export const MARK_DELIVERED_TODAY_ORDERS_FAILS = 'MARK_DELIVERED_TODAY_ORDERS_FAILS'
export const MARK_DELIVERED_TODAY_ORDERS_SUCCESS = 'MARK_DELIVERED_TODAY_ORDERS_SUCCESS'
export const MARK_DELIVERED_TODAY_ORDERS_RESET = 'MARK_DELIVERED_TODAY_ORDERS_RESET'

export const DELIVERED_SUBSCRIPTION_ORDERS_REQUEST = 'DELIVERED_SUBSCRIPTION_ORDERS_REQUEST'
export const DELIVERED_SUBSCRIPTION_ORDERS_SUCCESS = 'DELIVERED_SUBSCRIPTION_ORDERS_SUCCESS'
export const DELIVERED_SUBSCRIPTION_ORDERS_FAILS = 'DELIVERED_SUBSCRIPTION_ORDERS_FAILS'
export const DELIVERED_SUBSCRIPTION_ORDERS_RESET = 'DELIVERED_SUBSCRIPTION_ORDERS_RESET'

export const MARK_COMPLETED_ORDERS_STATUS_REQUEST = 'MARK_COMPLETED_ORDERS_STATUS_REQUEST'
export const MARK_COMPLETED_ORDERS_STATUS_SUCCESS = 'MARK_COMPLETED_ORDERS_STATUS_SUCCESS'
export const MARK_COMPLETED_ORDERS_STATUS_FAILS = 'MARK_COMPLETED_ORDERS_STATUS_FAILS'
export const MARK_COMPLETED_ORDERS_STATUS_RESET = 'MARK_COMPLETED_ORDERS_STATUS_RESET'

export const DELIVERED_SUBSCRIPTION_ORDER_DETAIL_REQUEST = 'DELIVERED_SUBSCRIPTION_ORDER_DETAIL_REQUEST'
export const DELIVERED_SUBSCRIPTION_ORDER_DETAIL_SUCCESS = 'DELIVERED_SUBSCRIPTION_ORDER_DETAIL_SUCCESS'
export const DELIVERED_SUBSCRIPTION_ORDER_DETAIL_FAILS = 'DELIVERED_SUBSCRIPTION_ORDER_DETAIL_FAILS'
export const DELIVERED_SUBSCRIPTION_ORDER_DETAIL_RESET = 'DELIVERED_SUBSCRIPTION_ORDER_DETAIL_RESET'

export const PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_REQUEST = 'PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_REQUEST'
export const PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_SUCCESS = 'PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_SUCCESS'
export const PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_FAILS = 'PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_FAILS'
export const PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_RESET = 'PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_RESET'

export const PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_REQUEST = 'PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_REQUEST'
export const PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_SUCCESS = 'PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_SUCCESS'
export const PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_FAILS = 'PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_FAILS'
export const PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_RESET = 'PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_RESET'

export const PAYMENT_REMINDER_REQUEST = 'PAYMENT_REMINDER_REQUEST'
export const PAYMENT_REMINDER_SUCCESS = 'PAYMENT_REMINDER_SUCCESS'
export const PAYMENT_REMINDER_FAILS = 'PAYMENT_REMINDER_FAILS'
export const PAYMENT_REMINDER_RESET = 'PAYMENT_REMINDER_RESET'

export const GET_DELIVERY_ORDERS_REQUEST = 'GET_DELIVERY_ORDERS_REQUEST'
export const GET_DELIVERY_ORDERS_SUCCESS = 'GET_DELIVERY_ORDERS_SUCCESS'
export const GET_DELIVERY_ORDERS_FAILS = 'GET_DELIVERY_ORDERS_FAILS'
export const GET_DELIVERY_ORDERS_RESET = 'GET_DELIVERY_ORDERS_RESET'

export const GET_DELIVERY_ORDERS_REQUEST_STAFF = 'GET_DELIVERY_ORDERS_REQUEST_STAFF'
export const GET_DELIVERY_ORDERS_SUCCESS_STAFF = 'GET_DELIVERY_ORDERS_SUCCESS_STAFF'
export const GET_DELIVERY_ORDERS_FAILS_STAFF = 'GET_DELIVERY_ORDERS_FAILS_STAFF'
export const GET_DELIVERY_ORDERS_RESET_STAFF = 'GET_DELIVERY_ORDERS_RESET_STAFF'


export const BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_REQUEST = 'BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_REQUEST'
export const BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS = 'BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS'
export const BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_FAILS = 'BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_FAILS'
export const BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_RESET = 'BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_RESET'

export const BLOCK_FOR_CHANGES_ORDERS_REQUEST = 'BLOCK_FOR_CHANGES_ORDERS_REQUEST'
export const BLOCK_FOR_CHANGES_ORDERS_SUCCESS = 'BLOCK_FOR_CHANGES_ORDERS_SUCCESS'
export const BLOCK_FOR_CHANGES_ORDERS_FAILS = 'BLOCK_FOR_CHANGES_ORDERS_FAILS'
export const BLOCK_FOR_CHANGES_ORDERS_RESET = 'BLOCK_FOR_CHANGES_ORDERS_RESET'

export const BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_REQUEST = 'BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_REQUEST'
export const BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_SUCCESS = 'BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_SUCCESS'
export const BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_FAILS = 'BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_FAILS'
export const BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_RESET = 'BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_RESET'
