import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Spinner } from 'react-bootstrap'
import ReactPixel from 'react-facebook-pixel';
import { 
    subscriptionUpdateStatusAction,
    subscriptionCheckActiveStatusAction,
    } from '../../../../actions/subscriptionActions'


export const ActivateSubscriptionButton = (props) => {

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { loading:subscriptionDetailLoading, subscription } = detailSubscription

    const updateStatusSubscription = useSelector(state => state.subscriptionUpdateStatus)
    const { loading } = updateStatusSubscription

    const dispatch = useDispatch()

    const activateSubscription = () => {
        dispatch(subscriptionUpdateStatusAction(subscription.id, 4))
        props.setActivateSubscriptionRequested(true)
        dispatch(subscriptionCheckActiveStatusAction())
        if (window.location.origin.includes('localhost') === false && window.location.origin.includes('qa') === false) {
            ReactPixel.track('ActiveSubscription');
        }
    }

    useState(() => {},[
        loading
    ])

    return (
        <div>
            <Button
                disabled={loading || subscriptionDetailLoading}
                variant='outline-info'
                className="my-3 btn-block btn-lg h-70" onClick={activateSubscription}
            >
                {loading || subscriptionDetailLoading ? (
                    <Spinner
                        animation='border'
                        role='status'
                        style={{
                            height:'25px',
                            width:'25px',
                            margin:'auto',
                            display:'block'
                        }}
                    >
                        <span className='sr-only'>
                            Loading...
                        </span>
                    </Spinner>
                ) : (
                    <p className='m-0 p-0'><i className="fas fa-link"></i> Pago contra entrega manual</p>
                )}
            </Button>
            <p className='m-n1 p-0'><small><small>Después de entregar cada mercado recibirás un link de pago (PSE, Nequi, tarjeta de crédito/débito)</small></small></p>
        </div>
    )
}
