import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
import { LazyLoadImage } from "react-lazy-load-image-component";

export const ProductOrderDetailImage = ({product}) => {

    return (
        <div>
            {product ? (
                <LazyLoadImage
                    className='rounded-circle no-logo-img'
                    style={{height: '2rem', width: '2rem'}}
                    src={product.image_32}
                    alt={product.name}
                />
            ) : (
                <Spinner animation="grow" variant="success" />
            )}
        </div>
    )
}
