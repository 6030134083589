import React, { useEffect, useState } from 'react'
import { Row, Form, Button, Spinner, ButtonGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../Message' 
import {
    createdOrderChangeAmountItemAction,
    localOrderAction,
    nextWeekSubscriptionOrderIdAction } from '../../../../actions/orderActions'

export const OrderButton = ({product}) => {
    const [inOrder, setInOrder] = useState(false)
    const [inLocalOrder, setInLocalOrder] = useState(false)
    const [reduceAmount] = useState(0)
    const [qty, setQty] = useState(1)
    const [localQty, setLocalQty] = useState(1)

    const orderInfo = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
    const { nextWeekActiveOrder } = orderInfo

    const orderIdNextWeekActiveSubscription = useSelector(state => state.nextWeekActiveSubscriptionOderId)
    const { loading: orderIdLoading, nextWeekActiveOrderId } = orderIdNextWeekActiveSubscription

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const orderUpdate = useSelector(state => state.updateCreatedSubscriptionOderProduct)
    const { loading: loadingUpdateOrder, error: errorUpdateOrder, updating_product } = orderUpdate

    const orderLocal = useSelector(state => state.localOrder)
    const { localProductsItems, updatedFromServer } = orderLocal

    const dispatch = useDispatch()

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createdOrderChangeAmountItemAction(nextWeekActiveOrderId, product.id, 1))
        dispatch(localOrderAction(product, 1, product.price, true))
    }

    const removeHandler = (e) => {
        e.preventDefault()
        dispatch(createdOrderChangeAmountItemAction(nextWeekActiveOrderId, product.id, Number(qty * -1)))
        for(var i = 0; i < localProductsItems.length; i++){
            if (localProductsItems[i].products.id === product.id && updatedFromServer === true) {
                dispatch(localOrderAction(product, 0, 0, true))
            }
        }
    }

    const reduceAmountHandler = () => {
        for(var i = 0; i < localProductsItems.length; i++){
            if (localProductsItems[i].products.id === product.id && updatedFromServer === true) {
                dispatch(localOrderAction(localProductsItems[i].products, localProductsItems[i].amount - 1, localProductsItems[i].products.price * (localProductsItems[i].amount - 1), true))
            }
        }
        if (reduceAmount === 0) {
            setInOrder(false)
        }
        dispatch(createdOrderChangeAmountItemAction(nextWeekActiveOrderId, product.id, -1))
    }

    const addAmountHandler = () => {
        for(var i = 0; i < localProductsItems.length; i++){
            if (localProductsItems[i].products.id === product.id && updatedFromServer === true) {
                dispatch(localOrderAction(localProductsItems[i].products, localProductsItems[i].amount + 1, localProductsItems[i].products.price * (localProductsItems[i].amount + 1), true))
            }
        }
        dispatch(createdOrderChangeAmountItemAction(nextWeekActiveOrderId, product.id, 1))
    }


    useEffect(() => {
        if(nextWeekActiveOrderId === null && orderIdLoading === false && user) {
            dispatch(nextWeekSubscriptionOrderIdAction())
        }
        setInOrder(false)
        if(nextWeekActiveOrder && nextWeekActiveOrder.products_items && nextWeekActiveOrder.products_items.length > 0) {
            const itemInOrder = nextWeekActiveOrder.products_items.filter(x => x.products.id === product.id)
            if (itemInOrder) {
                if (itemInOrder[0]) {
                    if (itemInOrder[0].amount) {
                        if (itemInOrder[0].amount > 0) {
                            setQty(itemInOrder[0].amount)
                            setInOrder(true)
                        } 
                    }    
                }
            } 
        }

        setInLocalOrder(false)
        if(localProductsItems && localProductsItems.length > 0) {
            const itemInLocalOrder = localProductsItems.filter(x => x.products.id === product.id)
            if (itemInLocalOrder) {
                if (itemInLocalOrder[0]) {
                    if (itemInLocalOrder[0].amount) {
                        if (itemInLocalOrder[0].amount > 0) {
                            setLocalQty(itemInLocalOrder[0].amount)
                            setInLocalOrder(true)
                        } 
                    }    
                }
            }
        }

    }, [
        nextWeekActiveOrder,
        product,
        localProductsItems,
        dispatch,
        nextWeekActiveOrderId,
        orderIdLoading,
        user])

    return (
        <div>
            {errorUpdateOrder && (<Message variant='danger'>{errorUpdateOrder}</Message>)}
            {(
                <div>
                    {product.availability > 0 ? (
                        <div>
                            {updatedFromServer ? (
                                <div>
                                    {inLocalOrder ? (
                                        <div>
                                            <Row>
                                                <ButtonGroup aria-label="Basic example" className='btn-block' size="lg">
                                                    <Button variant="outline-success"className='border-right-0' onClick={reduceAmountHandler}>-</Button>
                                                    <Button variant="outline-success" className='border-right-0 border-left-0'>{localQty}</Button>
                                                    <Button variant="outline-success" className='border-left-0' onClick={addAmountHandler}>+</Button>
                                                </ButtonGroup>
                                            </Row>
                                            <Row className="justify-content-md-center">
                                                <Form onSubmit={removeHandler}>
                                                        <Form.Group controlId='reduce' className='d-none'>
                                                            <Form.Control
                                                                readOnly
                                                                type='number'
                                                                value={reduceAmount}
                                                            >
                                                            </Form.Control>
                                                        </Form.Group>

                                                        <Form.Control as="select" htmlSize={3} custom  className='d-none'>
                                                            <option value={product.name}>{product.name}</option>
                                                        </Form.Control>

                                                        {/* <Form.Select readOnly aria-label="Default select example" className='d-none'>
                                                            <option value={product.name}>{product.name}</option>
                                                        </Form.Select> */}
                                                        <Button className='text-danger btn-sm' type='submit' variant='link'>Eliminar</Button>
                                                </Form>                                    
                                            </Row>
                                        </div>                        
                                ) : (
                                    <Row className='d-flex justify-content-center'>
                                        <Form onSubmit={submitHandler}>
                
                                            <Form.Control as="select" htmlSize={3} custom  className='d-none'>
                                                <option value={product.name}>{product.name}</option>
                                            </Form.Control>
    
                                            {/* <Form.Select readOnly aria-label="Default select example" className='d-none'>
                                                <option value={product.name}>{product.name}</option>
                                            </Form.Select> */}
                                            <Button variant="success" type="submit" className='rounded-pill px- py-1 mx-xs-n1 mx-sm-n3' size='lg'>
                                                Añadir a pedido
                                            </Button>                                
                                        </Form>
                                    </Row>
                                    )
                                } 
                                </div>
                            ) : (
                                <div>
                                    {inOrder ? (
                                        <div>
                                            {loadingUpdateOrder ? (
                                                <div>
                                                {product.id === updating_product ? (
                                                    <Spinner
                                                    animation='border'
                                                    role='status'
                                                    style={{
                                                        height:'25px',
                                                        width:'25px',
                                                        margin:'auto',
                                                        display:'block'
                                                    }}
                                                >
                                                    <span className='sr-only'>
                                                        Loading...
                                                    </span>
                                                    </Spinner>
                                                ) : (
                                                    <div>
                                                        <Row>
                                                            <ButtonGroup aria-label="Basic example" className='btn-block' size="lg">
                                                                <Button variant="outline-success"className='border-right-0' onClick={reduceAmountHandler}>-</Button>
                                                                <Button variant="outline-success" className='border-right-0 border-left-0'>{qty}</Button>
                                                                <Button variant="outline-success" className='border-left-0' onClick={addAmountHandler}>+</Button>
                                                            </ButtonGroup>
                                                        </Row>
                                                        <Row className="justify-content-md-center">
                                                            <Form onSubmit={removeHandler}>
                                                                <Form.Group controlId='reduce' className='d-none'>
                                                                    <Form.Control
                                                                        readOnly
                                                                        type='number'
                                                                        value={reduceAmount}
                                                                    >
                                                                    </Form.Control>
                                                                </Form.Group>
        
                                                                <Form.Control as="select" htmlSize={3} custom  className='d-none'>
                                                                    <option value={product.name}>{product.name}</option>
                                                                </Form.Control>
        
                                                                {/* <Form.Select readOnly aria-label="Default select example" className='d-none'>
                                                                    <option value={product.name}>{product.name}</option>
                                                                </Form.Select> */}
                                                                <Button disabled className='text-danger btn-sm' type='submit' variant='link'>Eliminar</Button>
                                                            </Form>                                    
                                                        </Row>
                                                    </div>
                                                )}
                                                </div>
                                                ) :  (
                                                <div>
                                                    <Row>
                                                        <ButtonGroup aria-label="Basic example" className='btn-block' size="lg">
                                                            <Button variant="outline-success"className='border-right-0' onClick={reduceAmountHandler}>-</Button>
                                                            <Button variant="outline-success" className='border-right-0 border-left-0'>{qty}</Button>
                                                            <Button variant="outline-success" className='border-left-0' onClick={addAmountHandler}>+</Button>
                                                        </ButtonGroup>
                                                    </Row>
                                                    <Row className="justify-content-md-center">
                                                        <Form onSubmit={removeHandler}>
                                                                <Form.Group controlId='reduce' className='d-none'>
                                                                    <Form.Control
                                                                        readOnly
                                                                        type='number'
                                                                        value={reduceAmount}
                                                                    >
                                                                    </Form.Control>
                                                                </Form.Group>
        
                                                                <Form.Control as="select" htmlSize={3} custom  className='d-none'>
                                                                    <option value={product.name}>{product.name}</option>
                                                                </Form.Control>
        
                                                                {/* <Form.Select readOnly aria-label="Default select example" className='d-none'>
                                                                    <option value={product.name}>{product.name}</option>
                                                                </Form.Select> */}
                                                                <Button className='text-danger btn-sm' type='submit' variant='link'>Eliminar</Button>
                                                        </Form>                                    
                                                    </Row>
                                                </div>                        
                                                )
                                            }
                                        </div>
                                ) : (
                                    <Row className='d-flex justify-content-center'>
                                        <Form onSubmit={submitHandler}>
                
                                            <Form.Control as="select" htmlSize={3} custom  className='d-none'>
                                                <option value={product.name}>{product.name}</option>
                                            </Form.Control>
    
                                            {/* <Form.Select readOnly aria-label="Default select example" className='d-none'>
                                                <option value={product.name}>{product.name}</option>
                                            </Form.Select> */}
                                            {loadingUpdateOrder ? (
                                                <div>
                                                    {product.id === updating_product ? (
                                                    <Spinner
                                                    animation='border'
                                                    role='status'
                                                    style={{
                                                        height:'25px',
                                                        width:'25px',
                                                        margin:'auto',
                                                        display:'block'
                                                    }}
                                                    >
                                                        <span className='sr-only'>
                                                            Loading...
                                                        </span>
                                                    </Spinner>
                                                ) : (
                                                    <Button disabled variant="success" type="submit" className='rounded-pill px- py-1 mx-n3' size='lg'>
                                                        Añadir a pedido
                                                    </Button>                                
                                                )}
    
                                                </div>
                                            ) : (
                                                <Button variant="success" type="submit" className='rounded-pill px- py-1 mx-xs-n1 mx-sm-n3' size='lg'>
                                                    Añadir a pedido
                                                </Button>                                
                                            )}
                                        </Form>
                                    </Row>
                                    )
                                } 
    
                                </div>
                            )}
                        </div>
                        ) : (
                            <div className="d-flex justify-content-center">
                                <h3>
                                    <span className="badge badge-danger">Agotado</span>
                                </h3>
                            </div>
                        )}
                </div>
            )}            
        </div>
    )
}

