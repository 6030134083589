export const SUBSCRIPTION_DETAIL_REQUEST = 'SUBSCRIPTION_DETAIL_REQUEST'
export const SUBSCRIPTION_DETAIL_SUCCESS = 'SUBSCRIPTION_DETAIL_SUCCESS'
export const SUBSCRIPTION_DETAIL_FAILS = 'SUBSCRIPTION_DETAIL_FAILS'
export const SUBSCRIPTION_DETAIL_RESET = 'SUBSCRIPTION_DETAIL_RESET'

export const SUBSCRIPTION_UPDATE_REQUEST = 'SUBSCRIPTION_UPDATE_REQUEST'
export const SUBSCRIPTION_UPDATE_SUCCESS = 'SUBSCRIPTION_UPDATE_SUCCESS'
export const SUBSCRIPTION_UPDATE_FAILS = 'SUBSCRIPTION_UPDATE_FAILS'
export const SUBSCRIPTION_UPDATE_RESET = 'SUBSCRIPTION_UPDATE_RESET'

export const FREQUENCY_DAYS_REQUEST = 'FREQUENCY_DAYS_REQUEST'
export const FREQUENCY_DAYS_SUCCESS = 'FREQUENCY_DAYS_SUCCESS'
export const FREQUENCY_DAYS_FAILS = 'FREQUENCY_DAYS_FAILS'

export const CREATE_FREQUENCY_REQUEST = 'CREATE_FREQUENCY_REQUEST'
export const CREATE_FREQUENCY_SUCCESS = 'CREATE_FREQUENCY_SUCCESS'
export const CREATE_FREQUENCY_FAILS = 'CREATE_FREQUENCY_FAILS'
export const CREATE_FREQUENCY_RESET = 'CREATE_FREQUENCY_RESET'

export const SUBSCRIPTION_UPDATE_STATUS_REQUEST = 'SUBSCRIPTION_UPDATE_STATUS_REQUEST'
export const SUBSCRIPTION_UPDATE_STATUS_SUCCESS = 'SUBSCRIPTION_UPDATE_STATUS_SUCCESS'
export const SUBSCRIPTION_UPDATE_STATUS_FAILS = 'SUBSCRIPTION_UPDATE_STATUS_FAILS'
export const SUBSCRIPTION_UPDATE_STATUS_RESET = 'SUBSCRIPTION_UPDATE_STATUS_RESET'

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS'
export const CANCEL_SUBSCRIPTION_FAILS = 'CANCEL_SUBSCRIPTION_FAILS'
export const CANCEL_SUBSCRIPTION_RESET = 'CANCEL_SUBSCRIPTION_RESET'


export const SUBSCRIPTION_CHECK_ACTIVE_STATUS_REQUEST = 'SUBSCRIPTION_CHECK_ACTIVE_STATUS_REQUEST'
export const SUBSCRIPTION_CHECK_STATUS_ACTIVE_SUCCESS = 'SUBSCRIPTION_CHECK_STATUS_ACTIVE_SUCCESS'
export const SUBSCRIPTION_CHECK_STATUS_ACTIVE_FAILS = 'SUBSCRIPTION_CHECK_STATUS_ACTIVE_FAILS'
export const SUBSCRIPTION_CHECK_STATUS_ACTIVE_RESET = 'SUBSCRIPTION_CHECK_STATUS_ACTIVE_RESET'

export const UPDATE_FREQUENCY_REQUEST = 'UPDATE_FREQUENCY_REQUEST'
export const UPDATE_FREQUENCY_SUCCESS = 'UPDATE_FREQUENCY_SUCCESS'
export const UPDATE_FREQUENCY_FAILS = 'UPDATE_FREQUENCY_FAILS'
export const UPDATE_FREQUENCY_RESET = 'UPDATE_FREQUENCY_RESET'

export const SUBSCRIPTION_PAUSE_REQUEST = 'SUBSCRIPTION_PAUSE_REQUEST'
export const SUBSCRIPTION_PAUSE_SUCCESS = 'SUBSCRIPTION_PAUSE_SUCCESS'
export const SUBSCRIPTION_PAUSE_FAILS = 'SUBSCRIPTION_PAUSE_FAILS'
export const SUBSCRIPTION_PAUSE_RESET = 'SUBSCRIPTION_PAUSE_RESET'

export const SUBSCRIPTION_PRODUCTS_MODAL_OPEN = 'SUBSCRIPTION_PRODUCTS_MODAL_OPEN'
export const SUBSCRIPTION_PRODUCTS_MODAL_CLOSE = 'SUBSCRIPTION_PRODUCTS_MODAL_CLOSE'

export const LOCAL_SUBSCRIPTION_CHANGE_AMOUNT_ITEM = 'LOCAL_SUBSCRIPTION_CHANGE_AMOUNT_ITEM'

export const REMOVE_LOCAL_SUBSCRIPTION = 'REMOVE_LOCAL_SUBSCRIPTION'

export const ENABLE_CHECK_LOCAL_SUBSCRIPTION = 'ENABLE_CHECK_LOCAL_SUBSCRIPTION'
export const DISABLE_CHECK_LOCAL_SUBSCRIPTION = 'DISABLE_CHECK_LOCAL_SUBSCRIPTION'

export const SUBSCRIPTION_ID = 'SUBSCRIPTION_ID'
