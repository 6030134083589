import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ModalChangeSubscriptionProducts } from './ModalChangeSubscriptionProducts'
import { Button } from 'react-bootstrap'
import {
    SUBSCRIPTION_PRODUCTS_MODAL_OPEN,
     } from '../../../../constants/subscriptionConstants'


export const ChangeSubscriptionProductsModalButton = () => {

    const dispatch = useDispatch()

    const modalSubscription = useSelector(state => state.subscriptionModal)
    const { open_modal } = modalSubscription

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    const toggleChangeSubscriptionProductsModal = () => {
        if(open_modal === false) {
            dispatch({type: SUBSCRIPTION_PRODUCTS_MODAL_OPEN})
        }
    }

    useEffect(() => {
        if (subscription) {
            if (subscription.status.name === 'Pending token' && subscription.products.length === 0) {
                toggleChangeSubscriptionProductsModal()
            }
        }
    }, [])

    const BlockedDeliveredNotPaid = useSelector(state => state.BlockedDeliveredNotPaidSubscriptionOderDetail)
    const { Order } = BlockedDeliveredNotPaid


    return (
        <div className='ml-auto'>
            <Button
                onClick={toggleChangeSubscriptionProductsModal}
                style={{ fontSize: '1em'}}
                disabled={Order}
            >
                <i className="fas fa-shopping-cart"></i> Añadir productos
            </Button>
            {open_modal ? (
                <ModalChangeSubscriptionProducts isOpen={open_modal} />
            ) : (
                <div></div>
            )}
        </div>
    )
}
