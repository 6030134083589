export const FORMER_ORDERS_COLUMNS = [
    {
        Header: 'Fecha',
        accessor: 'date',
        Cell: props => <div> <small>{new Intl.DateTimeFormat('es-GB', { 
            month: 'long', 
            day: '2-digit',
            year: 'numeric', 
        }).format(new Date(props.value))}</small></div>
    },
    {
        Header: 'Estado',
        accessor: 'status.name',
        Cell: props => <div> <small>{props.value}</small></div>
    },
    {
        Header: 'Valor productos',
        accessor: 'products_value',
        Cell: props => <p><small>{new Intl.NumberFormat('co-CO').format(Number(props.value))} pesos</small></p>
    },
    {
        Header: 'Donación de Verdemi',
        accessor: 'platform_donation',
        Cell: props => <p><small>{new Intl.NumberFormat('co-CO').format(Number(props.value))} pesos</small></p>
    },
    {
        Header: 'Pago transporte',
        accessor: 'transport_charge',
        Cell: props => <p><small>{new Intl.NumberFormat('co-CO').format(Number(props.value))} pesos</small></p>
    },
    {
        Header: 'Monto pagado por productos',
        accessor: 'products_amont_to_pay',
        Cell: props => <p><small>{new Intl.NumberFormat('co-CO').format(Number(props.value))} pesos</small></p>
    },
    {
        Header: 'Productos',
        accessor: row => row.products_items && row.products_items.length > 0 ? row.products_items : row.products_items_previous,
        Cell: props => {
            const productsItems = props.value;
            return (
                <ul className='pl-1'>
                    {productsItems.map(productItem => (
                        <small key={productItem.products.name}>
                            <li>
                                {productItem.amount} {productItem.products.name} (
                                {new Intl.NumberFormat('co-CO').format(Number(productItem.total_value))} pesos)
                            </li>
                        </small>
                    ))}
                </ul>
            );
        },
    }
]

// {
//     Header: 'Productos',
//     accessor: 'products_items',
//     Cell: props => <ul className='pl-1'> {props.value.map(product_item => (
//         <small><li>{product_item.amount} {product_item.products.name} ({new Intl.NumberFormat('co-CO').format(Number(product_item.total_value))} pesos)</li></small>
//     ))} </ul>,
// }