import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api"
import { userUpdateAddressAction } from '../../../../../actions/userActions'
import Message from '../../../../Message' 
import UserAddressMap from '../mobile/UserAddressMap';
import Loader from '../../../../Loader'
import { USER_UPDATE_ADDRESS_RESET } from '../../../../../constants/userConstants';
const { REACT_APP_GMAPS_API_KEY } = process.env

export const ModalChangeAddress = (props) => {

    const dispatch = useDispatch()

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const updateAddressUser = useSelector(state => state.userUpdateAddress)
    const { loading: loadingUpdateAddress, error: errorUpdateAddress, success } = updateAddressUser

    const[lat, setLat] = useState(parseFloat(user.latitude))
    const[lng, setLng] = useState(parseFloat(user.longitude))

    const onMarkerDragEnd = (coord) => {
        console.log(coord.latLng.lat(), coord.latLng.lng(), 'coord from the modal')
        setLat(coord.latLng.lat())
        setLng(coord.latLng.lng())
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: REACT_APP_GMAPS_API_KEY
      })
    

    const MyMapComponent = (((props) =>
        <GoogleMap
            defaultZoom={15}
            defaultCenter={{ lat: lat, lng: lng }}
            options={{streetViewControl: false, fullscreenControl: false, mapTypeControl: false}}
        >
            <Marker 
                position={{ lat: lat, lng: lng }}
                draggable={true}
                onDragEnd={(coord) => onMarkerDragEnd(coord)}
            />
        </GoogleMap>
        ))

    const submitChangeAddressHandler = (e) => {
        e.preventDefault()
        dispatch(userUpdateAddressAction(lat.toString().slice(0, 8), lng.toString().slice(0, 8)))
    }


    useEffect(() => {
        if(success === true) {
            setTimeout(() => {  dispatch({type: USER_UPDATE_ADDRESS_RESET}) }, 1500);
        }        
    }, [
        success
    ])


    return (
        <div>
             <Modal show={props.isOpen} onHide={props.toggle} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title className='text-success'>Ajustar Dirección</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {errorUpdateAddress && (<Message variant='danger'>{errorUpdateAddress}</Message>)}
                {success && (<Message variant='success'>Dirección actualizada</Message>)}
                {loadingUpdateAddress ? (
                    <Loader />                     
                ) : (
                    <span>
                        <UserAddressMap
                            draggableMarker={true}
                            onMarkerDragEnd={onMarkerDragEnd}
                            latLng={[lat, lng]}
                        />
                    </span>
                )}
                </Modal.Body>
                <Modal.Footer>
                    <Form onSubmit={submitChangeAddressHandler}>
                        <Form.Group className="mb-3 d-none" controlId="formlat">
                            <Form.Control type="number" step="0.01" value={lat}/>
                        </Form.Group>
                        <Form.Group className="mb-3 d-none" controlId="formlng">
                            <Form.Control type="number" step="0.01" value={lng}/>
                        </Form.Group>
                            <div>
                                <Button disabled={loadingUpdateAddress} variant="success" type="submit">
                                    {loadingUpdateAddress ? (
                                        <Spinner
                                        animation='border'
                                        role='status'
                                        style={{
                                            height:'25px',
                                            width:'25px',
                                            margin:'auto',
                                            display:'block'
                                        }}
                                        >
                                            <span className='sr-only'>
                                                Loading...
                                            </span>
                                        </Spinner>                            
                                    ) : (
                                        <span>
                                            Modificar Dirección
                                        </span>
                                    )}
                                </Button>
                                <Button variant="secondary" className='ml-3' onClick={props.toggle}>
                                    Cerrar
                                </Button>
                            </div>
                    </Form>
                </Modal.Footer>
            </Modal>            
        </div>
    )
}
