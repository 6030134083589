import {
    SUBSCRIPTION_ORDER_DETAIL_REQUEST,
    SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    SUBSCRIPTION_ORDER_DETAIL_FAILS,
    SUBSCRIPTION_ORDER_DETAIL_RESET,

    SUBSCRIPTION_ORDER_ID_REQUEST,
    SUBSCRIPTION_ORDER_ID_SUCCESS,
    SUBSCRIPTION_ORDER_ID_FAILS,
    SUBSCRIPTION_ORDER_ID_RESET,

    SUBSCRIPTION_ORDER_UPDATE_PRODUCT_FAILS,
    SUBSCRIPTION_ORDER_UPDATE_PRODUCT_SUCCESS,
    SUBSCRIPTION_ORDER_UPDATE_PRODUCT_REQUEST,
    SUBSCRIPTION_ORDER_UPDATE_PRODUCT_RESET,

    SUBSCRIPTION_ORDER_UPDATE_STATUS_FAILS,
    SUBSCRIPTION_ORDER_UPDATE_STATUS_REQUEST,
    SUBSCRIPTION_ORDER_UPDATE_STATUS_RESET,
    SUBSCRIPTION_ORDER_UPDATE_STATUS_SUCCESS,

    NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_FAILS,
    NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_REQUEST,
    NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_RESET,
    NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,

    CREATE_DELIVERY_ISSUE_REQUEST,
    CREATE_DELIVERY_ISSUE_SUCCESS,
    CREATE_DELIVERY_ISSUE_FAILS,
    CREATE_DELIVERY_ISSUE_RESET,

    COMPLETE_SUBSCRIPTION_ORDER_REQUEST,
    COMPLETE_SUBSCRIPTION_ORDER_SUCCESS,
    COMPLETE_SUBSCRIPTION_ORDER_FAILS,
    COMPLETE_SUBSCRIPTION_ORDER_RESET,

    CREATE_CLIENT_ISSUE_REQUEST,
    CREATE_CLIENT_ISSUE_SUCCESS,
    CREATE_CLIENT_ISSUE_FAILS,
    CREATE_CLIENT_ISSUE_RESET,

    CREATE_DELIVERY_ISSUE_RESPONSE_REQUEST,
    CREATE_DELIVERY_ISSUE_RESPONSE_SUCCESS,
    CREATE_DELIVERY_ISSUE_RESPONSE_FAILS,
    CREATE_DELIVERY_ISSUE_RESPONSE_RESET,

    CREATE_CLIENT_ISSUE_RESPONSE_REQUEST,
    CREATE_CLIENT_ISSUE_RESPONSE_SUCCESS,
    CREATE_CLIENT_ISSUE_RESPONSE_FAILS,
    CREATE_CLIENT_ISSUE_RESPONSE_RESET,

    UPDATE_PAST_ORDERS_STATUS_REQUEST,
    UPDATE_PAST_ORDERS_STATUS_SUCCESS,
    UPDATE_PAST_ORDERS_STATUS_FAILS,
    UPDATE_PAST_ORDERS_STATUS_RESET,

    GET_PAST_ACTIVE_ORDERS_REQUEST,
    GET_PAST_ACTIVE_ORDERS_SUCCESS,
    GET_PAST_ACTIVE_ORDERS_FAILS,
    GET_PAST_ACTIVE_ORDERS_RESET,

    SEND_ORDERS_NOTIFICATIONS_REQUEST, 
    SEND_ORDERS_NOTIFICATIONS_SUCCESS,
    SEND_ORDERS_NOTIFICATIONS_FAILS,
    SEND_ORDERS_NOTIFICATIONS_RESET,

    LOCAL_ORDER_CHANGE_AMOUNT_ITEM,

    REMOVE_LOCAL_ORDER,

    SUBSCRIPTION_ORDER_PAYMENT_DETAIL_REQUEST,
    SUBSCRIPTION_ORDER_PAYMENT_DETAIL_SUCCESS,
    SUBSCRIPTION_ORDER_PAYMENT_DETAIL_FAILS,
    SUBSCRIPTION_ORDER_PAYMENT_DETAIL_RESET,

    MARK_DELIVERED_TODAY_ORDERS_REQUEST,
    MARK_DELIVERED_TODAY_ORDERS_FAILS,
    MARK_DELIVERED_TODAY_ORDERS_SUCCESS,
    MARK_DELIVERED_TODAY_ORDERS_RESET,

    DELIVERED_SUBSCRIPTION_ORDERS_REQUEST,
    DELIVERED_SUBSCRIPTION_ORDERS_SUCCESS,
    DELIVERED_SUBSCRIPTION_ORDERS_FAILS,
    DELIVERED_SUBSCRIPTION_ORDERS_RESET,

    MARK_COMPLETED_ORDERS_STATUS_REQUEST,
    MARK_COMPLETED_ORDERS_STATUS_SUCCESS,
    MARK_COMPLETED_ORDERS_STATUS_FAILS,
    MARK_COMPLETED_ORDERS_STATUS_RESET,

    DELIVERED_SUBSCRIPTION_ORDER_DETAIL_REQUEST,
    DELIVERED_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    DELIVERED_SUBSCRIPTION_ORDER_DETAIL_FAILS,
    DELIVERED_SUBSCRIPTION_ORDER_DETAIL_RESET,

    PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_REQUEST,
    PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_FAILS,
    PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_RESET,   

    PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_REQUEST,
    PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_SUCCESS,
    PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_FAILS,
    PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_RESET,

    PAYMENT_REMINDER_REQUEST,
    PAYMENT_REMINDER_SUCCESS,
    PAYMENT_REMINDER_FAILS,
    PAYMENT_REMINDER_RESET,

    SUBSCRIPTION_ORDER_UPDATE_DATE_REQUEST,
    SUBSCRIPTION_ORDER_UPDATE_DATE_SUCCESS,
    SUBSCRIPTION_ORDER_UPDATE_DATE_FAILS,
    SUBSCRIPTION_ORDER_UPDATE_DATE_RESET,

    GET_DELIVERY_ORDERS_REQUEST,
    GET_DELIVERY_ORDERS_SUCCESS,
    GET_DELIVERY_ORDERS_FAILS,
    GET_DELIVERY_ORDERS_RESET,

    GET_DELIVERY_ORDERS_REQUEST_STAFF,
    GET_DELIVERY_ORDERS_SUCCESS_STAFF,
    GET_DELIVERY_ORDERS_FAILS_STAFF,
    GET_DELIVERY_ORDERS_RESET_STAFF,

    BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_REQUEST,
    BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_FAILS,
    BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_RESET,

    BLOCK_FOR_CHANGES_ORDERS_REQUEST,
    BLOCK_FOR_CHANGES_ORDERS_SUCCESS,
    BLOCK_FOR_CHANGES_ORDERS_FAILS,
    BLOCK_FOR_CHANGES_ORDERS_RESET,

    BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_REQUEST,
    BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_FAILS,
    BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_RESET

} from "../constants/orderConstants";

export const nextWeekActiveSubscriptionOderDetailReducer = ( state = { nextWeekActiveOrder: null, loading: false }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_ORDER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case SUBSCRIPTION_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                nextWeekActiveOrder: action.payload
            }
        case SUBSCRIPTION_ORDER_DETAIL_FAILS:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            }
        case SUBSCRIPTION_ORDER_DETAIL_RESET:
            return {
                ...state,
                loading: false,
                error: false,
                success: false,
                nextWeekActiveOrder: null
            }
        default:
            return state
    }
}

export const deliveredSubscriptionOderDetailReducer = ( state = { deliveredOrder: null, loading: false }, action) => {
    switch (action.type) {
        case DELIVERED_SUBSCRIPTION_ORDER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DELIVERED_SUBSCRIPTION_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                deliveredOrder: action.payload
            }
        case DELIVERED_SUBSCRIPTION_ORDER_DETAIL_FAILS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELIVERED_SUBSCRIPTION_ORDER_DETAIL_RESET:
            return {
                ...state,
                deliveredOrder: null
            }
        default:
            return state
    }
}

export const BlockedDeliveredNotPaidSubscriptionOderDetailReducer = ( state = { Order: [], loading: false }, action) => {
    switch (action.type) {
        case BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                Order: action.payload
            }
        case BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_FAILS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_RESET:
            return {
                ...state,
                Order: null
            }
        default:
            return state
    }
}

export const pendingPaymentSubscriptionOderDetailReducer = ( state = { pendingPaymentOrder: null, loading: false }, action) => {
    switch (action.type) {
        case PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                pendingPaymentOrder: action.payload
            }
        case PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_FAILS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_RESET:
            return {
                ...state,
                pendingPaymentOrder: null
            }
        default:
            return state
    }
}

export const blockedForChangeSubscriptionOderDetailReducer = ( state = { blockedForChangeOrder: null, loading: false }, action) => {
    switch (action.type) {
        case BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                blockedForChangeOrder: action.payload
            }
        case BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_FAILS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_RESET:
            return {
                ...state,
                blockedForChangeOrder: null
            }
        default:
            return state
    }
}


export const nextWeekActiveSubscriptionOderIdReducer = ( state = { nextWeekActiveOrderId: null, loading: false }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_ORDER_ID_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case SUBSCRIPTION_ORDER_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                nextWeekActiveOrderId: action.payload.id
            }
        case SUBSCRIPTION_ORDER_ID_FAILS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case SUBSCRIPTION_ORDER_ID_RESET:
            return {
                ...state,
                nextWeekActiveOrder: null
            }
        default:
            return state
    }
}

export const nextActiveSubscriptionOderDetailReducer = ( state = { }, action) => {
    switch (action.type) {
        case NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                nextActiveOrder: action.payload
            }
        case NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_FAILS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_RESET:
            return {
                ...state,
                nextActiveOrder: null
            }
        default:
            return state
    }
}

export const updateCreatedSubscriptionOderProductReducer = (state = {success: false}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_ORDER_UPDATE_PRODUCT_REQUEST:
            return {
                loading: true,
                updating_product: action.payload
            }
        case SUBSCRIPTION_ORDER_UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                updatedOrder: action.payload,
                success: true,
                updating_product: null
            }
        case SUBSCRIPTION_ORDER_UPDATE_PRODUCT_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case SUBSCRIPTION_ORDER_UPDATE_PRODUCT_RESET:
            return {
                ...state,
                updatedOrder: null,
                loading: false,
                success: false
            }
        default:
            return state
    }
}

export const updateCreatedSubscriptionOderStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_ORDER_UPDATE_STATUS_REQUEST:
            return {
                loading: true
            }
        case SUBSCRIPTION_ORDER_UPDATE_STATUS_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case SUBSCRIPTION_ORDER_UPDATE_STATUS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case SUBSCRIPTION_ORDER_UPDATE_STATUS_RESET:
            return {}
        default:
            return state
    }
}


export const updateSubscriptionOderDateReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_ORDER_UPDATE_DATE_REQUEST:
            return {
                loading: true
            }
        case SUBSCRIPTION_ORDER_UPDATE_DATE_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case SUBSCRIPTION_ORDER_UPDATE_DATE_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case SUBSCRIPTION_ORDER_UPDATE_DATE_RESET:
            return {}
        default:
            return state
    }
}


export const createOrderDeliveryIssueReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_DELIVERY_ISSUE_REQUEST:
            return {
                loading: true
            }
        case CREATE_DELIVERY_ISSUE_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case CREATE_DELIVERY_ISSUE_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case CREATE_DELIVERY_ISSUE_RESET:
            return {}
        default:
            return state
    }
}

export const completeOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case COMPLETE_SUBSCRIPTION_ORDER_REQUEST:
            return {
                loading: true
            }
        case COMPLETE_SUBSCRIPTION_ORDER_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case COMPLETE_SUBSCRIPTION_ORDER_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case COMPLETE_SUBSCRIPTION_ORDER_RESET:
            return {}
        default:
            return state
    }
}

export const createOrderClientIssueReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_CLIENT_ISSUE_REQUEST:
            return {
                loading: true
            }
        case CREATE_CLIENT_ISSUE_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case CREATE_CLIENT_ISSUE_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case CREATE_CLIENT_ISSUE_RESET:
            return {}
        default:
            return state
    }
}

export const createOrderDeliveryIssueResponseReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_DELIVERY_ISSUE_RESPONSE_REQUEST:
            return {
                loading: true
            }
        case CREATE_DELIVERY_ISSUE_RESPONSE_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case CREATE_DELIVERY_ISSUE_RESPONSE_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case CREATE_DELIVERY_ISSUE_RESPONSE_RESET:
            return {}
        default:
            return state
    }
}

export const createOrderClientIssueResponseReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_CLIENT_ISSUE_RESPONSE_REQUEST:
            return {
                loading: true
            }
        case CREATE_CLIENT_ISSUE_RESPONSE_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case CREATE_CLIENT_ISSUE_RESPONSE_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case CREATE_CLIENT_ISSUE_RESPONSE_RESET:
            return {}
        default:
            return state
    }
}

export const updatePastOrderStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PAST_ORDERS_STATUS_REQUEST:
            return {
                loading: true
            }
        case UPDATE_PAST_ORDERS_STATUS_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case UPDATE_PAST_ORDERS_STATUS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case UPDATE_PAST_ORDERS_STATUS_RESET:
            return {}
        default:
            return state
    }
}

export const getPastActiveOrdersReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PAST_ACTIVE_ORDERS_REQUEST:
            return {
                loading: true
            }
        case GET_PAST_ACTIVE_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                activePastOrders: action.payload
            }
        case GET_PAST_ACTIVE_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case GET_PAST_ACTIVE_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const updateMarkCompletedStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case MARK_COMPLETED_ORDERS_STATUS_REQUEST:
            return {
                loading: true
            }
        case MARK_COMPLETED_ORDERS_STATUS_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case MARK_COMPLETED_ORDERS_STATUS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case MARK_COMPLETED_ORDERS_STATUS_RESET:
            return {}
        default:
            return state
    }
}

export const getDeliveredOrdersReducer = (state = {}, action) => {
    switch (action.type) {
        case DELIVERED_SUBSCRIPTION_ORDERS_REQUEST:
            return {
                loading: true
            }
        case DELIVERED_SUBSCRIPTION_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                deliveredOrders: action.payload
            }
        case DELIVERED_SUBSCRIPTION_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case DELIVERED_SUBSCRIPTION_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const getpPaymentLinkSentOrdersReducer = (state = {}, action) => {
    switch (action.type) {
        case PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_REQUEST:
            return {
                loading: true
            }
        case PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                paymentLinkSentOrders: action.payload
            }
        case PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const sendOrdersNotificationsReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_ORDERS_NOTIFICATIONS_REQUEST:
            return {
                loading: true
            }
        case SEND_ORDERS_NOTIFICATIONS_SUCCESS:
            return {
                loading: false,
                success: true,            }
        case SEND_ORDERS_NOTIFICATIONS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case SEND_ORDERS_NOTIFICATIONS_RESET:
            return {}
        default:
            return state
    }
}


export const blockForChangesOrdersReducer = (state = {}, action) => {
    switch (action.type) {
        case BLOCK_FOR_CHANGES_ORDERS_REQUEST:
            return {
                loading: true
            }
        case BLOCK_FOR_CHANGES_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,            }
        case BLOCK_FOR_CHANGES_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case BLOCK_FOR_CHANGES_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const localOrderReducer = (state={ localProductsItems: [], updatedFromServer: false  }, action) => {
    switch (action.type) {
        case LOCAL_ORDER_CHANGE_AMOUNT_ITEM:
            const item = action.payload
            const existsItem = state.localProductsItems.find(x => x.products.id === item.products.id)
            if (existsItem) {
                if (item.amount === 0) {
                    return {
                        ...state,
                        updatedFromServer: action.payload.status,
                        localProductsItems: state.localProductsItems.filter(x => 
                            x.products.id !== existsItem.products.id)
                    }
                } else {
                    return {
                        ...state,
                        updatedFromServer: action.payload.status,
                        localProductsItems: state.localProductsItems.map(x => 
                            x.products.id === existsItem.products.id ? item : x )
                        }    
                }
            } else {
                return {
                        ...state,
                        updatedFromServer: action.payload.status,
                        localProductsItems: 
                        [
                            ...state.localProductsItems, 
                            item
                        ]
                }
            }
        case REMOVE_LOCAL_ORDER:
            return {
                localProductsItems: [],
                updatedFromServer: false
            }
        default:
            return state
    }
}

export const subscriptionOrderPaymentDetailReducer = ( state = { subscriptionOrder: null, loading: false }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_ORDER_PAYMENT_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case SUBSCRIPTION_ORDER_PAYMENT_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                subscriptionOrder: action.payload
            }
        case SUBSCRIPTION_ORDER_PAYMENT_DETAIL_FAILS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case SUBSCRIPTION_ORDER_PAYMENT_DETAIL_RESET:
            return {
                ...state,
                subscriptionOrder: null
            }
        default:
            return state
    }
}

export const todaySubscriptionOrdersMarkDeliveredReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MARK_DELIVERED_TODAY_ORDERS_REQUEST:
            return {
                loading: true
            }
        case MARK_DELIVERED_TODAY_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
            }
        case MARK_DELIVERED_TODAY_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case MARK_DELIVERED_TODAY_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const sendPaymentReminderReducer = (state = {}, action) => {
    switch (action.type) {
        case PAYMENT_REMINDER_REQUEST:
            return {
                loading: true
            }
        case PAYMENT_REMINDER_SUCCESS:
            return {
                loading: false,
                success: true,
            }
        case PAYMENT_REMINDER_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case PAYMENT_REMINDER_RESET:
            return {}
        default:
            return state
    }
}

export const getDeliveryOrdersReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_DELIVERY_ORDERS_REQUEST:
            return {
                loading: true
            }
        case GET_DELIVERY_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                deliveryOrders: action.payload
            }
        case GET_DELIVERY_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case GET_DELIVERY_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const getDeliveryOrdersStaffReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_DELIVERY_ORDERS_REQUEST_STAFF:
            return {
                loading: true
            }
        case GET_DELIVERY_ORDERS_SUCCESS_STAFF:
            return {
                loading: false,
                success: true,
                deliveryOrders: action.payload
            }
        case GET_DELIVERY_ORDERS_FAILS_STAFF:
            return {
                loading: false,
                error: action.payload
            }
        case GET_DELIVERY_ORDERS_RESET_STAFF:
            return {}
        default:
            return state
    }
}



