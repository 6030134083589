import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
import { LazyLoadImage } from "react-lazy-load-image-component";

export const ProductHorizontalCardImage = ({product, type}) => {

    return (
        <div>
            {product ? (
                <LazyLoadImage
                    className={type && type === 'cart' ? 'rounded p-3 no-logo-img my-3' : 'rounded p-3 no-logo-img my-3 ml-3' }
                    style={{height: '6rem'}}
                    src={product.image_96}
                    alt={product.name}
                />
            ) : (
                <Spinner animation="grow" variant="success" />
            )}
        </div>
    )
}
