export const TERMS_SECTIONS_REQUEST = 'TERMS_SECTIONS_REQUEST'
export const TERMS_SECTIONS_SUCCESS = 'TERMS_SECTIONS_SUCCESS'
export const TERMS_SECTIONS_FAILS = 'TERMS_SECTIONS_FAILS'

export const TERMS_AND_CONDITIONS_REQUEST = 'TERMS_AND_CONDITIONS_REQUEST'
export const TERMS_AND_CONDITIONS_SUCCESS = 'TERMS_AND_CONDITIONS_SUCCESS'
export const TERMS_AND_CONDITIONS_FAILS = 'TERMS_AND_CONDITIONS_FAILS'

export const PRIVACY_POLICY_REQUEST = 'PRIVACY_POLICY_REQUEST'
export const PRIVACY_POLICY_SUCCESS = 'PRIVACY_POLICY_SUCCESS'
export const PRIVACY_POLICY_FAILS = 'PRIVACY_POLICY_FAILS'
