import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { CompletePersonalInformationModalButton } from '../../mobile/buttons/CompletePersonalInformationModalButton';


export const NameEmailTelephoneTab = () => {

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser


    return (
        <div>
            {user && user.name ? (
            <h4 className='text-center mb-3'>
                {user.name} {user.surname}
                <CompletePersonalInformationModalButton
                    variant={'link'}
                    btnclass={'btn-sm'}
                    logo={"fas fa-edit"}
                    call_to_action={""}
                    />
            </h4>
            ) : (
                <div className="d-flex justify-content-center px-3">
                    <CompletePersonalInformationModalButton
                        variant={'success'}
                        btnclass={'fas fa-map-marked-alt'}
                        logo={"fas fa-signature"}
                        call_to_action={"Incluir Nombre"}
                    />
                </div>      
            )}
            {user && user.email && <p className='text-center mb-0' style={{ overflowWrap: 'break-word' }}><small><i className="fas fa-at"></i> {user.email}</small></p>}
            {user && user.telephone ? (
                <p className='text-center mt-0'>
                    <small>
                        <i className="fas fa-mobile-alt"></i> {user.telephone}
                    </small>
                    <CompletePersonalInformationModalButton
                        variant={'link'}
                        btnclass={'btn-sm'}                                            
                        logo={"fas fa-edit"}
                        call_to_action={""}
                    />
                </p>
            ) : (
                <div className="d-flex justify-content-center px-3">
                    <CompletePersonalInformationModalButton
                        variant={'success'}
                        btnclass={'btn-sm btn-block mb-1'}
                        logo={"fas fa-mobile-alt"}
                        call_to_action={"Incluir Teléfono"}
                    />
                </div>    
            )}
        </div>
    )
}
