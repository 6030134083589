import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import Loader from '../../../Loader'
import Message from '../../../Message'

import { UserInfoLaptop } from './laptop/UserInfoLaptop';
import { UserInfoMobile } from './mobile/UserInfoMobile';

import { userOrdersListAction, userDetailAction, userLastOrderAction, userLogout, userPaymentMethodListAction } from '../../../../actions/userActions'
import { platformBalanceChangeAction } from '../../../../actions/paymentsActions'


export const UserInfo = ({ history }) => {

    const dispatch = useDispatch()

    const detailUser = useSelector(state => state.userDetail)
    const { loading, user, error: userError } = detailUser

    const loginUser = useSelector(state => state.userLogin)
    const {user: userLogin} = loginUser

    const orderUserLast = useSelector(state => state.userLastOrder)
    const {error: lastOrderError, loading: lastOrderLoading, last_order} = orderUserLast

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    const paymentMethodList = useSelector(state => state.userPaymentMethodList)
    const { loading: loadingPayment, paymentMethod, error: errorPaymentMethod } = paymentMethodList

    const updateAddressUser = useSelector(state => state.userUpdateAddress)
    const { success: updateAddressSuccess } = updateAddressUser

    const [changeAddressShow, setChangeAddressShow] = useState(false);

    useEffect(() => {

        if ((user === null || !user) && loading === false) {
            console.log(2)
            dispatch(userDetailAction())
        }
        if (updateAddressSuccess) {
            console.log(3)
            setTimeout(() => {  dispatch(userDetailAction()) }, 1500);
            setTimeout(() => {  setChangeAddressShow(!changeAddressShow); }, 1500);
        }
        if(subscription && (subscription.status.name === 'Active' || subscription.status.name === 'Canceled')){
            if ((!last_order) && (!lastOrderError) && (!lastOrderLoading || lastOrderLoading === false)){
                dispatch(userLastOrderAction())
            }
        }
        if(loadingPayment === false && paymentMethod === null && !errorPaymentMethod) {
            dispatch(userPaymentMethodListAction())
        }
        if (userError) {
            dispatch(userLogout())
        }
    }, [
        dispatch,
        userError,
        user,
        loading,
        userLogin,
        last_order,
        lastOrderError,
        lastOrderLoading,
        subscription,
        loadingPayment,
        paymentMethod,
        errorPaymentMethod,
        updateAddressSuccess,
        changeAddressShow
    ])

    const changePlatformBalance = useSelector(state => state.platformBalanceChange)
    const { loading: loadingPlatformChanges, platformsChanges } = changePlatformBalance

    useEffect(() => {
        if(!platformsChanges && (!loadingPlatformChanges || loadingPlatformChanges === false) && user ) {
            dispatch(platformBalanceChangeAction())
        }
    }, [
        platformsChanges,
        loadingPlatformChanges,
        user,
        dispatch
    ])

    const listUserOrders = useSelector(state => state.userOrdersList)
    const { loading: loadingListUserOrders, order_list, error: errorListUserOrders } = listUserOrders

    useEffect(() => {
        if(subscription && (subscription.status.name === 'Active' || subscription.status.name === 'Canceled')){
            if (!order_list && (loadingListUserOrders === false || !loadingListUserOrders) && (!errorListUserOrders))  {
                dispatch(userOrdersListAction())
                }     
        }
    }, [
        subscription,
        loadingListUserOrders,
        order_list,
        errorListUserOrders,
        dispatch
    ])


    return (
        <div>
            <p className='my-n3 d-none d-sm-block' style={{ color: '#F8F9FA'}}>----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
            <p className='my-n3 d-block d-sm-none' style={{ color: '#F8F9FA'}}>----------------------------------------------------------------------------------------</p>
            {loading ? (
                <div className='mb-3'>
                    <Loader />
                </div>
                ) :
            userError ? (<Message variant='danger'>{userError}</Message>):
            user ? (
                <div className='mt-3'>
                    {user && user.is_staff && (
                        <LinkContainer to='/staff/'>
                            <Button variant='success' className='btn-lg btn-block mb-3'><i className="fas fa-id-card-alt"></i> Página de trabajador</Button>
                        </LinkContainer>                
                    )}
                    <div className='d-none d-sm-block'>
                        <UserInfoLaptop />
                    </div>
                    <div className='d-block d-sm-none'>
                    </div>
                    <UserInfoMobile />
                </div>
            ) : (<div></div>)}
        </div>
    )
}
