import React, { useEffect, useState } from 'react'
import { Row, Form, Button, ButtonGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { cartChangeAmountItemAction } from '../../../actions/cartActions'

export const CartButton = ({ product, type }) => {

    const [inCart, setInCart] = useState(false)
    const [addAmount, setAddAmount] = useState(1)
    const [reduceAmount, setReduceAmount] = useState(0)
    const [qty, setQty] = useState(1)

    const cartInfo = useSelector(state => state.cart)
    const { cartItems } = cartInfo

    const dispatch = useDispatch()

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(cartChangeAmountItemAction(product, qty))
    }

    const reduceAmountHandler = (product, amount) => {
        dispatch(cartChangeAmountItemAction(product, amount))
        if (reduceAmount === 0) {
            setInCart(false)
        }
    }

    const addAmountHandler = (product, amount) => {
        dispatch(cartChangeAmountItemAction(product, amount))
    }

    useEffect(() => {
        if(cartItems.length > 0) {
            const itemInCart = cartItems.filter(x => x.product.id === product.id)
            if (itemInCart) {
                if (itemInCart[0]) {
                    setAddAmount(Number(itemInCart[0].qty) + 1)
                    setReduceAmount(Number(itemInCart[0].qty) -1)
                    setQty(itemInCart[0].qty)
                    setInCart(true)
                }
                else {
                    setInCart(false)
                }
            }
            else {
                setInCart(false)
            }
        }
        else {
            setInCart(false)   
        }
    }, [cartItems, addAmount, product])


    return (
        <div>
            {product.availability > 0 ? (
                <div>
                    {inCart ? (
                        <Row>
                            <ButtonGroup aria-label="Basic example" className={type && type === 'horizontal' ? 'btn-block ml-n3' : 'btn-block mx-3'} size="lg">
                                <Button variant="outline-success"className='border-right-0' onClick={() => reduceAmountHandler(product, reduceAmount)}>-</Button>
                                <Button variant="outline-success" className='border-right-0 border-left-0' disabled='true'>{qty}</Button>
                                <Button variant="outline-success" className='border-left-0' onClick={() => addAmountHandler(product, addAmount)}>+</Button>
                            </ButtonGroup>
                        </Row>
                    ) : (
                        <Form onSubmit={submitHandler}>
                            <Form.Control as="select" htmlSize={3} custom  className='d-none'>
                                <option value={product.name}>{product.name}</option>
                            </Form.Control>
                            <Button type="submit" className='btn-block' style={{ backgroundColor: '#2c971f', display: 'block'}}>
                                Añadir
                            </Button>
                        </Form>
                    )}         
                </div>
            ) : (
                <div className="d-flex justify-content-center">
                    <h3>
                        <span className="badge badge-danger">Agotado</span>
                    </h3>
                </div>
            )}
        </div>
    )
}
