import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalPastOrders } from '../../shared/ModalPastOrders';


export const PastOrdersModalButton = () => {

    const [pastOrdersShow, setPastOrdersShow] = useState(false);
    const togglePastOrdersModal = () => {
        setPastOrdersShow(!pastOrdersShow)
    } 


    return (
        <div>
            <Button type="button" className="btn-block btn-sm my-1" onClick={togglePastOrdersModal} style={{ backgroundColor: '#00BAFF' }}>
                <i className="fa fa-fast-backward"></i> Órdenes anteriores
            </Button>
            {pastOrdersShow ? (
                <ModalPastOrders isOpen={pastOrdersShow} toggle={togglePastOrdersModal}/> 
            ) : (
                <div></div>
            )}
        </div>
    )
}
