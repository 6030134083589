import React, { useState } from 'react'
import { ModalChangeFrecuency } from './ModalChangeFrecuency'


export const ChangeFrecuencyModalButton = () => {

    const [changeSubscriptionFrecuencyShow, setchangeSubscriptionFrecuencyShow] = useState(false);

    const toggleChangeSubscriptionFrecuencyModal = () => {
        setchangeSubscriptionFrecuencyShow(!changeSubscriptionFrecuencyShow)
    }


    return (
        <div>
            <button type="button" className="btn btn-secondary btn-sm btn-block" onClick={toggleChangeSubscriptionFrecuencyModal}>
                <i className="fas fa-calendar-alt"></i> Modificar frecuencia
            </button>  
            <ModalChangeFrecuency isOpen={changeSubscriptionFrecuencyShow} toggle={setchangeSubscriptionFrecuencyShow} />
        </div>
    )
}
