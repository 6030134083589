import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Image } from 'react-bootstrap'

import Loader from '../../../Loader'
import ThreeSpheres from '../../prospects/ThreeSpheres'
import LogoFiambre from '../../../../images/logoFiambre-Inicio.png'
import { CreditCardModalButton } from '../AccountInfo/CreditCardModalButton'
import { FrecuencyForm } from '../FrecuencyForm'
import { ActivateSubscriptionButton } from './ActivateSubscriptionButton'
import { userMessageAction } from '../../../../actions/userActions'

import {
    nextWeekSubscriptionOrderDetailAction
} from '../../../../actions/orderActions'


import { 
    subscriptionDetailAction
    } from '../../../../actions/subscriptionActions'

import {
    SUBSCRIPTION_UPDATE_STATUS_RESET,
    ENABLE_CHECK_LOCAL_SUBSCRIPTION
    } from '../../../../constants/subscriptionConstants'


const { 
    REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE,
    REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT,
    REACT_APP_DONATION_PERCENTAGE,
    REACT_APP_MINIMUN_PURCHASE_NO_TRANSPORT,
    REACT_APP_TRANSPORT_CHARGE } = process.env



export const LocalPendingTokenSection = () => {

    const dispatch = useDispatch()

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const updateStatusSubscription = useSelector(state => state.subscriptionUpdateStatus)
    const { loading: updateStatusLoading, success: updateStatusSuccess } = updateStatusSubscription

    const[amountToPay, setAmountToPay] = useState(0)

    const[remainingPlatformBalanceUser, setRemainingPlatformBalanceUser] = useState(0)

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { loading:subscriptionDetailLoading, subscription } = detailSubscription

    const updateSubscription = useSelector(state => state.subscriptionUpdate)
    const { loading: subscriptionUpdateLoading } = updateSubscription

    const orderNextWeekActiveSubscription = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
    const { loading: orderLoading  } = orderNextWeekActiveSubscription

    const orderAmountToPay = () => {
        const minAmountToPay = 10000
        const itemsValue = SubscriptionCartItemsValue(subscription.products_items)
        const discount = subscriptionDiscoutValue(subscription.total_value)
        const transport = transportCost(subscription.total_value)
        const userBalance = user.user_platform_balance
        if (itemsValue - discount - transport - userBalance > minAmountToPay) {
            setAmountToPay(itemsValue - discount + transport - userBalance)
            if (userBalance > 0) {
                setPlatformBalanceUser(userBalance)
                setRemainingPlatformBalanceUser(0)    
            }
        } else {
            const balanceToUse = (itemsValue - discount - transport) - minAmountToPay
            setAmountToPay(itemsValue - discount + transport - balanceToUse)
            if (userBalance > 0) {
                setPlatformBalanceUser(balanceToUse)
                setRemainingPlatformBalanceUser(userBalance - balanceToUse)    
            }
        }
    }

    const[activateSubscriptionRequested, setActivateSubscriptionRequested] = useState(false)

    useEffect(() => {
        if (user && subscription) {
            orderAmountToPay()
        }
        if (updateStatusSuccess === true && orderLoading === false) {
            if (activateSubscriptionRequested) {
                dispatch(userMessageAction('Subscripción activada'))
                setActivateSubscriptionRequested(false)
            }
            dispatch({type: SUBSCRIPTION_UPDATE_STATUS_RESET})
            dispatch(subscriptionDetailAction())
            dispatch(nextWeekSubscriptionOrderDetailAction())
            dispatch({ type: ENABLE_CHECK_LOCAL_SUBSCRIPTION })
        }
    }, [subscription,
        user,
        updateStatusSuccess,
        orderLoading
    ])

    function donationValue(cartItemsArray) {
        const itemsCost = SubscriptionCartItemsValue(cartItemsArray)
        return itemsCost * REACT_APP_DONATION_PERCENTAGE
    }

    function transportCost(subscription_total_value) {
        const itemsCost = subscription_total_value
        if (itemsCost > REACT_APP_MINIMUN_PURCHASE_NO_TRANSPORT) {
            return 0
        }
        return Math.round(Number(REACT_APP_TRANSPORT_CHARGE) * (1 - (Number(itemsCost)/REACT_APP_MINIMUN_PURCHASE_NO_TRANSPORT)))
    }

    const[platformBalanceUser, setPlatformBalanceUser] = useState(0)

    function SubscriptionCartItemsValue(cartItemsArray) {
        const initialValue = 0;
        const itemsCost = cartItemsArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total_value,
        initialValue,
        );
        return itemsCost
    }

    function subscriptionDiscoutValue(subscription_total_value) {
        const productsDiscount = subscription_total_value * REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE
        if (productsDiscount > REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT) {
            return REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT
        }
        return productsDiscount
    }

    return (    
        <div>
            <div>
                <Row>
                    <Col className='text-center mx-auto'>
                        {subscription ? (
                            <div>
                                {subscription.frecuency.deliver_frecuency === null ? (
                                    <h2 className='text-muted'><b>Frecuencia de entrega</b></h2>
                                ) : (
                                    <h2 className='text-muted'><b>Elige la forma de pago</b></h2>
                                )}
                            </div>
                        ) : (
                            <h2 className='text-muted'><b>Actualizando...</b></h2>
                        )}
                    </Col>
                </Row>
                <div className='mb-3'>
                    {subscription ? (
                        <div>
                            {subscription.frecuency.deliver_frecuency === null ? (
                                <ThreeSpheres step={2} />
                            ) : (
                                <ThreeSpheres step={3} />
                            )}
                        </div>
                    ) : (
                        <ThreeSpheres />
                    )}
                </div>
                <hr className='border border-secondary mt-1'></hr>

                <Row className='my-1 py-3 px-3 px-sm-5'>
                    <Col
                        className='mx-sm-5'
                        style={{ boxShadow: 'rgba(135, 206, 235, 1) 0px 5px 15px' }}
                    >
                        <Row>
                            <Col
                                className='mx-sm-3 px-sm-3 pt-3'
                            >
                                <ul>
                                    <li className='mr-sm-3 ml-n3 ml-sm-3 fs-1'>
                                        <span style={{ float: 'left'}} className='d-block d-sm-none'>
                                            <small>Valor de los productos</small>
                                        </span>
                                        <span style={{ float: 'left'}} className='d-none d-sm-block'>
                                            Valor de los productos
                                        </span>
                                        {subscriptionDetailLoading || subscriptionUpdateLoading || !subscription ? (
                                            <>
                                            <span style={{ float: 'right'}} className='d-block d-sm-none'>
                                                <small>Actualizando....</small>
                                            </span>
                                            <span style={{ float: 'right'}} className='d-none d-sm-block'>
                                                Actualizando....
                                            </span>
                                            </>                                                    
                                        ) : (
                                            <span style={{ float: 'right'}}>
                                                ${new Intl.NumberFormat('co-CO').format(Number(subscription.total_value))}
                                            </span>
                                        )}
                                    </li>
                                    <li className='mr-sm-3 ml-n3 ml-sm-3' style={{ color: '#29BC5A' }}>
                                    <span style={{ float: 'left'}} className='d-block d-sm-none'>
                                            <small>Descuento</small>
                                        </span>
                                        <span style={{ float: 'left'}} className='d-none d-sm-block'>
                                            Descuento
                                        </span>
                                        {subscriptionDetailLoading || subscriptionUpdateLoading || !subscription ? (
                                            <>
                                                <span style={{ float: 'right'}} className='d-block d-sm-none'>
                                                    <small>Actualizando....</small>
                                                </span>
                                                <span style={{ float: 'right'}} className='d-none d-sm-block'>
                                                    Actualizando....
                                                </span>
                                            </>                                                    
                                        ) : (
                                            <div>
                                                {subscription && subscription.products_items.length > 0 ? (
                                                    <span style={{ float: 'right'}}>
                                                        -${new Intl.NumberFormat('co-CO').format(Number(subscriptionDiscoutValue(subscription.total_value)))}
                                                    </span>                                                    
                                                ) : (
                                                    <span>0</span>
                                                )}
                                            </div>
                                        )}
                                    </li>
                                    {subscription && subscription.products_items.length > 0 && (
                                        <>
                                            {Number(subscriptionDiscoutValue(subscription.total_value)) >= REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT && (
                                                <li style={{ listStyleType: 'none', marginLeft: '20px', color: '#29BC5A' }}>
                                                    <small style={{ marginLeft: 'auto' }}>
                                                        Descuento máximo por mercado para los 2 primeros mercados
                                                    </small>
                                                </li>                                    
                                            )}
                                        </>
                                    )}
                                    {user && user.user_platform_balance > 0 && (
                                    <li className='mr-sm-3 ml-n3 ml-sm-3' style={{ color: '#29BC5A' }}>
                                        <span style={{ float: 'left'}}>
                                            Balance en Verdemi a usar
                                        </span>
                                        <span style={{ float: 'right'}}>
                                            -${new Intl.NumberFormat('co-CO').format(Number(platformBalanceUser))}
                                        </span>
                                    </li>
                                    )}
                                    {remainingPlatformBalanceUser > 0 && (
                                        <li className='mr-sm-3 ml-n3 ml-sm-3' style={{ color: '#29BC5A' }}>
                                            <span style={{ float: 'left'}}>
                                                <small>Saldo en Verdemi para futuros mercados</small>
                                            </span>
                                            <span style={{ float: 'right'}}>
                                                <small>
                                                    ${new Intl.NumberFormat('co-CO').format(Number(remainingPlatformBalanceUser))}
                                                </small>
                                            </span>
                                        </li>
                                    )}
                                    <li className='mr-sm-3 ml-n3 ml-sm-3'>
                                    <span style={{ float: 'left'}} className='d-block d-sm-none'>
                                            <small>Transporte</small>
                                        </span>
                                        <span style={{ float: 'left'}} className='d-none d-sm-block'>
                                            Transporte
                                        </span>
                                        {subscriptionDetailLoading || subscriptionUpdateLoading || !subscription ? (
                                            <>
                                                <span style={{ float: 'right'}} className='d-block d-sm-none'>
                                                    <small>Actualizando....</small>
                                                </span>
                                                <span style={{ float: 'right'}} className='d-none d-sm-block'>
                                                    Actualizando....
                                                </span>
                                            </>                                                    
                                        ) : (
                                            <div>
                                                {subscription && subscription.products_items.length > 0 ? (
                                                    <span style={{ float: 'right'}}>
                                                        ${new Intl.NumberFormat('co-CO').format(Number(transportCost(subscription.total_value)))}
                                                    </span>
                                                        ) : (
                                                    <span>0</span>
                                                )}
                                            </div>
                                        )}
                                    </li>
                                    <li className='mr-sm-3 ml-n3 ml-sm-3'>
                                    <span style={{ float: 'left'}} className='d-block d-sm-none'>
                                            <small>Total a pagar</small>
                                        </span>
                                        <span style={{ float: 'left'}} className='d-none d-sm-block'>
                                            Total a pagar
                                        </span>
                                        {subscriptionDetailLoading || subscriptionUpdateLoading || !subscription ? (
                                            <>
                                                <span style={{ float: 'right'}} className='d-block d-sm-none'>
                                                    <small>Actualizando....</small>
                                                </span>
                                                <span style={{ float: 'right'}} className='d-none d-sm-block'>
                                                    Actualizando....
                                                </span>
                                            </>                                                    
                                        ) : (
                                            <div>
                                                {subscription && subscription.products_items.length > 0 ? (
                                                    <span style={{ float: 'right'}}>
                                                        <b>
                                                            ${new Intl.NumberFormat('co-CO').format(Number(amountToPay))}
                                                        </b>
                                                    </span>
                                                    ) : (
                                                    <span>0</span>
                                                )}
                                            </div>
                                        )}
                                    </li>
                                    <hr className='mr-sm-3 ml-n3 ml-sm-3' style={{ backgroundColor: '#29bc5a' }}></hr>
                                    <li className='mr-sm-3 ml-n3 ml-sm-3'>
                                    <span style={{ float: 'left'}} className='d-block d-sm-none'>
                                            <small>Donación de Verdemi</small>
                                        </span>
                                        <span style={{ float: 'left'}} className='d-none d-sm-block'>
                                        Donación de Verdemi
                                        </span>
                                        {subscriptionDetailLoading || subscriptionUpdateLoading || !subscription ? (
                                            <>
                                                <span style={{ float: 'right'}} className='d-block d-sm-none'>
                                                    <small>Actualizando....</small>
                                                </span>
                                                <span style={{ float: 'right'}} className='d-none d-sm-block'>
                                                    Actualizando....
                                                </span>
                                            </>                                                    
                                        ) : (
                                            <div>
                                                {subscription && subscription.products_items.length > 0 ? (
                                                    <span style={{ float: 'right'}}>
                                                        <b>
                                                            ${new Intl.NumberFormat('co-CO').format(Math.round(Number(donationValue(subscription.products_items))))}
                                                        </b>
                                                    </span>
                                                    ) : (
                                                    <span>0</span>
                                                )}
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row> 

                {updateStatusLoading || subscriptionDetailLoading || !subscription ? (
                    <>
                        <Loader/>
                    </>
                ) : (
                    <>
                        {subscription && (
                            <>
                                {subscription.frecuency.deliver_frecuency === null ? (
                                    <Row className='p-3'>
                                        <Col>
                                            <FrecuencyForm create={true}/>
                                        </Col>
                                    </Row>
                                ) : (
                                    <div>
                                        <Row>
                                            <Col className='text-center'>
                                                <Image src={LogoFiambre} className="img-fluid" alt="Responsive image" />
                                                <p className='mb-0'>La Fundación Fiambre da alimentos en los altos de Cazucá <small><a href="https://fundacionfiambre.org/" target="_blank" rel='noreferrer'>Más información</a></small></p>
                                                <p>Verdemi donará el 5% de tus compra a Fiambre para la adquisición de alimentos.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mb-3 mx-0 mx-sm-5 border' style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}}>

                                            <Col xs={12} lg={6} className='mb-3 px-3'>
                                                <CreditCardModalButton />
                                            </Col>

                                            <Col xs={12} lg={6} className='mb-3'>
                                                <ActivateSubscriptionButton setActivateSubscriptionRequested={setActivateSubscriptionRequested}/>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}

            </div>
        </div>
    )
}
