import { 
    SUBSCRIPTION_DETAIL_FAILS,
    SUBSCRIPTION_DETAIL_SUCCESS,
    SUBSCRIPTION_DETAIL_REQUEST,
    SUBSCRIPTION_DETAIL_RESET,

    SUBSCRIPTION_UPDATE_FAILS,
    SUBSCRIPTION_UPDATE_REQUEST,
    SUBSCRIPTION_UPDATE_SUCCESS,
    SUBSCRIPTION_UPDATE_RESET,

    FREQUENCY_DAYS_FAILS,
    FREQUENCY_DAYS_REQUEST,
    FREQUENCY_DAYS_SUCCESS,

    CREATE_FREQUENCY_FAILS,
    CREATE_FREQUENCY_REQUEST,
    CREATE_FREQUENCY_SUCCESS,
    CREATE_FREQUENCY_RESET,

    SUBSCRIPTION_UPDATE_STATUS_FAILS,
    SUBSCRIPTION_UPDATE_STATUS_REQUEST,
    SUBSCRIPTION_UPDATE_STATUS_RESET,
    SUBSCRIPTION_UPDATE_STATUS_SUCCESS,

    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CANCEL_SUBSCRIPTION_FAILS,
    CANCEL_SUBSCRIPTION_RESET,

    SUBSCRIPTION_CHECK_ACTIVE_STATUS_REQUEST,
    SUBSCRIPTION_CHECK_STATUS_ACTIVE_SUCCESS,
    SUBSCRIPTION_CHECK_STATUS_ACTIVE_FAILS,
    SUBSCRIPTION_CHECK_STATUS_ACTIVE_RESET,

    UPDATE_FREQUENCY_FAILS,
    UPDATE_FREQUENCY_REQUEST,
    UPDATE_FREQUENCY_RESET,
    UPDATE_FREQUENCY_SUCCESS,

    SUBSCRIPTION_PAUSE_FAILS,
    SUBSCRIPTION_PAUSE_REQUEST,
    SUBSCRIPTION_PAUSE_RESET,
    SUBSCRIPTION_PAUSE_SUCCESS,

    SUBSCRIPTION_PRODUCTS_MODAL_OPEN,
    SUBSCRIPTION_PRODUCTS_MODAL_CLOSE,

    LOCAL_SUBSCRIPTION_CHANGE_AMOUNT_ITEM,

    REMOVE_LOCAL_SUBSCRIPTION,

    ENABLE_CHECK_LOCAL_SUBSCRIPTION,
    DISABLE_CHECK_LOCAL_SUBSCRIPTION,

    SUBSCRIPTION_ID

} from "../constants/subscriptionConstants";

export const subscriptionDetailReducer = (state = {subscription: null, loading: false}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_DETAIL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUBSCRIPTION_DETAIL_SUCCESS:
            return {
                loading: false,
                subscription: action.payload
            }
        case SUBSCRIPTION_DETAIL_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case SUBSCRIPTION_DETAIL_RESET:
            return {
                ...state,
                subscription: null
            }
        default:
            return state;
    }
}

export const subscriptionUpdateReducer = (state = {loading: false, subscription: null}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_UPDATE_REQUEST:
            return {
                loading: true,
                updating_product: action.payload
            }
        case SUBSCRIPTION_UPDATE_SUCCESS:
            return {
                success: true,
                loading: false,
                subscription: action.payload,
                updating_product: null
            }
        case SUBSCRIPTION_UPDATE_FAILS:
            return {
                loading: false,
                success: false,
                error: action.payload
            }
        case SUBSCRIPTION_UPDATE_RESET:
            return {
                ...state,
                subscription: null,
                loading: false,
                success: false

            }            
        default:
            return state
    }
}

export const frequencyDaysReducer = (state = { days:null }, action) => {
    switch (action.type) {
        case FREQUENCY_DAYS_REQUEST:
            return {
                loading: true
            }
        case FREQUENCY_DAYS_SUCCESS:
            return {
                loading: false,
                days: action.payload
            }
        case FREQUENCY_DAYS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const createFrequencyReducer = ( state = {}, action) => {
    switch (action.type) {
        case CREATE_FREQUENCY_REQUEST:
            return {
                loading: true
            }
        case CREATE_FREQUENCY_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case CREATE_FREQUENCY_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case CREATE_FREQUENCY_RESET:
            return {}
        default:
            return state
    }
}

export const subscriptionUpdateStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_UPDATE_STATUS_REQUEST:
            return {
                loading: true
            }
        case SUBSCRIPTION_UPDATE_STATUS_FAILS:
            return {
                loading: false,
                error: action.payload
            }                
        case SUBSCRIPTION_UPDATE_STATUS_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case SUBSCRIPTION_UPDATE_STATUS_RESET:
            return {}
        default:
            return state
    }
}


export const cancelSubscriptionReducer = (state = {}, action) => {
    switch (action.type) {
        case CANCEL_SUBSCRIPTION_REQUEST:
            return {
                loading: true
            }
        case CANCEL_SUBSCRIPTION_FAILS:
            return {
                loading: false,
                error: action.payload
            }                
        case CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case CANCEL_SUBSCRIPTION_RESET:
            return {}
        default:
            return state
    }
}


export const subscriptionCheckActiveStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_CHECK_ACTIVE_STATUS_REQUEST:
            return {
                loading: true
            }
        case SUBSCRIPTION_CHECK_STATUS_ACTIVE_FAILS:
            return {
                loading: false,
                error: action.payload
            }                
        case SUBSCRIPTION_CHECK_STATUS_ACTIVE_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case SUBSCRIPTION_CHECK_STATUS_ACTIVE_RESET:
            return {}
        default:
            return state
    }
}



export const updateFrequencyReducer = ( state = {}, action) => {
    switch (action.type) {
        case UPDATE_FREQUENCY_REQUEST:
            return {
                loading: true
            }
        case UPDATE_FREQUENCY_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case UPDATE_FREQUENCY_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case UPDATE_FREQUENCY_RESET:
            return {}
        default:
            return state
    }
}

export const subscriptionPauseReducer = ( state = {}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_PAUSE_SUCCESS:
            return {
                success: true,
                loading: false
            }
        case SUBSCRIPTION_PAUSE_REQUEST:
            return {
                loading: true
            }
        case SUBSCRIPTION_PAUSE_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case SUBSCRIPTION_PAUSE_RESET:
            return {}
        default:
            return state
    }
}

export const subscriptionModalReducer = ( state = { open_modal: false }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_PRODUCTS_MODAL_OPEN:
            return {
                open_modal: true,
            }
        case SUBSCRIPTION_PRODUCTS_MODAL_CLOSE:
            return {
                open_modal: false
            }
        default:
            return state
    }
}

const OrderProductsByCategoryAndName = (a, b) => {
    if (a.products.category && b.products.category) {
        if (a.products.category.name === b.products.category.name) {
            if (a.products.name[0] > b.products.name[0]) {
                return 1
            } else if (a.products.name[0] < b.products.name[0]) {
                return -1
            } else if (a.products.name[0] === b.products.name[0]) {
                if (a.products.name[1] > b.products.name[1]) {
                    return 1
                } else if (a.products.name[1] < b.products.name[1]) {
                    return -1
                }
            }    
        }
        return a.products.category.name > b.products.category.name ? 1 : -1;    
    }
    return 0
}

export const localSubscriptionReducer = (state={ localProductsItems: [], updatedFromServer: false, localSubscriptionStatus: '', deliverPeriod: null }, action) => {
    switch (action.type) {
        case LOCAL_SUBSCRIPTION_CHANGE_AMOUNT_ITEM:
            const item = action.payload
            const existsItem = state.localProductsItems.find(x => x.products.id === item.products.id)
            if (existsItem) {
                if (item.amount === 0) {
                    return {
                        ...state,
                        updatedFromServer: action.payload.status,
                        localProductsItems: state.localProductsItems.filter(x => 
                            x.products.id !== existsItem.products.id),
                        localSubscriptionStatus: action.payload.subscription_status,
                        deliverPeriod: action.payload.delivery_period,
                    }
                } else {
                    return {
                        ...state,
                        updatedFromServer: action.payload.status,
                        localProductsItems: state.localProductsItems.map(x => 
                            x.products.id === existsItem.products.id ? item : x ),
                        localSubscriptionStatus: action.payload.subscription_status,
                        deliverPeriod: action.payload.delivery_period,
                        }    
                }
            } else {
                const new_array = [
                    ...state.localProductsItems, 
                    item
                ].sort(OrderProductsByCategoryAndName)
                return {
                        ...state,
                        updatedFromServer: action.payload.status,
                        localProductsItems: new_array.sort(OrderProductsByCategoryAndName),
                        localSubscriptionStatus: action.payload.subscription_status,
                        deliverPeriod: action.payload.delivery_period,
                }
            }
        case REMOVE_LOCAL_SUBSCRIPTION:
            return {
                localProductsItems: [],
                updatedFromServer: false,
                localSubscriptionStatus: '',
                deliverPeriod: null,
            }
        default:
            return state
    }
}

export const toggleCheckLocalSubscriptionReducer = ( state = { checkLocalSubscription: false }, action) => {
    switch (action.type) {
        case ENABLE_CHECK_LOCAL_SUBSCRIPTION:
            return {
                checkLocalSubscription: true,
            }
        case DISABLE_CHECK_LOCAL_SUBSCRIPTION:
            return {
                checkLocalSubscription: false
            }
        default:
            return state
    }
}

export const SubscriptionIdReducer = ( state = {}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_ID:
            return {
                id: action.payload
            }
        default:
            return state
    }
}
