export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST'
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS'
export const USER_DETAIL_FAILS = 'USER_DETAIL_FAILS'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_FAILS = 'USER_LOGIN_FAILS'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_CREATED_SUBSCRIPTION_ORDER_REQUEST = 'USER_CREATED_SUBSCRIPTION_ORDER_REQUEST'
export const USER_CREATED_SUBSCRIPTION_ORDER_SUCCESS = 'USER_CREATED_SUBSCRIPTION_ORDER_SUCCESS'
export const USER_CREATED_SUBSCRIPTION_ORDER_FAILS = 'USER_CREATED_SUBSCRIPTION_ORDER_FAILS'
export const USER_DETAIL_RESET = 'USER_DETAIL_RESET'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_FAILS = 'USER_REGISTER_FAILS'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'

export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST'
export const USER_CHANGE_PASSWORD_FAILS = 'USER_CHANGE_PASSWORD_FAILS'
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS'
export const USER_CHANGE_PASSWORD_RESET = 'USER_CHANGE_PASSWORD_RESET'

export const USER_UPDATE_ADDRESS_REQUEST = 'USER_UPDATE_ADDRESS_REQUEST'
export const USER_UPDATE_ADDRESS_FAILS = 'USER_UPDATE_ADDRESS_FAILS'
export const USER_UPDATE_ADDRESS_SUCCESS = 'USER_UPDATE_ADDRESS_SUCCESS'
export const USER_UPDATE_ADDRESS_RESET = 'USER_UPDATE_ADDRESS_RESET'

export const USER_UPDATE_PERSONAL_INFO_REQUEST = 'USER_UPDATE_PERSONAL_INFO_REQUEST'
export const USER_UPDATE_PERSONAL_INFO_FAILS = 'USER_UPDATE_PERSONAL_INFO_FAILS'
export const USER_UPDATE_PERSONAL_INFO_SUCCESS = 'USER_UPDATE_PERSONAL_INFO_SUCCESS'
export const USER_UPDATE_PERSONAL_INFO_RESET = 'USER_UPDATE_PERSONAL_INFO_RESET'

export const SET_HOST_PERSONAL_CODE = 'SET_HOST_PERSONAL_CODE'
export const REMOVE_HOST_PERSONAL_CODE = 'REMOVE_HOST_PERSONAL_CODE'

export const FUNDS_RECEIVED_REQUEST = 'FUNDS_RECEIVED_REQUEST'
export const FUNDS_RECEIVED_FAILS = 'FUNDS_RECEIVED_FAILS'
export const FUNDS_RECEIVED_SUCCESS = 'FUNDS_RECEIVED_SUCCESS'
export const FUNDS_RECEIVED_RESET = 'FUNDS_RECEIVED_RESET'

export const SET_RESET_PASSWORD_USER_PERSONAL_CODE = 'SET_RESET_PASSWORD_USER_PERSONAL_CODE'
export const REMOVE_RESET_PASSWORD_USER_PERSONAL_CODE = 'REMOVE_RESET_PASSWORD_USER_PERSONAL_CODE'

export const SET_RESET_PASSWORD_USER_ID = 'SET_RESET_PASSWORD_USER_ID'
export const REMOVE_RESET_PASSWORD_USER_ID = 'REMOVE_RESET_PASSWORD_USER_ID'

export const USER_NEW_MESSAGE = 'USER_NEW_MESSAGE'
export const REMOVE_USER_MESSAGE = 'REMOVE_USER_MESSAGE'

export const CONTACT_FORM_REQUEST = 'CONTACT_FORM_REQUEST'
export const CONTACT_FORM_FAILS = 'CONTACT_FORM_FAILS'
export const CONTACT_FORM_SUCCESS = 'CONTACT_FORM_SUCCESS'
export const CONTACT_FORM_RESET = 'CONTACT_FORM_RESET'

export const BOLETIN_CONTACT_REQUEST = 'BOLETIN_CONTACT_REQUEST'
export const BOLETIN_CONTACT_FAILS = 'BOLETIN_CONTACT_FAILS'
export const BOLETIN_CONTACT_SUCCESS = 'BOLETIN_CONTACT_SUCCESS'
export const BOLETIN_CONTACT_RESET = 'BOLETIN_CONTACT_RESET'

export const ORDERS_TO_NOTIFY_REQUEST = 'ORDERS_TO_NOTIFY_REQUEST'
export const ORDERS_TO_NOTIFY_FAILS = 'ORDERS_TO_NOTIFY_FAILS'
export const ORDERS_TO_NOTIFY_SUCCESS = 'ORDERS_TO_NOTIFY_SUCCESS'
export const ORDERS_TO_NOTIFY_RESET = 'ORDERS_TO_NOTIFY_RESET'

export const ORDERS_DELIVERY_ISSUES_REQUEST = 'ORDERS_DELIVERY_ISSUES_REQUEST'
export const ORDERS_DELIVERY_ISSUES_FAILS = 'ORDERS_DELIVERY_ISSUES_FAILS'
export const ORDERS_DELIVERY_ISSUES_SUCCESS = 'ORDERS_DELIVERY_ISSUES_SUCCESS'
export const ORDERS_DELIVERY_ISSUES_RESET = 'ORDERS_DELIVERY_ISSUES_RESET'

export const ORDERS_CLIENTS_ISSUES_REQUEST = 'ORDERS_CLIENTS_ISSUES_REQUEST'
export const ORDERS_CLIENTS_ISSUES_FAILS = 'ORDERS_CLIENTS_ISSUES_FAILS'
export const ORDERS_CLIENTS_ISSUES_SUCCESS = 'ORDERS_CLIENTS_ISSUES_SUCCESS'
export const ORDERS_CLIENTS_ISSUES_RESET = 'ORDERS_CLIENTS_ISSUES_RESET'

export const TODAY_SUBSCRIPTION_ORDERS_REQUEST = 'TODAY_SUBSCRIPTION_ORDERS_REQUEST'
export const TODAY_SUBSCRIPTION_ORDERS_FAILS = 'TODAY_SUBSCRIPTION_ORDERS_FAILS'
export const TODAY_SUBSCRIPTION_ORDERS_SUCCESS = 'TODAY_SUBSCRIPTION_ORDERS_SUCCESS'
export const TODAY_SUBSCRIPTION_ORDERS_RESET = 'TODAY_SUBSCRIPTION_ORDERS_RESET'

export const NEXT_WEEK_SUBSCRIPTION_ORDERS_REQUEST = 'NEXT_WEEK_SUBSCRIPTION_ORDERS_REQUEST'
export const NEXT_WEEK_SUBSCRIPTION_ORDERS_FAILS = 'NEXT_WEEK_SUBSCRIPTION_ORDERS_FAILS'
export const NEXT_WEEK_SUBSCRIPTION_ORDERS_SUCCESS = 'NEXT_WEEK_SUBSCRIPTION_ORDERS_SUCCESS'
export const NEXT_WEEK_SUBSCRIPTION_ORDERS_RESET = 'NEXT_WEEK_SUBSCRIPTION_ORDERS_RESET'

export const ORDER_PAYMENT_RESPONSE_REQUEST = 'ORDER_PAYMENT_RESPONSE_REQUEST'
export const ORDER_PAYMENT_RESPONSE_FAILS = 'ORDER_PAYMENT_RESPONSE_FAILS'
export const ORDER_PAYMENT_RESPONSE_SUCCESS = 'ORDER_PAYMENT_RESPONSE_SUCCESS'
export const ORDER_PAYMENT_RESPONSE_RESET = 'ORDER_PAYMENT_RESPONSE_RESET'

export const SUBSCRIPTION_LIST_REQUEST = 'SUBSCRIPTION_LIST_REQUEST'
export const SUBSCRIPTION_LIST_FAILS = 'SUBSCRIPTION_LIST_FAILS'
export const SUBSCRIPTION_LIST_SUCCESS = 'SUBSCRIPTION_LIST_SUCCESS'
export const SUBSCRIPTION_LIST_RESET = 'SUBSCRIPTION_LIST_RESET'

export const PAST_CANCELED_ORDERS_REQUEST = 'PAST_CANCELED_ORDERS_REQUEST'
export const PAST_CANCELED_ORDERS_FAILS = 'PAST_CANCELED_ORDERS_FAILS'
export const PAST_CANCELED_ORDERS_SUCCESS = 'PAST_CANCELED_ORDERS_SUCCESS'
export const PAST_CANCELED_ORDERS_RESET = 'PAST_CANCELED_ORDERS_RESET'

export const TOMORROW_SUBSCRIPTION_ORDERS_REQUEST = 'TOMORROW_SUBSCRIPTION_ORDERS_REQUEST'
export const TOMORROW_SUBSCRIPTION_ORDERS_FAILS = 'TOMORROW_SUBSCRIPTION_ORDERS_FAILS'
export const TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS = 'TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS'
export const TOMORROW_SUBSCRIPTION_ORDERS_RESET = 'TOMORROW_SUBSCRIPTION_ORDERS_RESET'

export const THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_REQUEST = 'THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_REQUEST'
export const THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_FAILS = 'THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_FAILS'
export const THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS = 'THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS'
export const THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_RESET = 'THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_RESET'

export const FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST = 'FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST'
export const FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS = 'FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS'
export const FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS = 'FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS'
export const FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET = 'FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET'

export const FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST = 'FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST'
export const FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS = 'FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS'
export const FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS = 'FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS'
export const FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET = 'FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET'

export const SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST = 'SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST'
export const SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS = 'SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS'
export const SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS = 'SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS'
export const SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET = 'SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET'

export const SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST = 'SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST'
export const SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS = 'SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS'
export const SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS = 'SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS'
export const SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET = 'SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET'

export const TODAY_CART_ITEMS_REQUEST = 'TODAY_CART_ITEMS_REQUEST'
export const TODAY_CART_ITEMS_FAILS = 'TODAY_CART_ITEMS_FAILS'
export const TODAY_CART_ITEMS_SUCCESS = 'TODAY_CART_ITEMS_SUCCESS'
export const TODAY_CART_ITEMS_RESET = 'TODAY_CART_ITEMS_RESET'

export const TOMORROW_CART_ITEMS_REQUEST = 'TOMORROW_CART_ITEMS_REQUEST'
export const TOMORROW_CART_ITEMS_FAILS = 'TOMORROW_CART_ITEMS_FAILS'
export const TOMORROW_CART_ITEMS_SUCCESS = 'TOMORROW_CART_ITEMS_SUCCESS'
export const TOMORROW_CART_ITEMS_RESET = 'TOMORROW_CART_ITEMS_RESET'

export const THE_DAY_AFTER_TOMORROW_CART_ITEMS_REQUEST = 'THE_DAY_AFTER_TOMORROW_CART_ITEMS_REQUEST'
export const THE_DAY_AFTER_TOMORROW_CART_ITEMS_FAILS = 'THE_DAY_AFTER_TOMORROW_CART_ITEMS_FAILS'
export const THE_DAY_AFTER_TOMORROW_CART_ITEMS_SUCCESS = 'THE_DAY_AFTER_TOMORROW_CART_ITEMS_SUCCESS'
export const THE_DAY_AFTER_TOMORROW_CART_ITEMS_RESET = 'THE_DAY_AFTER_TOMORROW_CART_ITEMS_RESET'

export const FOUR_DAYS_FROM_TODAY_CART_ITEMS_REQUEST = 'FOUR_DAYS_FROM_TODAY_CART_ITEMS_REQUEST'
export const FOUR_DAYS_FROM_TODAY_CART_ITEMS_FAILS = 'FOUR_DAYS_FROM_TODAY_CART_ITEMS_FAILS'
export const FOUR_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS = 'FOUR_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS'
export const FOUR_DAYS_FROM_TODAY_CART_ITEMS_RESET = 'FOUR_DAYS_FROM_TODAY_CART_ITEMS_RESET'

export const FIVE_DAYS_FROM_TODAY_CART_ITEMS_REQUEST = 'FIVE_DAYS_FROM_TODAY_CART_ITEMS_REQUEST'
export const FIVE_DAYS_FROM_TODAY_CART_ITEMS_FAILS = 'FIVE_DAYS_FROM_TODAY_CART_ITEMS_FAILS'
export const FIVE_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS = 'FIVE_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS'
export const FIVE_DAYS_FROM_TODAY_CART_ITEMS_RESET = 'FIVE_DAYS_FROM_TODAY_CART_ITEMS_RESET'

export const SIX_DAYS_FROM_TODAY_CART_ITEMS_REQUEST = 'SIX_DAYS_FROM_TODAY_CART_ITEMS_REQUEST'
export const SIX_DAYS_FROM_TODAY_CART_ITEMS_FAILS = 'SIX_DAYS_FROM_TODAY_CART_ITEMS_FAILS'
export const SIX_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS = 'SIX_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS'
export const SIX_DAYS_FROM_TODAY_CART_ITEMS_RESET = 'SIX_DAYS_FROM_TODAY_CART_ITEMS_RESET'

export const SEVEN_DAYS_FROM_TODAY_CART_ITEMS_REQUEST = 'SEVEN_DAYS_FROM_TODAY_CART_ITEMS_REQUEST'
export const SEVEN_DAYS_FROM_TODAY_CART_ITEMS_FAILS = 'SEVEN_DAYS_FROM_TODAY_CART_ITEMS_FAILS'
export const SEVEN_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS = 'SEVEN_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS'
export const SEVEN_DAYS_FROM_TODAY_CART_ITEMS_RESET = 'SEVEN_DAYS_FROM_TODAY_CART_ITEMS_RESET'

export const TOP_USERS_LIST_REQUEST = 'TOP_USERS_LIST_REQUEST'
export const TOP_USERS_LIST_SUCCESS = 'TOP_USERS_LIST_SUCCESS'
export const TOP_USERS_LIST_FAILS = 'TOP_USERS_LIST_FAILS'
export const TOP_USERS_LIST_RESET = 'TOP_USERS_LIST_RESET'

export const USER_ORDERS_LIST_REQUEST = 'USER_ORDERS_LIST_REQUEST'
export const USER_ORDERS_LIST_SUCCESS = 'USER_ORDERS_LIST_SUCCESS'
export const USER_ORDERS_LIST_FAILS = 'USER_ORDERS_LIST_FAILS'
export const USER_ORDERS_LIST_RESET = 'USER_ORDERS_LIST_RESET'

export const USER_LAST_ORDER_REQUEST = 'USER_LAST_ORDER_REQUEST'
export const USER_LAST_ORDER_SUCCESS = 'USER_LAST_ORDER_SUCCESS'
export const USER_LAST_ORDER_FAILS = 'USER_LAST_ORDER_FAILS'
export const USER_LAST_ORDER_RESET = 'USER_LAST_ORDER_RESET'

export const USER_PAYMENT_METHOD_LIST_REQUEST = 'USER_PAYMENT_METHOD_LIST_REQUEST'
export const USER_PAYMENT_METHOD_LIST_SUCCESS = 'USER_PAYMENT_METHOD_LIST_SUCCESS'
export const USER_PAYMENT_METHOD_LIST_FAILS = 'USER_PAYMENT_METHOD_LIST_FAILS'
export const USER_PAYMENT_METHOD_LIST_RESET = 'USER_PAYMENT_METHOD_LIST_RESET'

export const ORDERS_TO_BE_BLOCKED_REQUEST = 'ORDERS_TO_BE_BLOCKED_REQUEST'
export const ORDERS_TO_BE_BLOCKED_SUCCESS = 'ORDERS_TO_BE_BLOCKED_SUCCESS'
export const ORDERS_TO_BE_BLOCKED_FAILS = 'ORDERS_TO_BE_BLOCKED_FAILS'
export const ORDERS_TO_BE_BLOCKED_RESET = 'ORDERS_TO_BE_BLOCKED_RESET'
