import React, { lazy, Suspense} from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'
import { ProductDetailImage } from '../../../components/user/ProductDetailImage'
import { productDetailAction } from '../../../actions/productsActions'
import { CartButton } from '../../../components/user/prospects/CartButton'
import {Helmet} from "react-helmet";
import { WhatsappFloatingButton } from '../../../components/user/WhatsappFloatingButton'

const RightColumn = lazy(() => import('../../../components/user/prospects/RightColumn'))
const Footer = lazy(() => import('../../../components/user/prospects/Footer'))
const Header = lazy(() => import('../../../components/user/prospects/Header'))

export const ProductDetailScreen = ({history, match}) => {

    console.log(match, 'match')
  
    const dispatch = useDispatch()

    const detailProduct = useSelector(state => state.productDetail)
    const { loading, product, error } = detailProduct

    useEffect(() => {
        dispatch(productDetailAction(match.params.slug))
    }, [dispatch, match])

    return (
        <div>
            {product && (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{product.name}</title>
                <meta name="description" content={product.description} />
                <link rel="canonical" href="https://verdemi.com/" />
            </Helmet>
            )}
            <Suspense fallback={"Cargando..."}>
                <Header />
            </Suspense>
            <Container className='mt-5'>
                {loading ? <Loader />
                : error ? (
                    <Message variant='danger'>{error}</Message>
                ) : product ? (
                    <Row>
                        <Col lg={9} md={9}> 
                            <Row>
                            <Col md={5} className='pl-md-5'>
                                <ProductDetailImage product={product}/>
                            </Col>
                            <Col md={7}>
                                <p>{product.category}</p>
                                <h3>{product.name}</h3>
                                <h2><b>${new Intl.NumberFormat('co-CO').format(Number(product.price))}</b></h2>
                                <hr className='mt-5'></hr>
                                <CartButton product={product}/>
                                <hr></hr>
                                <p className='text-success'>{product.description}</p>
                            </Col>
                            </Row>
                        </Col>
                        <Col md={3} className='boder mt-n5' style={{zIndex:1}}>
                            <Suspense fallback={"Cargando..."}>
                                <RightColumn/>
                            </Suspense>
                        </Col>
                    </Row>
                ) : (
                    <div></div>
                )}

            </Container>
            <WhatsappFloatingButton />
            <Row className='mt-5'>
                <Col md={9} className='border bg-light pl-5 pt-3'>
                    <Suspense fallback={"Cargando..."}>
                        <Footer />
                    </Suspense>
                </Col>
            </Row>
        </div>
    )
}
