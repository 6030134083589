import React, { useEffect, useState, useRef, lazy, Suspense } from 'react'
import { 
    Col, 
    Row,
    Form,
    Button,
    Badge,
    Container,
    Spinner,
    Card
} from 'react-bootstrap'
import Carrousel4 from '../../../images/banner_3_3_11zon.webp'
import { useSelector, useDispatch } from 'react-redux'
import { ProductCheckoutImage } from '../../../components/user/prospects/ProductCheckoutImage'
import ThreeSpheres from '../../../components/user/prospects/ThreeSpheres'
import Message from '../../../components/Message';
import { userRegisterAction, userLoginAction } from '../../../actions/userActions';
import { userMessageAction } from '../../../actions/userActions'
import useGAEventTracker from '../../../hooks/useGAEventTracker';
import ReactPixel from 'react-facebook-pixel';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { WhatsappFloatingButton } from '../../../components/user/WhatsappFloatingButton'
import { ProductHorizontalCardImage } from '../../../components/user/ProductHorizontalCardImage'
import { cartValuesAction } from '../../../actions/cartActions'


const Footer = lazy(() => import('../../../components/user/prospects/Footer'))
const Header = lazy(() => import('../../../components/user/prospects/Header'))

const { 
    REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE,
    REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT,
    REACT_APP_MINIMUN_PURCHASE_NO_TRANSPORT,
    REACT_APP_TRANSPORT_CHARGE } = process.env

let autoComplete;

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
    script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
        }
    };
    } else {
    script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { componentRestrictions: { country: "co" } }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
    );
}

async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
}

export const CheckoutScreen = ({ history }) => {
    const GAEventsTracker = useGAEventTracker();
    
    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);  

    const nameRef = useRef(null);

    useEffect(() => {
        if (nameRef && nameRef.current) {
            nameRef.current.focus();
        }
    }, [nameRef])

    const makeid = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }

    const dispatch = useDispatch()

    const[name, setName] = useState('')
    const[surname, setSurname] = useState('')
    const[appartment, setAppartment] = useState('')
    const[email, setEmail] = useState('')
    const[telephone, setTelephone] = useState('')
    const[password, setPassword] = useState(makeid(10))
    const [validEmail, setValidEmail] = useState(false)
    const [validTelephone, setValidTelephone] = useState(false)
    const [sendPassword] = useState(true)

    const[cartCost, setCartCost] = useState(0)
    const[discount, setDiscount] = useState(0)
    const[transportCost, setTransportCost] = useState(0)

    const cartInfo = useSelector(state => state.cart)
    const { cartItems } = cartInfo

    const loginUser = useSelector(state => state.userLogin)
    const { loading, error, user } = loginUser

    const registeUser = useSelector(state => state.userRegister)
    const { loading: registerLoading, error: registerError, user:registerUser } = registeUser

    const personalCodeHost = useSelector(state => state.hostPersonalCode)
    const { hostPersonalCode } = personalCodeHost

    const submitHandler = (e) => {
        e.preventDefault()
        const user = {
            'name': name,
            'surname': surname,
            'address': query,
            'appartment': appartment,
            'email': email,
            'telephone': telephone,
            'password': password,
            'hostPersonalCode': hostPersonalCode,
            'sendPassword': sendPassword
        }
        dispatch(userRegisterAction(cartItems, user))
        GAEventsTracker('Sign In', 'Sign in button', 'Button')
    }

    const handleOnEmailChange = ( email ) => {
        setEmail(email)
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if ( re.test(email) ) {
            setValidEmail(true)
        }
        else {
            setValidEmail(false)
        }    
    }

    const handleOnTelephoneChange = (e) => {
        if (/^\d+$/.test(e) && e.length < 11) {
            setTelephone(e)
        } else if (e.length === 0) {
            setTelephone('')
        }
        if (e.length === 10) {
            setValidTelephone(true)
        }
    }


    useEffect(() => {
        if(registerUser) {
            dispatch(userLoginAction(registerUser.email, password))
        }
        if(user){
            history.push('/user/')
            dispatch(cartValuesAction(0, 0, 0))
            ReactPixel.track('CompleteRegistration');
            dispatch(userMessageAction(`Tu contraseña temporal es ${password}. Te la hemos enviado a tu email (puede estar en spam o promociones). Puedes modificarla ahora mismo`))
        }
        if(cartItems) {
            setCartCost(Number(cartItems.reduce((acc, item) => 
            acc + (item.qty * Number(item.product.price)), 0
            )))
            if (Number(cartItems.reduce((acc, item) => 
            acc + (item.qty * Number(item.product.price)), 0
            )) * REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE > REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT) {
                setDiscount(REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT)
            } else {
                setDiscount(Number(cartItems.reduce((acc, item) => 
                acc + (item.qty * Number(item.product.price)), 0
                )) * REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE)    
            }
            if (Number(cartItems.reduce((acc, item) => 
            acc + (item.qty * Number(item.product.price)), 0
            )) > REACT_APP_MINIMUN_PURCHASE_NO_TRANSPORT) {
                setTransportCost(0)
            } else {
                setTransportCost(Math.round(Number(REACT_APP_TRANSPORT_CHARGE) * (1 - (Number(cartItems.reduce((acc, item) => 
                acc + (item.qty * Number(item.product.price)), 0
                ))/REACT_APP_MINIMUN_PURCHASE_NO_TRANSPORT))))
            }
        }
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_API_KEY}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
          );    
    }, [
        user,
        history,
        registerLoading,
        registerUser,
        dispatch,
        password,
        cartItems,
        cartCost,
        setCartCost,
        setDiscount,
        setTransportCost
        ])

    return (
        <Container>
            <Suspense fallback={"Cargando..."}>
                <Header />
            </Suspense>
            <Row className='mt-5'>
                <LazyLoadImage
                    src={Carrousel4}
                    alt='Verdemi, Frutas y Verduras'
                    className="img-fluid d-none d-sm-block mt-3"
                    style={{
                        margin:'auto',
                        display:'block'
                    }}
                />
            </Row>
            <Row className='border-top border-bottom mb-3 mt-5 mt-sm-3 py-3'>
                <Col>
                    <Row>
                        <Col>
                            <Row>
                                <Col className='text-center mx-auto'>
                                <h2 className='text-muted'><b>Detalles de entrega</b></h2>
                                </Col>
                            </Row>
                            <ThreeSpheres step={1} />
                            {/* <h4 className='mt-1 text-success' style={{ fontSize: '1.4em'}}><i className="far fa-id-badge"></i> Datos de entrega (Paso 1 de 3)</h4> */}
                            <p className='mb-1 text-muted mt-5 mb-3'>Tipo de pedido</p>
                            {registerError && (<Message variant='danger'>{registerError}</Message>)}
                            {error && (<Message variant='danger'>{error}</Message>)}
                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`default-${type}`} className="mb-3">
                                    <Form.Check 
                                        type={type}
                                        id={`default-${type}`}
                                        checked
                                        label={<span style={{ marginTop: '20px' }}>Subscripción <span style={{ fontSize: '0.6em'}}>(te llevaremos tu pedido con la frecuencia que definas)</span></span>}
                                        className='mb-1'
                                        style={{ border: '1px solid green', round: '3px', paddingBottom: '0.8em', paddingTop: '0.8em', borderRadius: '0.4em', paddingLeft: '30px'}}
                                    />

                                    </div>
                                ))}
                            </Form>
                            <Form onSubmit={submitHandler}>
                                <Row className="g-2">
                                    <Col md>
                                        <Form.Control
                                            ref={nameRef}
                                            className='mb-3 mb-sm-0'
                                            type="text"
                                            disabled={registerLoading || loading}
                                            required placeholder="Nombre"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                        />
                                    </Col>
                                    <Col md className='mt-xs-3 '>
                                        <Form.Control
                                            type="text"
                                            placeholder="Apellido"
                                            disabled={registerLoading || loading}
                                            value={surname}
                                            onChange={(e) => setSurname(e.target.value)}
                                            style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                        />
                                    </Col>
                                </Row>
                                <Row className="g-2 my-3">
                                    <Col md>
                                        <div className="search-location-input">
                                            <label  style={{ position: 'relative', backgroundColor: '#F7F7F7', display: 'flex', alignItems: 'center', marginBottom: '0.0em'}}>
                                                <Form.Control
                                                    ref={autoCompleteRef}
                                                    placeholder=""
                                                    disabled={registerLoading || loading}
                                                    type="text"
                                                    value={query}
                                                    onChange={event => setQuery(event.target.value)}
                                                    style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                                />
                                                {query === '' && (
                                                    <>
                                                        <div style={{ position: 'absolute', top: '50%', left: '15px', transform: 'translateY(-50%)', fontSize: '16px', color: '#666' }}>Dirección</div>
                                                        <div style={{ position: 'absolute', top: '50%', left: '90px', transform: 'translateY(-50%)', fontSize: '12px', color: '#666' }}>(para enviarte tu pedido)</div>
                                                    </>
                                                )}
                                            </label>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="g-2 my-3">
                                    <Col md>
                                        <label style={{ position: 'relative', backgroundColor: '#F7F7F7', display: 'flex', alignItems: 'center', marginBottom: '0.0em' }}>
                                            <Form.Control
                                                type="text"
                                                required
                                                disabled={registerLoading || loading}
                                                placeholder=""
                                                value={appartment}
                                                onChange={(e) => setAppartment(e.target.value)}
                                                style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                                />
                                                {appartment === '' && (
                                                    <>
                                                        <div style={{ position: 'absolute', top: '50%', left: '15px', transform: 'translateY(-50%)', fontSize: '16px', color: '#666' }}>Apartamento, escalera, etc </div>
                                                        <div style={{ position: 'absolute', top: '50%', left: '215px', transform: 'translateY(-50%)', fontSize: '12px', color: '#666' }}>(para entregarte tu pedido)</div>
                                                    </>
                                                )}
                                        </label>
                                    </Col>
                                </Row>

                                <Row className="g-2 my-3">
                                    <Col md>
                                        <Form.Control
                                            type="email"
                                            required
                                            disabled={registerLoading || loading}
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => handleOnEmailChange(e.target.value)}
                                            style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                        />
                                        {registerLoading === '' && (
                                            <>
                                                <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', fontSize: '16px', color: '#666' }}>Apartamento, escalera, etc </div>
                                                <div style={{ position: 'absolute', top: '50%', left: '150px', transform: 'translateY(-50%)', fontSize: '12px', color: '#666' }}>(para entregarte tu pedido)</div>
                                            </>
                                        )}
                                        {validEmail === false && email.length > 0 && (
                                            <Row>
                                                <Col className='mb-n1'>
                                                    <p className='text-danger mb-0' style={{fontSize: '0.9rem'}}>Inserta un email válido</p>
                                                </Col>
                                            </Row>
                                            )}
                                    </Col>
                                </Row>
                                <Row className="g-2 my-3">
                                    <Col md>
                                        <label style={{ position: 'relative', backgroundColor: '#F7F7F7', display: 'flex', alignItems: 'center' }}>
                                            <Form.Control 
                                                type="text"
                                                required
                                                disabled={registerLoading || loading}
                                                placeholder=""
                                                value={telephone}
                                                onChange={(e) => handleOnTelephoneChange(e.target.value)}
                                                style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                            />
                                            {telephone === '' && (
                                                <>
                                                    <div style={{ position: 'absolute', top: '50%', left: '15px', transform: 'translateY(-50%)', fontSize: '16px', color: '#666' }}>Teléfono</div>
                                                    <div style={{ position: 'absolute', top: '50%', left: '85px', transform: 'translateY(-50%)', fontSize: '12px', color: '#666' }}>(para contactarte si es necesario por tu pedido)</div>
                                                </>
                                            )}
                                        </label>
                                        {validTelephone === false && telephone.length > 0 && (
                                            <Row>
                                                <Col className='mb-n1'>
                                                    <p className='text-danger mb-0' style={{fontSize: '0.9rem'}}>Inserta un teléfono válido</p>
                                                </Col>
                                            </Row>
                                            )}
                                    </Col>
                                </Row>
                                <Row className="g-2 my-3">
                                    <Col md>
                                        <Form.Control readOnly type="password" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} className='d-none'/>
                                    </Col>
                                </Row>
                                <Row className='justify-content-center'>
                                        <Button
                                            className='mb-3 btn-block mx-3'
                                            disabled={registerLoading || loading || validEmail === false || telephone.length < 9 || query.length < 10 } 
                                            type="submit"
                                            style={{ backgroundColor: '#2C971F', borderRadius: '0.4em', paddingBottom: '0.6em', paddingTop: '0.6em'}}
                                        >
                                        {registerLoading || loading ? (
                                                <Spinner
                                                    animation='border'
                                                    role='status'
                                                    style={{
                                                        height:'25px',
                                                        width:'25px',
                                                        margin:'auto',
                                                        display:'block',
                                                    }}
                                                >
                                                <span className='sr-only'>
                                                    Loading...
                                                </span>
                                                </Spinner>
                                            ) : (
                                                <span>
                                                    Procesar orden
                                                </span>
                                            )}
                                        </Button>
                                        {/* <Button variant='link' className='ml-3'>
                                            Volver al carrito
                                        </Button> */}
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col md={5} className='bg-light border pl-md-5'>
                    <p className='mt-3 ml-3' style={{ color: '#000000' }}>Tus productos</p>
                    <div className='mt-3'>
                    {cartItems ? (
                        <div>
                            <div className='d-none d-sm-block'>
                                {cartItems.map(item => (
                                    <Row key={item.product.id} className='mt-1'>
                                        <Col md={9}>
                                            <ProductCheckoutImage product={item.product}/>
                                            <Badge bg="success" className='ml-1 text-white'>{item.qty}</Badge>
                                            <span className='ml-1 ml-md-3'>{item.product.name}</span>
                                        </Col>
                                        <Col md={3}>
                                            <span className='ms-auto'>${new Intl.NumberFormat('co-CO').format(Number(item.qty * item.product.price))}</span>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                            <div className='d-none'>
                            {cartItems.map(item => (
                                <Card key={item.product.id} className='my-1 ml-3'>
                                    <Card.Body className='py-n5'>
                                            <div className='rounded bg-white my-1' key={item.product.id}>
                                                <Row>
                                                    <Col xs={3} className='my-n3'>
                                                        <ProductHorizontalCardImage product={item.product}/>
                                                    </Col>
                                                    <Col xs={9} className='my-n3'>
                                                        <p className="card-title pl-3" style={{ marginTop: '0.6em', marginBottom: '0.1em'}}><b>{item.product.name}</b></p>
                                                        <p className="card-text pl-3" style={{ marginTop: '0.1em'}}>
                                                            <small className="text-muted">
                                                                {item.qty} unidades. ${new Intl.NumberFormat('co-CO').format(Number(item.qty * item.product.price))}
                                                            </small>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>    
                                    </Card.Body>
                                </Card>
                                ))}   
                            </div>
                            <hr></hr>
                            <Row className='mb-0 text-muted'>
                                <Col xs={8}>
                                    <p className='ml-3 ml-sm-0'>Subtotal <small>(detalle en el carrito)</small></p>
                                </Col>
                                <Col xs={4} className='text-right'>
                                    <p className='mr-3'>$
                                    <span>{new Intl.NumberFormat('co-CO').format(Math.round(Number(cartCost)))}</span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className='mb-0 text-muted'>
                                <Col xs={8}>
                                    <p className='ml-3 ml-sm-0'>Transporte</p>
                                </Col>
                                <Col xs={4} className='text-right'>
                                    <p className='mr-3'>$
                                    <span>{new Intl.NumberFormat('co-CO').format(Math.round(Number(transportCost)))}</span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className='mb-0 text-muted'>
                                <Col xs={8}>
                                    <p className='ml-3 ml-sm-0' style={{ color: '#29BC5A'}}>Descuentos</p>
                                </Col>
                                <Col xs={4} className='text-right'>
                                    <p className='mr-3'>
                                    <span style={{ color: '#29BC5A'}}>-${new Intl.NumberFormat('co-CO').format(Math.round(Number(discount)))}</span>
                                    </p>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col xs={7}>
                                    <p className='ml-3 ml-sm-0'>Total</p>
                                </Col>
                                <Col xs={5} className='text-right'>
                                    <p style={{fontSize:'2rem'}}>$
                                    <span className='ms-auto mr-3'>{new Intl.NumberFormat('co-CO').format(Math.round(Number(cartCost + transportCost - discount)))}</span>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <p>Tu carrito está vacío</p>
                    )}
                    </div>
                </Col>
            </Row>
            <Suspense fallback={"Cargando..."}>
                <Footer />
            </Suspense>
            <WhatsappFloatingButton />
        </Container>
    )
}
