import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Alert } from 'react-bootstrap'


export const InscriptionCompletedMessage = () => {

    const [inscriptionCompleted, setInscriptionCompleted] = useState(false);

    const paymentMethodList = useSelector(state => state.userPaymentMethodList)
    const { paymentMethod } = paymentMethodList

    const cardCreate = useSelector(state => state.createCard)
    const { success: creditCardCreationSuccess } = cardCreate

    useEffect(() => {
        if (paymentMethod) {
            if (paymentMethod.length > 1) {
                setInscriptionCompleted(false)
            }    
        }
        if (creditCardCreationSuccess) {
            setInscriptionCompleted(true)
        }
        if (inscriptionCompleted) {
            setTimeout(() => { setInscriptionCompleted(false) }, 20000);
        }
        // if (updateStatusSuccess) {
        //     setTimeout(() => { setDisplayConfety(false) }, 10000);
        // }
    }, [
        paymentMethod,
        creditCardCreationSuccess,
        inscriptionCompleted,
    ])

    return (
        <div>
            {inscriptionCompleted && (
                <Alert variant='success'>
                    <Alert.Heading className="text-center">
                        ENHORABUENA! INSCRIPCIÓN COMPLETADA!
                    </Alert.Heading>
                </Alert>
            )}
        </div>    
    )
}
