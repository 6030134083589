import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalChangeFrecuency } from '../../../OrderInfo/ModalChangeFrecuency'


export const ChangeFrecuencyUserModalButton = () => {

    const [changeSubscriptionFrecuencyShow, setchangeSubscriptionFrecuencyShow] = useState(false);
    const toggleChangeSubscriptionFrecuencyModal = () => {
        setchangeSubscriptionFrecuencyShow(!changeSubscriptionFrecuencyShow)
    }


    return (
        <div>
            <Button type="Button" className="btn btn-info btn-sm btn-block" onClick={toggleChangeSubscriptionFrecuencyModal}>
                <i className="fas fa-calendar-alt"></i> Modificar frecuencia
            </Button>
            {changeSubscriptionFrecuencyShow ? (
                <ModalChangeFrecuency isOpen={changeSubscriptionFrecuencyShow} toggle={setchangeSubscriptionFrecuencyShow} />
            ) : (
                <div></div>
            )}
        </div>
    )
}
