import React, { useEffect, useState } from 'react'

import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { WhatsappFloatingButton } from './WhatsappFloatingButton'
import { getDeliveryOrdersAction } from '../../actions/orderActions'
import { userDetailAction } from '../../actions/userActions'


const UserDelivery = ({history}) => {

    const [loading] = useState(false);

    const detailUser = useSelector(state => state.userDetail)
    const { user: userDetail, loading: loadingUserDetail } = detailUser

    const loginUser = useSelector(state => state.userLogin)
    const {user: userLogin } = loginUser

    const dispatch = useDispatch()

    useEffect(() => {
        if ((userDetail === null || !userDetail) && loadingUserDetail === false) {
            console.log(1)
            dispatch(userDetailAction())
        }
    }, [history, userLogin, dispatch, loadingUserDetail, userDetail])

    const [maxOrderToShow, setMaxOrderToShow] = useState(null)

    const [showDeliveryInfoToUser, setShowDeliveryInfoToUser] = useState(false)

    const ordersDelivery = useSelector(state => state.getDeliveryOrders)
    const { deliveryOrders } = ordersDelivery

    useEffect(() => {
        if (deliveryOrders && userDetail) {
            const maxOrder = deliveryOrders.filter(order => order.subscription.user.validated_address === userDetail.validated_address)
            if (maxOrder && maxOrder[0] && maxOrder[0].delivery_position) {
                setMaxOrderToShow(maxOrder[0].delivery_position)
            }
            const includedInDelivery = deliveryOrders.filter(deliveryOrder => deliveryOrder.subscription.user.validated_address === userDetail.validated_address)
            if (includedInDelivery.length > 0) {
                setShowDeliveryInfoToUser(true)
            }
        }
    }, [deliveryOrders, userDetail])


    return (
        <div>
            <Container>
                <Row>
                    <Col className='mt-5 delivery-body'>
                        <h5>ENTREGA DE PRODUCTOS</h5>
                        {loading || loadingUserDetail ? (
                            <Spinner
                                animation='border'
                                role='status'
                                style={{
                                    height:'25px',
                                    width:'25px',
                                    margin:'auto',
                                    display:'block'
                                }}
                                >
                                    <span className='sr-only'>
                                        Loading...
                                    </span>
                            </Spinner>
                        ) : (
                            <div>
                            {deliveryOrders && deliveryOrders.length > 0 && showDeliveryInfoToUser ? (
                                <div>
                                    <div>
                                        <div className="timeline-main">
                                        <div className="timeline-wrap">
                                        {deliveryOrders.filter(order => order.delivery_position <= maxOrderToShow).map(order => 
                                                <div key={order.pk} className="timeline-card">
                                                <div className="timeline-card-wrap my-0 py-1" style={{backgroundColor: order.status.name === 'Delivered' ? 'green' : 'red', border: order.subscription.user.validated_address === userDetail.validated_address ? '10px solid yellow' : ''}}>
                                                    <div className="card-head-wrap">
                                                        <h2 className="timeline-card-head">{order.status.name === 'Delivered' ? 'Entregado' : 'En camino'}</h2>
                                                    </div>
                                                        {order.status.name === 'Delivered' && (
                                                            <p className="timeline-card-text my-0" style={{ color: 'white'}}>Hora de entrega: {new Date(order.real_delivery_time).getHours()}:{new Date(order.real_delivery_time).getMinutes()}</p>
                                                        )}
                                                        <p className="timeline-card-text my-0" style={{ color: 'white'}}>
                                                            {order.subscription.user.validated_address}
                                                        </p>
                                                </div>
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                    </div></div>
                            ) : (
                                <div>No hay información de entrega para mostrar</div>
                            )}
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
            <WhatsappFloatingButton />
        </div>
    )
}

export default UserDelivery
