import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Confetti from 'react-confetti'


export const DisplayConfety = () => {

    const [displayConfety, setDisplayConfety] = useState(false);

    const paymentMethodList = useSelector(state => state.userPaymentMethodList)
    const { paymentMethod } = paymentMethodList

    const cardCreate = useSelector(state => state.createCard)
    const { success: creditCardCreationSuccess } = cardCreate

    const updateStatusSubscription = useSelector(state => state.subscriptionUpdateStatus)
    const { success: updateStatusSuccess } = updateStatusSubscription

    useEffect(() => {
        if (paymentMethod) {
            if (paymentMethod.length > 1) {
                setDisplayConfety(false)
            }    
        }
        if (creditCardCreationSuccess) {
            setDisplayConfety(true)
            setTimeout(() => { setDisplayConfety(false) }, 10000);
        }
        // if (inscriptionCompleted) {
        //     setTimeout(() => { setDisplayConfety(false) }, 10000);
        // }
        if (updateStatusSuccess) {
            setDisplayConfety(true)
            setTimeout(() => { setDisplayConfety(false) }, 10000);
        }
    }, [
        paymentMethod,
        creditCardCreationSuccess,
        updateStatusSuccess
        // inscriptionCompleted,
    ])

    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
        });
        useEffect(() => {
        function handleResize() {
            setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowSize;
    }

    const { width, height } = useWindowSize()
    return (
        <div>
            {displayConfety && (
                <Confetti
                width={width}
                height={height}
                />
            )}

        </div>    
)
}
