import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import { FrecuencyForm } from '../FrecuencyForm'

export const ModalChangeFrecuency = (props) => {

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    const CloseChangeFrecuencyModal = () => {
        props.toggle()
    }

    return (
        <div>
            <Modal show={props.isOpen} onHide={props.toggle} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title><i className="fas fa-calendar-alt"></i> Modificar frecuencia de entrega</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Frecuencia actual: {subscription && subscription.frecuency ? (<span>{subscription.frecuency && (subscription.frecuency.readeble_frecuency)}</span>) : (<span></span>)}</p>
                    <p>CREA TU NUEVA FRECUENCIA DE ENTREGA <small>(Ajustaremos tu órdenes actuales a esta nueva frecuencia)</small></p>
                    <hr></hr>
                    <FrecuencyForm create={false} closeModal={props.toggle}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={CloseChangeFrecuencyModal} className='mx-3 btn-block'> 
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>            
        </div>
    )
}
