import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalPaymentMethods } from './ModalPaymentMethods';


export const PaymentMethodsModalButton = () => {

    const [paymentMethodsShow, setPaymentMethodsShow] = useState(false);
    const togglePaymentMethodModal = () => {
        setPaymentMethodsShow(!paymentMethodsShow)
    } 

    return (
        <div>
            <Button variant='outline-primary' className="btn btn-outline-primary btn-block btn-lg my-1" onClick={togglePaymentMethodModal}>
                <i className="fas fa-credit-card"></i>
                <small> Gestionar Métodos de pago</small>
            </Button>

            <ModalPaymentMethods isOpen={paymentMethodsShow} toggle={togglePaymentMethodModal}/>
        </div>
    )
}
