import React, { useState, useEffect, lazy, Suspense, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Form, Button, Spinner, Image } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../../../components/Message' 
import { userLoginAction } from '../../../actions/userActions'
import VerdemiLogo from '../../../images/logo verdemi.png'
import useGAEventTracker from '../../../hooks/useGAEventTracker';
import { WhatsappFloatingButton } from '../../../components/user/WhatsappFloatingButton'

const Footer = lazy(() => import('../../../components/user/prospects/Footer'))
const Header = lazy(() => import('../../../components/user/prospects/Header'))

export const UserLoginScreen = ({history}) => {
    const GAEventsTracker = useGAEventTracker();

    const dispatch = useDispatch()

    const emailRef = useRef(null)

    useEffect(() => {
        if (emailRef && emailRef.current) {
            emailRef.current.focus();
        }
    }, [emailRef])

    const loginUser = useSelector(state => state.userLogin)
    const { loading, error, user } = loginUser

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [validEmail, setValidEmail] = useState(false)

    const submitHandler = e => {
        e.preventDefault()
        dispatch(userLoginAction(email, password))
        GAEventsTracker('Sign In', 'Sign in button', 'Button')
    }

    const handleOnEmailChange = ( email ) => {
        setEmail(email)
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if (re.test(email) ) {
            setValidEmail(true)
        }
        else {
            setValidEmail(false)
        }
    }


    useEffect(() => {
        if(user){
            history.push('/user/')
        }
    }, [user, history])

    return (
        <div>
            <Suspense fallback={"Cargando..."}>
                <Header />
            </Suspense>
            <Container>
                <Row className='mt-3 d-none d-md-block'>
                    <Col className='d-flex justify-content-center mx-3 my-1 mt-5'>
                        <Image className="img-fluid" src={VerdemiLogo} alt="First slide"/>
                    </Col>
                </Row>
                <div className='primary-overlay mt-5 mt-md-1'>
                    <Row className='justify-content-md-center mt-5 mt-md-1'>
                        <Col md={6} className='mt-5 mt-md-1'>
                            <h1 className='mt-1 text-center'>Inicia Sesión</h1>
                            <p className='text-center text-dark'>Si todavía no tienes una cuenta, entonces por favor
                                <LinkContainer to='/register/' className='text-primary'>
                                    <span> regístrate </span>
                                </LinkContainer>
                            primero.</p>
                            {error ? (
                                <div>
                                    {error === 'No active account found with the given credentials' ? (
                                        <Message variant='danger'>No se encontró una cuenta activa con las credenciales dadas</Message>
                                        ) : (
                                        <Message variant='danger'>{error}</Message>
                                    )}
                                </div>
                            ) : (
                                <div></div>
                            )}


                            <Form onSubmit={submitHandler}>
                                <Row className="g-2 my-3">
                                    <Col md>
                                        <Form.Control
                                            ref={emailRef}
                                            type="email"
                                            required
                                            disabled={loading}
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => handleOnEmailChange(e.target.value)}
                                            style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                        />
                                        {validEmail === false && email.length > 0 && (
                                            <Row>
                                                <Col className='mb-n1'>
                                                    <p className='text-danger mb-0' style={{fontSize: '0.9rem'}}>Inserta un email válido</p>
                                                </Col>
                                            </Row>
                                            )}
                                    </Col>
                                </Row>

                                <Row className="g-2 my-3">
                                    <Col md>
                                        <Form.Control
                                            type="password"
                                            required
                                            disabled={loading}
                                            placeholder="Contraseña"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                        />
                                        {password.length < 10 && password.length > 0 && (
                                            <Row>
                                                <Col className='mb-n1'>
                                                    <p className='text-danger mb-0' style={{fontSize: '0.9rem'}}>La contraseña tiene que tener al menos 10 caracteres</p>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                                <Button type='submit' disabled={loading || validEmail === false || password.length === 0 } variant='success' className='mt-3 align-self-center btn-block btn-lg rounded-pill'>
                                    {loading ? (
                                            <Spinner
                                            animation='border'
                                            role='status'
                                            style={{
                                                height:'25px',
                                                width:'25px',
                                                margin:'auto',
                                                display:'block'
                                            }}
                                            >
                                                <span className='sr-only'>
                                                    Loading...
                                                </span>
                                            </Spinner>
                                    ) : (
                                    <span>
                                        Iniciar sesión
                                    </span>
                                    )}
                                </Button>
                            </Form>
                            <Row className='d-flex justify-content-center mb-3 mt-1'>
                                <LinkContainer to='/recover_password/' className='text-primary'>
                                    <span> ¿Olvidaste tu contraseña? </span>
                                </LinkContainer>
                                {/* <a className="button secondaryAction align-self-center text-primary" href="https://www.verdemi.com/accounts/password/reset/">¿Olvidaste tu contraseña?</a> */}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
            <WhatsappFloatingButton />
            <Row className='bg-light d-flex justify-content-center p-3 p-md-5'>
                <Col>
                    <Suspense fallback={"Cargando..."}>
                        <Footer />
                    </Suspense>
                </Col>
            </Row>
        </div>
    )
}
