import {
    TERMS_SECTIONS_REQUEST,
    TERMS_SECTIONS_SUCCESS,
    TERMS_SECTIONS_FAILS,

    TERMS_AND_CONDITIONS_REQUEST,
    TERMS_AND_CONDITIONS_SUCCESS,
    TERMS_AND_CONDITIONS_FAILS,

    PRIVACY_POLICY_REQUEST,
    PRIVACY_POLICY_SUCCESS,
    PRIVACY_POLICY_FAILS

} from "../constants/appInfoConstants"


export const termsSectionsReducer = (state = {}, action) => {
    switch (action.type) {
        case TERMS_SECTIONS_REQUEST:
            return {
                loading: true
            }
        case TERMS_SECTIONS_SUCCESS:
            return {
                loading: false,
                termsSections: action.payload
            }
        case TERMS_SECTIONS_FAILS:
            return {
                loading: true,
                error: action.payload
            }
        default:
            return state
    }
}


export const termsAndConditionsReducer = (state = {}, action) => {
    switch (action.type) {
        case TERMS_AND_CONDITIONS_REQUEST:
            return {
                loading: true
            }
        case TERMS_AND_CONDITIONS_SUCCESS:
            return {
                loading: false,
                termsInfo: action.payload
            }
        case TERMS_AND_CONDITIONS_FAILS:
            return {
                loading: true,
                error: action.payload
            }
        default:
            return state
    }
}


export const privacyPolicyReducer = (state = {}, action) => {
    switch (action.type) {
        case PRIVACY_POLICY_REQUEST:
            return {
                loading: true
            }
        case PRIVACY_POLICY_SUCCESS:
            return {
                loading: false,
                privacyPolicy: action.payload
            }
        case PRIVACY_POLICY_FAILS:
            return {
                loading: true,
                error: action.payload
            }
        default:
            return state
    }
}
