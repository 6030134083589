import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../../Loader'
import Message from '../../../Message' 
import { ActivateSubscriptionSection } from './ActivateSubscriptionSection'
import { PendingTokenSubscriptionSection } from './PendingTokenSubscriptionSection'
import { CanceledSubscriptionSection } from './CanceledSubscriptionSection'

import {
    nextWeekSubscriptionOrderDetailAction,
    removeLocalOrderAction,
} from '../../../../actions/orderActions'
import { 
    subscriptionDetailAction,
    removeLocalSubscriptionAction,
    SubscriptionIdAction } from '../../../../actions/subscriptionActions'
import {
    SUBSCRIPTION_UPDATE_STATUS_RESET,
    SUBSCRIPTION_CHECK_STATUS_ACTIVE_RESET
     } from '../../../../constants/subscriptionConstants'
import { userMessageAction } from '../../../../actions/userActions'

export const SubscriptionInfoSection = ({ history }) => {

    const dispatch = useDispatch()

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const idSubscription = useSelector(state => state.SubscriptionId)
    const { id } = idSubscription

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { error:subscriptionDetailError, loading:subscriptionDetailLoading, subscription } = detailSubscription

    const updateStatusSubscription = useSelector(state => state.subscriptionUpdateStatus)
    const { success: updateStatusSuccess } = updateStatusSubscription

    const productUpdateCreatedSubscriptionOder = useSelector(state => state.updateCreatedSubscriptionOderProduct)
    const { success: successUpdateSubsciptionOrderProduct } = productUpdateCreatedSubscriptionOder

    const checkActiveStatusSubscription = useSelector(state => state.subscriptionCheckActiveStatus)
    const { success: successCheckActiveStatusSubscription } = checkActiveStatusSubscription

    useEffect(() => {
        if (successCheckActiveStatusSubscription) {
            dispatch(subscriptionDetailAction())
            dispatch(removeLocalSubscriptionAction())
            dispatch({ type: SUBSCRIPTION_CHECK_STATUS_ACTIVE_RESET })
        }
        if(!subscription && subscriptionDetailLoading === false && user) {
            dispatch(subscriptionDetailAction())
        }
        if (updateStatusSuccess) {
            dispatch(userMessageAction('SUBSCRIPCIÓN ACTIVADA!'))
            dispatch({type: SUBSCRIPTION_UPDATE_STATUS_RESET})
            dispatch(subscriptionDetailAction())
            dispatch(nextWeekSubscriptionOrderDetailAction())
        }
        if (successUpdateSubsciptionOrderProduct) {
            removeLocalOrderAction()
        }
        if (subscription) {
            if (!id || id && subscription.id !== id) {
                dispatch(SubscriptionIdAction(subscription.id))
            }
        }
        }, [
        dispatch,
        user,
        subscription,
        subscriptionDetailLoading,
        subscriptionDetailError,
        updateStatusSuccess,
        successUpdateSubsciptionOrderProduct,
        successCheckActiveStatusSubscription,
        id
    ])

    const [activeTab, setActiveTab] = useState('OrderSubscription');
    // ESTO HAY QUE CAMBIARLO. PASAR FUNCIÓN AL COMPONENTE

    const handleSelect = (selectedKey) => {
        setActiveTab(selectedKey);
    };

    return (
        <div>
            <div>
                {subscriptionDetailLoading ? 
                    (<div>
                        <Loader fullWidth={'true'}/>
                    </div>) :
                    (
                        <div>
                            {subscriptionDetailError ? 
                                (<Message variant='danger'>{subscriptionDetailError}</Message>) : 
                            (
                                <div>
                                    {subscription ? (
                                        <div>
                                            {subscription.status.name === 'Active' ? (
                                                <div>
                                                    <ActivateSubscriptionSection />
                                                </div>
                                                ) :
                                            subscription.status.name === 'Pending token' ? (
                                                    <div>
                                                        <PendingTokenSubscriptionSection />
                                                    </div>
                                                ) :
                                            subscription.status.name === 'Canceled' ? (
                                                    <div>
                                                        <CanceledSubscriptionSection />
                                                    </div>
                                                ) :
                                                (<div></div>)
                                            }
                                        </div>
                                    ) : (
                                        <div>
                                        </div>
                                    )}
                                </div>
                            )} 
                        </div>
                    )
                }
            </div>
        </div>
    )
}
