import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
    hostPersonalCodeAction,
    resetPasswordPersonalCodeAction,
    resetPasswordPersonalIdAction
        } from '../actions/userActions'

export const RedirectScreen = ({location, history}) => {

    const dispatch = useDispatch()

    const { from } = location.state

    useEffect(() => {
        if (from.includes('/reset_password/')) {
            dispatch(resetPasswordPersonalCodeAction(from.split('/')[5]))
            dispatch(resetPasswordPersonalIdAction(from.split('/')[4]))
            history.push('/reset_password/')
        } else {
            console.log('aquí estamos llegando??')
            const url_string = from.split('/').slice(-2)[0]
            dispatch(hostPersonalCodeAction(url_string))
            history.push('/')    
        }
    })

    return (
        <div>
        </div>
    )
}
