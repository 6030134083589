import React, { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import { Row, Col } from 'react-bootstrap'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { CartButton } from '../user/prospects/CartButton'
import { OrderButton } from './clients/OrderInfo/OrderButton'

import { PriceApplied } from './PriceApplied'

import { SubscriptionButton } from './clients/OrderInfo/SubscriptionButton'
import Message from '../Message'

const { 
  REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE,
  REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT } = process.env

function Product({ product, type, new_user, horizontalSlider, disableButtons, centeredItem, DisableButtonFunction, categoryName, discount }) {

  const orderSubscriptionCreated = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
  const { error: errorOrderCreated } = orderSubscriptionCreated

  const subscriptionLocal = useSelector(state => state.localSubscription)
  const { localProductsItems: localSubscriptionProductsItems } = subscriptionLocal

  const[hoveredProduct, setHoveredProduct] = useState('')

  function SubscriptionCartItemsValue(cartItemsArray) {
    const initialValue = 0;
    const itemsCost = cartItemsArray.reduce(
    (accumulator, currentValue) => accumulator + currentValue.total_value,
    initialValue,
    );
    return itemsCost
}

  return(
    <Card
      id={`${product.id}`}
      className={horizontalSlider ? ('my-0 border card-block') : ('my-3 rounded border-0 h-100')}
      style={{
        height: horizontalSlider ? '87%' : '100%',
        width: horizontalSlider ? '14rem' : '13rem',
        borderRadius: horizontalSlider ? '5.89px' : '',
        transform: centeredItem === product.id && horizontalSlider && 'scale(1.08)',
        }}
      onMouseEnter={() => setHoveredProduct(product.id)}
      onMouseLeave={() => setHoveredProduct('product.id')}
    >

    {type === 'cart' ? (
        <div className='d-flex justify-content-center' >
        {horizontalSlider === false ? (
          <Link to={`/product/${product.slug_name}`}>
          {product ? (
            <LazyLoadImage
                className="img-fluid card-img"
                src={product.image_144}
                alt={product.name}
            />
          ) : (
            <Spinner animation="grow" variant="success" />
          )}
        </Link>
        ) : (
            <LazyLoadImage
              className="img-fluid card-img"
              src={product.image_144}
              alt={product.name}
              style={{
                borderRadius: '5.89px',
                width: '55%',
                margin: '20px',
                marginTop: '5px',
                // border: '1px solid #7F7F7F'
              }}
            />
        )}
        </div>
      ) : type === 'order' ? (
        <div>
          {product ? (
            <LazyLoadImage
                className="img-fluid card-img"
                src={product.image_144}
                alt={product.name}
                style={{borderRadius: horizontalSlider ? '20px' : ''}}
              />
            ) : (
            <Spinner animation="grow" variant="success" />
          )}
        </div>
      ) : type === 'subscription' ? (
        <div className='d-flex justify-content-center'>
        {horizontalSlider === false ? (
          <Link to={`/product/${product.slug_name}`}>
          {product ? (
            <LazyLoadImage
                className="img-fluid card-img"
                src={product.image_144}
                alt={product.name}
            />
          ) : (
            <Spinner animation="grow" variant="success" />
          )}
        </Link>
        ) : (
            <LazyLoadImage
              className="img-fluid card-img"
              src={product.image_144}
              alt={product.name}
              style={{
                borderRadius: '5.89px',
                width: '55%',
                margin: '20px',
                marginTop: '5px',
                marginBottom: '5px'
              }}
            />
        )}
        </div>
      ) : (
        <div></div>
    )}  

      <Card.Body className='d-flex flex-column pt-0'>

      <Col >
        <Row style={{ display: 'block', height: 'auto'}}>
            {type === 'cart' ? (
              <h5
                style={{ color: 'black', fontWeight: '600', height: '40%', fontSize: horizontalSlider ? '4.7vw' : '1.5vw'}}
              >
                {product.name}
              </h5>
            ) : type === 'order' ? (
              <h5 className='text-primary'>{product.name}</h5>
            ) : type === 'subscription' ? (
              <h5
                style={{ color: 'black', fontWeight: '600', height: '40%', fontSize: horizontalSlider ? '4.7vw' : '1.5vw'}}
              >
                {product.name.length > 42 ? (<>{product.name.slice(0, 39)}...</>) : (<>{product.name}</>)}
              </h5>
            ) : (
              <div></div>
            )}        
        </Row>
        <Row className='pt-0' style={{
            marginTop: '-20px',
            paddingLeft: '3px'
          }}
        >
            <Card.Text as="h4" className='mb-n1 mx-n1'>
            {type === 'cart' ? (
              <div>
                <Row className='mb-n3' style={{ display: 'flex', justifyContent: 'center'}}>
                  <PriceApplied type={'cart'}/>
                  <Col xs={7} className='mr-n1'>
                    <p
                      className='mb-0'
                      style={{
                        marginTop: '10px',
                        borderTop: discount && discount < REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT && '3px solid #2C971F'
                      }}
                    >
                        ${new Intl.NumberFormat('co-CO').format(Number(Math.round(product.price * (1 - REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE))))}
                    </p>
                    <p style={{fontSize:"12px", marginBotton:"1px"}}>Precio dos primeras entregas</p>
                  </Col>
                  <Col className='ml-n3'>
                    <p
                      className='mb-0'
                      style={{
                        marginTop: '10px',
                        borderTop: discount === REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT ? '3px solid #2C971F' : ''}}
                    >
                      <span
                        style={{
                          fontSize: '0.6em',
                          color: 'grey'  
                        }}
                      >
                        {' '}${new Intl.NumberFormat('co-CO').format(Number(product.price))}
                      </span>
                    </p>
                    <p className='text-muted mb-0' style={{fontSize:"12px"}}>Precio regular {discount}</p>
                    <p className='text-muted mt-1' style={{fontSize:"11px"}}>{product.unit_cost}</p>  
                  </Col>
                </Row>

                {/* <p className='text-muted mb-0 mt-n3'><small>${new Intl.NumberFormat('co-CO').format(Number(product.price))}</small></p> */}
                <Row>
                  <Col>
                    <Link to={`/product/${product.slug_name}`} >
                      <small style={{ fontSize: '0.5em'}} >Más info</small>
                    </Link>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                {new_user ? (
                  <Row style={{ marginBottom: '-20px'}}>
                    <PriceApplied />
                    <Col xs={6}>
                      <p
                          className='mb-0'
                          style={{
                          fontSize: '0.8em',
                          borderTop: SubscriptionCartItemsValue(localSubscriptionProductsItems) * REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE < REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT ? '3px solid #2C971F' : '',
                        }}
                      >
                        ${new Intl.NumberFormat('co-CO').format(Number(Math.round(product.price * (1 - REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE))))}
                      </p>
                      <p style={{ fontSize: '0.5em'}}>
                          Precio dos primeras entregas
                      </p>
                    </Col>
                    <Col xs={6} className='pl-0 pr-3' style={{ borderRight: '1px solid white'}}>
                      <p
                          className='mb-0'
                          style={{
                          fontSize: '0.8em',
                          borderTop: SubscriptionCartItemsValue(localSubscriptionProductsItems) * REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE > REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT ? '3px solid #2C971F' : ''
                        }}
                      >
                        <small>${new Intl.NumberFormat('co-CO').format(Number(Math.round(product.price)))}</small>
                      </p>
                      <p style={{fontSize:"12px", paddingRight: '0.4em'}}>
                        <small>
                          Precio regular <small>(Descuento máx.: ${new Intl.NumberFormat('co-CO').format(REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT/1000)}k/mercado)</small>
                        </small>
                      </p>
                    </Col>
                  </Row>
              ) : (
                  <div>
                    <p className='mt-3'>${new Intl.NumberFormat('co-CO').format(Number(product.price))}</p>
                    <p className='text-muted mt-n1 mb-n3 text-center' style={{fontSize:"11px"}}>{product.unit_cost}</p>
                  </div>                
                )}
              </div>
            )}
            </Card.Text>
        </Row>
      </Col>



        {errorOrderCreated ?  (
          <Message variant='danger'>{errorOrderCreated}</Message>
        ) : (
          <div className='mt-auto'>
            {type === 'cart' ? (
                      <CartButton product={product} />
            ) : type === 'order' ? (
                      <OrderButton product={product} />
            ) : type === 'subscription' ? (
                      <SubscriptionButton
                        product={product}
                        disableButtons={disableButtons}
                        DisableButtonFunction={DisableButtonFunction}
                        categoryName={categoryName}
                      />
            ) : (
              <div></div>
            )}  
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default Product