export const PLATFORM_BALANCE_CHANGE_COLUMNS = [
    {
        Header: 'Fecha',
        accessor: 'date',
        Cell: props => <div> <small>{new Intl.DateTimeFormat('es-GB', { 
            month: 'long', 
            day: '2-digit',
            year: 'numeric', 
        }).format(new Date(props.value))}</small></div>
    },
    {
        Header: 'Incremento',
        accessor: 'debit_amount',
        Cell: props => <div> <small>{new Intl.NumberFormat('co-CO').format(Number(props.value))} pesos</small></div>
    },
    {
        Header: 'Reducción',
        accessor: 'credit_amount',
        Cell: props => <p><small>{new Intl.NumberFormat('co-CO').format(Number(props.value))} pesos</small></p>
    },
    {
        Header: 'Nuevo saldo',
        accessor: 'new_balance',
        Cell: props => <p><small>{new Intl.NumberFormat('co-CO').format(Number(props.value))} pesos</small></p>
    },
    {
        Header: 'Descripción',
        accessor: 'description',
        Cell: props => <div> <small>{props.value}</small></div>
    }
]