import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Message from '../../../Message' 
import { ProductHorizontalCard } from './ProductHorizontalCard'
import { ReactivateSubscriptionForm } from '../AccountInfo/ReactivateSubscriptionForm'

export const CanceledSubscriptionSection = () => {

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { error:subscriptionDetailError, loading:subscriptionDetailLoading, subscription } = detailSubscription

    const [disableButtons, setDisableButtons] = useState(false)

    const clickThreshold = 900; // Umbral de tiempo en milisegundos
    const clickTimes = useRef([]);

    const DisableButtonFunction = () => {
        clickTimes.current.push(Date.now());

        // Mantén solo los tiempos de clic dentro del umbral
        const now = Date.now();
        clickTimes.current = clickTimes.current.filter(time => now - time < clickThreshold);
    
        if (clickTimes.current.length >= 3) {
        setDisableButtons(true);
    
        // Habilitar el botón después de un tiempo
        setTimeout(() => {
            setDisableButtons(false);
        }, clickThreshold);
        }
    }

    return (
        <div>
            <div className='mt-3'>
                <Message variant='danger'>SUBSCRIPCIÓN CANCELADA</Message>
                <p>Tu subscripción está cancelada. Pueder reactivarla haciendo click en este botón</p>
                <ReactivateSubscriptionForm />                                          
            </div>
            <h4 className='mt-3 text-success'><i className="fas fa-shopping-basket"></i> Tus productos</h4>
            <hr className='border border-secondary my-0'></hr>
            <Row className='d-flex justify-content-around px-3'>
                {subscription.products_items && subscription.products_items.map(item => (
                    <Col xs={12} lg={6} key={item.id} className='px-3'>
                        <div className=' my-1 w-100' key={item.id}>
                            <ProductHorizontalCard
                                item={item}
                                disableButtons={disableButtons}
                                DisableButtonFunction={DisableButtonFunction}                                
                            />
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    )
}
