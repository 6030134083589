import React, { useEffect, useState, lazy } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect, Switch, useLocation } from 'react-router-dom'
import { LandingScreen } from './Screens/user/prospect/LandingScreen';
import { ProductDetailScreen } from './Screens/user/prospect/ProductDetailScreen';
import { CategoryDetailScreen } from './Screens/user/prospect/CategoryDetailScreen';
import { UserDetailScreen } from './Screens/user/clients/UserDetailScreen';
import { UserLoginScreen } from './Screens/user/clients/UserLoginScreen';

import { UserRecoverPasswoedScreen } from './Screens/user/clients/UserRecoverPasswoedScreen';
import { UserRecoverPasswoedEmailSentScreen } from './Screens/user/clients/UserRecoverPasswoedEmailSentScreen';
import { UserResetPasswordScreen } from './Screens/user/clients/UserResetPasswordScreen';


import { UserRegisterScreen } from './Screens/user/prospect/UserRegisterScreen';
import { RedirectScreen } from './Screens/RedirectScreen';
import { CheckoutScreen } from './Screens/user/prospect/CheckoutScreen';

import { productCategoryListAction } from './actions/productsActions'
import Sitemap from './Sitemap'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel';


function usePageViews() {
    let location = useLocation();
    useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname })
    ReactPixel.pageView()
    }, [location]);
  }

const NextWeekDeliveriesScreen = lazy(() => import('./Screens/staff/NextWeekDeliveriesScreen'))
const TodayDeliveriesScreen = lazy(() => import('./Screens/staff/TodayDeliveriesScreen'))
const StaffLandingScreen = lazy(() => import('./Screens/staff/StaffLandingScreen'))
const AboutUsScreen = lazy(() => import('./Screens/user/prospect/AboutUsScreen'))


const DonationsScreen = lazy(() => import('./Screens/user/prospect/DonationsScreen'))

const StaffDeliveryScreen = lazy(() => import('./Screens/staff/StaffDeliveryScreen'))


const InventoryScreen = lazy(() => import('./Screens/staff/InventoryScreen'))
const SubscriptionInfoScreen = lazy(() => import('./Screens/user/clients/SubscriptionInfoScreen'))
const PaymentsInfoScreen = lazy(() => import('./Screens/user/clients/PaymentsInfoScreen'))
const ClientReportedIssuesScreen = lazy(() => import('./Screens/staff/ClientReportedIssuesScreen'))
const DeliveryReportedIssuesScreen = lazy(() => import('./Screens/staff/DeliveryReportedIssuesScreen'))
const AnalyticsScreen = lazy(() => import('./Screens/staff/AnalyticsScreen'))
const TermsOfServiceScreen = lazy(() => import('./Screens/user/prospect/TermsOfServiceScreen'))
const PrivatePolicy = lazy(() => import('./Screens/user/prospect/PrivatePolicy'))
const ContactScreen = lazy(() => import('./Screens/user/prospect/ContactScreen'))
const CartDetailScreen = lazy(() => import('./Screens/user/prospect/CartDetailScreen'))
const PastCanceledOrdersInfoScreen = lazy(() => import('./Screens/staff/PastCanceledOrdersInfoScreen'))
const OrderPaymentScreen = lazy(() => import('./Screens/user/clients/OrderPaymentScreen'))
const OrderPaymentResponseScreen = lazy(() => import('./Screens/user/clients/OrderPaymentResponseScreen'))


function AppRouter(props) {

  const dispatch = useDispatch()

  const [categoryList, setCategoryList] = useState([])
  const [productList, setProductList] = useState([])

  // const listProductCategory = useSelector(state => state.productCategoryList)
  // const { categories, error, loading } = listProductCategory


  // useEffect(() => {
  //   if ((!categories || categories.length === 0) && !error && (loading === false || !loading)) {
  //     dispatch(productCategoryListAction())
  //   }
  //   if (categories) {
  //     const categories_slug = []
  //     const products_slug = []
  //     for(let i = 0; i<categories.length; i++){
  //       categories_slug.push({slug: categories[i].slug_name})
  //       for(let j = 0; j<categories[i].products.length; j++){
  //         products_slug.push({slug: categories[i].products[j].slug_name})
  //       }
  //     }
  //     setCategoryList(categories_slug)
  //     setProductList(products_slug)
  //   }
  // }, [dispatch, error, categories, loading])

  usePageViews();

  return (

    <React.Suspense fallback={<p>Loading...</p>}>
        <Switch>
            <Route path='/' component={LandingScreen} sitemapIndex={true} exact/>

            <Route
              path='/Cartdetail/'
              render={() => <CartDetailScreen />}
              sitemapIndex={true}
            />

            <Route path='/user/' component={UserDetailScreen} sitemapIndex={true}/>
            <Route path='/staff/' component={StaffLandingScreen} sitemapIndex={true}/>
            <Route path='/next_week_deliveries/' component={NextWeekDeliveriesScreen} sitemapIndex={true}/>
            <Route path='/cliente_issues_list/' component={ClientReportedIssuesScreen} sitemapIndex={true}/>
            <Route path='/staff_delivery_screen/' component={StaffDeliveryScreen} sitemapIndex={true}/>
            <Route path='/inventory/' component={InventoryScreen} sitemapIndex={true}/>
            <Route path='/analytics/' component={AnalyticsScreen} sitemapIndex={true}/>

            <Route path='/Checkout/' component={CheckoutScreen} sitemapIndex={true}/>

            <Route path='/login/' component={UserLoginScreen} sitemapIndex={true}/>
            <Route path='/recover_password/' component={UserRecoverPasswoedScreen} sitemapIndex={true}/>

            <Route path='/recover_password_email_sent/' component={UserRecoverPasswoedEmailSentScreen} sitemapIndex={true}/>
            <Route path='/reset_password/' component={UserResetPasswordScreen} sitemapIndex={true}/>

            <Route path='/register/' component={UserRegisterScreen} sitemapIndex={true}/>
            <Route path='/category/:slug' component={CategoryDetailScreen} sitemapIndex={true} slugs={categoryList}/>
            <Route path='/product/:slug' component={ProductDetailScreen} sitemapIndex={true} slugs={productList}/>
            <Route path='/redirect/' component={RedirectScreen} />

            <Route
              path='/aboutus/'
              render={() => <AboutUsScreen />}
            />

            <Route
              path='/donations/'
              render={() => <DonationsScreen />}
            />

            <Route
              path='/contact/'
              render={() => <ContactScreen />}
            />


            <Route
              path='/today_deliveries/'
              render={() => <TodayDeliveriesScreen />}
            />
  
            <Route
              path='/subscription_list/'
              render={() => <SubscriptionInfoScreen />}
            />

            <Route
              path='/payment_list/'
              render={() => <PaymentsInfoScreen />}
            />

            <Route
              path='/delivery_issues_list/'
              render={() => <DeliveryReportedIssuesScreen />}
            />

            <Route
              path='/terms_of_service/'
              render={() => <TermsOfServiceScreen />}
            />

            <Route
              path='/privacy_policy/'
              render={() => <PrivatePolicy />}
            />
            
            <Route
              path='/past_canceled_order_list/'
              render={() => <PastCanceledOrdersInfoScreen />}
            />


            <Route path='/order_payment/:order_number' component={OrderPaymentScreen} />

            <Route path='/order_payment_response/' component={OrderPaymentResponseScreen} />

            <Route path='/sitemap.xml' component={Sitemap}/>
            <Redirect path='*' to={{ pathname: '/redirect/', state: { from: window.location.href } }} />
        </Switch>

    </React.Suspense>
    
  );
}

export default AppRouter;
