import axios from 'axios'

import { 
    USER_DETAIL_FAILS, 
    USER_DETAIL_SUCCESS,
    USER_DETAIL_REQUEST,
    USER_DETAIL_RESET,

    USER_LOGIN_REQUEST,
    USER_LOGIN_FAILS,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,

    USER_REGISTER_FAILS,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,

    USER_CHANGE_PASSWORD_FAILS,
    USER_CHANGE_PASSWORD_REQUEST,
    USER_CHANGE_PASSWORD_SUCCESS,

    USER_UPDATE_ADDRESS_FAILS,
    USER_UPDATE_ADDRESS_REQUEST,
    USER_UPDATE_ADDRESS_SUCCESS,

    USER_UPDATE_PERSONAL_INFO_REQUEST,
    USER_UPDATE_PERSONAL_INFO_FAILS,
    USER_UPDATE_PERSONAL_INFO_SUCCESS,

    SET_HOST_PERSONAL_CODE,

    SET_RESET_PASSWORD_USER_PERSONAL_CODE,

    SET_RESET_PASSWORD_USER_ID,

    USER_NEW_MESSAGE,

    CONTACT_FORM_FAILS,
    CONTACT_FORM_REQUEST,
    CONTACT_FORM_SUCCESS,

    BOLETIN_CONTACT_SUCCESS,
    BOLETIN_CONTACT_REQUEST,
    BOLETIN_CONTACT_FAILS,

    ORDERS_TO_NOTIFY_REQUEST,
    ORDERS_TO_NOTIFY_FAILS,
    ORDERS_TO_NOTIFY_SUCCESS,

    ORDERS_DELIVERY_ISSUES_REQUEST,
    ORDERS_DELIVERY_ISSUES_FAILS,
    ORDERS_DELIVERY_ISSUES_SUCCESS,

    ORDERS_CLIENTS_ISSUES_REQUEST,
    ORDERS_CLIENTS_ISSUES_FAILS,
    ORDERS_CLIENTS_ISSUES_SUCCESS,

    TODAY_SUBSCRIPTION_ORDERS_REQUEST,
    TODAY_SUBSCRIPTION_ORDERS_FAILS,
    TODAY_SUBSCRIPTION_ORDERS_SUCCESS,

    NEXT_WEEK_SUBSCRIPTION_ORDERS_REQUEST,
    NEXT_WEEK_SUBSCRIPTION_ORDERS_FAILS,
    NEXT_WEEK_SUBSCRIPTION_ORDERS_SUCCESS,

    ORDER_PAYMENT_RESPONSE_REQUEST,
    ORDER_PAYMENT_RESPONSE_FAILS,
    ORDER_PAYMENT_RESPONSE_SUCCESS,

    SUBSCRIPTION_LIST_REQUEST,
    SUBSCRIPTION_LIST_FAILS,
    SUBSCRIPTION_LIST_SUCCESS,

    PAST_CANCELED_ORDERS_REQUEST,
    PAST_CANCELED_ORDERS_FAILS,
    PAST_CANCELED_ORDERS_SUCCESS,

    TOMORROW_SUBSCRIPTION_ORDERS_REQUEST,
    TOMORROW_SUBSCRIPTION_ORDERS_FAILS,
    TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS,
    
    THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_REQUEST,
    THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_FAILS,
    THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS,
    
    FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST,
    FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
    FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
    
    FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST,
    FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
    FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
    
    SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST,
    SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
    SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
    
    SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST,
    SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
    SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,

    TODAY_CART_ITEMS_REQUEST,
    TODAY_CART_ITEMS_FAILS,
    TODAY_CART_ITEMS_SUCCESS,

    TOMORROW_CART_ITEMS_REQUEST,
    TOMORROW_CART_ITEMS_FAILS,
    TOMORROW_CART_ITEMS_SUCCESS,
    
    THE_DAY_AFTER_TOMORROW_CART_ITEMS_REQUEST,
    THE_DAY_AFTER_TOMORROW_CART_ITEMS_FAILS,
    THE_DAY_AFTER_TOMORROW_CART_ITEMS_SUCCESS,
    
    FOUR_DAYS_FROM_TODAY_CART_ITEMS_REQUEST,
    FOUR_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
    FOUR_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,
    
    FIVE_DAYS_FROM_TODAY_CART_ITEMS_REQUEST,
    FIVE_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
    FIVE_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,
    
    SIX_DAYS_FROM_TODAY_CART_ITEMS_REQUEST,
    SIX_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
    SIX_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,
    
    SEVEN_DAYS_FROM_TODAY_CART_ITEMS_REQUEST,
    SEVEN_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
    SEVEN_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,

    TOP_USERS_LIST_REQUEST,
    TOP_USERS_LIST_SUCCESS,
    TOP_USERS_LIST_FAILS,

    USER_ORDERS_LIST_REQUEST,
    USER_ORDERS_LIST_SUCCESS,
    USER_ORDERS_LIST_FAILS,

    USER_LAST_ORDER_REQUEST,
    USER_LAST_ORDER_SUCCESS,
    USER_LAST_ORDER_FAILS,

    USER_PAYMENT_METHOD_LIST_REQUEST,
    USER_PAYMENT_METHOD_LIST_SUCCESS,
    USER_PAYMENT_METHOD_LIST_FAILS,
    USER_PAYMENT_METHOD_LIST_RESET,

    REMOVE_USER_MESSAGE,

    ORDERS_TO_BE_BLOCKED_REQUEST,
    ORDERS_TO_BE_BLOCKED_SUCCESS,
    ORDERS_TO_BE_BLOCKED_FAILS,

    FUNDS_RECEIVED_REQUEST,
    FUNDS_RECEIVED_FAILS,
    FUNDS_RECEIVED_SUCCESS,
    FUNDS_RECEIVED_RESET

} from '../constants/userConstants'

import {
    SUBSCRIPTION_ORDER_DETAIL_RESET,
    REMOVE_LOCAL_ORDER,
    NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_RESET,
    DELIVERED_SUBSCRIPTION_ORDERS_RESET,
    GET_DELIVERY_ORDERS_RESET } from '../constants/orderConstants'

import { SUBSCRIPTION_DETAIL_RESET, REMOVE_LOCAL_SUBSCRIPTION } from '../constants/subscriptionConstants'

import { CART_CLEAR_ITEMS } from '../constants/cartConstants'

export const userDetailAction = () => async (dispatch, getState) => {
    try {

        dispatch({type: USER_DETAIL_REQUEST})

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/accounts/user/', config)

        const { data } = res

        dispatch({
            type: USER_DETAIL_SUCCESS,
            payload: data
        })

        localStorage.setItem('userDetail', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: USER_DETAIL_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const userLoginAction = (email, password) => async (dispatch) => {
    try {

        dispatch({type: USER_LOGIN_REQUEST})

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const res = await axios.post('/api/accounts/login/',
                {'email':email, 'password':password},
                config)

        const { data } = res

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userLogin', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                
    }
}

export const userLogout = () => (dispatch) => {
    dispatch({
        type: USER_LOGOUT
    })
    dispatch({
        type: SUBSCRIPTION_ORDER_DETAIL_RESET
    })
    dispatch({
        type: SUBSCRIPTION_DETAIL_RESET
    })
    dispatch({
        type: USER_DETAIL_RESET
    })
    dispatch({
        type: CART_CLEAR_ITEMS
    })
    dispatch({
        type: REMOVE_USER_MESSAGE
    })
    dispatch({
        type: USER_PAYMENT_METHOD_LIST_RESET
    })
    dispatch({
        type: REMOVE_LOCAL_ORDER
    })
    dispatch({
        type: NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_RESET
    })
    dispatch({
        type: REMOVE_LOCAL_SUBSCRIPTION
    })
    dispatch({
        type: DELIVERED_SUBSCRIPTION_ORDERS_RESET
    })
    dispatch({
        type: GET_DELIVERY_ORDERS_RESET
    })
    localStorage.removeItem('userLogin')
    localStorage.removeItem('userDetail')
    localStorage.removeItem('nextWeekActiveSubscriptionOderDetail')
    localStorage.removeItem('nextActiveSubscriptionOderDetail')
    localStorage.removeItem('subscriptionDetail')
    localStorage.removeItem('cartItems')
}

export const userRegisterAction = (cart, user) => async (dispatch) => {

    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const res = await axios.post('/api/accounts/register/',
    {user, cart}, config)

    const { data } = res

    dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data
    })
        
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                        
    }
}

export const userChangePasswordAction = (passwords) => async (dispatch, getState) => {
    try {

        dispatch({
            type: USER_CHANGE_PASSWORD_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.put('/api/accounts/change_password/', 
        {passwords}, config)

        const { data } = res

        dispatch({
            type: USER_CHANGE_PASSWORD_SUCCESS,
            payload: data
        })

        
    } catch (error) {
        dispatch({
            type: USER_CHANGE_PASSWORD_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })
    }
}


export const fundsReceivedAction = (hostPersonalCode) => async (dispatch, getState) => {
    try {

        dispatch({
            type: FUNDS_RECEIVED_REQUEST
        })

        const res = await axios.get(`/api/accounts/get_host_give_away_amount/${hostPersonalCode}/`)
        
        const { data } = res

        dispatch({
            type: FUNDS_RECEIVED_SUCCESS,
            payload: data
        })

        
    } catch (error) {
        dispatch({
            type: FUNDS_RECEIVED_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })
    }
}

export const userUpdateAddressAction = (latitude, longitude) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_ADDRESS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.put('/api/accounts/update_address/', 
        {latitude, longitude}, config)

        const { data } = res

        dispatch({
            type: USER_UPDATE_ADDRESS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: USER_UPDATE_ADDRESS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const userUpdatePersonalInfoAction = (name, surname, provided_address, telephone, provided_address_second_line) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_PERSONAL_INFO_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.put('/api/accounts/update_personal_info/', 
        {name, surname, provided_address, telephone, provided_address_second_line}, config)

        const { data } = res

        dispatch({
            type: USER_UPDATE_PERSONAL_INFO_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: USER_UPDATE_PERSONAL_INFO_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const hostPersonalCodeAction = (host_code) => (dispatch) => {
    try {
        dispatch({
            type: SET_HOST_PERSONAL_CODE,
            payload: host_code
        })        
    } catch (error) {
        console.log(error)
    }
} 

export const resetPasswordPersonalCodeAction = (host_code) => (dispatch) => {
    try {
        dispatch({
            type: SET_RESET_PASSWORD_USER_PERSONAL_CODE,
            payload: host_code
        })        
    } catch (error) {
        console.log(error)
    }
} 


export const resetPasswordPersonalIdAction = (host_code) => (dispatch) => {
    try {
        dispatch({
            type: SET_RESET_PASSWORD_USER_ID,
            payload: host_code
        })        
    } catch (error) {
        console.log(error)
    }
} 


export const userMessageAction = (message) => (dispatch) => {
    dispatch({
        type: USER_NEW_MESSAGE,
        payload: message
    })
}


export const contactFormAction = (name, email, mobile_phone, message) => async (dispatch) => {
    try {

        dispatch({type: CONTACT_FORM_REQUEST })

        const res = await axios.post('/api/accounts/contact_form/', {name, email, mobile_phone, message})

        const { data } = res

        dispatch({
            type: CONTACT_FORM_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: CONTACT_FORM_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                
    }
}

export const boletinContactAction = (email) => async(dispatch) => {
    try {
        dispatch({type: BOLETIN_CONTACT_REQUEST})

        const res = await axios.post('/api/accounts/contact/', {email})

        const { data } = res

        dispatch({
            type: BOLETIN_CONTACT_SUCCESS,
            payload: data
        })
        
        
    } catch (error) {
        dispatch({
            type: BOLETIN_CONTACT_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })
    }
}

export const ordersToNotifyAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDERS_TO_NOTIFY_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/orders_to_notify/', config)

        const { data } = res

        dispatch({
            type: ORDERS_TO_NOTIFY_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: ORDERS_TO_NOTIFY_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}


export const ordersToBeBlockedAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDERS_TO_BE_BLOCKED_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/orders_to_be_blocked/', config)

        const { data } = res

        dispatch({
            type: ORDERS_TO_BE_BLOCKED_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: ORDERS_TO_BE_BLOCKED_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}


export const orderDeliveryIssuesAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDERS_DELIVERY_ISSUES_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/orders_delivery_issues/', config)

        const { data } = res

        dispatch({
            type: ORDERS_DELIVERY_ISSUES_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: ORDERS_DELIVERY_ISSUES_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const orderClientsIssuesAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDERS_CLIENTS_ISSUES_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/orders_client_issues/', config)

        const { data } = res

        dispatch({
            type: ORDERS_CLIENTS_ISSUES_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: ORDERS_CLIENTS_ISSUES_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const todaySubscriptionOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: TODAY_SUBSCRIPTION_ORDERS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/today_subscription_orders/', config)

        const { data } = res

        dispatch({
            type: TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: TODAY_SUBSCRIPTION_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const nextWeekSubscriptionOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: NEXT_WEEK_SUBSCRIPTION_ORDERS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/next_week_subscription_orders/', config)

        const { data } = res

        dispatch({
            type: NEXT_WEEK_SUBSCRIPTION_ORDERS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: NEXT_WEEK_SUBSCRIPTION_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const orderPaymentResponseAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_PAYMENT_RESPONSE_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/order_payment_response/', config)

        const { data } = res

        dispatch({
            type: ORDER_PAYMENT_RESPONSE_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: ORDER_PAYMENT_RESPONSE_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const subscriptionListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SUBSCRIPTION_LIST_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/subscription_list/', config)

        const { data } = res

        dispatch({
            type: SUBSCRIPTION_LIST_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_LIST_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const pastCanceledOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: PAST_CANCELED_ORDERS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/past_canceled_orders/', config)

        const { data } = res

        dispatch({
            type: PAST_CANCELED_ORDERS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: PAST_CANCELED_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const tomorrowSubscriptionOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: TOMORROW_SUBSCRIPTION_ORDERS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_orders/1/', config)

        const { data } = res

        dispatch({
            type: TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: TOMORROW_SUBSCRIPTION_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const dayAfterTomorrowSubscriptionOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_orders/2/', config)

        const { data } = res

        dispatch({
            type: THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const fourDaysFromTodaySubscriptionOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_orders/3/', config)

        const { data } = res

        dispatch({
            type: FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const fiveDaysFromTodaySubscriptionOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_orders/4/', config)

        const { data } = res

        dispatch({
            type: FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const sixDaysFromTodaySubscriptionOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_orders/5/', config)

        const { data } = res

        dispatch({
            type: SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const sevenDaysFromTodaySubscriptionOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_orders/6/', config)

        const { data } = res

        dispatch({
            type: SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const todayCartItemsAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: TODAY_CART_ITEMS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_product_items/0/supplier/', config)

        const { data } = res

        dispatch({
            type: TODAY_CART_ITEMS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: TODAY_CART_ITEMS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const tomorrowCartItemsAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: TOMORROW_CART_ITEMS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_product_items/1/supplier/', config)

        const { data } = res

        dispatch({
            type: TOMORROW_CART_ITEMS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: TOMORROW_CART_ITEMS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const dayAfterTomorrowCartItemsAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: THE_DAY_AFTER_TOMORROW_CART_ITEMS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_product_items/2/supplier/', config)

        const { data } = res

        dispatch({
            type: THE_DAY_AFTER_TOMORROW_CART_ITEMS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: THE_DAY_AFTER_TOMORROW_CART_ITEMS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const fourDaysFromTodayCartItemsAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: FOUR_DAYS_FROM_TODAY_CART_ITEMS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_product_items/3/supplier/', config)

        const { data } = res

        dispatch({
            type: FOUR_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: FOUR_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const fiveDaysFromTodayCartItemsAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: FIVE_DAYS_FROM_TODAY_CART_ITEMS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_product_items/4/supplier/', config)

        const { data } = res

        dispatch({
            type: FIVE_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: FIVE_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const sixDaysFromTodayCartItemsAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SIX_DAYS_FROM_TODAY_CART_ITEMS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_product_items/5/supplier/', config)

        const { data } = res

        dispatch({
            type: SIX_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: SIX_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const sevenDaysFromTodayCartItemsAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SEVEN_DAYS_FROM_TODAY_CART_ITEMS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/day_product_items/6/supplier/', config)

        const { data } = res

        dispatch({
            type: SEVEN_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: SEVEN_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const topUserListAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: TOP_USERS_LIST_REQUEST})

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/accounts/users_analytics/', config)

        const { data } = res

        dispatch({
            type: TOP_USERS_LIST_SUCCESS,
            payload: data
        })


        
    } catch (error) {
        dispatch({
            type: TOP_USERS_LIST_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                
    }
}

export const userOrdersListAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_ORDERS_LIST_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/user_order_list/', config)

        const { data } = res

        dispatch({
            type: USER_ORDERS_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_ORDERS_LIST_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                        
    }
}

export const userLastOrderAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_LAST_ORDER_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/user_last_order/', config)

        const { data } = res

        dispatch({
            type: USER_LAST_ORDER_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_LAST_ORDER_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                        
    }
}

export const userPaymentMethodListAction = () => async (dispatch, getState) => {
    try {

        dispatch({type: USER_PAYMENT_METHOD_LIST_REQUEST})

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/payments/user_payment_methods/', config)

        const { data } = res

        dispatch({
            type: USER_PAYMENT_METHOD_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_PAYMENT_METHOD_LIST_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

