import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import ReactPixel from 'react-facebook-pixel';
import { userMessageAction, userPaymentMethodListAction } from '../../../../actions/userActions'
import useGAEventTracker from '../../../../hooks/useGAEventTracker';
import { DisplayConfety } from './DisplayConfety'

import { ProductHorizontalCard } from './ProductHorizontalCard'
import { ChangeSubscriptionProductsModalButton } from './ChangeSubscriptionProductsModalButton'
import { LocalPendingTokenSection } from './LocalPendingTokenSection'
import { LocalActiveSubscriptionSection } from './LocalActiveSubscriptionSection'
import { 
    subscriptionDetailAction,
    localSubscriptionAction,
    removeLocalSubscriptionAction } from '../../../../actions/subscriptionActions'

import {
    localOrderAction,
    nextWeekSubscriptionOrderDetailAction
} from '../../../../actions/orderActions'

import {
    ENABLE_CHECK_LOCAL_SUBSCRIPTION,
    SUBSCRIPTION_UPDATE_RESET,
    SUBSCRIPTION_DETAIL_RESET
    } from '../../../../constants/subscriptionConstants'

import { CREATE_PAYMENT_CARD_RESET } from '../../../../constants/paymentsConstants'

    
export const LocalSubscriptionSection = ( props ) => {

    const dispatch = useDispatch()

    const GAEventsTracker = useGAEventTracker();

    const orderLocal = useSelector(state => state.localOrder)
    const { localProductsItems: localOrderProductsItems, updatedFromServer: updatedOrderFromServer } = orderLocal

    const orderNextWeekActiveSubscription = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
    const { loading: orderLoading, nextWeekActiveOrder  } = orderNextWeekActiveSubscription

    const updateSubscription = useSelector(state => state.subscriptionUpdate)
    const { loading: subscriptionUpdateLoading, success: subscriptionUpdateSuccess } = updateSubscription

    const subscriptionLocal = useSelector(state => state.localSubscription)
    const { localProductsItems: localSubscriptionProductsItems, localSubscriptionStatus } = subscriptionLocal

    const [disableButtons, setDisableButtons] = useState(false)

    const subscriptionToggleCheckLocal = useSelector(state => state.toggleCheckLocalSubscription)
    const { checkLocalSubscription } = subscriptionToggleCheckLocal

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    useEffect(() => {
        if (nextWeekActiveOrder) {
            if (updatedOrderFromServer === false && nextWeekActiveOrder.products_items && orderLoading === false && subscriptionUpdateLoading === false) {
                for(let i = 0; i < nextWeekActiveOrder.products_items.length; i++) {
                    dispatch(localOrderAction(nextWeekActiveOrder.products_items[i].products, nextWeekActiveOrder.products_items[i].amount, nextWeekActiveOrder.products_items[i].total_value, true))
                }
            }    
        }
        if(subscriptionUpdateSuccess && orderLoading === false)  {
            dispatch(nextWeekSubscriptionOrderDetailAction())
            dispatch({type: SUBSCRIPTION_DETAIL_RESET})
            dispatch({type: SUBSCRIPTION_UPDATE_RESET})
            dispatch({ type: ENABLE_CHECK_LOCAL_SUBSCRIPTION })
            dispatch(subscriptionDetailAction(false))
        }
        if (checkLocalSubscription && subscription && subscription.products_items && subscriptionUpdateLoading === false) {
            const localSubscriptionTotalValue = localSubscriptionProductsItems.reduce((acc, item) => 
            acc + (Number(item.total_value)), 0
            )
            const subscriptionTotalValue = subscription.products_items.reduce((acc, item) => 
            acc + (Number(item.total_value)), 0
            )
            if (localSubscriptionTotalValue !== subscriptionTotalValue || localSubscriptionStatus !== subscription.status.name) {
                dispatch(removeLocalSubscriptionAction())
                for(var i = 0; i < subscription.products_items.length; i++) {
                    dispatch(localSubscriptionAction(
                        subscription.products_items[i].products,
                        subscription.products_items[i].amount,
                        subscription.products_items[i].total_value,
                        true,
                        subscription.status.name,
                        subscription.frecuency.deliver_period))
                }
            }
        }
    }, [
        updatedOrderFromServer,
        nextWeekActiveOrder,
        orderLoading,
        subscriptionUpdateLoading,
        localOrderProductsItems,
        subscriptionUpdateSuccess,
        checkLocalSubscription,
        subscription,
        localSubscriptionProductsItems
    ])

    const cardCreate = useSelector(state => state.createCard)
    const { error: cardCreateError, loading: cardCreateLoading, success: successCardCreate } = cardCreate

    useEffect(() => {
        if(successCardCreate) {
            ReactPixel.track('AddPaymentInfo');
            dispatch(nextWeekSubscriptionOrderDetailAction())
            dispatch({type: SUBSCRIPTION_DETAIL_RESET})
            dispatch({type: SUBSCRIPTION_UPDATE_RESET})
            dispatch({ type: ENABLE_CHECK_LOCAL_SUBSCRIPTION })
            dispatch(subscriptionDetailAction())
            dispatch(userMessageAction('Medio de pago registrado con éxito'))
            dispatch(userPaymentMethodListAction())
            GAEventsTracker('Credit Cart', 'Credit Cart Success', 'Success')
            dispatch({
                type: CREATE_PAYMENT_CARD_RESET
            })
        }
    }, [
        successCardCreate
    ])


    const OrderProductsByCategoryAndName = (a, b) => {
        if (a.products.category && b.products.category) {
            if (a.products.category.name === b.products.category.name) {
                if (a.products.name[0] > b.products.name[0]) {
                    return 1
                } else if (a.products.name[0] < b.products.name[0]) {
                    return -1
                } else if (a.products.name[0] === b.products.name[0]) {
                    if (a.products.name[1] > b.products.name[1]) {
                        return 1
                    } else if (a.products.name[1] < b.products.name[1]) {
                        return -1
                    }
                }    
            }
            return a.products.category.name > b.products.category.name ? 1 : -1;    
        }
        return 0
    }

    const clickThreshold = 900; // Umbral de tiempo en milisegundos

    const clickTimes = useRef([]);

    const DisableButtonFunction = () => {
        clickTimes.current.push(Date.now());

        // Mantén solo los tiempos de clic dentro del umbral
        const now = Date.now();
        clickTimes.current = clickTimes.current.filter(time => now - time < clickThreshold);
    
        if (clickTimes.current.length >= 3) {
        setDisableButtons(true);
    
        // Habilitar el botón después de un tiempo
        setTimeout(() => {
            setDisableButtons(false);
        }, clickThreshold);
        }
    }

    const[activatedSubscription, setActivatedSubscription] = useState(false)


    return (    
        <div>
            <div>
                {localSubscriptionStatus === 'Pending token' ? (
                    <div>
                        <LocalPendingTokenSection /> 
                    </div>
                ) : localSubscriptionStatus === 'Active' && (
                    <div>
                        <LocalActiveSubscriptionSection />
                    </div>
                )}
            </div>
            <h5 className='text-center'>Detalle de productos de tu próxima entrega</h5>
            <Row className='d-flex justify-content-around'>
                {localSubscriptionProductsItems && (
                    <>
                    {localSubscriptionProductsItems.length > 0 && localSubscriptionProductsItems.filter((cart_item) => cart_item.amount > 0).length > 0 ? (
                        <>
                            {localSubscriptionProductsItems.filter((cart_item) => cart_item.amount > 0).sort(OrderProductsByCategoryAndName).map((item, index, array) =>
                                <Col xs={12} lg={6} key={item.id} className='px-3'>
                                    <div className=' my-1 w-100'>
                                        {index === 0 && item && item.products && item.products.category ?
                                            (<h3 className='text-center border-bottom mt-3 d-block d-md-none' style={{ backgroundColor: 'white' }}><b>{item.products.category.name}</b></h3>) :
                                            (<div>
                                                    {item && item.products && item.products.category && array[index -1] && array[index -1].products && array[index -1].products.category && item.products.category.name !== array[index -1].products.category.name &&
                                                        <h3 className='text-center border-bottom mt-3 d-block d-md-none' style={{ backgroundColor: 'white' }}>
                                                            <b>{item.products.category.name}</b>
                                                        </h3>
                                                    }
                                            </div>)
                                        }
                                        <ProductHorizontalCard 
                                            item={item}
                                            disableButtons={disableButtons}
                                            DisableButtonFunction={DisableButtonFunction}
                                        />
                                    </div>
                                </Col>
                                )
                            }
                        </>
                    ) : (
                        <Row className='mb-3'>
                            <Col className="text-center"> {/* Agregar la clase text-center aquí */}
                                <p>No tienes productos en tu subscripción</p>
                                <ChangeSubscriptionProductsModalButton />
                            </Col>
                        </Row>
                        )}
                    </>
                    )
                }
            </Row>
            <DisplayConfety />
        </div>
    )
}
