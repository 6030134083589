import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap'
import { userOrdersListAction } from '../../../../../actions/userActions'
import Loader from '../../../../Loader'
import Message from '../../../../Message' 
import { FORMER_ORDERS_COLUMNS } from '../../FormerOrdersColumns'
import { DisplayInfoTable } from '../../../../DisplayInfoTable'


export const ModalPastOrders = (props) => {

    const dispatch = useDispatch()

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    const listUserOrders = useSelector(state => state.userOrdersList)
    const { loading, order_list, error: errorListUserOrders } = listUserOrders


    return (
        <div>
            <Modal show={props.isOpen} onHide={props.toggle} animation={false} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Órdenes pasadas</Modal.Title>
                </Modal.Header>
                <Modal.Body  style={{ overflowY: 'auto', height: '70vh' }}>
                    {loading ? (<Loader />) : (
                        <div>
                            {errorListUserOrders ? (
                                <div>
                                    <Message variant='danger'>{errorListUserOrders}</Message>
                                </div>
                            ) : (
                                <div>
                                    {order_list && order_list.length > 0 ? (
                                        <div>
                                            <DisplayInfoTable rows={order_list} columns={FORMER_ORDERS_COLUMNS} staff_table={false}/>
                                        </div>
                                    ) : (
                                        <p>
                                            No tienes órdenes anteriores
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.toggle}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
