import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalChangePassword } from '../../shared/ModalChangePassword';


export const ChangePasswordModalButton = () => {

    const [changePasswordShow, setChangePasswordShow] = useState(false);
    const toggleChangePassswordModal = () => {
        setChangePasswordShow(!changePasswordShow)
    } 


    return (
        <div>
            <Button type="button" className="btn-block btn-sm my-1" onClick={toggleChangePassswordModal} style={{ backgroundColor: '#2C971F', border: '0px' }}>
                <i className="fas fa-lock"></i> Cambiar Contraseña
            </Button>
            {changePasswordShow ? (
                <ModalChangePassword isOpen={changePasswordShow} toggle={toggleChangePassswordModal}/>                                                                        
            ) : (
                <div></div>
            )}
        </div>
    )
}
