import React, { Suspense } from 'react'
import { HashRouter as Router } from 'react-router-dom'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel';
import AppRouter from './AppRouter'
import './App.css';
import { Helmet } from "react-helmet";
import './custom.scss';


function App(props) {

  const advancedMatching = { em: 'some@email.com' };
  const options = {
    autoConfig: true,
    debug: false,
  };

  ReactPixel.init('1437113343390485', advancedMatching, options);
  ReactGA.initialize('G-7YDYDWWF0T');
  
  // useEffect(() => {
  //   const advancedMatching = { em: 'some@email.com' };
  //   const options = {
  //     autoConfig: true,
  //     debug: false,
  //   };

  //   ReactPixel.init('686410700028550', advancedMatching, options);
  //   // ReactPixel.init('686410700028550', options);
  // }, [window.location.hash])



  return (

    <Router>
      <Helmet>
        <title>Verdemi - Frutas y Verduras</title>
        <meta
          name="description"
          content="Verdemi - Frutas y verduras por subscripción y con descuentos"
        />
      </Helmet>
      <main className="py-3">
        <Suspense fallback={"Cargando..."}>
          <AppRouter />
        </Suspense>
      </main>
    </Router>
    
  );
}

export default App;
