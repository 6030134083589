import React from 'react'
import { Table, Image } from 'react-bootstrap'
const { REACT_APP_DONATION_PERCENTAGE } = process.env


export const PendingPaymentOrderInfo = (props) => {

    const{ order } = props

    const OrderProductsByCategoryAndName = (a, b) => {
        if (a.products.category && b.products.category) {
            if (a.products.category.name === b.products.category.name) {
                if (a.products.name[0] > b.products.name[0]) {
                    return 1
                } else if (a.products.name[0] < b.products.name[0]) {
                    return -1
                } else if (a.products.name[0] === b.products.name[0]) {
                    if (a.products.name[1] > b.products.name[1]) {
                        return 1
                    } else if (a.products.name[1] < b.products.name[1]) {
                        return -1
                    }
                }    
            }
            return a.products.category.name > b.products.category.name ? 1 : -1;    
        }
        return 0
    }

    return (
        <div>

            <p className='text-center' style={{ color: '#29BC5A' }}>
                <b>
                    ¡Hemos enviado el link de pago!
                </b>
            </p>

            <p className='text-center'>
                Tu último mercado está pendiente de pago. Si no recibiste el link puedes proceder a realizar el pago aquí{' '}
                <a href={`${window.location.origin}/#/order_payment/${order.id}`} target="_blank" rel='noreferrer'>
                {/* <a href={'https://www.verdemi.com/#/order_payment/' + order.id} target="_blank" rel='noreferrer'> */}
                    Link de pago
                </a>
            </p>

            <p className='text-center'>
                Si crees que la información es incorrecta o no te funciona el link de pago, nos puedes escribir vía Whatsapp
            </p>

            <ul>
                <li className='mx-md-5'>
                    <span style={{ float: 'left'}}>
                        Valor de los productos
                    </span>
                        <span style={{ float: 'right'}}>
                        ${new Intl.NumberFormat('co-CO').format(Number(props.order.products_value))}
                    </span>
                </li>
                <li className='mx-md-5' style={{ color: '#29BC5A' }}>
                    <span style={{ float: 'left'}}>
                        Descuento
                    </span>
                    <span style={{ float: 'right'}}>
                        ${new Intl.NumberFormat('co-CO').format(Number(props.order.products_value - props.order.products_amont_to_pay))}
                        {/* -${new Intl.NumberFormat('co-CO').format(Number(subscriptionDiscoutValue(localSubscriptionProductsItems)))} */}
                    </span>
                </li>
                <li className='mx-md-5'>
                    <span style={{ float: 'left'}}>
                        Transporte
                    </span>
                    <span style={{ float: 'right'}}>
                        ${new Intl.NumberFormat('co-CO').format(Number(props.order.transport_charge))}
                    </span>
                </li>
                <li className='mx-md-5'>
                    <span style={{ float: 'left'}}>
                        <b>Total a pagar</b>
                    </span>
                    <span style={{ float: 'right'}}>
                        <b>
                            ${new Intl.NumberFormat('co-CO').format(Number(props.order.products_value - (props.order.products_value - props.order.products_amont_to_pay) + props.order.transport_charge))}
                        </b>
                    </span>
                </li>
                <hr className='mx-md-5' style={{ backgroundColor: '#29bc5a' }}></hr>
                <li className='mx-md-5'>
                    Donación de Verdemi a la Fundación Fiambre ${new Intl.NumberFormat('co-CO').format(Math.round(Number(props.order.products_value * REACT_APP_DONATION_PERCENTAGE)))}
                </li>
            </ul>


            <h2 className='mt-3'>Productos entregados el {new Intl.DateTimeFormat('es-GB', { month: 'long', day: '2-digit',year: 'numeric'}).format(new Date(order.date))}</h2>
            {order && order.products_items && (
                <div>
                    {order.products_items.length > 0 && (
                        <Table>
                        <thead>
                            <tr>
                                <th className='text-center'></th>
                                <th className='text-center'>Producto</th>
                                <th className='text-center'>Coste - Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.products_items.filter((product_item) => product_item.amount > 0).sort(OrderProductsByCategoryAndName).map((product_item, index, array) =>
                                <>
                                    {index === 0 && product_item && product_item.products && product_item.products.category ? 
                                        (<td colSpan={6} className='text-center border-bottom mt-3' style={{ backgroundColor: 'white' }}><b>{product_item.products.category.name}</b></td>) :
                                        (<>
                                                {product_item && product_item.products && product_item.products.category && product_item.products.category.name !== array[index -1].products.category.name &&
                                                    <td colSpan={6} className='text-center border-bottom mt-3' style={{ backgroundColor: 'white' }}>
                                                        <b>{product_item.products.category.name}</b>
                                                    </td>
                                                }
                                        </>)
                                    }
                                    <tr>
                                        <td><Image src={product_item.products.image_32} rounded /></td>
                                        <td className='text-center'>{product_item.products.name}</td>
                                        <td className='text-center'>
                                            <p>${new Intl.NumberFormat('co-CO').format(Number(product_item.total_value))}</p>
                                            <p style={{ fontSize: '0.9em', marginTop: '-10px', marginBottom: '0px' }}>({product_item.amount} und)</p>
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </Table>
                    )}
                </div>
            )}
        </div>
    )
}
