import React, { useEffect, useState, lazy } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Col, Row, Container } from 'react-bootstrap'
import { SubscriptionAndOrderInfo } from '../../../components/user/clients/OrderInfo/SubscriptionAndOrderInfo'
import { WhatsappFloatingButton } from '../../../components/user/WhatsappFloatingButton'
import { ShareLinkFloatingButton } from '../../../components/user/clients/ShareLinkFloatingButton'
import { GiveAwayFloatingButton } from '../../../components/user/clients/GiveAwayFloatingButton'
import Message from '../../../components/Message'
import { ModalLogout } from '../../../components/user/clients/Header/ModalLogout';
import {
    SUBSCRIPTION_PRODUCTS_MODAL_OPEN
     } from '../../../constants/subscriptionConstants'


export const UserDetailScreen = ({ history }) => {

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const HeaderUser = lazy(() => import('../../../components/user/clients/Header/HeaderUser'))

    const dispatch = useDispatch()

    const modalSubscription = useSelector(state => state.subscriptionModal)
    const { open_modal } = modalSubscription

    const loginUser = useSelector(state => state.userLogin)
    const {user: userLogin } = loginUser

    const messageUser = useSelector(state => state.userMessage)
    const {message: theMessage, display: showMessage } = messageUser

    const[display, setDisplay] = useState(showMessage)


    const [logoutShow, setLogoutShow] = useState(false);
    const toggleLogoutModal = () => {
        setLogoutShow(!logoutShow)
    }

    const toggleAddProductsModal = () => {
        if(open_modal === false) {
            dispatch({type: SUBSCRIPTION_PRODUCTS_MODAL_OPEN})
        }
    } 

    useEffect(() => {
        if (!userLogin) {
            history.push('/login')
        }
        if (theMessage) {
            setDisplay(showMessage)
            setTimeout(() => setDisplay(false), 6000);
        }
        if (user && user.is_staff === true) {
            history.push('/staff')
        }
    }, [dispatch, userLogin, history, theMessage, showMessage, user])

    return (
        <Container>
            <p className='my-n3 d-none d-sm-block' style={{ color: '#F8F9FA'}}>----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
            {display && theMessage && theMessage.length > 0 && (
                <Row className='mt-5 mb-n5 d-block d-sm-none pt-3'>
                    <Col className='mt-5 mx-3 pr-5'>
                        <Message variant='info' className='mt-5 mt-md-1' floating={true}>{theMessage}</Message>                    
                    </Col>
                </Row>
            )}
            <Row className='p-3'>
                <div className='mb-5'>
                    {theMessage && theMessage.length > 0 ? (
                        <HeaderUser toggleLogoutModal={toggleLogoutModal} toggleAddProductsModal={toggleAddProductsModal} headerMessage={theMessage}/>
                    ) : (
                        <HeaderUser toggleLogoutModal={toggleLogoutModal} toggleAddProductsModal={toggleAddProductsModal}/>
                    )}
                </div>
                <div className='mt-3 mt-sm-5'>
                    <Col md={{order:2}} className="border ml-md-3 mb-3 mr-md-1 rounded bg-light">
                        <SubscriptionAndOrderInfo history={history}/>
                    </Col>
                    <Col md={3} className='border ml-md-1 mr-md-3 mb-3 rounded bg-light pt-3 h-25 d-none' >
                    </Col>
                </div>
                <ModalLogout isOpen={logoutShow} toggle={toggleLogoutModal} history={history}/> 
                <WhatsappFloatingButton />
                <ShareLinkFloatingButton />
                <GiveAwayFloatingButton />
            </Row>
        </Container>
    )
}
