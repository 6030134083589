import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ChangeFrecuencyModalButton } from './ChangeFrecuencyModalButton'
import { ChangeSubscriptionProductsModalButton } from './ChangeSubscriptionProductsModalButton'
import Loader from '../../../Loader'
import Message from '../../../Message' 
import { ProductHorizontalCard } from './ProductHorizontalCard'
import { SubscriptionProducts } from './SubscriptionProducts'
import {
    nextWeekSubscriptionOrderDetailAction,
    nextWeekSubscriptionOrderIdAction,
    nextActiveSubscriptionOrderDetailAction,
} from '../../../../actions/orderActions'
import { 
    localSubscriptionAction } from '../../../../actions/subscriptionActions'



export const ActivateSubscriptionSection = () => {

    const dispatch = useDispatch()

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { loading:subscriptionDetailLoading, subscription } = detailSubscription

    const orderNextWeekActiveSubscription = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
    const { error: orderError, loading: orderLoading, nextWeekActiveOrder  } = orderNextWeekActiveSubscription

    const orderNextActiveSubscription = useSelector(state => state.nextActiveSubscriptionOderDetail)
    const { error: orderNextActiveError, loading: orderNextActiveLoading, nextActiveOrder } = orderNextActiveSubscription

    const orderIdNextWeekActiveSubscription = useSelector(state => state.nextWeekActiveSubscriptionOderId)
    const { loading: orderIdLoading, nextWeekActiveOrderId } = orderIdNextWeekActiveSubscription

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const subscriptionLocal = useSelector(state => state.localSubscription)
    const { updatedFromServer: updatedSubscriptionFromServer  } = subscriptionLocal

    const [disableButtons, setDisableButtons] = useState(false)

    const clickThreshold = 900; // Umbral de tiempo en milisegundos
    const clickTimes = useRef([]);

    const DisableButtonFunction = () => {
        clickTimes.current.push(Date.now());

        // Mantén solo los tiempos de clic dentro del umbral
        const now = Date.now();
        clickTimes.current = clickTimes.current.filter(time => now - time < clickThreshold);
    
        if (clickTimes.current.length >= 3) {
        setDisableButtons(true);
    
        // Habilitar el botón después de un tiempo
        setTimeout(() => {
            setDisableButtons(false);
        }, clickThreshold);
        }
    }

    useEffect(() => {
        if(subscription && subscription.status.name === 'Active'){
            if(!nextWeekActiveOrder && orderLoading === false && user ) {
                dispatch(nextWeekSubscriptionOrderDetailAction())
            }
            if (updatedSubscriptionFromServer === false && subscription.products_items && subscriptionDetailLoading === false) {
                for(var i = 0; i < subscription.products_items.length; i++) {
                    dispatch(localSubscriptionAction(
                        subscription.products_items[i].products,
                        subscription.products_items[i].amount,
                        subscription.products_items[i].total_value,
                        true,
                        subscription.status.name,
                        subscription.frecuency.deliver_period))
                }
            }
        if(subscription && nextWeekActiveOrder) {
                if (nextWeekActiveOrder.date === null) {
                    if (!nextActiveOrder && orderNextActiveLoading === false) {
                        dispatch(nextActiveSubscriptionOrderDetailAction())
                    }
                }
                if((nextWeekActiveOrderId === null || !nextWeekActiveOrderId) &&
                    orderIdLoading === false
                    && user
                    && nextWeekActiveOrder.date !== null) {
                        dispatch(nextWeekSubscriptionOrderIdAction())
                }
            }
        }
    }, [
        user,
        subscription
    ])

    return (
        <div>
            {subscription.products_items.length === 0 ? (
                <div>
                    <SubscriptionProducts subscription={subscription}/>
                </div>
            ) : (
                <div>
                    {orderLoading ? (
                        <div>
                            <Loader />
                        </div>
                    ) : (
                        <div>
                            {orderError ? (
                                <div>
                                    <Message variant='danger'>{orderError}</Message>
                                </div>
                            ) : (
                                <div>
                                    {orderNextActiveLoading ? (
                                        <div>
                                            <Loader/>
                                        </div>
                                    ) : (
                                        <div>
                                            {orderNextActiveError ? (
                                                <div>
                                                    <Message variant='danger'>{orderNextActiveError}</Message>   
                                                </div>
                                                ) : (
                                                    <div>
                                                        {(nextWeekActiveOrder && nextWeekActiveOrder.date !== null) ? (
                                                        <div>
                                                            <h4 className='mt-3' style={{ color: '#29bc5a' }}><i className="fas fa-arrow-alt-circle-right"></i> Tu próximo pedido {new Intl.DateTimeFormat('es-GB', { 
                                                                month: 'long', 
                                                                day: '2-digit',
                                                                year: 'numeric', 
                                                            }).format(new Date(nextWeekActiveOrder.date))}</h4>
                                                            <hr className='border border-secondary my-0'></hr>
                                                            <Row className='my-1 p-3'>
                                                                <Col>
                                                                    <Row>
                                                                        <p>Se hará un cargo por {new Intl.NumberFormat('co-CO').format(Number(nextWeekActiveOrder.products_amont_to_pay) + Number(nextWeekActiveOrder.transport_charge))} pesos en tu tarjeta.</p>
                                                                        <ul>
                                                                            <li><i className="fas fa-shopping-basket"></i> Valor de los productos ${new Intl.NumberFormat('co-CO').format(Number(nextWeekActiveOrder.products_value))}</li>
                                                                            <li><i className="fas fa-truck"></i> Transporte ${new Intl.NumberFormat('co-CO').format(Number(nextWeekActiveOrder.transport_charge))}</li>
                                                                            {(Number(nextWeekActiveOrder.products_value) - Number(nextWeekActiveOrder.products_amont_to_pay)) > 0 && (
                                                                                <li style={{ color: '#29BC5A' }}><i className="fas fa-percent"></i> Descuento ${new Intl.NumberFormat('co-CO').format(Number(nextWeekActiveOrder.products_value) - Number(nextWeekActiveOrder.products_amont_to_pay))}</li>
                                                                            )}
                                                                            <li><i className="fas fa-thumbs-up"></i> Donación de Verdemi a la Fundación Fiambre ${new Intl.NumberFormat('co-CO').format(Number(nextWeekActiveOrder.platform_donation))}</li>
                                                                        </ul>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </div>                                                                
                                                        ) : (
                                                            <div>
                                                            {nextActiveOrder && nextActiveOrder.date !== null ? (
                                                                <div>
                                                                    <h4 className='mt-3' style={{ color: '#29bc5a' }}><i className="fas fa-arrow-alt-circle-right"></i> Tu próximo pedido 
                                                                    </h4>
                                                                    <hr className='border border-secondary my-0'></hr>
                                                                    <p className='mt-3 mb-5'>No tienes ninguna entrega programada para los próximos 7 días. Tu próxima entrega será el {new Intl.DateTimeFormat('es-GB', { 
                                                                            month: 'long', 
                                                                            day: '2-digit',
                                                                            year: 'numeric', 
                                                                        }).format(new Date(nextActiveOrder.date))}
                                                                    </p>
                                                                    <h4 className='mt-1 text-success'><i className="far fa-calendar-alt"></i> Frecuencia de entrega 1</h4>
                                                                    <hr className='border border-secondary my-0'></hr>
                                                                    <Row className='mt-3 mb-5'>
                                                                        <Col md={9}>
                                                                            <p>Tus entregas se harán: {subscription.frecuency.readeble_frecuency}</p>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <ChangeFrecuencyModalButton />
                                                                        </Col>
                                                                    </Row>
                                                                    <h4 className='mt-1 text-success'><i className="fas fa-retweet"></i> Gestiona tu subscripción</h4>
                                                                    <hr className='border border-secondary my-0'></hr>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <h4 className='mt-3 text-success'><i className="fas fa-shopping-basket"></i> Tus productos</h4>
                                                                    <hr className='border border-secondary my-0'></hr>                                            
                                                                    <Row className='d-flex justify-content-center mt-3 mb-3 px-3'>
                                                                        <Col className='px-md-1 my-1 my-md-0'>
                                                                            <ChangeSubscriptionProductsModalButton />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='d-flex justify-content-around px-3'>
                                                                        {subscription.products_items && subscription.products_items.map(item => (
                                                                        <div className=' my-1 w-100' key={item.id}>
                                                                            <ProductHorizontalCard
                                                                                item={item}
                                                                                disableButtons={disableButtons}
                                                                                DisableButtonFunction={DisableButtonFunction}
                                                                            />
                                                                        </div>
                                                                        ))}
                                                                    </Row>
                                                                </div>                                                                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                        </div>                                                                                                        
                                        )}
                                    </div>
                                )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
