import React from 'react'
import { useSelector } from 'react-redux'
import { FrecuencyForm } from '../FrecuencyForm'

export const ChangeFrecuency = (props) => {

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription


    return (
        <div>
            <p>Frecuencia actual: {subscription && subscription.frecuency ? (<span>{subscription.frecuency && (subscription.frecuency.readeble_frecuency)}</span>) : (<span></span>)}</p>
            <p>CREA TU NUEVA FRECUENCIA DE ENTREGA <small>(Ajustaremos tu órdenes actuales a esta nueva frecuencia)</small></p>
            <hr></hr>
            <FrecuencyForm create={false} closeModal={props.toggle}/>
        </div>
    )
}
