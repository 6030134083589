import { 
    CART_CHANGE_AMOUNT_ITEM,
    CART_CLEAR_ITEMS,
    SET_CART_VALUES,
    CLEAR_CART_VALUES
 } from "../constants/cartConstants";

 export const cartReducer = (state={ cartItems: [], total_products_cost: 0, transport_cost: 0}, action) => {
     switch (action.type) {
         case CART_CHANGE_AMOUNT_ITEM:
            const item = action.payload
            const existsItem = state.cartItems.find(x => x.product.id === item.product.id)
            if (existsItem) {
                if (item.qty === 0) {
                    return {
                        ...state,
                        cartItems: state.cartItems.filter(x => 
                            x.product.id !== existsItem.product.id)
                    }
                } else {
                    return {
                        ...state,
                        cartItems: state.cartItems.map(x => 
                            x.product.id === existsItem.product.id ? item : x )
                        }    
                }
            } else {
                return {
                    cartItems: [item, ...state.cartItems]
                }
            }
         case CART_CLEAR_ITEMS:
            return {
                cartItems: []
            }
         default:
             return state
     }
 }

 export const cartValuesReducer = (state={ cartCost: 0, discount: 0, transportCost: 0}, action) => {
    switch (action.type) {
        case SET_CART_VALUES:
            return {
                ...state,
                cartCost: action.payload.cartCost,
                discount: action.payload.discount,
                transportCost: action.payload.transportCost
            }
        case CLEAR_CART_VALUES:
           return {
               cartCost: 0,
               discount: 0,
               transportCost: 0
           }
        default:
            return state
    }
}

// const cartCostReducer = (state = {}, action) => {
//     switch (action.type) {
//         case UPDATE_CART_COST:
//             return {
//                 total_cost: action.payload.reduce((acc, item) => 
//                 acc + (item.qty * Number(item.product.price)), 0
//             ),
//                 transport: 10000
//             }
//         default:
//             return state
//     }
// }