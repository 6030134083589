import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalPostponeOrder } from './ModalPostponeOrder'


export const PostponeOrderModalButton = () => {

    const [postponeOrderShow, setPostponeOrderShow] = useState(false);

    const togglePostponeOrderModal = () => {
        setPostponeOrderShow(!postponeOrderShow)
    }

    return (
        <div>
            <Button
                variant='outline-success'
                className="btn-lg btn-block"
                style={{ width: "105%", fontSize: '1.1em', border: '2px solid #3FE22B'}}
                onClick={togglePostponeOrderModal}
            >
                Cambiar fecha de entrega
            </Button>
            <ModalPostponeOrder isOpen={postponeOrderShow} toggle={setPostponeOrderShow}/>
        </div>
    )
}
