import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalCompletePersonalInformation } from '../../shared/ModalCompletePersonalInformation';


export const CompletePersonalInformationModalButton = (props) => {

    const [completePersonalInfoShow, setcompletePersonalInfoShow] = useState(false);
    const toggleCompletePersonalInfoShow = () => {
        setcompletePersonalInfoShow(!completePersonalInfoShow)
    } 

    return (
        <div>
            <Button variant={props.variant} className={props.btnclass} onClick={toggleCompletePersonalInfoShow}>
                <i className={props.logo}></i> {props.call_to_action}
            </Button>
            {completePersonalInfoShow ? (
                <ModalCompletePersonalInformation isOpen={completePersonalInfoShow} toggle={toggleCompletePersonalInfoShow}/>
            ) : (
                <div></div>
            )}
        </div>
    )
}
