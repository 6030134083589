import ReactGA from 'react-ga4'

const useGAEventTracker = (category, action, label) => {
    const trackEvent = (category, action, label) => {
        ReactGA.event({
            category,
            action,
            label,
        });
    };

    return trackEvent;
};

export default useGAEventTracker;