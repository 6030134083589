import React from 'react'
import { useSelector } from 'react-redux'

export const WhatsappFloatingButton = () => {

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    return (
        <div>
            <a href="https://wa.link/4el72k"
            target="_blank"
            rel="noreferrer"
            style={{ 
                position: 'fixed',
                width: '2em' ,
                height: '2em',
                bottom: user && user.balance_to_give_away > 0 ? '4.2em' : '2.3em',
                right: '0.6em',
                backgroundColor: '#25d366',
                color: '#FFF',
                borderRadius: '50px',
                textAlign: 'center',
                fontSize: '30px',
                boxShadow: '2px 2px 3px #999',
                zIndex: '100'
            }}
            >
                <i className="fab fa-whatsapp" style={{ marginTop: '16px' }}></i>
            </a>
        </div>
    )
}