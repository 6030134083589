import React, { useState, lazy, Suspense } from 'react'
import axios from "axios"
import { LinkContainer } from 'react-router-bootstrap'
import { useSelector } from 'react-redux'
import { Row, Col, Container, Form, Button, Image } from 'react-bootstrap'
import Message from '../../../components/Message'
import VerdemiLogo from '../../../images/logo verdemi.png'
import { WhatsappFloatingButton } from '../../../components/user/WhatsappFloatingButton'

const Footer = lazy(() => import('../../../components/user/prospects/Footer'))
const Header = lazy(() => import('../../../components/user/prospects/Header'))

export const UserResetPasswordScreen = () => {

    const personalCodeResetPassword = useSelector(state => state.resetPasswordPersonalCode)
    const { resetPasswordPersonalCode } = personalCodeResetPassword

    const personalCodeResetId = useSelector(state => state.resetPasswordPersonalId)
    const { resetPasswordPersonalId } = personalCodeResetId

    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')

    const [passwordChanged, setPasswordChanged] = useState(false)
    const [passwordError, setPasswordError] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()
        const change_password_info = {
            new_password: password,
            personal_code: resetPasswordPersonalCode,
            personal_id: resetPasswordPersonalId
        }
        axios
        .put(`${window.location.origin}/api/accounts/user_reset_password/`, change_password_info)
        .then((response) => {
                if (response.status === 200) {
                    setPasswordChanged(true)
                    console.log(response, 'response')
                }
        }).catch(error => {
            setPasswordError(error.message)
            console.log(error.message, 'error')
        });
        setPassword('')
        setPassword2('')
    }

    return (
        <div>
            <Suspense fallback={"Cargando..."}>
                <Header />
            </Suspense>
            <Container>
                <div className='primary-overlay'>
                    <Row className='mt-3'>
                        <Col className='d-flex justify-content-center mx-3 my-1 mt-5'>
                            <Image className="img-fluid" src={VerdemiLogo} alt="Verdemi Logo"/>
                        </Col>
                    </Row>
                    <Row className='justify-content-md-center'>
                        <Col md={6}>
                        <h1 className='mt-1 text-center'>Cambiar Contraseña</h1>
                        {passwordError && passwordError.length > 0 && (
                            <Message variant='danger'>{passwordError}</Message>
                            )}
                        {passwordChanged ? (
                            <div>
                                <Message variant='danger'>Contraseña cambiada. Ya puedes iniciar sesión</Message>
                                <LinkContainer to='/login/'>
                                    <Button variant='success' className='btn-block'>
                                        Iniciar sesión
                                    </Button>
                                </LinkContainer>
                            </div>
                        ) : (
                            <Form onSubmit={submitHandler}>
                                <Form.Group controlId='text' className='mt-3'>
                                <Form.Group controlId='password' className='mt-3'>
                                <Form.Control
                                    required
                                    type='password'
                                    placeholder='Nueva contraseña'
                                    className='rounded-pill text-center'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                >
                                </Form.Control>
                                </Form.Group>
                                    {password.length < 10 && password.length > 0 && (
                                        <Row>
                                            <Col className='mb-n1'>
                                                <p className='text-danger mt-n2 mb-0' style={{fontSize: '0.9rem'}}>La contraseña tiene que tener al menos 10 caracteres</p>
                                            </Col>
                                        </Row>
                                    )}
                                <Form.Group controlId='password' className='mt-3'>
                                    <Form.Control
                                        required
                                        type='password'
                                        placeholder='Nueva contraseña (de nuevo)'
                                        className='rounded-pill text-center'
                                        value={password2}
                                        onChange={(e) => setPassword2(e.target.value)}
                                    >
                                    </Form.Control>

                                        {password !== password2 && password.length > 10 && password2.length > 1 && (
                                            <Row>
                                                <Col className='mb-n1'>
                                                    <p className='text-danger mt-1 mb-0' style={{fontSize: '0.9rem'}}>No coinciden las contraseñas</p>
                                                </Col>
                                            </Row>
                                        )}
        
                                </Form.Group>
                                </Form.Group>
                                <Button type='submit' disabled={password !== password2 || password.length < 10 || password2.length < 10 } variant='success' className='my-3 align-self-center btn-block btn-lg rounded-pill'>
                                    {/* {registerLoading || loading ? (
                                        <Spinner
                                        animation='border'
                                        role='status'
                                        style={{
                                            height:'25px',
                                            width:'25px',
                                            margin:'auto',
                                            display:'block'
                                        }}
                                        >
                                            <span className='sr-only'>
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ) : (
                                        <span> */}
                                            Cambiar la contraseña
                                        {/* </span>
                                    )} */}
                                </Button>
                            </Form>
                        )}
                        </Col>
                    </Row>
                </div>
            </Container>
            <WhatsappFloatingButton />
            <Row className='bg-light d-flex justify-content-center p-3 p-md-5'>
                <Col>
                    <Suspense fallback={"Cargando..."}>
                        <Footer />
                    </Suspense>
                </Col>
            </Row>
        </div>
    )
}
