import React from 'react'

export const SubscriptionGlobalFilter = ({ filter, setFilter }) => {
  return (
    <span className='mb-3'>
        Filtrar: {' '}
        <input 
            value={filter || ''}
            onChange={(e) => setFilter(e.target.value)}
            className='form-control mb-3'
            placeholder='(por nombre, email, estado, etc)'
            />
    </span>
  )
}
