import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { Row, Col } from 'react-bootstrap'
import { LazyLoadImage } from "react-lazy-load-image-component";

export const ProductDetailImage = ({product}) => {

    return (
        <div>
            <Row className='mb-3'>
                {product ? (
                    <LazyLoadImage
                        className="card-img-top"
                        src={product.image_390}
                        alt={product.name}
                        style={{width: '100%'}}
                    />
                    ) : (
                    <Spinner animation="grow" variant="success" />
                )}
            </Row>
            <Row>
                <Col xs={3} p-0>
                    {product ? (
                        <LazyLoadImage
                            className="card-img-top"
                            src={product.image_68}
                            alt={product.name}
                            style={{width: '100%'}}
                        />
                    ) : (
                        <Spinner animation="grow" variant="success" />
                    )}
                </Col>
                <Col xs={3} p-0>
                    {product ? (
                        <LazyLoadImage
                            className="card-img-top"
                            src={product.image_68}
                            alt={product.name}
                            style={{width: '100%'}}
                        />
                    ) : (
                        <Spinner animation="grow" variant="success" />
                    )}
                </Col>
            </Row>
        </div>
    )
}
