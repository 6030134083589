import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import {
    cancelSubscriptionAction,
    subscriptionDetailAction,
    removeLocalSubscriptionAction } from '../../../../../../actions/subscriptionActions'
import { nextWeekSubscriptionOrderDetailAction,
        removeLocalOrderAction } from '../../../../../../actions/orderActions'
import { CANCEL_SUBSCRIPTION_RESET } from '../../../../../../constants/subscriptionConstants'
import Message from '../../../../../Message'
import { userMessageAction, userLastOrderAction, userOrdersListAction } from '../../../../../../actions/userActions'

export const ModalCancelSubscription = (props) => {

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    const cancelSubscription = useSelector(state => state.subscriptionCancel)
    const { error: cancelError, loading: cancelLoading, success } = cancelSubscription

    const dispatch = useDispatch()

    const cancelSubscriptionHandler = (e) => {
        e.preventDefault()
        // 3: the pk id of the Canceled status. Check the pk on production
        dispatch(cancelSubscriptionAction(subscription.id))
    }

    const CloseCancelSubscriptionModal = () => {
        props.toggle()
    }

    useEffect(() => {
        if(success) {
            console.log('EL PROBLEMA ES POR AQUÌ VERDAD??')
            dispatch(subscriptionDetailAction())
            dispatch(nextWeekSubscriptionOrderDetailAction())
            dispatch({
                type: CANCEL_SUBSCRIPTION_RESET
            })
            CloseCancelSubscriptionModal()
            dispatch(userMessageAction('Subscripción cancelada. Esperamos verte de vuelta pronto'))
            dispatch(userLastOrderAction())
            dispatch(userOrdersListAction())
            dispatch(removeLocalOrderAction())
            dispatch(removeLocalSubscriptionAction())
        }
    }, [success, dispatch, CloseCancelSubscriptionModal])


    return (
        <div>
            <Modal show={props.isOpen} onHide={props.toggle} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title className='text-success'><i className="far fa-window-close"></i> Cancelar subscripción</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Cancelar subscripción de:</p>
                    <ul>
                        {subscription && subscription.products_items && subscription.products_items.map(item => (
                            <li key={item.id}>
                                {item.products.name} <small>({item.amount} unidades)</small>
                            </li>
                        ))
                        }    
                    </ul>
                        {cancelError && (<Message variant='danger'>{cancelError}</Message>)}
                        <Form onSubmit={cancelSubscriptionHandler}>
                            <Button variant="danger" disabled={cancelLoading} type="submit" className='btn-block'>
                                {cancelLoading ? (
                                    <Spinner
                                    animation='border'
                                    role='status'
                                    style={{
                                        height:'25px',
                                        width:'25px',
                                        margin:'auto',
                                        display:'block'
                                    }}
                                    >
                                        <span className='sr-only'>
                                            Loading...
                                        </span>
                                    </Spinner>
                                ) : (
                                    <span>
                                        <i className="far fa-window-close"></i> Cancelar subscripción
                                    </span>
                                )}
                            </Button>
                        </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={CloseCancelSubscriptionModal} className='btn-block'>
                    Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>                        
        </div>
    )
}
