import axios from 'axios'

import { 
    CREATE_PAYMENT_CARD_REQUEST,
    CREATE_PAYMENT_CARD_FAILS,
    CREATE_PAYMENT_CARD_SUCCESS,
    
    GET_DONATIONS_REQUEST,
    GET_DONATIONS_SUCCESS,
    GET_DONATIONS_FAILS,

    REMOVE_PAYMENT_CARD_REQUEST,
    REMOVE_PAYMENT_CARD_SUCCESS,
    REMOVE_PAYMENT_CARD_FAILS,

    CHANGE_PAYMENT_METHOD_REQUEST,
    CHANGE_PAYMENT_METHOD_SUCCESS,
    CHANGE_PAYMENT_METHOD_FAILS,

    PLATFORM_BALANCE_CHANGE_REQUEST,
    PLATFORM_BALANCE_CHANGE_SUCCESS,
    PLATFORM_BALANCE_CHANGE_FAILS,

    NOT_BROADCASTED_DONATIONS_REQUEST,
    NOT_BROADCASTED_DONATIONS_SUCCESS,
    NOT_BROADCASTED_DONATIONS_FAILS, 
    
    BROADCAST_DONATIONS_REQUEST,
    BROADCAST_DONATIONS_SUCCESS,
    BROADCAST_DONATIONS_FAILS,

} from "../constants/paymentsConstants";

export const createCardAction = (titular, cvv_value, numero_de_tarjeta, documentType, numero_documento, mes_caducidad, año_caducidad, creditCardType) => async (dispatch, getState) => {
    try {
        dispatch({type: CREATE_PAYMENT_CARD_REQUEST })

        const {
            userLogin: { user }
        } = getState()
    
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
    
        const res = await axios.post('/api/payments/create_credit_card/', 
            {titular, cvv_value, numero_de_tarjeta, documentType, numero_documento, mes_caducidad, año_caducidad, creditCardType}, config)

    
        const { data } = res
   
        
        dispatch({
            type: CREATE_PAYMENT_CARD_SUCCESS,
            payload: data
        })    
        
    } catch (error) {
        dispatch({
            type: CREATE_PAYMENT_CARD_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })   
    }
}

export const getDonationsAction = () => async (dispatch) => {
    try {
        dispatch({ type: GET_DONATIONS_REQUEST })

        const res = await axios.get('/api/payments/donations_list/')
    
        const { data } = res
   
        
        dispatch({
            type: GET_DONATIONS_SUCCESS,
            payload: data
        })    


    } catch (error) {     
        dispatch({
            type: GET_DONATIONS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })   

    }
}

export const removeCardAction = ( lastFourDigits, franchise ) => async (dispatch, getState) => {
    try {
        dispatch({type: REMOVE_PAYMENT_CARD_REQUEST })

        const {
            userLogin: { user }
        } = getState()
    
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.delete(`/api/payments/user_remove_payment_methods/${lastFourDigits}/${franchise}`, config)

        const { data } = res
           
        dispatch({
            type: REMOVE_PAYMENT_CARD_SUCCESS,
            payload: data
        })    
        
    } catch (error) {
        dispatch({
            type: REMOVE_PAYMENT_CARD_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })   
    }
}

export const changePaymentMethodAction = (lastFourDigits, franchise) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CHANGE_PAYMENT_METHOD_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.put('/api/payments/change_selected_payment_method/', 
        {lastFourDigits, franchise}, config)

        const { data } = res

        dispatch({
            type: CHANGE_PAYMENT_METHOD_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: CHANGE_PAYMENT_METHOD_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message})
    }
}

export const platformBalanceChangeAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: PLATFORM_BALANCE_CHANGE_REQUEST

        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/payments/platform_balance_change/', config)

        const { data } = res

        dispatch({
            type: PLATFORM_BALANCE_CHANGE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PLATFORM_BALANCE_CHANGE_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message})
    }
}

export const getNotBroadcastedDonationsAction = () => async (dispatch, getState) => {
    try {
        dispatch({type: NOT_BROADCASTED_DONATIONS_REQUEST })

        const {
            userLogin: { user }
        } = getState()
    
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
    
        const res = await axios.get('/api/payments/not_broadcasted_donations_list/', config)
    
        const { data } = res
   
        
        dispatch({
            type: NOT_BROADCASTED_DONATIONS_SUCCESS,
            payload: data
        })    
        
    } catch (error) {
        dispatch({
            type: NOT_BROADCASTED_DONATIONS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })   
    }
}

export const broadcastDonationsAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: BROADCAST_DONATIONS_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/payments/broadcast_pending_donations/', config)

        const { data } = res

        dispatch({
            type: BROADCAST_DONATIONS_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: BROADCAST_DONATIONS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}
