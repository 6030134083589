import axios from 'axios'
import { 
    SUBSCRIPTION_DETAIL_FAILS,
    SUBSCRIPTION_DETAIL_SUCCESS,
    SUBSCRIPTION_DETAIL_REQUEST,

    SUBSCRIPTION_UPDATE_FAILS,
    SUBSCRIPTION_UPDATE_REQUEST,
    SUBSCRIPTION_UPDATE_SUCCESS,

    FREQUENCY_DAYS_FAILS,
    FREQUENCY_DAYS_REQUEST,
    FREQUENCY_DAYS_SUCCESS,

    CREATE_FREQUENCY_FAILS,
    CREATE_FREQUENCY_REQUEST,
    CREATE_FREQUENCY_SUCCESS,

    SUBSCRIPTION_UPDATE_STATUS_FAILS,
    SUBSCRIPTION_UPDATE_STATUS_REQUEST,
    SUBSCRIPTION_UPDATE_STATUS_SUCCESS,

    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CANCEL_SUBSCRIPTION_FAILS,
    CANCEL_SUBSCRIPTION_RESET,


    SUBSCRIPTION_CHECK_ACTIVE_STATUS_REQUEST,
    SUBSCRIPTION_CHECK_STATUS_ACTIVE_SUCCESS,
    SUBSCRIPTION_CHECK_STATUS_ACTIVE_FAILS,
    SUBSCRIPTION_CHECK_STATUS_ACTIVE_RESET,
    

    UPDATE_FREQUENCY_FAILS,
    UPDATE_FREQUENCY_REQUEST,
    UPDATE_FREQUENCY_SUCCESS,

    SUBSCRIPTION_PAUSE_FAILS,
    SUBSCRIPTION_PAUSE_REQUEST,
    SUBSCRIPTION_PAUSE_SUCCESS,

    LOCAL_SUBSCRIPTION_CHANGE_AMOUNT_ITEM,

    REMOVE_LOCAL_SUBSCRIPTION,

    SUBSCRIPTION_ID

} from "../constants/subscriptionConstants";

export const subscriptionDetailAction = (loading=true) => async (dispatch, getState) => {
    try {
        if (loading) {
            dispatch({
                type: SUBSCRIPTION_DETAIL_REQUEST
            })
            
        }    

    const {
        userLogin: { user }
    } = getState()

    const config = {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    }

    const res = await axios.get('/api/plans/subscription/', config)

    const { data } = res

    dispatch({
        type: SUBSCRIPTION_DETAIL_SUCCESS,
        payload: data
    })

    localStorage.setItem('subscriptionDetail', JSON.stringify(data))
        
    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_DETAIL_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })
    }
} 

export const subscriptionUpdateAction = (subscription_pk, product_pk, qty) => async (dispatch, getState) => {
    try {

    dispatch({
        type: SUBSCRIPTION_UPDATE_REQUEST,
        payload: product_pk
    })

    const {
        userLogin: { user }
    } = getState()

    const config = {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    }

    const res = await axios.put('/api/plans/update_subscription_products/', 
        {'subscription_pk': subscription_pk, 'product_pk': product_pk, 'qty': qty}, config)

    const { data } = res

    dispatch({
        type: SUBSCRIPTION_UPDATE_SUCCESS,
        payload: data
    })
        
    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_UPDATE_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })       
    }
}


export const frequencyDaysAction = () => async (dispatch) => {
    try {
        dispatch({
            type: FREQUENCY_DAYS_REQUEST
        })

    const res = await axios.get('/api/plans/delivery_days/')

    const { data } = res

    dispatch({
        type: FREQUENCY_DAYS_SUCCESS,
        payload: data
    })

    } catch (error) {
        dispatch({
            type: FREQUENCY_DAYS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })               
    }
}

export const createFrequencyAction = (frequency_info) => async (dispatch, getState) => {
    try {

        dispatch({
            type: CREATE_FREQUENCY_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()
    
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
    
        const res = await axios.post('/api/plans/create_frequency/', 
            { frequency_info }, config)
   
        const { data } = res

        dispatch({
            type: CREATE_FREQUENCY_SUCCESS,
            payload: data
        })
    
        
    } catch (error) {
        dispatch({
            type: CREATE_FREQUENCY_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const subscriptionUpdateStatusAction = (subscription_pk, status_pk) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SUBSCRIPTION_UPDATE_STATUS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()
    
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
    
        const res = await axios.put('/api/plans/update_subscription_status/', 
            { subscription_pk, status_pk }, config)
   
        const { data } = res

        dispatch({
            type: SUBSCRIPTION_UPDATE_STATUS_SUCCESS,
            payload: data
        })

        
    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_UPDATE_STATUS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                
    }
}

export const cancelSubscriptionAction = (subscription_pk) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CANCEL_SUBSCRIPTION_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()
    
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const status_pk = 3
    
        const res = await axios.put('/api/plans/update_subscription_status/', 
            { subscription_pk, status_pk }, config)
   
        const { data } = res

        dispatch({
            type: CANCEL_SUBSCRIPTION_SUCCESS,
            payload: data
        })

        
    } catch (error) {
        dispatch({
            type: CANCEL_SUBSCRIPTION_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                
    }
}


export const subscriptionCheckActiveStatusAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SUBSCRIPTION_CHECK_ACTIVE_STATUS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()
    
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
    
        const res = await axios.get('/api/plans/check_subscription_status/active/', config)
   
        const { data } = res

        dispatch({
            type: SUBSCRIPTION_CHECK_STATUS_ACTIVE_SUCCESS,
            payload: data
        })

        
    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_CHECK_STATUS_ACTIVE_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                
    }
}


export const updateFrequencyAction = (frecuency_info) => async (dispatch, getState) => {
    try {
        
        dispatch({
            type: UPDATE_FREQUENCY_REQUEST
        })
    
        const {
            userLogin: { user }
        } = getState()
    
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
    
        const res = await axios.put('/api/plans/update_frecuency/', 
            { frecuency_info }, config)
   
        const { data } = res

        dispatch({
            type: UPDATE_FREQUENCY_SUCCESS,
            payload: data
        })
        
        
    } catch (error) {
        dispatch({
            type: UPDATE_FREQUENCY_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                        
    }
}

export const subscriptionPauseAction = (subscription, periods) => async (dispatch, getState) => {
    try {

        dispatch({
            type: SUBSCRIPTION_PAUSE_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()
    
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
    
        const res = await axios.post('/api/plans/pause_subscription/', 
            { subscription, periods }, config)

  
        const { data } = res

        dispatch({
            type: SUBSCRIPTION_PAUSE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_PAUSE_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                
    }
}


export const localSubscriptionAction = (products, amount, total_value, status, subscription_status, delivery_period) => (dispatch, getState) =>{
    try {
        dispatch({
            type: LOCAL_SUBSCRIPTION_CHANGE_AMOUNT_ITEM,
            payload: {products, amount, total_value, status, subscription_status, delivery_period}
        })        
    } catch (error) {
        console.log(error)
    }
    // localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeLocalSubscriptionAction = () => (dispatch) => {
    try {
        dispatch({
            type: REMOVE_LOCAL_SUBSCRIPTION
        })
    } catch (error) {
        console.log(error)        
    }
    // localStorage.removeItem('cartItems')
}

export const SubscriptionIdAction = (id) => (dispatch) =>{
    try {
        dispatch({
            type: SUBSCRIPTION_ID,
            payload: id
        })        
    } catch (error) {
        console.log(error)
    }
}
