import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const { REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT } = process.env
  

export const PriceApplied = (props) => {

    const valueCart = useSelector(state => state.cartValue)
    const { discount } = valueCart

    console.log(props.type)

    return (
        <Row style={{ marginTop: '1.3em', marginBottom: '-0.4em'}}>
            <Col 
                xs={6}
                className='mb-0 my-n1 px-n1'
                style={{
                borderTop:
                    discount && discount < REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT && props.type === 'cart' && '3px solid #2C971F',
                }}
            >
                <hr
                    className='my-n1'
                    style={{ width:'2.3em', opacity: '0'}}
                ></hr>
            </Col>
            <Col
                xs={6}
                className='mb-0 my-n1'
                style={{
                    borderTop: discount && discount === REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT && props.type === 'cart' && '3px solid #2C971F',
            }}        
            >
                <hr className='my-n1' style={{ width:'60px', opacity: '0'}}></hr>
            </Col>
        </Row>
    )
}
