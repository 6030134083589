export const PRODUCTS_CATEGORIES_REQUEST = 'PRODUCTS_CATEGORIES_REQUEST'
export const PRODUCTS_CATEGORIES_SUCCESS = 'PRODUCTS_CATEGORIES_SUCCESS'
export const PRODUCTS_CATEGORIES_FAILS = 'PRODUCTS_CATEGORIES_FAILS'
export const PRODUCTS_CATEGORIES_RESET = 'PRODUCTS_CATEGORIES_RESET'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAILS = 'PRODUCT_DETAILS_FAILS'

export const CATEGORY_DETAILS_REQUEST = 'CATEGORY_DETAILS_REQUEST'
export const CATEGORY_DETAILS_SUCCESS = 'CATEGORY_DETAILS_SUCCESS'
export const CATEGORY_DETAILS_FAILS = 'CATEGORY_DETAILS_FAILS'

export const PRODUCT_UPDATE_INFO_REQUEST = 'PRODUCT_UPDATE_INFO_REQUEST'
export const PRODUCT_UPDATE_INFO_SUCCESS = 'PRODUCT_UPDATE_INFO_SUCCESS'
export const PRODUCT_UPDATE_INFO_FAILS = 'PRODUCT_UPDATE_INFO_FAILS'
export const PRODUCT_UPDATE_INFO_RESET = 'PRODUCT_UPDATE_INFO_RESET'

export const TOP_PRODUCTS_LIST_REQUEST = 'TOP_PRODUCTS_LIST_REQUEST'
export const TOP_PRODUCTS_LIST_SUCCESS = 'TOP_PRODUCTS_LIST_SUCCESS'
export const TOP_PRODUCTS_LIST_FAILS = 'TOP_PRODUCTS_LIST_FAILS'
export const TOP_PRODUCTS_LIST_RESET = 'TOP_PRODUCTS_LIST_RESET'

export const TOP_PRODUCT_CATEGORY_LIST_REQUEST = 'TOP_PRODUCT_CATEGORY_LIST_REQUEST'
export const TOP_PRODUCT_CATEGORY_LIST_SUCCESS = 'TOP_PRODUCT_CATEGORY_LIST_SUCCESS'
export const TOP_PRODUCT_CATEGORY_LIST_FAILS = 'TOP_PRODUCT_CATEGORY_LIST_FAILS'
export const TOP_PRODUCT_CATEGORY_LIST_RESET = 'TOP_PRODUCT_CATEGORY_LIST_RESET'

export const PRODUCTS_TO_BUY_SEND_EMAIL_REQUEST = 'PRODUCTS_TO_BUY_SEND_EMAIL_REQUEST'
export const PRODUCTS_TO_BUY_SEND_EMAIL_SUCCESS = 'PRODUCTS_TO_BUY_SEND_EMAIL_SUCCESS'
export const PRODUCTS_TO_BUY_SEND_EMAIL_FAILS = 'PRODUCTS_TO_BUY_SEND_EMAIL_FAILS'

export const PRODUCTS_LIST_REQUEST = 'PRODUCTS_LIST_REQUEST'
export const PRODUCTS_LIST_SUCCESS = 'PRODUCTS_LIST_SUCCESS'
export const PRODUCTS_LIST_FAILS = 'PRODUCTS_LIST_FAILS'
export const PRODUCTS_LIST_RESET = 'PRODUCTS_LIST_RESET'

export const PRODUCTS_HISTORIC_INFO_REQUEST = 'PRODUCTS_HISTORIC_INFO_REQUEST'
export const PRODUCTS_HISTORIC_INFO_SUCCESS = 'PRODUCTS_HISTORIC_INFO_SUCCESS'
export const PRODUCTS_HISTORIC_INFO_FAILS = 'PRODUCTS_HISTORIC_INFO_FAILS'
export const PRODUCTS_HISTORIC_INFO_RESET = 'PRODUCTS_HISTORIC_INFO_RESET'

export const MARKETS_REQUEST = 'MARKETS_REQUEST'
export const MARKETS_SUCCESS = 'MARKETS_SUCCESS'
export const MARKETS_FAILS = 'MARKETS_FAILS'
export const MARKETS_RESET = 'MARKETS_RESET'