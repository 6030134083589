import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalChangeAddress } from './ModalChangeAddress';


export const ChangeAddressModalButton = () => {

    const [changeAddressShow, setChangeAddressShow] = useState(false);
    const toggleChangeAddressModal = () => {
        setChangeAddressShow(!changeAddressShow)
    } 

    return (
        <div>
            <Button variant='outline-success' className='btn-sm btn-block mb-1' onClick={toggleChangeAddressModal} style={{ backgroundColor: 'rgba(0,0,0,0)', border: '1px solid #3FE22B', color: '#3FE22B' }}>
                <i className="fas fa-map-marked-alt"></i> Modificar Dirección
            </Button>

            <ModalChangeAddress isOpen={changeAddressShow} toggle={toggleChangeAddressModal}/>
        </div>
    )
}
