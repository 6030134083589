export const CREATE_PAYMENT_CARD_REQUEST = 'CREATE_PAYMENT_CARD_REQUEST'
export const CREATE_PAYMENT_CARD_SUCCESS = 'CREATE_PAYMENT_CARD_SUCCESS'
export const CREATE_PAYMENT_CARD_FAILS = 'CREATE_PAYMENT_CARD_FAILS'
export const CREATE_PAYMENT_CARD_RESET = 'CREATE_PAYMENT_CARD_RESET'

export const GET_DONATIONS_REQUEST = 'GET_DONATIONS_REQUEST'
export const GET_DONATIONS_SUCCESS = 'GET_DONATIONS_SUCCESS'
export const GET_DONATIONS_FAILS = 'GET_DONATIONS_FAILS'
export const GET_DONATIONS_RESET = 'GET_DONATIONS_RESET'

export const REMOVE_PAYMENT_CARD_REQUEST = 'REMOVE_PAYMENT_CARD_REQUEST'
export const REMOVE_PAYMENT_CARD_SUCCESS = 'REMOVE_PAYMENT_CARD_SUCCESS'
export const REMOVE_PAYMENT_CARD_FAILS = 'REMOVE_PAYMENT_CARD_FAILS'
export const REMOVE_PAYMENT_CARD_RESET = 'REMOVE_PAYMENT_CARD_RESET'

export const CHANGE_PAYMENT_METHOD_REQUEST = 'CHANGE_PAYMENT_METHOD_REQUEST'
export const CHANGE_PAYMENT_METHOD_SUCCESS = 'CHANGE_PAYMENT_METHOD_SUCCESS'
export const CHANGE_PAYMENT_METHOD_FAILS = 'CHANGE_PAYMENT_METHOD_FAILS'
export const CHANGE_PAYMENT_METHOD_RESET = 'CHANGE_PAYMENT_METHOD_RESET'

export const PLATFORM_BALANCE_CHANGE_REQUEST = 'PLATFORM_BALANCE_CHANGE_REQUEST'
export const PLATFORM_BALANCE_CHANGE_SUCCESS = 'PLATFORM_BALANCE_CHANGE_SUCCESS'
export const PLATFORM_BALANCE_CHANGE_FAILS = 'PLATFORM_BALANCE_CHANGE_FAILS'
export const PLATFORM_BALANCE_CHANGE_RESET = 'PLATFORM_BALANCE_CHANGE_RESET'

export const NOT_BROADCASTED_DONATIONS_REQUEST = 'NOT_BROADCASTED_DONATIONS_REQUEST'
export const NOT_BROADCASTED_DONATIONS_SUCCESS = 'NOT_BROADCASTED_DONATIONS_SUCCESS'
export const NOT_BROADCASTED_DONATIONS_FAILS = 'NOT_BROADCASTED_DONATIONS_FAILS'
export const NOT_BROADCASTED_DONATIONS_RESET = 'NOT_BROADCASTED_DONATIONS_RESET'

export const BROADCAST_DONATIONS_REQUEST = 'BROADCAST_DONATIONS_REQUEST'
export const BROADCAST_DONATIONS_SUCCESS = 'BROADCAST_DONATIONS_SUCCESS'
export const BROADCAST_DONATIONS_FAILS = 'BROADCAST_DONATIONS_FAILS'
export const BROADCAST_DONATIONS_RESET = 'BROADCAST_DONATIONS_RESET'



