import React, { useState, useEffect } from 'react'
import {  useSelector, useDispatch } from 'react-redux'
import { fundsReceivedAction } from '../../../actions/userActions'

export const ReceivedFundsFloatingButton = (props) => {

    const personalCodeHost = useSelector(state => state.hostPersonalCode)
    const { hostPersonalCode } = personalCodeHost

    const userFundsReceived = useSelector(state => state.fundsReceived)
    const { receivedFunds, loading: loadingReceivedFunds } = userFundsReceived

    const [reset, setReset] = useState(true)

    const dispatch = useDispatch()

    const toggleClassName = () => {
        setReset(false)
      };
    
    useEffect(() => {
        if (hostPersonalCode) {
            if(receivedFunds === null && loadingReceivedFunds === false) {
                console.log('fundsReceivedAction')
                dispatch(fundsReceivedAction(hostPersonalCode))
            }
        }
        }, [hostPersonalCode, receivedFunds, loadingReceivedFunds, dispatch])


    useEffect(() => {
        setTimeout(() => toggleClassName(), 6000);
    },[])

    const[ pixelsWidth, setPixelWidth ] = useState('0px')

    useEffect(() => {
        if (receivedFunds) {
            const pixels = String(receivedFunds.balance_to_give_away).length * 25
            setPixelWidth(String(pixels) + 'px')
        }
    }, [receivedFunds])

    return (
        <div>
            {receivedFunds && (
                <a className={reset ? 'flip' : ''} href='' target='_blank' rel="noreferrer" 
                    style={{ 
                        position: 'fixed',
                        width: receivedFunds.balance_to_give_away && receivedFunds.balance_to_give_away > 0 ?  `${pixelsWidth}`  : '60px',
                        height: '1.3em',
                        bottom: '1em',
                        right: '15px',
                        backgroundColor: '#00baff',
                        color: '#FFF',
                        borderRadius: '50px',
                        textAlign: 'center',
                        fontSize: '1.6em',
                        boxShadow: '2px 2px 3px #999',
                        zIndex: '100'
                    }}                    
                >
                    {receivedFunds && receivedFunds !== null ? (
                        
                        <p>
                            ${new Intl.NumberFormat('co-CO').format(Number(receivedFunds.balance_to_give_away))}
                        </p>
                    ) : (
                        <i className="fas fa-share-alt" style={{ marginTop: '16px' }}></i>
                    )}
                </a>
                )}
        </div>
    )
}
