import React from 'react'
import { useSelector } from 'react-redux'
import { PaymentMethodsModalButton } from '../../shared/PaymentMethodsModalButton';
import { CreditCardModalButton } from '../../CreditCardModalButton';


export const PaymentsTab = () => {

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const paymentMethodList = useSelector(state => state.userPaymentMethodList)
    const { paymentMethod } = paymentMethodList

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    return (
        <div>
            {user && paymentMethod && subscription && subscription.frecuency.deliver_weekday.length > 0 ? (
                <div>
                    <div className="d-flex justify-content-center px-3">
                        {paymentMethod !== null && paymentMethod.length > 0 ? (
                            <PaymentMethodsModalButton />
                                ) : (
                            <div>
                                <CreditCardModalButton />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div>
                </div>
            )}                                    
        </div>
    )
}
