import React, { useState, useEffect } from 'react'
import {  useSelector } from 'react-redux'

const { 
    REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE
     } = process.env

export const GiveAwayFloatingButton = (props) => {

    const [reset, setReset] = useState(true)

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const toggleClassName = () => {
        setReset(false)
      };
    

    useEffect(() => {
        setTimeout(() => toggleClassName(), 6000);
    },[])

    const[ pixelsWidth, setPixelWidth ] = useState('0px')

    useEffect(() => {
        if (user) {
            const pixels = String(user.balance_to_give_away).length * 0.9
            setPixelWidth(String(pixels) + 'em')
        }
    }, [user])

    return (
        <div>
            {user && user.balance_to_give_away > 0 && (
                <a className={reset ? 'flip' : ''} href={`https://wa.me/?text=Hola! Estoy usando una plataforma que se llama Verdemi para comprar frutas y verduras. Te comparto mi código https://www.verdemi.com/%23/${user.personal_code}/ y disfruta de un descuento de ${REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE * 100}% en tus dos primeras compras y yo también recibiré descuentos`} target='_blank' rel="noreferrer" 
                    style={{ 
                        position: 'fixed',
                        width: user && user.balance_to_give_away > 0 ?  `${pixelsWidth}`  : '60px',
                        height: '2.7em',
                        bottom: '3.6em',
                        right: '0.8em',
                        backgroundColor: 'orange',
                        color: '#FFF',
                        borderRadius: '50px',
                        textAlign: 'center',
                        fontSize: '1.2em',
                        boxShadow: '2px 2px 3px #999',
                        zIndex: '100',
                        display: 'flex',
                        alignItems: 'center', // Centrado vertical
                        justifyContent: 'center', // Centrado horizontal
                    }}
                >
                    {user ? (
                        <p style={{margin: '0', verticalAlign: 'middle'}}>
                            ${new Intl.NumberFormat('co-CO').format(Number(user.balance_to_give_away))}
                        </p>
                    ) : (
                        <i className="fas fa-share-alt" style={{ marginTop: '16px' }}></i>
                    )}
                </a>
            )}
        </div>
    )
}
