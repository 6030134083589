import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalReporClientIssue } from '../modals/ModalReporClientIssue';


export const ClientIssueUserModalButton = () => {

    const [clientIssueShow, setClientIssueShow] = useState(false);
    const toggleDeliveryIssueModal = () => {
        setClientIssueShow(!clientIssueShow)
    } 


    return (
        <div>
            <Button type="button" variant='info' className="btn-block btn-sm my-1" onClick={toggleDeliveryIssueModal}>
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Reportar problema con orden
            </Button>
            {clientIssueShow ? (
                <ModalReporClientIssue isOpen={clientIssueShow} toggle={toggleDeliveryIssueModal} />
            ) : (
                <div></div>
            )}
        </div>
    )
}
