import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { PastOrdersModalButton } from './buttons/PastOrdersModalButton';
import { VerdemiBalanceChangesModalButton } from './modals/VerdemiBalanceChangesModalButton';
import { ChangePasswordModalButton } from './buttons/ChangePasswordModalButton';
import { ChangeAddressModalButton } from '../shared/ChangeAddressModalButton';
import { CompletePersonalInformationModalButton } from './buttons/CompletePersonalInformationModalButton';
import { ChangeFrecuencyUserModalButton } from './buttons/ChangeFrecuencyUserModalButton';
import { CancelSubscriptionModalButton } from './buttons/CancelSubscriptionModalButton';
import { PaymentMethodsModalButton } from '../shared/PaymentMethodsModalButton';
import { ClientIssueUserModalButton } from './buttons/ClientIssueUserModalButton';
import { CreditCardModalButton } from '../CreditCardModalButton';
import UserAddressMap from './UserAddressMap';
import Loader from '../../../../Loader'

const { REACT_APP_GMAPS_API_KEY } = process.env


export const UserInfoMobile = ({ history }) => {

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    const paymentMethodList = useSelector(state => state.userPaymentMethodList)
    const { paymentMethod } = paymentMethodList

    const orderUserLast = useSelector(state => state.userLastOrder)
    const { last_order } = orderUserLast

    const orderNextWeekActiveSubscription = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
    const { loading: orderLoading, nextWeekActiveOrder } = orderNextWeekActiveSubscription

    return (
        <div>
            <Row>
                <Col xs={12} md={6} className='d-block d-sm-none'>
                    {user && user.name ? (
                    <h4 className='text-center mb-3'>
                        {user.name} {user.surname}
                        <CompletePersonalInformationModalButton
                            variant={'link'}
                            btnclass={'btn-sm'}                                                                
                            logo={"fas fa-edit"}
                            call_to_action={""}
                        />
                    </h4>
                    ) : (
                        <div className="d-flex justify-content-center px-3">
                            <CompletePersonalInformationModalButton
                                variant={'success'}
                                btnclass={'btn-sm btn-block mb-1'}                                                                
                                logo={"fas fa-signature"}
                                call_to_action={"Incluir Nombre"}
                            />
                        </div>      
                    )}
                    {user && user.email && <p className='text-center mb-0' style={{ overflowWrap: 'break-word' }}><small><i className="fas fa-at"></i> {user.email}</small></p>}
                    {user && user.telephone ? (
                        <p className='text-center mt-0'>
                            <small>
                                <i className="fas fa-mobile-alt"></i> {user.telephone}
                            </small>
                            <CompletePersonalInformationModalButton
                                variant={'link'}
                                btnclass={'btn-sm'}                                                                
                                logo={"fas fa-edit"}
                                call_to_action={""}
                            />
                        </p>
                    ) : (
                        <div className="d-flex justify-content-center px-3">
                            <CompletePersonalInformationModalButton
                                variant={'success'}
                                btnclass={'btn-sm btn-block mb-1'}                                                                
                                logo={"fas fa-mobile-alt"}
                                call_to_action={"Incluir Teléfono"}
                            />
                        </div>    
                    )}
                    {user && paymentMethod && subscription && subscription.frecuency.deliver_weekday.length > 0 ? (
                        <div>
                            <div className="d-flex justify-content-center px-3">
                                {paymentMethod !== null && paymentMethod.length > 0 ? (
                                    <PaymentMethodsModalButton />
                                ) : (
                                    <div>
                                        <CreditCardModalButton />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div>

                        </div>
                    )}
                    {user && user.user_platform_balance > 0 ? (
                        <div>
                            <hr></hr>
                            <div className="d-flex justify-content-center px-3">
                                <Col>
                                    <p className='my-0'>Balance en Verdemi:</p>
                                    <h2 className='text-primary' style={{fontWeight: "bold"}}>{new Intl.NumberFormat('co-CO').format(Number(user.user_platform_balance))} <small>pesos.</small></h2>
                                </Col>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    {user && user.total_platform_donations > 0 ? (
                        <div>
                            <div className="alert alert-success mx-3" role="alert">
                                <i className="fas fa-utensils text-success text-center"></i> Verdemi ha donado por tus compras {new Intl.NumberFormat('co-CO').format(Number(user.total_platform_donations))} a la Fundación Fiambre. <i className="fas fa-thumbs-up"></i>
                            </div>
                            <hr></hr>
                        </div>              
                    ) : (
                        <div></div>
                    )}
                    {last_order && last_order.length > 0 && (
                    <div className="d-flex justify-content-center px-3">
                        <ClientIssueUserModalButton />
                    </div>
                    )}
                </Col>
                <Col xs={12} md={6}>
                    <div className="d-sm-none">
                        <PastOrdersModalButton />
                    </div>
                    <div className="d-sm-none">
                        <VerdemiBalanceChangesModalButton />
                    </div>
                    <div className="d-sm-none">
                        <ChangePasswordModalButton />
                    </div>
                    {detailSubscription && detailSubscription.subscription && detailSubscription.subscription.status.name !== 'Canceled' && (
                        <div className="mb-1 d-sm-none">
                            <CancelSubscriptionModalButton />
                        </div>
                    )}
                    <div className='d-sm-none'>
                        {orderLoading ? (<Loader />) : 
                            nextWeekActiveOrder && nextWeekActiveOrder.date && (
                            <div>
                                <Col className='text-center'>
                                    {subscription && subscription.frecuency ? (
                                        <div>{subscription.frecuency.readeble_frecuency}</div>
                                    ) : (
                                        <span></span>
                                    )}
                                </Col>
                                <Col className='mb-1'>
                                    <ChangeFrecuencyUserModalButton />
                                </Col>
                            </div>
                            )
                        }
                    </div>
                </Col>
            </Row>
            <hr className='my-3 d-block d-sm-none'></hr>
            <div className='d-block d-sm-none'>
                {user && user.validated_address ? (
                    <div>
                        <Row>
                            <Col xs={12} md={6}>
                                <UserAddressMap
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=weekly&key=${REACT_APP_GMAPS_API_KEY}`}
                                    containerElement={<div style={{ height: '250px' }} />}
                                    mapElement={<div style={{ height:'100%' }} />}
                                    loadingElement={<p>Cargando</p>}
                                    dragableMarker={false}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <p className='text-center mt-3'><small><i className="fas fa-map-marker-alt"></i> {user.validated_address}</small></p>
                                {user && user.provided_address_second_line ? (
                                    <p className='text-center mt-0'>
                                        <small>
                                            <i className="fas fa-building"></i> {user.provided_address_second_line}
                                        </small>
                                        <CompletePersonalInformationModalButton
                                            variant={'link'}
                                            btnclass={'btn-sm'}                                                                
                                            logo={"fas fa-edit"}
                                            call_to_action={""}
                                        />
                                    </p>
                                ) : (
                                    <div className="d-flex justify-content-center px-3">
                                        <CompletePersonalInformationModalButton
                                            variant={'success'}
                                            btnclass={'btn-sm btn-block mb-1'}                                                                
                                            logo={"fas fa-mobile-alt"}
                                            call_to_action={"Incluir Teléfono"}
                                        />
                                    </div>    
                                )}
                                <div className="d-flex justify-content-center px-3 mb-3">
                                    <ChangeAddressModalButton />
                                </div>
                            </Col>
                        </Row>
                        <hr className='d-none d-sm-block'></hr>
                    </div>
                ) : (
                    <div className="d-flex justify-content-center px-3">
                        <CompletePersonalInformationModalButton
                            variant={'success'}
                            btnclass={'btn-sm btn-block mb-1'}                                                                
                            logo={"fas fa-map-marked-alt"}
                            call_to_action={"Incluir Dirección"}
                        />
                    </div>      
                )}
            </div>
        </div>
    )
}
