import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { userMessageAction } from '../actions/userActions'

function Message({variant, children, floating}){

  const[display, setDisplay] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => setDisplay(false), 5000);
    if (display === false) {
      dispatch(userMessageAction(''))
    }
  }, [display])

  return (
    <>
      {display && (
        <Alert
          variant={variant}
          className={display ? ('text-center') : ('text-center d-none')}
        >
        {children}
      </Alert>
      )}
    </>
  )
}

export default Message