import React from 'react'
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap'
import Loader from '../../../../../Loader'
import Message from '../../../../../Message' 
import { DisplayInfoTable } from '../../../../../DisplayInfoTable'
import { PLATFORM_BALANCE_CHANGE_COLUMNS } from '../../../../PlatformBalanceChangeColumns'


export const ModalVerdemiBalanceChanges = (props) => {


    const changePlatformBalance = useSelector(state => state.platformBalanceChange)
    const { error, loading, platformsChanges } = changePlatformBalance

    return (
        <div>
            <Modal show={props.isOpen} onHide={props.toggle} animation={false} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Cambios de Balance en Verdemi</Modal.Title>
                </Modal.Header>
                <Modal.Body  style={{ overflowY: 'auto', height: '70vh' }}>
                    {loading ? (<Loader />) : (
                        <div>
                            {error ? (
                                <div>
                                    <Message variant='danger'>{error}</Message>
                                </div>
                            ) : (
                                <div>
                                    {platformsChanges && platformsChanges.length > 0 ? (
                                        <div>
                                            <DisplayInfoTable rows={platformsChanges} columns={PLATFORM_BALANCE_CHANGE_COLUMNS} staff_table={false}/>
                                        </div>
                                    ) : (
                                        <p>
                                            <p>No tienes movimientos en tu saldo en Verdemi</p>
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.toggle}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
