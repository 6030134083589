import axios from 'axios'

import { 
    PRODUCTS_CATEGORIES_REQUEST,
    PRODUCTS_CATEGORIES_SUCCESS,
    PRODUCTS_CATEGORIES_FAILS,

    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAILS,

    CATEGORY_DETAILS_FAILS,
    CATEGORY_DETAILS_SUCCESS,
    CATEGORY_DETAILS_REQUEST,

    PRODUCT_UPDATE_INFO_SUCCESS,
    PRODUCT_UPDATE_INFO_REQUEST,
    PRODUCT_UPDATE_INFO_FAILS,

    TOP_PRODUCTS_LIST_REQUEST,
    TOP_PRODUCTS_LIST_SUCCESS,
    TOP_PRODUCTS_LIST_FAILS,

    TOP_PRODUCT_CATEGORY_LIST_REQUEST,
    TOP_PRODUCT_CATEGORY_LIST_SUCCESS,
    TOP_PRODUCT_CATEGORY_LIST_FAILS,

    PRODUCTS_TO_BUY_SEND_EMAIL_REQUEST,
    PRODUCTS_TO_BUY_SEND_EMAIL_SUCCESS,
    PRODUCTS_TO_BUY_SEND_EMAIL_FAILS,

    PRODUCTS_LIST_REQUEST,
    PRODUCTS_LIST_SUCCESS,
    PRODUCTS_LIST_FAILS,

    PRODUCTS_HISTORIC_INFO_REQUEST,
    PRODUCTS_HISTORIC_INFO_SUCCESS,
    PRODUCTS_HISTORIC_INFO_FAILS,

    MARKETS_REQUEST,
    MARKETS_SUCCESS,
    MARKETS_FAILS,

} from "../constants/productsConstants";

export const productCategoryListAction = () => async (dispatch) => {
    try {
        dispatch({type: PRODUCTS_CATEGORIES_REQUEST})

        const res = await axios.get('/api/plans/products_category_list/')

        const { data } = res

        dispatch({
            type: PRODUCTS_CATEGORIES_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: PRODUCTS_CATEGORIES_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        })
    }
}


export const marketsAction = () => async (dispatch, getState) => {

    try {
        dispatch({type: MARKETS_REQUEST})

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/markets_list/', config)

        const { data } = res

        dispatch({
            type: MARKETS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: MARKETS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message})
    }
}


export const productsHistoricInfoAction = () => async (dispatch, getState) => {
    try {
        dispatch({type: PRODUCTS_HISTORIC_INFO_REQUEST})

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/products_historic_info_list/', config)

        const { data } = res

        dispatch({
            type: PRODUCTS_HISTORIC_INFO_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: PRODUCTS_HISTORIC_INFO_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        })
    }
}

export const productDetailAction = (slug) => async (dispatch) => {
    try {
        dispatch({type: PRODUCT_DETAILS_REQUEST})

        const res = await axios.get(`/api/plans/products/${slug}`,  {
            headers: {
            'Content-Type': 'application/json',
            'Content-Language': 'es-CO,es;',
            'locale': 'es-CO,es;'
            }
          })

        const { data } = res

        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        })
    }
}

export const categoryDetailAction = (slug) => async (dispatch) => {
    try {
        dispatch({type: CATEGORY_DETAILS_REQUEST})

        const res = await axios.get(`/api/plans/category/${slug}`)

        const { data } = res

        dispatch({
            type: CATEGORY_DETAILS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: CATEGORY_DETAILS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message})
    }
}

export const productUpdateAction = (product_pk, price, cost, availability) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_UPDATE_INFO_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.put('/api/plans/update_product/', 
        {product_pk, price, cost, availability}, config)

        const { data } = res

        dispatch({
            type: PRODUCT_UPDATE_INFO_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PRODUCT_UPDATE_INFO_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message})
    }
}

export const topProductsListAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: TOP_PRODUCTS_LIST_REQUEST})

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/product_analytics/', config)

        const { data } = res

        dispatch({
            type: TOP_PRODUCTS_LIST_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: TOP_PRODUCTS_LIST_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message})
        }        
    }


export const topProductCategoryListAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: TOP_PRODUCT_CATEGORY_LIST_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/product_category_analytics/', config)

        const { data } = res

        dispatch({
            type: TOP_PRODUCT_CATEGORY_LIST_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: TOP_PRODUCT_CATEGORY_LIST_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message})
        }        
    }

export const productsToBuySendEmailAction = (day) => async (dispatch, getState) => {
        try {
            dispatch({ type: PRODUCTS_TO_BUY_SEND_EMAIL_REQUEST })
    
            const {
                userLogin: { user }
            } = getState()
    
            const config = {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            }
    
            const res = await axios.get(`/api/plans/day_product_items_buy_send_email/${day}`, config)
    
            const { data } = res
    
            dispatch({
                type: PRODUCTS_TO_BUY_SEND_EMAIL_SUCCESS,
                payload: data
            })
            
        } catch (error) {
            dispatch({
                type: PRODUCTS_TO_BUY_SEND_EMAIL_FAILS,
                payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message})
            }
    }

export const productListAction = () => async (dispatch) => {
    try {
        dispatch({type: PRODUCTS_LIST_REQUEST})

        const res = await axios.get('/api/plans/products_list/')

        const { data } = res

        dispatch({
            type: PRODUCTS_LIST_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: PRODUCTS_LIST_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message })
    }
}
    



    
    
    
