import React, { useEffect, useState, lazy, Suspense } from 'react'
import { Row, Col, Button, Container, Carousel, Form, Alert, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { productCategoryListAction, productListAction } from '../../../actions/productsActions'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { WhatsappFloatingButton } from '../../../components/user/WhatsappFloatingButton'
import { ReceivedFundsFloatingButton } from '../../../components/user/prospects/ReceivedFundsFloatingButton'

import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import Product from '../../../components/user/Product'
import VerdemiLogo from '../../../images/logo verdemi_11zon.webp'

import banner_1 from '../../../images/banner_1_1_11zon.webp'
import banner_2 from '../../../images/banner_2_2_11zon.webp'
import banner_3 from '../../../images/banner_3_3_11zon.webp'

import { ProductsHorizontalSlider } from '../../../components/user/ProductsHorizontalSlider'

const RightColumn = lazy(() => import('../../../components/user/prospects/RightColumn'))
const Footer = lazy(() => import('../../../components/user/prospects/Footer'))
const Header = lazy(() => import('../../../components/user/prospects/Header'))

export const LandingScreen = () => {

    const listProductCategory = useSelector(state => state.productCategoryList)
    const { loading, error, categories } = listProductCategory

    const listProduct = useSelector(state => state.productList)
    const { loading: productsLoading, error: productsError, products } = listProduct

    const[productNameFilter, setproductNameFilter] = useState('')
    const[productFilterResults, setProductFilterResults] = useState([])

    const cartInfo = useSelector(state => state.cart)
    const { cartItems } = cartInfo

    const dispatch = useDispatch()

    useEffect(() => {
        if ((!categories || categories.length === 0) && !error && (loading === false || !loading)) {
            dispatch(productCategoryListAction())
          }

          if ((!products || products.length === 0) && !productsError && (productsLoading === false || !productsLoading)) {
            dispatch(productListAction())
          }
          if (products && products.length > 0) {
            if (productNameFilter.length > 0) {
                setProductFilterResults(products.filter(product => product.name.toLowerCase()
                    .replace('á', 'a')
                    .replace('é', 'e')
                    .replace('í', 'i')
                    .replace('ó', 'o')
                    .replace('ú', 'u')
                    .includes(productNameFilter
                        .replace('á', 'a')
                        .replace('é', 'e')
                        .replace('í', 'i')
                        .replace('ó', 'o')
                        .replace('ú', 'u')
                        .toLowerCase())))
                }                  
            }
    }, [
        dispatch,
        error,
        categories,
        loading,
        productNameFilter,
        products,
        productsError,
        productsLoading,
        cartItems
    ])
    

    return (
        <Container style={{overflowX: 'hidden', maxWidth: '100%', width: '100%'}} className='px-1'>

            <Suspense fallback={"Cargando..."}>
                <Header style={{ backgroundColor: 'white'}}/>
            </Suspense>
            <Row>
            </Row>
            <Row>
                <Col md={9} className='mt-5'>
                    <Container>
                        <div id='content'>
                            <Row className='d-none d-md-block'>
                                {VerdemiLogo ? (
                                    <Col className='d-flex justify-content-center mx-0 my-3'>
                                        <LazyLoadImage
                                            className="img-fluid"
                                            src={VerdemiLogo}
                                            alt="Verdemi Logo"
                                        />
                                    </Col>                                
                                ) : (
                                    <Loader />
                                )}
                            </Row>
                            <Row style={{height:'auto'}} >
                                {banner_1 && banner_1 && banner_1 ? (
                                    <Container>
                                        <Carousel nextLabel={''} prevLabel={' '} indicators={false} className='mt-5 mt-md-0'>
                                            <Carousel.Item interval={2500}>
                                                <LazyLoadImage 
                                                    className="d-block w-100"
                                                    src={banner_1}
                                                    alt="Image Alt"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={4000}>
                                                <LazyLoadImage
                                                        className="d-block w-100"
                                                        src={banner_2}
                                                        alt="Image Alt"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={4000}>
                                                <LazyLoadImage
                                                        className="d-block w-100"
                                                        src={banner_3}
                                                        alt="Image Alt"
                                                />
                                            </Carousel.Item>
                                        </Carousel>
                                        <div>
                                        <InputGroup style={{ marginTop: '20px', marginBottom: '20px'}}>
                                            <InputGroup.Text id="basic-addon1"
                                                style={{
                                                    borderColor: '#F7F7F7',
                                                    backgroundColor: '#F7F7F7',
                                                    height: '50px',
                                                    marginRight: '-2px',
                                                    color: '#8F8F8F'
                                                }}
                                            ><i className="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control
                                                    placeholder="Buscar producto"
                                                    type="text"
                                                    onChange={(e) => setproductNameFilter(e.target.value)}
                                                    style={{
                                                    borderColor: '#F7F7F7',
                                                    backgroundColor: '#F7F7F7',
                                                    height: '50px',
                                                    marginLeft: '-2px'
                                                    }}
                                                    disabled={products && products.length > 0}
                                            />
                                        </InputGroup>
                                        </div>
                                    </Container>                                
                                ) : (
                                    <Loader />
                                )}
                            </Row>
                            <Row>
                                <Container>
                                    <Col>
                                        {loading ? <Loader />
                                        : error ? <Message variant='danger'>{error}</Message>
                                        : (
                                            <div>
                                            {productNameFilter.length !== 0 && productFilterResults.length > 0 ? (
                                                <div>
                                                    <div className='d-block d-md-none'>
                                                        <ProductsHorizontalSlider categoryProducts={productFilterResults} type={'cart'}/>
                                                    </div>
                                                    <div className='d-none d-md-block'>
                                                        <Row>
                                                            {products.map(product => product.name.toLowerCase()
                                                                .replace('á', 'a')
                                                                .replace('é', 'e')
                                                                .replace('í', 'i')
                                                                .replace('ó', 'o')
                                                                .replace('ú', 'u')
                                                                    .includes(productNameFilter.toLowerCase()
                                                                    .replace('á', 'a')
                                                                    .replace('é', 'e')
                                                                    .replace('í', 'i')
                                                                    .replace('ó', 'o')
                                                                    .replace('ú', 'u')) && (
                                                            <Col key={product.id} xs={6} lg={4} xl={3} className=''>
                                                                <Product product={product} type={'cart'} new_user={false} />
                                                            </Col>
                                                            ))}                                                            
                                                        </Row>
                                                    </div>
                                                </div>
                                            ) : productNameFilter.length !== 0 && productFilterResults.length === 0 ? (
                                                <div>
                                                    <Alert variant='primary' className='my-3 text-center'>No hay productos con ese el nombre "{productNameFilter}". Puedes probar con otro nombre</Alert>
                                                </div>
                                            ) : (
                                                <div>
                                                {categories.filter((category) => category.products.length > 0).map(category => 
                                                    <div key={category.id}>
                                                        <Row className='pl-3 pr-1 d-none d-sm-block'>
                                                            <h2 className='mt-3'>{category.name}</h2>
                                                            <LinkContainer to={`/category/${category.slug_name}`}>
                                                                <Button variant='link' className='ml-auto'>VER TODOS</Button>
                                                            </LinkContainer>
                                                        </Row>
                                                        <div>
                                                            <div className='d-none d-sm-block'>
                                                                <Row>
                                                                    {category.products.slice(0, 8).map(product =>
                                                                        <Col key={product.id} xs={6} lg={4} xl={3} className=''>
                                                                            <Product product={product} type={'cart'} new_user={false} horizontalSlider={false} />
                                                                        </Col>
                                                                    )}
                                                                </Row >
                                                            </div>
                                                            <div className="container-fluid mr-n3 d-block d-sm-none"
                                                                style={{
                                                                        width: '100vw',
                                                                        position: 'relative',
                                                                        marginLeft: '-35px',
                                                                        marginTop: '0px',
                                                                        marginBottom: '0px',
                                                                        backgroundColor: category.name === 'Verduras' ? '#f6f8f7' :
                                                                        (category.name === 'Frutas' ? '#f0eded' :
                                                                        (category.name === 'Frutos Secos' ? '#f6f8f7' :
                                                                        (category.name === 'Semillas, especias y aromáticas' ? '#f0eded' : '#ffe7cc')))
                                                                }}>
                                                                <Row>
                                                                    <Col>
                                                                        <h2 className='ml-3 my-4 text-center'
                                                                            style={{
                                                                                fontWeight: '500',
                                                                                fontSize: '28px',
                                                                                lineHeight: '4.7vw',
                                                                            }}
                                                                        >
                                                                            <b>{category.name}</b>
                                                                        </h2>
                                                                    </Col>
                                                                </Row>
                                                                <ProductsHorizontalSlider categoryProducts={category.products} type={'cart'}/>
                                                                {/* <div
                                                                    className="scrolling-wrapper row flex-row flex-nowrap pb-4 pt-2"
                                                                    style={{ height: '550px'}}
                                                                >
                                                                    {category.products.map(product =>
                                                                        <Col key={product.id} xs={8} lg={4} xl={3} className='scroll-item'>
                                                                            <Product product={product} type={'cart'} new_user={false} horizontalSlider={true}/>
                                                                        </Col>
                                                                    )}
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )}    
                                                </div>
                                            )}
                                        </div>
                                        )}
                                    </Col>
                                </Container>
                            </Row>
                        </div>
                    </Container>
                </Col>
                <Col md={3} className='boder mt-n5'>
                    <Suspense fallback={"Cargando..."}>
                        <RightColumn/>
                    </Suspense>
                </Col>
            </Row>
            <Row>
                <Col md={9} className='md-border bg-light pl-3 pl-md-5 pt-5 mt-md-3'>
                    <Suspense fallback={"Cargando..."}>
                        <Footer />
                    </Suspense>
                </Col>
            </Row>
        <WhatsappFloatingButton />
        <ReceivedFundsFloatingButton />
        </Container>
    )
}
