import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Spinner } from 'react-bootstrap'
import { ModalCreditCard } from '../ModalCreditCard'


export const CreditCardModalButton = () => {

    const updateStatusSubscription = useSelector(state => state.subscriptionUpdateStatus)
    const { loading } = updateStatusSubscription

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { loading:subscriptionDetailLoading } = detailSubscription

    const [changeCardInfoShow, setChangeCardInfoShow] = useState(false);

    const toggleCreditCardInfoModal = () => {
        setChangeCardInfoShow(!changeCardInfoShow)
    }   


    return (
        <div>
            <Button
                disabled={loading || subscriptionDetailLoading}
                className="my-3 btn-block btn-lg h-70"
                onClick={toggleCreditCardInfoModal}
                style={{ backgroundColor: '00BAFF'}}
            >
                {loading || subscriptionDetailLoading ? (
                    <Spinner
                        animation='border'
                        role='status'
                        style={{
                            height:'25px',
                            width:'25px',
                            margin:'auto',
                            display:'block'
                        }}
                    >
                        <span className='sr-only'>
                            Loading...
                        </span>
                    </Spinner>
                ) : (
                    <p className='m-0 p-0'><i className="fas fa-credit-card"></i> Pago contra entrega automático</p>
                )}
            </Button>            
            <p className='m-n1 p-0 mb-5'><small><small>Registra tu tarjeta de débito o cŕedito y después de entregar cada mercado el cobro es automático.</small></small></p>
            <ModalCreditCard isOpen={changeCardInfoShow} toggle={setChangeCardInfoShow} />                                            
        </div>
    )
}
