import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { PostponeOrderModalButton } from './PostponeOrderModalButton'
import { CancelOrderModalButton } from './CancelOrderModalButton'
import { LocalActiveSubscriptionPaymentSection } from './LocalActiveSubscriptionPaymentSection'
import { InscriptionCompletedMessage } from './InscriptionCompletedMessage'
import { LocalActiveSubscriptionOrderResume } from './LocalActiveSubscriptionOrderResume'    

export const LocalActiveSubscriptionSection = ( props ) => {

    const orderNextWeekActiveSubscription = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
    const { loading: orderLoading, nextWeekActiveOrder  } = orderNextWeekActiveSubscription

    return (
        <div>
            <Row className='my-1 p-3'>
                <Col>
                    <Row>
                        <Col className='mx-sm-5 px-sm-5'>
                        <h4 className='mt-3' style={{ fontSize: '1.15em' }}><b>Tu próximo pedido {nextWeekActiveOrder && nextWeekActiveOrder.date !== null && orderLoading === false ? (
                            <span>
                            {new Intl.DateTimeFormat('es-GB', { 
                            month: 'long', 
                            day: '2-digit',
                            year: 'numeric', 
                                }).format(new Date(nextWeekActiveOrder.date))}
                            </span>
                            ): (<span>Actualizando...</span>)}</b></h4>
                        <hr className='border border-secondary my-0'></hr>
                        </Col>
                    </Row>
                </Col>
            </Row> 
            <InscriptionCompletedMessage />
            <Row className='my-1 py-3 px-3 px-sm-5'>
                <Col
                    className='mx-sm-5'
                    style={{ boxShadow: 'rgba(135, 206, 235, 1) 0px 5px 15px' }}
                >
                    <Row>
                        <Col
                            className='mx-sm-3 px-sm-3 pt-3'
                        >
                            <LocalActiveSubscriptionPaymentSection />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Col
                            className='mx-sm-3 px-sm-3'
                            >
                            <LocalActiveSubscriptionOrderResume />
                        </Col>
                    </Row>
                </Col>
            </Row> 
            <Row className='d-flex justify-content-center mb-3 px-md-3'>
                <Col xs={6} sm={5} className='my-1 mr-sm-3'>
                    <PostponeOrderModalButton />
                </Col>
                <Col xs={6} sm={5} className='my-1 ml-sm-3'>
                    <CancelOrderModalButton />
                </Col>
            </Row>
        </div>
)
}


