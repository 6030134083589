import { 
    USER_DETAIL_REQUEST, 
    USER_DETAIL_FAILS, 
    USER_DETAIL_SUCCESS,
    USER_DETAIL_RESET,

    USER_LOGIN_REQUEST,
    USER_LOGIN_FAILS,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,

    USER_REGISTER_SUCCESS,
    USER_REGISTER_REQUEST,
    USER_REGISTER_FAILS,

    USER_CHANGE_PASSWORD_FAILS,
    USER_CHANGE_PASSWORD_REQUEST,
    USER_CHANGE_PASSWORD_SUCCESS,
    USER_CHANGE_PASSWORD_RESET,

    USER_UPDATE_ADDRESS_FAILS,
    USER_UPDATE_ADDRESS_REQUEST,
    USER_UPDATE_ADDRESS_RESET,
    USER_UPDATE_ADDRESS_SUCCESS,

    USER_UPDATE_PERSONAL_INFO_REQUEST,
    USER_UPDATE_PERSONAL_INFO_FAILS,
    USER_UPDATE_PERSONAL_INFO_SUCCESS,
    USER_UPDATE_PERSONAL_INFO_RESET,

    SET_HOST_PERSONAL_CODE,
    REMOVE_HOST_PERSONAL_CODE,

    SET_RESET_PASSWORD_USER_PERSONAL_CODE,
    REMOVE_RESET_PASSWORD_USER_PERSONAL_CODE,

    SET_RESET_PASSWORD_USER_ID,
    REMOVE_RESET_PASSWORD_USER_ID,

    USER_NEW_MESSAGE,
    REMOVE_USER_MESSAGE,

    CONTACT_FORM_FAILS,
    CONTACT_FORM_REQUEST,
    CONTACT_FORM_RESET,
    CONTACT_FORM_SUCCESS,

    BOLETIN_CONTACT_SUCCESS,
    BOLETIN_CONTACT_REQUEST,
    BOLETIN_CONTACT_FAILS,
    BOLETIN_CONTACT_RESET,

    ORDERS_TO_NOTIFY_REQUEST,
    ORDERS_TO_NOTIFY_FAILS,
    ORDERS_TO_NOTIFY_SUCCESS,
    ORDERS_TO_NOTIFY_RESET,

    ORDERS_DELIVERY_ISSUES_REQUEST,
    ORDERS_DELIVERY_ISSUES_FAILS,
    ORDERS_DELIVERY_ISSUES_SUCCESS,
    ORDERS_DELIVERY_ISSUES_RESET,

    ORDERS_CLIENTS_ISSUES_REQUEST,
    ORDERS_CLIENTS_ISSUES_FAILS,
    ORDERS_CLIENTS_ISSUES_SUCCESS,
    ORDERS_CLIENTS_ISSUES_RESET,

    TODAY_SUBSCRIPTION_ORDERS_REQUEST,
    TODAY_SUBSCRIPTION_ORDERS_FAILS,
    TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
    TODAY_SUBSCRIPTION_ORDERS_RESET,

    NEXT_WEEK_SUBSCRIPTION_ORDERS_REQUEST,
    NEXT_WEEK_SUBSCRIPTION_ORDERS_FAILS,
    NEXT_WEEK_SUBSCRIPTION_ORDERS_SUCCESS,
    NEXT_WEEK_SUBSCRIPTION_ORDERS_RESET,

    ORDER_PAYMENT_RESPONSE_REQUEST,
    ORDER_PAYMENT_RESPONSE_FAILS,
    ORDER_PAYMENT_RESPONSE_SUCCESS,
    ORDER_PAYMENT_RESPONSE_RESET,

    SUBSCRIPTION_LIST_REQUEST,
    SUBSCRIPTION_LIST_FAILS,
    SUBSCRIPTION_LIST_SUCCESS,
    SUBSCRIPTION_LIST_RESET,

    PAST_CANCELED_ORDERS_REQUEST,
    PAST_CANCELED_ORDERS_FAILS,
    PAST_CANCELED_ORDERS_SUCCESS,
    PAST_CANCELED_ORDERS_RESET,    

    TOMORROW_SUBSCRIPTION_ORDERS_REQUEST,
    TOMORROW_SUBSCRIPTION_ORDERS_FAILS,
    TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS,
    TOMORROW_SUBSCRIPTION_ORDERS_RESET,
    
    THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_REQUEST,
    THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_FAILS,
    THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS,
    THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_RESET,
    
    FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST,
    FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
    FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
    FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET,
    
    FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST,
    FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
    FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
    FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET,
    
    SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST,
    SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
    SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
    SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET,
    
    SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST,
    SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS,
    SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS,
    SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET,

    TODAY_CART_ITEMS_REQUEST,
    TODAY_CART_ITEMS_FAILS,
    TODAY_CART_ITEMS_SUCCESS,
    TODAY_CART_ITEMS_RESET,

    TOMORROW_CART_ITEMS_REQUEST,
    TOMORROW_CART_ITEMS_FAILS,
    TOMORROW_CART_ITEMS_SUCCESS,
    TOMORROW_CART_ITEMS_RESET,
    
    THE_DAY_AFTER_TOMORROW_CART_ITEMS_REQUEST,
    THE_DAY_AFTER_TOMORROW_CART_ITEMS_FAILS,
    THE_DAY_AFTER_TOMORROW_CART_ITEMS_SUCCESS,
    THE_DAY_AFTER_TOMORROW_CART_ITEMS_RESET,
    
    FOUR_DAYS_FROM_TODAY_CART_ITEMS_REQUEST,
    FOUR_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
    FOUR_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,
    FOUR_DAYS_FROM_TODAY_CART_ITEMS_RESET,
    
    FIVE_DAYS_FROM_TODAY_CART_ITEMS_REQUEST,
    FIVE_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
    FIVE_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,
    FIVE_DAYS_FROM_TODAY_CART_ITEMS_RESET,
    
    SIX_DAYS_FROM_TODAY_CART_ITEMS_REQUEST,
    SIX_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
    SIX_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,
    SIX_DAYS_FROM_TODAY_CART_ITEMS_RESET,
    
    SEVEN_DAYS_FROM_TODAY_CART_ITEMS_REQUEST,
    SEVEN_DAYS_FROM_TODAY_CART_ITEMS_FAILS,
    SEVEN_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS,
    SEVEN_DAYS_FROM_TODAY_CART_ITEMS_RESET,

    TOP_USERS_LIST_REQUEST,
    TOP_USERS_LIST_SUCCESS,
    TOP_USERS_LIST_FAILS,
    TOP_USERS_LIST_RESET,

    USER_ORDERS_LIST_REQUEST,
    USER_ORDERS_LIST_SUCCESS,
    USER_ORDERS_LIST_FAILS,
    USER_ORDERS_LIST_RESET,

    USER_LAST_ORDER_REQUEST,
    USER_LAST_ORDER_SUCCESS,
    USER_LAST_ORDER_FAILS,
    USER_LAST_ORDER_RESET,

    USER_PAYMENT_METHOD_LIST_REQUEST,
    USER_PAYMENT_METHOD_LIST_SUCCESS,
    USER_PAYMENT_METHOD_LIST_FAILS,
    USER_PAYMENT_METHOD_LIST_RESET,

    ORDERS_TO_BE_BLOCKED_REQUEST,
    ORDERS_TO_BE_BLOCKED_SUCCESS,
    ORDERS_TO_BE_BLOCKED_FAILS,
    ORDERS_TO_BE_BLOCKED_RESET,

    FUNDS_RECEIVED_REQUEST,
    FUNDS_RECEIVED_FAILS,
    FUNDS_RECEIVED_SUCCESS,
    FUNDS_RECEIVED_RESET

} from '../constants/userConstants'

export const userDetailReducer = (state = { user:null, loading: false}, action) => {
    switch (action.type) {
        case USER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case USER_DETAIL_SUCCESS:
            return {
                loading: false,
                user: action.payload
            }
        case USER_DETAIL_FAILS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case USER_DETAIL_RESET:
            return {
                user: null,
                loading: false
            }
        default:
            return state
    }
}

export const userLoginReducer = ( state = { user: null}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {
                loading: true,
            }
        case USER_LOGIN_SUCCESS:
            return {
                loading: false,
                user: action.payload
            }
        case USER_LOGIN_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case USER_LOGOUT:
            return {
                user: null
            }
        default :
            return state
    }
}

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return {
                loading: true
            }
        case USER_REGISTER_SUCCESS:
            return {
                loading: false,
                user: action.payload
            }
        case USER_REGISTER_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return {}
    }
} 

export const userChangePasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_CHANGE_PASSWORD_REQUEST:
            return {
                loading: true
            }            
        case USER_CHANGE_PASSWORD_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case USER_CHANGE_PASSWORD_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case USER_CHANGE_PASSWORD_RESET:
            return {}
        default:
            return state
    }
}


export const fundsReceivedReducer = (state = { receivedFunds: null, loading: false }, action) => {
    switch (action.type) {
        case FUNDS_RECEIVED_REQUEST:
            return {
                loading: true
            }            
        case FUNDS_RECEIVED_SUCCESS:
            return {
                loading: false,
                receivedFunds: action.payload,
                success: true
            }
        case FUNDS_RECEIVED_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case FUNDS_RECEIVED_RESET:
            return {}
        default:
            return state
    }
}




export const userUpdateAddressReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE_ADDRESS_REQUEST:
            return {
                loading: true
            }
        case USER_UPDATE_ADDRESS_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case USER_UPDATE_ADDRESS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case USER_UPDATE_ADDRESS_RESET:
            return {
                loading: false,
                success: false,
                error: null
            }
        default:
            return state
    }
}

export const userUpdatePersonalInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE_PERSONAL_INFO_REQUEST:
            return {
                loading: true
            }
        case USER_UPDATE_PERSONAL_INFO_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case USER_UPDATE_PERSONAL_INFO_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case USER_UPDATE_PERSONAL_INFO_RESET:
            return {}
        default:
            return state
    }
}


export const hostPersonalCodeReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_HOST_PERSONAL_CODE:
            return {
                hostPersonalCode: action.payload
            }
        case REMOVE_HOST_PERSONAL_CODE:
            return {}
        default:
            return state
    }
}

export const resetPasswordPersonalCodeReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_RESET_PASSWORD_USER_PERSONAL_CODE:
            return {
                resetPasswordPersonalCode: action.payload
            }
        case REMOVE_RESET_PASSWORD_USER_PERSONAL_CODE:
            return {}
        default:
            return state
    }
}

export const resetPasswordPersonalIdReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_RESET_PASSWORD_USER_ID:
            return {
                resetPasswordPersonalId: action.payload
            }
        case REMOVE_RESET_PASSWORD_USER_ID:
            return {}
        default:
            return state
    }
}


export const userMessageReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_NEW_MESSAGE:
            return {
                message: action.payload,
                display: true
            }
        case REMOVE_USER_MESSAGE:
            return {}
        default:
            return state
    }
}

export const contactFormReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_FORM_REQUEST:
            return {
                loading: true
            }
        case CONTACT_FORM_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case CONTACT_FORM_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case CONTACT_FORM_RESET:
            return {}
        default:
            return state
    }
}

export const boletinContactReducer = (state = {}, action) => {
    switch (action.type) {
        case BOLETIN_CONTACT_REQUEST:
            return {
                loading: true
            }
        case BOLETIN_CONTACT_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case BOLETIN_CONTACT_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case BOLETIN_CONTACT_RESET:
            return {}
        default:
            return state
    }
}

export const ordersToNotifyReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case ORDERS_TO_NOTIFY_REQUEST:
            return {
                loading: true
            }
        case ORDERS_TO_NOTIFY_SUCCESS:
            return {
                loading: false,
                success: true,
                orders: action.payload
            }
        case ORDERS_TO_NOTIFY_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case ORDERS_TO_NOTIFY_RESET:
            return {}
        default:
            return state
    }
}

export const ordersToBeBlockedReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case ORDERS_TO_BE_BLOCKED_REQUEST:
            return {
                loading: true
            }
        case ORDERS_TO_BE_BLOCKED_SUCCESS:
            return {
                loading: false,
                success: true,
                orders: action.payload
            }
        case ORDERS_TO_BE_BLOCKED_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case ORDERS_TO_BE_BLOCKED_RESET:
            return {}
        default:
            return state
    }
}


export const orderDeliveryIssuesReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case ORDERS_DELIVERY_ISSUES_REQUEST:
            return {
                loading: true
            }
        case ORDERS_DELIVERY_ISSUES_SUCCESS:
            return {
                loading: false,
                success: true,
                ordersDeliveryIssues: action.payload
            }
        case ORDERS_DELIVERY_ISSUES_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case ORDERS_DELIVERY_ISSUES_RESET:
            return {}
        default:
            return state
    }
}

export const orderClientsIssuesReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case ORDERS_CLIENTS_ISSUES_REQUEST:
            return {
                loading: true
            }
        case ORDERS_CLIENTS_ISSUES_SUCCESS:
            return {
                loading: false,
                success: true,
                ordersClientsIssues: action.payload
            }
        case ORDERS_CLIENTS_ISSUES_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case ORDERS_CLIENTS_ISSUES_RESET:
            return {}
        default:
            return state
    }
}

export const todaySubscriptionOrdersReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case TODAY_SUBSCRIPTION_ORDERS_REQUEST:
            return {
                loading: true
            }
        case TODAY_SUBSCRIPTION_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                todaySubscriptionOrders: action.payload
            }
        case TODAY_SUBSCRIPTION_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case TODAY_SUBSCRIPTION_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const nextWeekSubscriptionOrdersReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case NEXT_WEEK_SUBSCRIPTION_ORDERS_REQUEST:
            return {
                loading: true
            }
        case NEXT_WEEK_SUBSCRIPTION_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                nextWeekSubscriptionOrders: action.payload
            }
        case NEXT_WEEK_SUBSCRIPTION_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case NEXT_WEEK_SUBSCRIPTION_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const orderPaymentResponseReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case ORDER_PAYMENT_RESPONSE_REQUEST:
            return {
                loading: true
            }
        case ORDER_PAYMENT_RESPONSE_SUCCESS:
            return {
                loading: false,
                success: true,
                orderPaymentResponse: action.payload
            }
        case ORDER_PAYMENT_RESPONSE_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case ORDER_PAYMENT_RESPONSE_RESET:
            return {}
        default:
            return state
    }
}

export const subscriptionListReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_LIST_REQUEST:
            return {
                loading: true
            }
        case SUBSCRIPTION_LIST_SUCCESS:
            return {
                loading: false,
                success: true,
                subscriptionList: action.payload
            }
        case SUBSCRIPTION_LIST_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case SUBSCRIPTION_LIST_RESET:
            return {}
        default:
            return state
    }
}

export const pastCanceledOrdersReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case PAST_CANCELED_ORDERS_REQUEST:
            return {
                loading: true
            }
        case PAST_CANCELED_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                pastCanceledOrders: action.payload
            }
        case PAST_CANCELED_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case PAST_CANCELED_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const tomorrowSubscriptionOrdersReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case TOMORROW_SUBSCRIPTION_ORDERS_REQUEST:
            return {
                loading: true
            }
        case TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                tomorrowSubscriptionOrders: action.payload
            }
        case TOMORROW_SUBSCRIPTION_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case TOMORROW_SUBSCRIPTION_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const dayAfterTomorrowSubscriptionOrdersReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_REQUEST:
            return {
                loading: true
            }
        case THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                dayAfterTomorrowSubscriptionOrders: action.payload
            }
        case THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case THE_DAY_AFTER_TOMORROW_SUBSCRIPTION_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const fourDaysFromTodaySubscriptionOrdersReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST:
            return {
                loading: true
            }
        case FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                fourDaysFromTodaySubscriptionOrders: action.payload
            }
        case FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case FOUR_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const fiveDaysFromTodaySubscriptionOrdersReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST:
            return {
                loading: true
            }
        case FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                fiveDaysFromTodaySubscriptionOrders: action.payload
            }
        case FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case FIVE_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const sixDaysFromTodaySubscriptionOrdersReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST:
            return {
                loading: true
            }
        case SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                sixDaysFromTodaySubscriptionOrders: action.payload
            }
        case SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case SIX_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const sevenDaysFromTodaySubscriptionOrdersReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_REQUEST:
            return {
                loading: true
            }
        case SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_SUCCESS:
            return {
                loading: false,
                success: true,
                sevenDaysFromTodaySubscriptionOrders: action.payload
            }
        case SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case SEVEN_DAYS_FROM_TODAY_SUBSCRIPTION_ORDERS_RESET:
            return {}
        default:
            return state
    }
}

export const todayCartItemsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case TODAY_CART_ITEMS_REQUEST:
            return {
                loading: true
            }
        case TODAY_CART_ITEMS_SUCCESS:
            return {
                loading: false,
                success: true,
                todayCartItems: action.payload
            }
        case TODAY_CART_ITEMS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case TODAY_CART_ITEMS_RESET:
            return {}
        default:
            return state
    }
}

export const tomorrowCartItemsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case TOMORROW_CART_ITEMS_REQUEST:
            return {
                loading: true
            }
        case TOMORROW_CART_ITEMS_SUCCESS:
            return {
                loading: false,
                success: true,
                tomorrowCartItems: action.payload
            }
        case TOMORROW_CART_ITEMS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case TOMORROW_CART_ITEMS_RESET:
            return {}
        default:
            return state
    }
}

export const dayAfterTomorrowCartItemsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case THE_DAY_AFTER_TOMORROW_CART_ITEMS_REQUEST:
            return {
                loading: true
            }
        case THE_DAY_AFTER_TOMORROW_CART_ITEMS_SUCCESS:
            return {
                loading: false,
                success: true,
                dayAfterTomorrowCartItems: action.payload
            }
        case THE_DAY_AFTER_TOMORROW_CART_ITEMS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case THE_DAY_AFTER_TOMORROW_CART_ITEMS_RESET:
            return {}
        default:
            return state
    }
}

export const fourDaysFromTodayCartItemsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case FOUR_DAYS_FROM_TODAY_CART_ITEMS_REQUEST:
            return {
                loading: true
            }
        case FOUR_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS:
            return {
                loading: false,
                success: true,
                fourDaysFromTodayCartItems: action.payload
            }
        case FOUR_DAYS_FROM_TODAY_CART_ITEMS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case FOUR_DAYS_FROM_TODAY_CART_ITEMS_RESET:
            return {}
        default:
            return state
    }
}

export const fiveDaysFromTodayCartItemsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case FIVE_DAYS_FROM_TODAY_CART_ITEMS_REQUEST:
            return {
                loading: true
            }
        case FIVE_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS:
            return {
                loading: false,
                success: true,
                fiveDaysFromTodayCartItems: action.payload
            }
        case FIVE_DAYS_FROM_TODAY_CART_ITEMS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case FIVE_DAYS_FROM_TODAY_CART_ITEMS_RESET:
            return {}
        default:
            return state
    }
}

export const sixDaysFromTodayCartItemsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case SIX_DAYS_FROM_TODAY_CART_ITEMS_REQUEST:
            return {
                loading: true
            }
        case SIX_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS:
            return {
                loading: false,
                success: true,
                sixDaysFromTodayCartItems: action.payload
            }
        case SIX_DAYS_FROM_TODAY_CART_ITEMS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case SIX_DAYS_FROM_TODAY_CART_ITEMS_RESET:
            return {}
        default:
            return state
    }
}

export const sevenDaysFromTodayCartItemsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case SEVEN_DAYS_FROM_TODAY_CART_ITEMS_REQUEST:
            return {
                loading: true
            }
        case SEVEN_DAYS_FROM_TODAY_CART_ITEMS_SUCCESS:
            return {
                loading: false,
                success: true,
                sevenDaysFromTodayCartItems: action.payload
            }
        case SEVEN_DAYS_FROM_TODAY_CART_ITEMS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case SEVEN_DAYS_FROM_TODAY_CART_ITEMS_RESET:
            return {}
        default:
            return state
    }
}

export const topUserListReducer = (state = {}, action) => {
    switch (action.type) {
        case TOP_USERS_LIST_REQUEST:
            return {
                loading: true
            }
        case TOP_USERS_LIST_SUCCESS:
            return {
                loading: false,
                top_users: action.payload
            }
        case TOP_USERS_LIST_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case TOP_USERS_LIST_RESET:
            return {}
        default:
            return state
    }
}

export const userOrdersListReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case USER_ORDERS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }            
        case USER_ORDERS_LIST_SUCCESS:
            return {
                loading: false,
                order_list: action.payload
            }
        case USER_ORDERS_LIST_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case USER_ORDERS_LIST_RESET:
            return {}
        default:
            return state
    }
}

export const userLastOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LAST_ORDER_REQUEST:
            return {
                loading: true
            }            
        case USER_LAST_ORDER_SUCCESS:
            return {
                loading: false,
                last_order: action.payload
            }
        case USER_LAST_ORDER_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case USER_LAST_ORDER_RESET:
            return {}
        default:
            return state
    }
}


export const userPaymentMethodListReducer = (state = { paymentMethod:null, loading: false}, action) => {
    switch (action.type) {
        case USER_PAYMENT_METHOD_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case USER_PAYMENT_METHOD_LIST_SUCCESS:
            return {
                loading: false,
                paymentMethod: action.payload
            }
        case USER_PAYMENT_METHOD_LIST_FAILS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case USER_PAYMENT_METHOD_LIST_RESET:
            return {
                paymentMethod: null,
                loading: false
            }
        default:
            return state
    }
}






