import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { createOrderClientIssueAction } from '../../../../../../actions/orderActions'
import { userLastOrderAction } from '../../../../../../actions/userActions'
import { CREATE_CLIENT_ISSUE_RESET } from '../../../../../../constants/orderConstants'
import { userMessageAction } from '../../../../../../actions/userActions'


export const ModalReporClientIssue = (props) => {

    const dispatch = useDispatch()

    const [description, setDescription] = useState('')

    const lastOrderUser = useSelector(state => state.userLastOrder) 
    const { last_order, loading, error } = lastOrderUser

    const orderClientIssueCreate = useSelector(state => state.createOrderClientIssue)
    const { loading: orderClientIssueLoading, success } = orderClientIssueCreate

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    const CloseReportClientIssueModal = () => {
        props.toggle()
    }

    useEffect(() =>{
        if(subscription && (subscription.status.name === 'Active' || subscription.status.name === 'Canceled')){
            if ((!last_order) && (!error) && (!loading || loading === false)){
                dispatch(userLastOrderAction())
            }
        }
        if(success){
            dispatch({ type: CREATE_CLIENT_ISSUE_RESET })
            dispatch(userMessageAction('Evento reportado. Te contactaremos pronto.'))
            CloseReportClientIssueModal()
        }
    }, [
        last_order,
        dispatch,
        loading,
        error,
        CloseReportClientIssueModal,
        subscription,
        success
    ])

    const submitReportClientIssueHandler = (e) => {
        e.preventDefault()
        dispatch(createOrderClientIssueAction([...last_order].shift().id, description))
    }

    return (
        <div>
            <Modal show={props.isOpen} onHide={props.toggle} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title className='text-info'><i className="fa fa-exclamation-triangle"></i> Reportar problema con orden</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {last_order && [...last_order].shift() && (
                        <div>
                            <p>Reportar problema el pedido del {[...last_order].shift().subscription.user.surname} para el:{' '}
                                {new Intl.DateTimeFormat('es-GB', { 
                                    month: 'long', 
                                    day: '2-digit',
                                    year: 'numeric', 
                                }).format(new Date([...last_order].shift().date))} (Estado: {[...last_order].shift().status.name}).
                            </p>
                            <p>Productos de la orden</p>
                            <ul>
                                {[...last_order].shift().products_items.map(product_item => product_item.amount > 0 &&
                                        <li key={product_item.products.name}>
                                            {product_item.amount} {product_item.products.name}
                                        </li>
                                )}
                            </ul>
                            <Form onSubmit={submitReportClientIssueHandler}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" onChange={(e) => setDescription(e.target.value)} value={description}>
                                    <Form.Label>Problema a reportar</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Explicar problema con la entrega (al menos 10 carácteres)"/>
                                </Form.Group>
                                <Button disabled={orderClientIssueLoading || description.length < 10} type="submit" className="btn btn-success btn-block">
                                    {orderClientIssueLoading ? (
                                        <div>
                                            <Spinner
                                                animation='border'
                                                role='status'
                                                style={{
                                                    height:'25px',
                                                    width:'25px',
                                                    margin:'auto',
                                                    display:'block'
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Loading...
                                                </span>
                                            </Spinner>
                                        </div>
                                    ) : (
                                        <span>
                                           <i className="fa fa-exclamation-triangle"></i> Reportar problema
                                        </span>
                                    )}
                                </Button>
                            </Form>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={CloseReportClientIssueModal} className='btn-block'>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>                                    
        </div>
    )
}
