import React, { useState } from 'react'
import { Row, Col, Tab, Nav } from 'react-bootstrap'
import { CancelSubscription } from '../../OrderInfo/CancelSubscription'
import { PastOrders } from './PastOrders';
import { NameEmailTelephoneTab } from './tabs/NameEmailTelephoneTab';
import { AddressTab } from './tabs/AddressTab';
import { PaymentsTab } from './tabs/PaymentsTab';
import { FrecuencyTab } from './tabs/FrecuencyTab';
import { VerdemiBalanceChanges } from './VerdemiBalanceChanges';
import { ChangePassword } from './ChangePassword';


export const UserInfoLaptop = ({ history }) => {

    const [selectedKey, setSelectedKey] = useState('')

    const consoleSelectedTab = (key) => {
        setSelectedKey(key)
    }

    return (
        <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="user-info">
                <Row>
                    <Col sm={3} style={{ borderRight: '1px solid #ACA7A7'}} className='mb-3'>
                    <Nav className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="user-info" className='user-info-tab'>
                                Información de usuario
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="user-address" className='user-info-tab'>
                                Dirección
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="user-payment-methods" className='user-info-tab'>
                                Formas de pago
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="user-previous-orders" className='user-info-tab'>
                                Órdenes anteriores
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="user-balance-changes" className='user-info-tab'>
                                Cambios de balance en Verdemi
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="user-change-password" onSelect={(selectedKey) => consoleSelectedTab(selectedKey)} className='user-info-tab'>
                                Cambiar contraseña
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="user-cancel-subscription" className='user-info-tab'>
                                Cancelar mi subscripción
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="user-modify-frecuency" className='user-info-tab'>
                                Modificar frecuencia
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                    <Col sm={9}>
                    <Tab.Content transition='true'>
                        <Tab.Pane eventKey="user-info">
                            <NameEmailTelephoneTab />
                        </Tab.Pane>
                        <Tab.Pane eventKey="user-address">
                            <AddressTab />
                        </Tab.Pane>
                        <Tab.Pane eventKey="user-payment-methods">
                            <PaymentsTab />
                        </Tab.Pane>
                        <Tab.Pane eventKey="user-previous-orders">
                            <PastOrders />
                        </Tab.Pane>
                        <Tab.Pane eventKey="user-balance-changes">
                            <VerdemiBalanceChanges />
                        </Tab.Pane>
                        <Tab.Pane eventKey="user-change-password">
                            <ChangePassword active={selectedKey}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="user-cancel-subscription">
                            <CancelSubscription />
                        </Tab.Pane>
                        <Tab.Pane eventKey="user-modify-frecuency">
                            <FrecuencyTab />
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}
