import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import UserAddressMap from '../../mobile/UserAddressMap';
import { CompletePersonalInformationModalButton } from '../../mobile/buttons/CompletePersonalInformationModalButton';
import { ChangeAddressModalButton } from '../../shared/ChangeAddressModalButton';


export const AddressTab = () => {

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    return (
        <div>
            {user && user.validated_address ? (
                <div>
                    <Row>
                        <Col xs={12} md={6}>
                            <UserAddressMap draggableMarker={false}/>
                        </Col>
                        <Col xs={12} md={6}>
                            <p className='text-center mt-3'><small><i className="fas fa-map-marker-alt"></i> {user.validated_address}</small></p>
                            {user && user.provided_address_second_line && (
                                <p className='text-center mt-0'>
                                    <small>
                                        <i className="fas fa-building"></i> {user.provided_address_second_line}
                                    </small>
                                    <CompletePersonalInformationModalButton
                                        variant={'link'}
                                        btnclass={'btn-sm'}                                                                
                                        logo={"fas fa-edit"}
                                        call_to_action={""}
                                    />
                                </p>
                            )}
                            {user && user.validated_address && (
                                <div className="d-flex justify-content-center px-3 mb-3">
                                    <ChangeAddressModalButton />
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            ) : (
                <div className="d-flex justify-content-center px-3">
                    <CompletePersonalInformationModalButton
                        variant={'success'}
                        btnclass={'btn-sm btn-block mb-1'}                                                                
                        logo={"fas fa-map-marked-alt"}
                        call_to_action={"Incluir Dirección"}
                    />
                </div>      
            )}
        </div>
    )
}
