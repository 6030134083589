import React, { useMemo } from 'react'
import { useTable, useSortBy, useGlobalFilter, usePagination } from'react-table'
import { Table, Button } from 'react-bootstrap'
import { SubscriptionGlobalFilter } from './staff/SubscriptionGlobalFilter'
import { CSVLink } from 'react-csv'

export const DisplayInfoTable = (props) => {

    const columns = useMemo(() => props.columns, [props.columns])

    const data = useMemo(() => props.rows, [props.rows])

    const { 
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        rows,
        state,
        setGlobalFilter 
    } = useTable({
        columns,
        data
    }, useGlobalFilter, useSortBy, usePagination)

    var csv_array = []

    for (let i = 0; i < rows.length; i++) {
        csv_array.push(rows[i].values)
    }  

    const { globalFilter, pageIndex, pageSize } = state


  return (
    <>
        {props.staff_table && (
            <div>
                <CSVLink data={csv_array} filename='InformacionPagos.csv'><Button variant='primary'><i className="fas fa-file-download"></i> Descargar Tabla</Button></CSVLink>
                <br />
                <SubscriptionGlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
            </div>
        )}
        <Table striped bordered hover {...getTableBodyProps()} responsive>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                            {column.isSorted
                            ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                            : ''}
                        </span>
                    </th>
                ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>

                {
                    page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {
                                    row.cells.map( cell => {
                                        return (
                                            <td key={cell.value} {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>      
        <div>
            <span>
                Página{' '}
                <strong>
                    {pageIndex + 1} de {pageOptions.length} {' '}
                </strong>
            </span>
            <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                {
                    [10, 25, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Mostrar {pageSize} 
                        </option>
                    ))
                }
            </select>{' '}
            <Button variant="outline-primary" size="sm" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</Button>
            <Button variant="outline-primary" size="sm" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
            <Button variant="outline-primary" size="sm" onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
            <Button variant="outline-primary" size="sm" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</Button>
        </div>      
    </>
  )
}
