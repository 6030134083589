import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Spinner, ButtonGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../Message' 
import { 
    subscriptionUpdateAction,
    localSubscriptionAction,
    subscriptionDetailAction } from '../../../../actions/subscriptionActions'
import {
    REMOVE_LOCAL_SUBSCRIPTION,
    DISABLE_CHECK_LOCAL_SUBSCRIPTION
        } from '../../../../constants/subscriptionConstants'
import { 
    localOrderAction,
    removeLocalOrderAction,
    localOrderFromLocalSubscriptionAction,
 } from '../../../../actions/orderActions'


export const SubscriptionButton = ({product, disableButtons, DisableButtonFunction, horizontalCard, categoryName}) => {

    const [inSubscription, setInSubscription] = useState(false)
    const [inLocalSubscription, setInLocalSubscription] = useState(false)
    const [addAmount] = useState(1)
    const [reduceAmount] = useState(0)
    const [qty, setQty] = useState(1)
    const [localQty, setLocalQty] = useState(1)

    const subscriptionInfo = useSelector(state => state.subscriptionDetail)
    const { subscription, loading } = subscriptionInfo

    const subscriptionUpdate = useSelector(state => state.subscriptionUpdate)
    const { loading: loadingUpdateSubscription, error: errorUpdateSubscription, updating_product, success } = subscriptionUpdate

    const subscriptionLocal = useSelector(state => state.localSubscription)
    const { localProductsItems, updatedFromServer, localSubscriptionStatus, deliverPeriod: localSubscriptionDeliverPeriod } = subscriptionLocal

    const idSubscription = useSelector(state => state.SubscriptionId)
    const { id } = idSubscription

    const dispatch = useDispatch()

    const submitHandler = (e) => {
        e.preventDefault()
        try {
            dispatch(subscriptionUpdateAction(subscription.id, product.id, 1))
        } catch(error) {
            console.log(error)
        }
        if (updatedFromServer) {
            product['category'] = {}
            product['category']['name'] = categoryName
            dispatch(localSubscriptionAction(
                product,
                1,
                product.price,
                true,
                localSubscriptionStatus,
                localSubscriptionDeliverPeriod
                ))
        } else {
            if (subscription) {
                dispatch(localSubscriptionAction(
                    product,
                    1,
                    product.price,
                    true,
                    subscription.status.name,
                    subscription.frecuency.deliver_period))
            }
        }
        dispatch(localOrderAction(product, 1, product.price, true))
        dispatch({ type: DISABLE_CHECK_LOCAL_SUBSCRIPTION })
    }

    const removeHandler = (e) => {
        e.preventDefault()
        for(var i = 0; i < localProductsItems.length; i++){
            if (localProductsItems[i].products.id === product.id && updatedFromServer === true) {
                dispatch(localSubscriptionAction(
                    product,
                    0,
                    0,
                    true,
                    localSubscriptionStatus,
                    localSubscriptionDeliverPeriod))
            }
        }
        try {
            if (subscription) {
                dispatch(subscriptionUpdateAction(subscription.id, product.id, localQty * -1))    
            } else if ( id ) {
                dispatch(subscriptionUpdateAction(id, product.id, localQty -1))    
            }
        } catch(error) {
            console.log(error)
        }
        dispatch(removeLocalOrderAction())
        dispatch(localOrderFromLocalSubscriptionAction())
        dispatch({ type: DISABLE_CHECK_LOCAL_SUBSCRIPTION })
        setLocalQty(0)
        setInLocalSubscription(false)
    }


    const reduceAmountHandler = () => {
        for(var i = 0; i < localProductsItems.length; i++){
            if (localProductsItems[i].products.id === product.id && updatedFromServer === true) {
                dispatch(localSubscriptionAction(
                    localProductsItems[i].products,
                    localProductsItems[i].amount - 1,
                    localProductsItems[i].products.price * (localProductsItems[i].amount - 1),
                    true,
                    localSubscriptionStatus,
                    localSubscriptionDeliverPeriod))
            }
        }
        if (reduceAmount === 0) {
            setInSubscription(false)
        }
        try {
            if (subscription) {
                dispatch(subscriptionUpdateAction(subscription.id, product.id, -1))    
            } else if ( id ) {
                dispatch(subscriptionUpdateAction(id, product.id, -1))    
            }
        } catch(error) {
            console.log(error)
        }
        dispatch(removeLocalOrderAction())
        dispatch(localOrderFromLocalSubscriptionAction())
        DisableButtonFunction()
        dispatch({ type: DISABLE_CHECK_LOCAL_SUBSCRIPTION })
    }


    const addAmountHandler = () => {
        console.log('Adding one')
        for(var i = 0; i < localProductsItems.length; i++){
            if (localProductsItems[i].products.id === product.id && updatedFromServer === true) {
                console.log('Subscription Button 5')
                dispatch(localSubscriptionAction(
                    localProductsItems[i].products,
                    localProductsItems[i].amount + 1,
                    localProductsItems[i].products.price * (localProductsItems[i].amount + 1),
                    true,
                    localSubscriptionStatus,
                    localSubscriptionDeliverPeriod
                    ))
            }
        }
        try {
            if (subscription) {
                dispatch(subscriptionUpdateAction(subscription.id, product.id, 1))    
            } else if ( id ) {
                dispatch(subscriptionUpdateAction(id, product.id, 1))    
            }
        } catch(error) {
            console.log(error)
        }
        dispatch(removeLocalOrderAction())
        dispatch(localOrderFromLocalSubscriptionAction())
        DisableButtonFunction()
        dispatch({ type: DISABLE_CHECK_LOCAL_SUBSCRIPTION })
        // setTimeout(() => {
        //     setDisableButtons(false)
        //   }, "5000");
    }

    useEffect(() => {
        if(subscription && subscription.products_items && subscription.products_items.length > 0) {
            const itemInSubscription = subscription.products_items.filter(x => x.products.id === product.id)
            if (itemInSubscription) {
                if (itemInSubscription[0]) {
                    if (itemInSubscription[0].amount) {
                        setQty(itemInSubscription[0].amount)
                        setInSubscription(true)
                    }    
                }
            }
        }

        setInLocalSubscription(false)
        if(localProductsItems && localProductsItems.length > 0) {
            const inLocalSubscription = localProductsItems.filter(x => x.products.id === product.id)
            if (inLocalSubscription) {
                if (inLocalSubscription[0]) {
                    if (inLocalSubscription[0].amount) {
                        if (inLocalSubscription[0].amount > 0) {
                            setLocalQty(inLocalSubscription[0].amount)
                            setInLocalSubscription(true)
                        } 
                    }    
                }
            }
        }
        if (errorUpdateSubscription && loading === false) {
            setTimeout(() => {
                dispatch(subscriptionDetailAction())
                dispatch({ type: REMOVE_LOCAL_SUBSCRIPTION })
            }, "3000");
        }
    }, [
        subscription,
        addAmount,
        dispatch,
        product,
        success,
        localProductsItems,
        errorUpdateSubscription,
        loading])

    return (
        <div>
            {errorUpdateSubscription && (<Message variant='danger'>{errorUpdateSubscription}</Message>)} 
            {(
                <div>
                    {product.availability > 0 ? (
                        <div>
                            {updatedFromServer ? (
                                <div>
                                    {inLocalSubscription ? (
                                        <div>
                                            <Row className='d-flex justify-content-center'>
                                                <Form onSubmit={removeHandler}>
                                                    <Form.Group controlId='reduce' className='d-none'>
                                                        <Form.Control
                                                            readOnly
                                                            type='number'
                                                            value={reduceAmount}
                                                        >
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Form.Control as="select" htmlSize={3} custom  className='d-none'>
                                                        <option value={product.name}>{product.name}</option>
                                                    </Form.Control>

                                                    <Button className='text-danger btn-sm' type='submit' variant='link' disabled={disableButtons}>{disableButtons ? <Spinner animation="grow" /> : 'Eliminar'}</Button>
                                                </Form>                                    
                                            </Row>                                        

                                            <Row>
                                                <ButtonGroup aria-label="Basic example" className='btn-block' size="lg">
                                                    <Button variant="outline-success" className={horizontalCard ? 'border-right-0' : 'border-right-0 ml-3'} onClick={reduceAmountHandler} disabled={disableButtons}>{disableButtons ? <Spinner animation="grow" /> : '-'}</Button>
                                                    <Button variant="outline-success" className='border-right-0 border-left-0' disabled={disableButtons} style={{ pointerEvents: 'none'}}>{localQty}</Button>
                                                    <Button variant="outline-success" className={horizontalCard ? 'border-left-0' : 'border-left-0 mr-3'} onClick={addAmountHandler} disabled={disableButtons}>{disableButtons ? <Spinner animation="grow" /> : '+'}</Button>
                                                </ButtonGroup>
                                            </Row>
                                        </div>
                                    ) : (
                                        <div>
                                            <Row className='d-flex justify-content-center'>
                                                <Col>
                                                <Form onSubmit={submitHandler}>        
                                                    <Form.Control as="select" htmlSize={3} custom  className='d-none'>
                                                        <option value={product.name}>{product.name}</option>
                                                    </Form.Control>
                                                    <Button
                                                        variant="success"
                                                        type="submit"
                                                        className='py-1 mx-xs-n1 mx-sm-n3 btn-block'
                                                        disabled={disableButtons}
                                                    >
                                                        {disableButtons ? <Spinner animation="grow" /> : 'Añadir'}
                                                    </Button>                                
                                                </Form>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                {inSubscription ? (
                                    <div>
                                        {loadingUpdateSubscription ? (
                                            <div>
                                                {product.id === updating_product ? (
                                                    <Spinner
                                                    animation='border'
                                                    role='status'
                                                    style={{
                                                        height:'25px',
                                                        width:'25px',
                                                        margin:'auto',
                                                        display:'block'
                                                    }}
                                                >
                                                    <span className='sr-only'>
                                                        Loading...
                                                    </span>
                                                    </Spinner>
                                                ) : (
                                                    <div>
                                                        <Row className='d-flex justify-content-center'>
                                                            <Form onSubmit={removeHandler}>
                                                                <Form.Group controlId='reduce' className='d-none'>
                                                                    <Form.Control
                                                                        readOnly
                                                                        type='number'
                                                                        value={reduceAmount}
                                                                    >
                                                                    </Form.Control>
                                                                </Form.Group>
        
                                                                <Form.Control as="select" htmlSize={3} custom  className='d-none'>
                                                                    <option value={product.name}>{product.name}</option>
                                                                </Form.Control>
        
                                                                <Button className='text-danger btn-sm' type='submit' variant='link'>Eliminar</Button>
                                                            </Form>                                    
                                                        </Row>
                                                        <Row>
                                                            <ButtonGroup aria-label="Basic example" className='btn-block' size="lg">
                                                                <Button variant="outline-success"className='border-right-0' onClick={reduceAmountHandler} disabled={disableButtons}>{disableButtons ? <Spinner animation="grow" /> : '-'}</Button>
                                                                <Button variant="outline-success" className='border-right-0 border-left-0' disabled={disableButtons} style={{ pointerEvents: 'none'}}>{qty}</Button>
                                                                <Button variant="outline-success" className='border-left-0' onClick={addAmountHandler} disabled={disableButtons}>{disableButtons ? <Spinner animation="grow" /> : '+'}</Button>
                                                            </ButtonGroup>
        
                                                        </Row>
                                                    </div>
                                                )}
                                            </div>
                                        ) :  (
                                            <div>
                                                <Row  className='d-flex justify-content-center'>
                                                    <Form onSubmit={removeHandler}>
                                                            <Form.Group controlId='reduce' className='d-none'>
                                                                <Form.Control
                                                                    readOnly
                                                                    type='number'
                                                                    value={reduceAmount}
                                                                >
                                                                </Form.Control>
                                                            </Form.Group>
        
                                                            <Form.Control as="select" htmlSize={3} custom  className='d-none'>
                                                                <option value={product.name}>{product.name}</option>
                                                            </Form.Control>
        
        
                                                            <Button className='text-danger btn-sm' type='submit' variant='link'>Eliminar</Button>
                                                    </Form>                                    
                                                </Row>

                                                <Row>
                                                    <ButtonGroup aria-label="Basic example" className='btn-block' size="lg">
                                                        <Button variant="outline-success"className='border-right-0' onClick={reduceAmountHandler} disabled={disableButtons}>{disableButtons ? <Spinner animation="grow" /> : '-'}</Button>
                                                        <Button variant="outline-success" className='border-right-0 border-left-0' disabled={disableButtons} style={{ pointerEvents: 'none'}}>{qty}</Button>
                                                        <Button variant="outline-success" className='border-left-0' onClick={addAmountHandler} disabled={disableButtons}>{disableButtons ? <Spinner animation="grow" /> : '+'}</Button>
                                                    </ButtonGroup>
        
                                                </Row>
                                            </div>                        
                                            )
                                        }
                                    </div>
                                    ) : (
                                        <Row className='d-flex justify-content-center'>
                                            <Form onSubmit={submitHandler}>
        
                                                <Form.Control as="select" htmlSize={3} custom  className='d-none'>
                                                    <option value={product.name}>{product.name}</option>
                                                </Form.Control>
        
                                                {loadingUpdateSubscription ? (
                                                    <div>
                                                        {product.id === updating_product ? (
                                                            <Spinner
                                                            animation='border'
                                                            role='status'
                                                            style={{
                                                                height:'25px',
                                                                width:'25px',
                                                                margin:'auto',
                                                                display:'block'
                                                            }}
                                                        >
                                                            <span className='sr-only'>
                                                                Loading...
                                                            </span>
                                                            </Spinner>
                            
                                                        ) : (
                                                            <Button disabled variant="success" type="submit" className='rounded-pill px- py-1 mx-xs-n1 mx-sm-n3' size='lg'>
                                                                Añadir
                                                            </Button>                                
                                                        )}
                                                    </div>
                                                ) : (
                                                    <Button variant="success" type="submit" className='rounded-pill px- py-1 mx-xs-n1 mx-sm-n3' size='lg' disabled={disableButtons}>
                                                        {disableButtons ? <Spinner animation="grow" /> : 'Añadir'}
                                                    </Button>                                
                                                )}
                                            </Form>
                                        </Row>
                                )}  
                                </div>
                            )}       
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center">
                            <h3>
                                <span className="badge badge-danger">Agotado</span>
                            </h3>
                        </div>
                    )}
                </div>
            )}            
        </div>
    )
}
