import { 
    CART_CHANGE_AMOUNT_ITEM,
    SET_CART_VALUES
 } from "../constants/cartConstants";

export const cartChangeAmountItemAction = (product, qty) => (dispatch, getState) =>{
    try {
        dispatch({
            type: CART_CHANGE_AMOUNT_ITEM,
            payload: {product, qty}
        })        
    } catch (error) {
        console.log(error)
    }
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const cartValuesAction = (cartCost, discount, transportCost) => (dispatch) =>{
    try {
        dispatch({
            type: SET_CART_VALUES,
            payload: {cartCost, discount, transportCost}
        })        
    } catch (error) {
        console.log(error)
    }
    // localStorage.setItem('cartValues', JSON.stringify(getState().cart.cartItems))
}
