import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
import { LazyLoadImage } from "react-lazy-load-image-component";


export const ProductCheckoutImage = ({product}) => {

    return (
        <div>
            {product ? (
                <LazyLoadImage
                    className="card-img-top"
                    src={product.image_68}
                    alt={product.name}
                    style={{width:'3rem'}}
                />
            ) : (
                <Spinner animation="grow" variant="success" />
            )}
        </div>
    )
}
