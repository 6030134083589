import { 
    PRODUCTS_CATEGORIES_REQUEST,
    PRODUCTS_CATEGORIES_SUCCESS,
    PRODUCTS_CATEGORIES_FAILS,
    PRODUCTS_CATEGORIES_RESET,

    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAILS,

    CATEGORY_DETAILS_FAILS,
    CATEGORY_DETAILS_SUCCESS,
    CATEGORY_DETAILS_REQUEST,

    PRODUCT_UPDATE_INFO_SUCCESS,
    PRODUCT_UPDATE_INFO_REQUEST,
    PRODUCT_UPDATE_INFO_FAILS,
    PRODUCT_UPDATE_INFO_RESET,

    TOP_PRODUCTS_LIST_REQUEST,
    TOP_PRODUCTS_LIST_SUCCESS,
    TOP_PRODUCTS_LIST_FAILS,
    TOP_PRODUCTS_LIST_RESET,

    TOP_PRODUCT_CATEGORY_LIST_REQUEST,
    TOP_PRODUCT_CATEGORY_LIST_SUCCESS,
    TOP_PRODUCT_CATEGORY_LIST_FAILS,
    TOP_PRODUCT_CATEGORY_LIST_RESET,

    PRODUCTS_TO_BUY_SEND_EMAIL_REQUEST,
    PRODUCTS_TO_BUY_SEND_EMAIL_SUCCESS,
    PRODUCTS_TO_BUY_SEND_EMAIL_FAILS,

    PRODUCTS_LIST_REQUEST,
    PRODUCTS_LIST_SUCCESS,
    PRODUCTS_LIST_FAILS,
    PRODUCTS_LIST_RESET,

    PRODUCTS_HISTORIC_INFO_REQUEST,
    PRODUCTS_HISTORIC_INFO_SUCCESS,
    PRODUCTS_HISTORIC_INFO_FAILS,
    PRODUCTS_HISTORIC_INFO_RESET,

    MARKETS_REQUEST,
    MARKETS_SUCCESS,
    MARKETS_FAILS,
    MARKETS_RESET

} from "../constants/productsConstants";


export const productCategoryListReducer = (state = { categories: []}, action) => {
    switch (action.type) {
        case PRODUCTS_CATEGORIES_REQUEST:
            return {
                categories: [],
                loading: true                
            }
        case PRODUCTS_CATEGORIES_SUCCESS:
            return {
                loading: false,
                categories: action.payload
            }
        case PRODUCTS_CATEGORIES_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case PRODUCTS_CATEGORIES_RESET:
            return {
                categories: []
            }    
        default:
            return state
    }
}


export const marketsReducer = (state = { markets_list: []}, action) => {
    switch (action.type) {
        case MARKETS_REQUEST:
            return {
                markets_list: [],
                loading: true                
            }
        case MARKETS_SUCCESS:
            return {
                loading: false,
                markets_list: action.payload
            }
        case MARKETS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case MARKETS_RESET:
            return {
                markets_list: []
            }    
        default:
            return state
    }
}


export const productsHistoricInfoReducer = (state = { historicInfo: []}, action) => {
    switch (action.type) {
        case PRODUCTS_HISTORIC_INFO_REQUEST:
            return {
                historicInfo: [],
                loading: true                
            }
        case PRODUCTS_HISTORIC_INFO_SUCCESS:
            return {
                loading: false,
                historicInfo: action.payload
            }
        case PRODUCTS_HISTORIC_INFO_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case PRODUCTS_HISTORIC_INFO_RESET:
            return {
                historicInfo: []
            }    
        default:
            return state
    }
}

export const productDetailReducer = (state = { product: {} }, action) => {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return {
                product: {},
                loading: true                
            }
        case PRODUCT_DETAILS_SUCCESS:
            return {
                loading: false,
                product: action.payload
            }
        case PRODUCT_DETAILS_FAILS:
            return {
                loading: false,
                error: action.payload
            }    
        default:
            return state
    }
}

export const categoryDetailReducer = (state = { category: {} }, action) => {
    switch (action.type) {
        case CATEGORY_DETAILS_REQUEST:
            return {
                category: {},
                loading: true                
            }
        case CATEGORY_DETAILS_SUCCESS:
            return {
                loading: false,
                category: action.payload
            }
        case CATEGORY_DETAILS_FAILS:
            return {
                loading: false,
                error: action.payload
            }    
        default:
            return state
    }
}

export const productUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case PRODUCT_UPDATE_INFO_REQUEST:
            return {
                loading: true
            }
        case PRODUCT_UPDATE_INFO_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case PRODUCT_UPDATE_INFO_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case PRODUCT_UPDATE_INFO_RESET:
            return {}
        default:
            return state
    }
}

export const topProductsListReducer = (state = {}, action) => {
    switch (action.type) {
        case TOP_PRODUCTS_LIST_REQUEST:
            return {
                loading: true
            }
        case TOP_PRODUCTS_LIST_SUCCESS:
            return {
                loading: false,
                top_products: action.payload
            }
        case TOP_PRODUCTS_LIST_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case TOP_PRODUCTS_LIST_RESET:
            return {}
        default:
            return state
    }
}

export const topProductCategoryListReducer = (state = {}, action) => {
    switch (action.type) {
        case TOP_PRODUCT_CATEGORY_LIST_REQUEST:
            return {
                loading: true
            }
        case TOP_PRODUCT_CATEGORY_LIST_SUCCESS:
            return {
                loading: false,
                top_product_categories: action.payload
            }
        case TOP_PRODUCT_CATEGORY_LIST_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case TOP_PRODUCT_CATEGORY_LIST_RESET:
            return {}
        default:
            return state
    }
}

export const productsToBuySendEmailReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCTS_TO_BUY_SEND_EMAIL_REQUEST:
            return {
                loading: true
            }
        case PRODUCTS_TO_BUY_SEND_EMAIL_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case PRODUCTS_TO_BUY_SEND_EMAIL_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}


export const productListReducer = (state = { products: []}, action) => {
    switch (action.type) {
        case PRODUCTS_LIST_REQUEST:
            return {
                categories: [],
                loading: true                
            }
        case PRODUCTS_LIST_SUCCESS:
            return {
                loading: false,
                products: action.payload
            }
        case PRODUCTS_LIST_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case PRODUCTS_LIST_RESET:
            return {
                products: []
            }    
        default:
            return state
    }
}

