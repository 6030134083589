import React, { useEffect, useState } from 'react'
import { Form, Col, Row, Button, Spinner } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { frequencyDaysAction, createFrequencyAction } from '../../../actions/subscriptionActions'
import Loader from '../../Loader'
import { CREATE_FREQUENCY_RESET, UPDATE_FREQUENCY_RESET } from '../../../constants/subscriptionConstants'
import { subscriptionDetailAction, removeLocalSubscriptionAction } from '../../../actions/subscriptionActions'
import { 
    nextWeekSubscriptionOrderDetailAction,
    nextActiveSubscriptionOrderDetailAction,
    removeLocalOrderAction } from '../../../actions/orderActions'
import { updateFrequencyAction } from '../../../actions/subscriptionActions'
import Message from '../../Message'
import { userMessageAction } from '../../../actions/userActions'

export const FrecuencyForm = ({ create, closeModal }) => {

    const[selectedDays, setSelectedDays] = useState([])
    const[frequency, setFrequency] = useState('1')
    const[deliveryDays, setDeliveryDays] = useState(0)
    const[deliveryOption, setDeliveryOption] = useState('')

    const frequencyDays = useSelector(state => state.frequencyDays)
    const { loading, error, days } = frequencyDays

    const frequencyCreate = useSelector(state => state.createFrequency)
    const { loading: loadingCreateFrequency, success, error: errorCreateFrequency } = frequencyCreate

    const frequencyUpdate = useSelector(state => state.updateFrequency)
    const { loading: loadingUpdateFrequency, success: successUpdateFrequency, error: errorUpdateFrequency } = frequencyUpdate

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    const dispatch = useDispatch()

    useEffect(() => {
        if(days === null){
            dispatch(frequencyDaysAction())            
        }
        if(success){
            dispatch(subscriptionDetailAction())
            dispatch({type: CREATE_FREQUENCY_RESET})
            dispatch(userMessageAction('Frecuencia creada'))
            dispatch(removeLocalSubscriptionAction())
        }
        if(successUpdateFrequency){
            dispatch(subscriptionDetailAction())
            dispatch(nextWeekSubscriptionOrderDetailAction())
            console.log('FrecuencyForm')
            dispatch(nextActiveSubscriptionOrderDetailAction())
            dispatch(removeLocalOrderAction())
            dispatch({type: UPDATE_FREQUENCY_RESET})
            if (closeModal) {
                closeModal()
            }
            dispatch(userMessageAction('Frecuencia actualizada'))
        }
        // if(subscription && subscription.frecuency.deliver_frecuency !== null){
        //     setFrequency(subscription.frecuency)
        // }
        if(days) {
            let available_days = 0
            for(let i = 0; i < days.length; i++) {
                if(days[i].active_delivery) {
                    available_days += 1
                }
            }
            setDeliveryDays(available_days)
            if (available_days === 1){
                const available_day = days.filter(x => x.active_delivery === true)
                setSelectedDays(available_day)
            }
        }
    }, [
        success,
        dispatch,
        subscription,
        successUpdateFrequency,
        closeModal,
        days,
        deliveryDays])

    const daysSelectedChange = (e) => {
        if(e.target.checked === true){
            setSelectedDays([
                ...selectedDays,
                e.target.id
            ]
            )
        } else if(e.target.checked === false){
            setSelectedDays(
                selectedDays.filter(x => x !== e.target.id)
            )
        }
    }

    const submitCreateFrequencyHandler = (e) => {
        e.preventDefault()
        const frequency_info = { selectedDays, frequency, deliveryOption}
        dispatch(createFrequencyAction(frequency_info))
    }

    const submitUpdateFrequencyHandler = (e) => {
        e.preventDefault()
        const frequency_info = { selectedDays, frequency }
        dispatch(updateFrequencyAction(frequency_info))
        
    }

    return (
        <div>
            {error && (<Message variant='danger'>{error}</Message>)}
            {errorUpdateFrequency && (<Message variant='danger'>{errorUpdateFrequency}</Message>)}
            {errorCreateFrequency && (<Message variant='danger'>{errorCreateFrequency}</Message>)}
            {loading ? (<Loader />) :
            (
                <div>
                    <Form onSubmit={create ? submitCreateFrequencyHandler : submitUpdateFrequencyHandler}>
                        {deliveryDays > 1 ? (
                            <div>
                                <Row className='px-md-3'>
                                    <Col md={6}>
                                        <p className='ml-3 mb-n1'>¿Qué día te entregamos tu mercado?</p>
                                        <hr></hr>
                                        <Form.Group required className="mb-3" >
                                        {['checkbox'].map((type) => (
                                            <div key={`default-${type}`} className="mb-3">
                                                {days && (days.map(day =>
                                                    <Form.Group key={day.name}>
                                                        <Form.Check key={day.id}
                                                            name="group1"
                                                            type={type}
                                                            id={day.id}
                                                            onChange={daysSelectedChange}
                                                            disabled={!day.active_delivery}
                                                            className='ml-3'
                                                        />
                                                        {day.active_delivery === true ? (
                                                        <Form.Label className='ml-3'>{day.name} <small>(entrega entre 9:00 am y 12:30 pm)</small></Form.Label>
                                                        ) : (
                                                            <Form.Label className='text-muted ml-3'>{day.name} <small>(opción no disponible todavía)</small></Form.Label>
                                                        )}
                                                    </Form.Group>
                                                ))}                   
                                            </div>
                                        ))}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <p className='ml-3 mb-n1'>¿Con qué frecuencia te entregamos tu mercado?</p>
                                        <hr></hr>
                                        <Form.Group onChange={(e) => {setFrequency(e.target.value)}}>
                                            <Form.Control
                                                as="select"
                                                disabled={loadingCreateFrequency || loadingUpdateFrequency}
                                                htmlSize={3}
                                                custom 
                                                defaultValue="Cada semana">
                                                <option value='1'>Cada semana</option>
                                                <option value='2'>Cada 2 semanas</option>
                                                <option value='3'>Cada 3 semanas</option>
                                                <option value='4'>Cada 4 semanas</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className='mb-n1 text-center'>¿Cómo entregamos el mercado mercado?</p>
                                        <hr></hr>
                                        <Form.Group
                                            disabled={loadingCreateFrequency || loadingUpdateFrequency}
                                            onChange={(e) => {setDeliveryOption(e.target.value)}}>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    label="Se puede dejar el pedido en la portería"
                                                    value="1"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-1`}
                                                />
                                                <Form.Check
                                                    label="Habrá alguien en la casa para recibir el pedido"
                                                    value="2"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-2`}
                                                />
                                            </div>
                                        ))}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='px-md-3'>
                                    {(selectedDays.length === 0 || deliveryOption.length === 0) ? (
                                        <Col>
                                            <Button
                                                disabled
                                                className='btn-block'
                                                type='submit'
                                            >
                                                Crear frecuencia
                                            </Button>
                                        </Col>
                                    ) : (
                                        <Col>
                                            <Button
                                                className='btn-block mt-1'
                                                type='submit'
                                                disabled={loadingCreateFrequency || loadingUpdateFrequency}
                                                >
                                            {loadingCreateFrequency || loadingUpdateFrequency ? (
                                                <Spinner
                                                animation='border'
                                                role='status'
                                                style={{
                                                    height:'25px',
                                                    width:'25px',
                                                    margin:'auto',
                                                    display:'block'
                                                    }}
                                                >
                                                    <span className='sr-only'>
                                                        Loading...
                                                    </span>
                                                </Spinner> 
                                            ) : (
                                                <span>Crear frecuencia</span>
                                            )}
                                            </Button>    
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        ) : (
                            <div>
                                <h3 className='text-center'>Día de entrega Miércoles <small>(de 9:30 a 3:30 pm)</small></h3>
                                <p className='text-center'>Pronto tendremos más opciones disponibles</p>
                                <Row className='px-md-3'>
                                    <Col md={12}>
                                        <p className='ml-3 mb-n1'>¿Con qué frecuencia te entregamos tu mercado?</p>
                                        <hr></hr>
                                        <Form.Group
                                            onChange={(e) => {setFrequency(e.target.value)}}
                                        >
                                            <Form.Control
                                                as="select"
                                                defaultValue="Cada semana"
                                                disabled={loadingCreateFrequency || loadingUpdateFrequency}
                                            >
                                                <option value='1'>Cada semana</option>
                                                <option value='2'>Cada 2 semanas</option>
                                                <option value='3'>Cada 3 semanas</option>
                                                <option value='4'>Cada 4 semanas</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <p className='mb-n1 ml-3 mt-3'>¿Cómo entregamos el mercado mercado?</p>
                                        <hr></hr>
                                        <Form.Group
                                            onChange={(e) => {setDeliveryOption(e.target.value)}}
                                            disabled={loadingCreateFrequency || loadingUpdateFrequency}
                                        >
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    disabled={loadingCreateFrequency || loadingUpdateFrequency}
                                                    label="Se puede dejar el pedido en la portería"
                                                    value="1"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-1`}
                                                />
                                                <Form.Check
                                                    disabled={loadingCreateFrequency || loadingUpdateFrequency}
                                                    label="Habrá alguien en la casa para recibir el pedido"
                                                    value="2"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-2`}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='px-md-3'>
                                    {(selectedDays.length === 0 || deliveryOption.length === 0) ? (
                                        <Col>
                                            <Button
                                                disabled
                                                className='btn-block mx-auto'
                                                type='submit'
                                                style={{ width: '95%'}}
                                            >
                                                Crear frecuencia
                                            </Button>
                                        </Col>
                                    ) : (
                                        <Col>
                                            <Button
                                                className='btn-block mt-1 mx-auto'
                                                type='submit'
                                                disabled={loadingCreateFrequency || loadingUpdateFrequency}
                                                style={{ width: '95%'}}
                                            >
                                            {loadingCreateFrequency || loadingUpdateFrequency ? (
                                                <Spinner
                                                animation='border'
                                                role='status'
                                                style={{
                                                    height:'25px',
                                                    width:'25px',
                                                    margin:'auto',
                                                    display:'block'
                                                    }}
                                                >
                                                    <span className='sr-only'>
                                                        Loading...
                                                    </span>
                                                </Spinner> 
                                            ) : (
                                                <span>Crear frecuencia</span>
                                            )}
                                            </Button>    
                                        </Col>
                                    )}
                                </Row>

                            </div>
                        )}
                    </Form>
                </div>
            )}
        </div>
    )
}
