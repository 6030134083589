import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../Loader'
import Message from '../../../../Message' 
import { DisplayInfoTable } from '../../../../DisplayInfoTable'
import { PLATFORM_BALANCE_CHANGE_COLUMNS } from '../../../PlatformBalanceChangeColumns'


export const VerdemiBalanceChanges = () => {


    const changePlatformBalance = useSelector(state => state.platformBalanceChange)
    const { error, loading, platformsChanges } = changePlatformBalance

    return (
        <div>
            {loading ? (<Loader />) : (
                <div>
                    {error ? (
                        <div>
                            <Message variant='danger'>{error}</Message>
                        </div>
                    ) : (
                        <div>
                            {platformsChanges && platformsChanges.length > 0 ? (
                                <div>
                                    <DisplayInfoTable rows={platformsChanges} columns={PLATFORM_BALANCE_CHANGE_COLUMNS} staff_table={false}/>
                                </div>
                            ) : (
                                <p>
                                    <p>No tienes movimientos en tu saldo en Verdemi</p>
                                </p>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
