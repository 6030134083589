import { 
    CREATE_PAYMENT_CARD_REQUEST,
    CREATE_PAYMENT_CARD_FAILS,
    CREATE_PAYMENT_CARD_RESET,
    CREATE_PAYMENT_CARD_SUCCESS,

    GET_DONATIONS_REQUEST,
    GET_DONATIONS_SUCCESS,
    GET_DONATIONS_FAILS,
    GET_DONATIONS_RESET,

    REMOVE_PAYMENT_CARD_REQUEST,
    REMOVE_PAYMENT_CARD_SUCCESS,
    REMOVE_PAYMENT_CARD_FAILS,
    REMOVE_PAYMENT_CARD_RESET,

    CHANGE_PAYMENT_METHOD_REQUEST,
    CHANGE_PAYMENT_METHOD_SUCCESS,
    CHANGE_PAYMENT_METHOD_FAILS,
    CHANGE_PAYMENT_METHOD_RESET,

    PLATFORM_BALANCE_CHANGE_REQUEST,
    PLATFORM_BALANCE_CHANGE_SUCCESS,
    PLATFORM_BALANCE_CHANGE_FAILS,
    PLATFORM_BALANCE_CHANGE_RESET,

    NOT_BROADCASTED_DONATIONS_REQUEST,
    NOT_BROADCASTED_DONATIONS_SUCCESS,
    NOT_BROADCASTED_DONATIONS_FAILS, 
    NOT_BROADCASTED_DONATIONS_RESET,

    BROADCAST_DONATIONS_REQUEST,
    BROADCAST_DONATIONS_SUCCESS,
    BROADCAST_DONATIONS_FAILS,
    BROADCAST_DONATIONS_RESET

} from "../constants/paymentsConstants";


export const createCardReducers = (state = {}, action) => {
    switch (action.type) {
        case CREATE_PAYMENT_CARD_REQUEST:
            return {
                loading: true
            }
        case CREATE_PAYMENT_CARD_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case CREATE_PAYMENT_CARD_FAILS:
            return {
                loading: false,
                error: action.payload
            } 
        case CREATE_PAYMENT_CARD_RESET:
            return {
                loading: false,
                success: false
            }  
        default:
            return state
    }
}

export const getDonationsReducer = ( state = {}, action) => {
    switch (action.type) {
     case GET_DONATIONS_REQUEST:
        return {
             loading: true
         }
    case GET_DONATIONS_SUCCESS:
        return {
            loading: false,
            donations: action.payload
        }
    case GET_DONATIONS_FAILS:
        return {
            loading: false,
            error: action.payload
        }
    case GET_DONATIONS_RESET:
        return {
        }
    default:
        return state
    
    }
}

export const removeCardReducers = (state = {}, action) => {
    switch (action.type) {
        case REMOVE_PAYMENT_CARD_REQUEST:
            return {
                loading: true
            }
        case REMOVE_PAYMENT_CARD_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case REMOVE_PAYMENT_CARD_FAILS:
            return {
                loading: false,
                error: action.payload
            } 
        case REMOVE_PAYMENT_CARD_RESET:
            return {
                loading: false,
                success: false
            }  
        default:
            return state
    }
}

export const changeSelectedPaymentMethoddReducers = (state = {}, action) => {
    switch (action.type) {
        case CHANGE_PAYMENT_METHOD_REQUEST:
            return {
                loading: true
            }
        case CHANGE_PAYMENT_METHOD_SUCCESS:
            return {
                loading: false,
                success: true
            }
        case CHANGE_PAYMENT_METHOD_FAILS:
            return {
                loading: false,
                error: action.payload
            } 
        case CHANGE_PAYMENT_METHOD_RESET:
            return {
                loading: false,
                success: false
            }  
        default:
            return state
    }
}

export const platformBalanceChangeReducers = ( state = { platformsChanges: null, loading: false }, action) => {
    switch (action.type) {
        case PLATFORM_BALANCE_CHANGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PLATFORM_BALANCE_CHANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                platformsChanges: action.payload
            }
        case PLATFORM_BALANCE_CHANGE_FAILS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case PLATFORM_BALANCE_CHANGE_RESET:
            return {
                platformsChanges: null,
                loading: false
            }
        default:
            return state
    }
}

export const getNotBroadcastedDonationsReducer = (state = {}, action) => {
    switch (action.type) {
        case NOT_BROADCASTED_DONATIONS_REQUEST:
            return {
                loading: true
            }
        case NOT_BROADCASTED_DONATIONS_SUCCESS:
            return {
                loading: false,
                success: true,
                notBroadcastedDonations: action.payload
            }
        case NOT_BROADCASTED_DONATIONS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case NOT_BROADCASTED_DONATIONS_RESET:
            return {}
        default:
            return state
    }
}

export const broadcastDonationsReducer = (state = {}, action) => {
    switch (action.type) {
        case BROADCAST_DONATIONS_REQUEST:
            return {
                loading: true
            }
        case BROADCAST_DONATIONS_SUCCESS:
            return {
                loading: false,
                success: true,            }
        case BROADCAST_DONATIONS_FAILS:
            return {
                loading: false,
                error: action.payload
            }
        case BROADCAST_DONATIONS_RESET:
            return {}
        default:
            return state
    }
}