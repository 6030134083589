import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap'
import { removeCardAction } from '../../../../../actions/paymentsActions'
import { REMOVE_PAYMENT_CARD_RESET } from '../../../../../constants/paymentsConstants'
import { SUBSCRIPTION_DETAIL_RESET, REMOVE_LOCAL_SUBSCRIPTION } from '../../../../../constants/subscriptionConstants'
import { SUBSCRIPTION_ORDER_DETAIL_RESET, REMOVE_LOCAL_ORDER } from '../../../../../constants/orderConstants'

import { userMessageAction } from '../../../../../actions/userActions'

export const ModalConfirmDeletePaymentMethod = (props) => {

    const dispatch = useDispatch()

    const cardRemove = useSelector(state => state.removeCard)
    const { loading: loadingRemoveCard, success: cardRemovedSucces } = cardRemove

    const deletePaymentMethod = (lastFourDigits, franchise) => {
        dispatch(removeCardAction(lastFourDigits, franchise))
    }

    useEffect(() => {
        if (cardRemovedSucces) {
            dispatch(userMessageAction('Medio de pago eliminado exitosamente'))
            dispatch({
                type: REMOVE_PAYMENT_CARD_RESET
            })
            dispatch({
                type: SUBSCRIPTION_DETAIL_RESET
            })
            dispatch({
                type: SUBSCRIPTION_ORDER_DETAIL_RESET
            })
            dispatch({
                type: REMOVE_LOCAL_SUBSCRIPTION
            })
            dispatch({
                type: REMOVE_LOCAL_ORDER
            })
        }
    }, [cardRemovedSucces, dispatch])

    return (
        <div>
            <Modal show={props.isOpen} onHide={props.toggle} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Confirmar borrar el método de pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="danger" size="lg" onClick={props.toggle} onClick={() => deletePaymentMethod(props.lastFourDigits, props.franchise)}>
                        {loadingRemoveCard ? (
                            <div>
                                <Spinner
                                    animation='border'
                                    role='status'
                                    style={{
                                        height:'25px',
                                        width:'25px',
                                        margin:'auto',
                                        display:'block'
                                    }}
                                ></Spinner>                                    
                            </div>
                        ) : (
                            <div>
                                <div>Haz click aquí para eliminar este medio de pago:</div>
                                <div>**** **** **** {props.lastFourDigits} - {props.franchise}</div>
                            </div>
                        )}
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.toggle}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
