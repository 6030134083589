import React from 'react'
import { useDispatch } from 'react-redux';
import { Modal, Button, Row } from 'react-bootstrap'
import { userLogout } from '../../../../actions/userActions';


export const ModalLogout = (props) => {

    const dispatch = useDispatch()

    const logoutHandler = () => {
        dispatch(userLogout())
        if (props && props.history) {
            props.history.push('/login')
        }
    }

    return (
        <div>
            <Modal show={props.isOpen} onHide={props.toggle} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Cerrar sesión</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='justify-content-center'>
                        <Button variant="secondary" onClick={props.toggle}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={logoutHandler} className='ml-3'>
                            Cerrar sesión
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}
