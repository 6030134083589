import React, { useState, useEffect, useRef, lazy, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Form, Button, Spinner, Image } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../../../components/Message' 
import { userRegisterAction, userLoginAction } from '../../../actions/userActions';
import VerdemiLogo from '../../../images/logo verdemi.png'
import ReactPixel from 'react-facebook-pixel';
import useGAEventTracker from '../../../hooks/useGAEventTracker';
import { WhatsappFloatingButton } from '../../../components/user/WhatsappFloatingButton'

let autoComplete;

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
    script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
        }
    };
    } else {
    script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { componentRestrictions: { country: "co" } }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
    );
}

async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
}

const Footer = lazy(() => import('../../../components/user/prospects/Footer'))
const Header = lazy(() => import('../../../components/user/prospects/Header'))

export const UserRegisterScreen = ({ history }) => {

    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);  

    const GAEventsTracker = useGAEventTracker();

    const dispatch = useDispatch()

    const [email, setEmail] = useState('')
    const [appartment, setAppartment] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [telephone, setTelephone] = useState('')
    const [sendPassword] = useState(false)

    const cartInfo = useSelector(state => state.cart)
    const { cartItems } = cartInfo

    const loginUser = useSelector(state => state.userLogin)
    const { loading, error, user } = loginUser

    const registeUser = useSelector(state => state.userRegister)
    const { loading: registerLoading, error: registerError, user:registerUser } = registeUser

    const personalCodeHost = useSelector(state => state.hostPersonalCode)
    const { hostPersonalCode } = personalCodeHost

    const [validTelephone, setValidTelephone] = useState(false)

    const handleOnTelephoneChange = (e) => {
        if (/^\d+$/.test(e) && e.length < 11) {
            setTelephone(e)
        } else if (e.length === 0) {
            setTelephone('')
        }
        if (e.length === 10) {
            setValidTelephone(true)
        }
    }

    const submitHandler = (e) => {
        e.preventDefault()
        const user = {
            'name': 'Rafa',
            'surname': 'Risco',
            'email': email,
            'password': password,
            'hostPersonalCode': hostPersonalCode,
            'address': query,
            'appartment': appartment,
            'telephone': telephone,
            'sendPassword': sendPassword
        }
        dispatch(userRegisterAction(cartItems, user))
        GAEventsTracker('Sign In', 'Sign in button', 'Button')
    }

    const handleOnEmailChange = ( email ) => {
        setEmail(email)
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if ( re.test(email) ) {
            setValidEmail(true)
        }
        else {
            setValidEmail(false)
        }    
    }

    useEffect(() => {
        if(registerUser) {
            dispatch(userLoginAction(registerUser.email, password))
        }
        if(user){
            history.push('/user/')
            ReactPixel.track('CompleteRegistration');
        }
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_API_KEY}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
          );    
    }, [
        user,
        history,
        registerLoading,
        registerUser,
        dispatch,
        password,
        password2 ])

    const emailRef = useRef(null)

    useEffect(() => {
        if (emailRef && emailRef.current) {
            emailRef.current.focus();
        }
    }, [emailRef])
    

    return (
        <div>
            <Suspense fallback={"Cargando..."}>
                <Header />
            </Suspense>
            <Container>
                <div className='primary-overlay'>
                    <Row className='mt-3 d-none d-md-block'>
                        <Col className='d-flex justify-content-center mx-3 my-1 mt-5'>
                            <Image className="img-fluid" src={VerdemiLogo} alt="Verdemi Logo"/>
                        </Col>
                    </Row>
                    <Row className='justify-content-md-center mt-5 mt-md-1'>
                        <Col md={6} className='mt-5 mt-md-1'>
                        <h1 className='mt-1 text-center'>Regístrate</h1>
                        <p className='text-center text-dark'>Si ya tienes una cuenta, entonces por favor
                            <LinkContainer to='/login/' className='text-primary'>
                                <span> inicia sesión.</span>
                            </LinkContainer>
                         </p>
                        {error && (
                            <Message variant='danger'>{error}</Message>
                            )}
                        {registerError && (
                            <Message variant='danger'>{registerError}</Message>
                            )}
                        <Form onSubmit={submitHandler}>
                            <Row className="g-2 my-3">
                                <Col md>
                                    <Form.Control
                                        ref={emailRef}
                                        type="email"
                                        required
                                        disabled={registerLoading || loading}
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => handleOnEmailChange(e.target.value)}
                                        style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                    />
                                    {validEmail === false && email.length > 0 && (
                                        <Row>
                                            <Col className='mb-n1'>
                                                <p className='text-danger mb-0' style={{fontSize: '0.9rem'}}>Inserta un email válido</p>
                                            </Col>
                                        </Row>
                                        )}
                                </Col>
                            </Row>

                            <Row className="g-2 my-3">
                                <Col md>
                                    <label style={{ position: 'relative', backgroundColor: '#F7F7F7', display: 'flex', alignItems: 'center' }}>
                                        <Form.Control 
                                            type="number"
                                            required
                                            disabled={registerLoading || loading}
                                            placeholder=""
                                            value={telephone}
                                            onChange={(e) => handleOnTelephoneChange(e.target.value)}
                                            style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                        />
                                        {telephone === '' && (
                                            <>
                                                <div style={{ position: 'absolute', top: '50%', left: '15px', transform: 'translateY(-50%)', fontSize: '16px', color: '#666' }}>Teléfono</div>
                                                <div style={{ position: 'absolute', top: '50%', left: '85px', transform: 'translateY(-50%)', fontSize: '12px', color: '#666' }}>(para contactarte si es necesario por tu pedido)</div>
                                            </>
                                        )}
                                    </label>
                                    {validTelephone === false && telephone.length > 0 && (
                                        <Row>
                                            <Col className='mb-n1'>
                                                <p className='text-danger mb-0' style={{fontSize: '0.9rem'}}>Inserta un teléfono válido</p>
                                            </Col>
                                        </Row>
                                        )}
                                </Col>
                            </Row>

                            <Row className="g-2 my-3">
                                <Col md>
                                    <div className="search-location-input">
                                        <label  style={{ position: 'relative', backgroundColor: '#F7F7F7', display: 'flex', alignItems: 'center', marginBottom: '0.0em'}}>
                                            <Form.Control
                                                ref={autoCompleteRef}
                                                placeholder=""
                                                disabled={registerLoading || loading}
                                                type="text"
                                                value={query}
                                                onChange={event => setQuery(event.target.value)}
                                                style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                            />
                                            {query === '' && (
                                                <>
                                                    <div style={{ position: 'absolute', top: '50%', left: '15px', transform: 'translateY(-50%)', fontSize: '16px', color: '#666' }}>Dirección</div>
                                                    <div style={{ position: 'absolute', top: '50%', left: '90px', transform: 'translateY(-50%)', fontSize: '12px', color: '#666' }}>(para enviarte tu pedido)</div>
                                                </>
                                            )}
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="g-2 my-3">
                                <Col md>
                                    <label style={{ position: 'relative', backgroundColor: '#F7F7F7', display: 'flex', alignItems: 'center', marginBottom: '0.0em' }}>
                                        <Form.Control
                                            type="text"
                                            required
                                            disabled={registerLoading || loading}
                                            placeholder=""
                                            value={appartment}
                                            onChange={(e) => setAppartment(e.target.value)}
                                            style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                            />
                                            {appartment === '' && (
                                                <>
                                                    <div style={{ position: 'absolute', top: '50%', left: '15px', transform: 'translateY(-50%)', fontSize: '16px', color: '#666' }}>Apartamento, escalera, etc </div>
                                                    <div style={{ position: 'absolute', top: '50%', left: '215px', transform: 'translateY(-50%)', fontSize: '12px', color: '#666' }}>(para entregarte tu pedido)</div>
                                                </>
                                            )}
                                    </label>
                                </Col>
                            </Row>

                            <Row className="g-2 my-3">
                                <Col md>
                                    <Form.Control
                                        type="password"
                                        required
                                        disabled={registerLoading || loading}
                                        placeholder="Contraseña"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                    />
                                    {password.length < 10 && password.length > 0 && (
                                        <Row>
                                            <Col className='mb-n1'>
                                                <p className='text-danger mb-0' style={{fontSize: '0.9rem'}}>La contraseña tiene que tener al menos 10 caracteres</p>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>



                            {/* <Form.Group controlId='password' className='mt-3'>
                                <Form.Control
                                    required
                                    type='password'
                                    disabled={registerLoading || loading}
                                    placeholder='Contraseña'
                                    className='rounded-pill text-center'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>
                            {password.length < 10 && password.length > 0 && (
                                <Row>
                                    <Col className='mb-n1'>
                                        <p className='text-danger mt-n2 mb-0' style={{fontSize: '0.9rem'}}>La contraseña tiene que tener al menos 10 caracteres</p>
                                    </Col>
                                </Row>
                            )} */}

                            <Row className="g-2 my-3">
                                <Col md>
                                    <Form.Control
                                        type="password"
                                        required
                                        disabled={registerLoading || loading}
                                        placeholder="Repite la contraseña"
                                        value={password2}
                                        onChange={(e) => setPassword2(e.target.value)}
                                        style={{ border: '0px', backgroundColor: '#F7F7F7'}}
                                    />
                                    {registerLoading === '' && (
                                        <>
                                            <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', fontSize: '16px', color: '#666' }}>Apartamento, escalera, etc </div>
                                            <div style={{ position: 'absolute', top: '50%', left: '150px', transform: 'translateY(-50%)', fontSize: '12px', color: '#666' }}>(para entregarte tu pedido)</div>
                                        </>
                                    )}
                                    {password !== password2 && password.length > 10 && password2.length > 1 && (
                                        <Row>
                                            <Col className='mb-n1'>
                                                <p className='text-danger mt-1 mb-0' style={{fontSize: '0.9rem'}}>No coinciden las contraseñas</p>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>

                            {/* <Form.Group controlId='password' className='mt-3'>
                                <Form.Control
                                    required
                                    type='password'
                                    disabled={registerLoading || loading}
                                    placeholder='Repite la contraseña'
                                    className='rounded-pill text-center'
                                    value={password2}
                                    onChange={(e) => setPassword2(e.target.value)}
                                >
                                </Form.Control>

                                    {password !== password2 && password.length > 10 && password2.length > 1 && (
                                        <Row>
                                            <Col className='mb-n1'>
                                                <p className='text-danger mt-1 mb-0' style={{fontSize: '0.9rem'}}>No coinciden las contraseñas</p>
                                            </Col>
                                        </Row>
                                    )}
    
                            </Form.Group> */}

                            <Button
                                type='submit'
                                disabled={registerLoading || loading || password !== password2 || password.length < 10 || password2.length < 10 || telephone.length < 10 || query.length < 10}
                                variant='success'
                                className='mt-3 align-self-center btn-block btn-lg rounded-pill'
                            >
                                {registerLoading || loading ? (
                                    <Spinner
                                    animation='border'
                                    role='status'
                                    style={{
                                        height:'25px',
                                        width:'25px',
                                        margin:'auto',
                                        display:'block'
                                    }}
                                    >
                                        <span className='sr-only'>
                                            Loading...
                                        </span>
                                    </Spinner>
                                ) : (
                                    <span>
                                        Registrarse
                                    </span>
                                )}
                            </Button>
                        </Form>
                        <Row className='d-flex justify-content-center mb-3 mt-1'>
                            <Button className="button secondary Action align-self-center">¿Olvidaste tu contraseña?</Button>
                        </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
            <WhatsappFloatingButton />
            <Row className='bg-light d-flex justify-content-center p-3 p-md-5'>
                <Col>
                    <Suspense fallback={"Cargando..."}>
                        <Footer />
                    </Suspense>
                </Col>
            </Row>
        </div>
    )
}
