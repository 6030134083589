import React from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../../../../Loader'
import { ChangeFrecuency } from '../../../OrderInfo/ChangeFrecuency'


export const FrecuencyTab = () => {

    const orderNextWeekActiveSubscription = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
    const { loading: orderLoading, nextWeekActiveOrder } = orderNextWeekActiveSubscription

    return (
        <div>
            <div>
                {orderLoading ? (<Loader />) :
                        nextWeekActiveOrder && nextWeekActiveOrder.date && (
                    <div>
                        <ChangeFrecuency />
                    </div>
                    )
                }
            </div>
        </div>
    )
}
