import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Col, Row, Image, Button } from 'react-bootstrap'
import { ModalChangeSubscriptionProducts } from './ModalChangeSubscriptionProducts'
import { SubscriptionButton } from './SubscriptionButton'
import { SUBSCRIPTION_PRODUCTS_MODAL_OPEN } from '../../../../constants/subscriptionConstants' 

export const SubscriptionProducts = ({ subscription }) => {

    const dispatch = useDispatch()

    const [changeSubscriptionProductsShow, setChangeSubscriptionProductsShow] = useState(false);

    const modalSubscription = useSelector(state => state.subscriptionModal)
    const { open_modal } = modalSubscription

    const toggleChangeSubscriptionProductsModal = () => {
        if(open_modal === false) {
            dispatch({type: SUBSCRIPTION_PRODUCTS_MODAL_OPEN})
        }
    }

    return (
        <div>
            <h4 className='mt-3 text-success'><i className="fas fa-shopping-basket"></i> Tus productos</h4>
            <hr className='border border-secondary my-0'></hr>
            <Row className='p-3'>
                <Col>
                    {subscription && subscription.products_items.length > 0 ? (
                    <div>
                    <Row className='d-flex justify-content-around'>
                        {subscription.products_items.map(item => (
                                <Col xs={12} lg={6} key={item.id}>
                                    <div className='border rounded bg-white my-1'>
                                        <Row>
                                            <Col xs={3}>
                                                {item.products.image ? (
                                                    <Image src={item.products.image} alt={item.products.name} className='rounded-circle p-3 no-logo-img' style={{height: '6rem'}}></Image>
                                                ) : (
                                                    <Image src="http://via.placeholder.com/144x144" className='rounded-circle p-3 no-logo-img' style={{height: '6rem'}}></Image>
                                                )}
                                            </Col>
                                            <Col xs={9} className='pl-3'>
                                                <p className="card-title mb-n1 pt-md-3 pl-1"><b>{item.products.name}</b></p>
                                                <p className="card-text mt-n1 pl-1"><small className="text-muted">{item.amount} unidades. ${new Intl.NumberFormat('co-CO').format(Number(item.total_value))}</small></p>
                                            </Col>
                                        </Row>
                                        <Row className='mb-1 mt-n1'>
                                            <Col className='px-5'>
                                                <SubscriptionButton product={item.products}/>
                                            </Col>
                                        </Row>
                                    </div>            
                                </Col>
                        ))}
                    </Row>
                    <Row>
                        <Button 
                            variant='info'
                            className="btn-sm btn-block"
                            onClick={toggleChangeSubscriptionProductsModal}
                        >
                                <i className="fas fa-shopping-cart"></i> Incluir productos en mi subscripción
                        </Button>                                            
                    </Row>                        
                        
                    </div>
                        ) : (
                            <div>
                            <Col md={12} className='px-md-1 my-1 my-md-0'>
                            <p className='my-n3 d-none d-sm-block' style={{ color: '#F8F9FA'}}>----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                                <p className='text-center'>No tienes productos en tu subscripción.</p>
                                <Button 
                                    variant='success'
                                    className="btn-lg btn-block"
                                    onClick={toggleChangeSubscriptionProductsModal}
                                >
                                        <i className="fas fa-shopping-cart"></i> Incluir productos en mi subscripción
                                </Button>                                            
                            </Col>
                            </div>
                        )}
                </Col>
            </Row>
            <ModalChangeSubscriptionProducts isOpen={changeSubscriptionProductsShow} toggle={setChangeSubscriptionProductsShow}/>

        </div>   
    )
}
