import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'

import Product from './Product'


export const ProductsHorizontalSlider = ({ categoryProducts, type, firstPurchasesDiscountApplies, DisableButtonFunction, categoryName }) => {

    const containerRef = useRef(null);
    const [centeredElement, setCenteredElement] = useState(null);

    const cartInfo = useSelector(state => state.cart)
    const { cartItems } = cartInfo

    useEffect(() => {
        const handleScroll = () => {
          const container = containerRef.current;
          const containerRect = container.getBoundingClientRect();
          const containerCenterX = containerRect.left + containerRect.width / 2;
    
          const elements = container.querySelectorAll('.scroll-item');
          let closestElement = null;
          let minDistance = Number.MAX_SAFE_INTEGER;
    
          elements.forEach((element) => {
            const elementRect = element.getBoundingClientRect();
            const elementCenterX = elementRect.left + elementRect.width / 2;
            const distance = Math.abs(containerCenterX - elementCenterX);
    
            if (distance < minDistance) {
              minDistance = distance;
              closestElement = element;
            }
          });
    
          setCenteredElement(closestElement);
        };
    
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
    
            // Inicializar la posición centrada al cargar la página
            handleScroll();    
        }
    
        return () => {
          container.removeEventListener('scroll', handleScroll);
        };
      }, []); // Empty dependency array to run the effect only once

    const scrollToItem = (index) => {
      const container = containerRef.current;
      const item = container.children[index];
      if (container && item) {
        const scrollLeft = item.offsetLeft - container.offsetLeft;
        container.scrollTo({
          left: scrollLeft,
          behavior: 'smooth', // Puedes ajustar el comportamiento según tus preferencias
        });
      }
    };
  
    return (
      <div>
        <div
            ref={containerRef}
            className="horizontal-product-slider horizontal-scroll-container scrolling-wrapper row flex-row flex-nowrap pt-2"
            style={{
              height: '27em',
              // overflowX: 'hidden',
              '&::WebkitScrollbarThumb': { height: '0px', backgroundColor: 'transparent', display: 'none', width: '0px' },
              '&::WebkitScrollbarTrack': { display: 'none', backgroundColor: 'transparent' }
          }}
        >
            {categoryProducts.map(product =>
                <Col key={product.id} xs={8} lg={4} xl={3} className='scroll-item'>
                  {centeredElement ? (
                    <Product
                      product={product}
                      type={type}
                      new_user={type === 'cart' ? false : firstPurchasesDiscountApplies}
                      horizontalSlider={true}
                      centeredItem={Object.values(centeredElement)[1].children.props.product.id}
                      DisableButtonFunction={DisableButtonFunction}
                      categoryName={categoryName ? categoryName : (product && product.category && product.category.name)}
                    />
                  ) : (
                    <Product
                      product={product}
                      type={type}
                      new_user={type === 'cart' ? false : firstPurchasesDiscountApplies}
                      horizontalSlider={true}
                      centeredItem={null}
                      DisableButtonFunction={DisableButtonFunction}
                      categoryName={categoryName ? categoryName : (product && product.category && product.category.name)}
                    />
                  )}
                </Col>
            )}
        </div>
        {centeredElement && (
        <Row style={{height: '40%'}} className='border'>
          <Col className='d-flex justify-content-center align-items-center'>
            <ol style={{
              position: "absolute",
              bottom: "1px",
              display: "flex",
              justifyContent: "center",
              left: "45%",
              transform: "translateX(-50%)",
              // zIndex: "9"
            }}>
              {categoryProducts.slice(0, 45).map((product, index) =>
              <li key={product.id} style={{listStyle: "none", padding: "0 1px"}}>
                <div style={{
                  marginLeft: product.id === Object.values(centeredElement)[1].children.props.product.id && '2px',
                  marginRight: product.id === Object.values(centeredElement)[1].children.props.product.id && '2px'}}>
                  {product.id === Object.values(centeredElement)[1].children.props.product.id && (
                    <div style={{
                        position: 'absolute',
                        borderRadius: '50%',
                        backgroundColor: 'transparent',
                        marginLeft: '-2px',
                        marginTop: '-2px',
                        width: '10px',
                        height: '10px',
                        border: '1px solid black', // Ajusta el ancho y color del borde según tus preferencias
                        boxSizing: 'border-box',
                      }}
                    ></div>
                  )}
                  <a
                      // href={`#${product.id}/product_navigation/`}
                      style={{
                        display: "block",
                        height: "6px",
                        width: "6px",
                        border: "2px solid black",
                        background: product.id === Object.values(centeredElement)[1].children.props.product.id ? "black" : "transparent",
                        transform: product.id === Object.values(centeredElement)[1].children.props.product.id && 'scale(1.1)',
                        borderRadius: "100%"
                      }}
                    onClick={() => scrollToItem(index)}
                  ></a>
                </div>
              </li>
              )}
            </ol>
          </Col>
        </Row>
        )}
      </div>
    )
}
