import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalCancelOrder } from './ModalCancelOrder'


export const CancelOrderModalButton = () => {

    const [cancelOrderShow, setCancelOrderShow] = useState(false);

    const toggleCancelOrderModal = () => {
        setCancelOrderShow(!cancelOrderShow)
    }

    return (
        <div>
            <Button
                variant='outline-danger'
                className="btn-lg btn-block"
                style={{ width: "105%", marginLeft: "-0.5em", fontSize: '1.1em', border: '2px solid #F70D13' }}
                onClick={toggleCancelOrderModal}
            >
                Cancelar orden
            </Button>                                            
            <ModalCancelOrder isOpen={cancelOrderShow} toggle={setCancelOrderShow}/>
        </div>
    )
}
