import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { 
  productCategoryListReducer,
  productDetailReducer,
  categoryDetailReducer,
  productUpdateReducer,
  topProductsListReducer,
  topProductCategoryListReducer,
  productsToBuySendEmailReducer,
  productListReducer,
  productsHistoricInfoReducer,
  marketsReducer
 } from './reducers/productsReducers';
 import { 
  cartReducer,
  cartValuesReducer } from './reducers/cartReducers';
 import { 
   termsSectionsReducer,
   termsAndConditionsReducer,
   privacyPolicyReducer } from './reducers/appInfoReducers';
 import { 
   userDetailReducer, 
   userLoginReducer,
   userRegisterReducer,
   userChangePasswordReducer,
   userUpdateAddressReducer,
   userUpdatePersonalInfoReducer,
   hostPersonalCodeReducer,
   userMessageReducer,
   contactFormReducer,
   boletinContactReducer,
   ordersToBeBlockedReducer,
   ordersToNotifyReducer,
   orderDeliveryIssuesReducer,
   orderClientsIssuesReducer,
   todaySubscriptionOrdersReducer,
   nextWeekSubscriptionOrdersReducer,
   orderPaymentResponseReducer,
   subscriptionListReducer,
   pastCanceledOrdersReducer,
   tomorrowSubscriptionOrdersReducer,
   dayAfterTomorrowSubscriptionOrdersReducer,
   fourDaysFromTodaySubscriptionOrdersReducer,
   fiveDaysFromTodaySubscriptionOrdersReducer,
   sixDaysFromTodaySubscriptionOrdersReducer,
   sevenDaysFromTodaySubscriptionOrdersReducer,
   todayCartItemsReducer,
   tomorrowCartItemsReducer,
   dayAfterTomorrowCartItemsReducer,
   fourDaysFromTodayCartItemsReducer,
   fiveDaysFromTodayCartItemsReducer,
   sixDaysFromTodayCartItemsReducer,
   sevenDaysFromTodayCartItemsReducer,
   topUserListReducer,
   userOrdersListReducer,
   userLastOrderReducer,
   userPaymentMethodListReducer,
   resetPasswordPersonalCodeReducer,
   resetPasswordPersonalIdReducer,
   fundsReceivedReducer
} from './reducers/userReducers';
import { 
  nextWeekActiveSubscriptionOderDetailReducer,
  deliveredSubscriptionOderDetailReducer,
  BlockedDeliveredNotPaidSubscriptionOderDetailReducer,
  pendingPaymentSubscriptionOderDetailReducer,
  blockedForChangeSubscriptionOderDetailReducer,
  nextWeekActiveSubscriptionOderIdReducer,
  nextActiveSubscriptionOderDetailReducer, 
  updateCreatedSubscriptionOderProductReducer,
  updateCreatedSubscriptionOderStatusReducer,
  createOrderClientIssueReducer,
  createOrderDeliveryIssueReducer,
  completeOrderReducer,
  createOrderDeliveryIssueResponseReducer,
  createOrderClientIssueResponseReducer,
  updatePastOrderStatusReducer,
  getPastActiveOrdersReducer,
  sendOrdersNotificationsReducer,
  blockForChangesOrdersReducer,
  localOrderReducer,
  subscriptionOrderPaymentDetailReducer,
  todaySubscriptionOrdersMarkDeliveredReducer,
  getDeliveredOrdersReducer,
  updateMarkCompletedStatusReducer,
  getpPaymentLinkSentOrdersReducer,
  sendPaymentReminderReducer,
  updateSubscriptionOderDateReducer,
  getDeliveryOrdersReducer,
  getDeliveryOrdersStaffReducer
} from './reducers/orderReducers';
import {
  subscriptionDetailReducer,
  subscriptionUpdateReducer,
  frequencyDaysReducer,
  createFrequencyReducer,
  subscriptionUpdateStatusReducer,
  cancelSubscriptionReducer,
  subscriptionCheckActiveStatusReducer,
  updateFrequencyReducer,
  subscriptionPauseReducer,
  subscriptionModalReducer,
  localSubscriptionReducer,
  toggleCheckLocalSubscriptionReducer,
  SubscriptionIdReducer
} from './reducers/subscriptionReducers'
import { 
  createCardReducers,
  getDonationsReducer,
  removeCardReducers,
  changeSelectedPaymentMethoddReducers,
  platformBalanceChangeReducers,
  getNotBroadcastedDonationsReducer,
  broadcastDonationsReducer
} from './reducers/paymentsReducers';

const reducer = combineReducers({

    blockForChangesOrders: blockForChangesOrdersReducer,
    blockedForChangeSubscriptionOderDetail: blockedForChangeSubscriptionOderDetailReducer,

    boletinContact: boletinContactReducer,
    broadcastDonations: broadcastDonationsReducer,

    changeSelectedPaymentMethod: changeSelectedPaymentMethoddReducers,

    cart: cartReducer,
    cartValue: cartValuesReducer, 
    categoryDetail: categoryDetailReducer,
    contactForm: contactFormReducer,
    completeOrder: completeOrderReducer,
    createCard: createCardReducers,
    createFrequency: createFrequencyReducer,
    createOrderClientIssue: createOrderClientIssueReducer,
    createOrderClientIssueResponse: createOrderClientIssueResponseReducer,
    createOrderDeliveryIssue: createOrderDeliveryIssueReducer,
    createOrderDeliveryIssueResponse: createOrderDeliveryIssueResponseReducer,

    dayAfterTomorrowCartItems: dayAfterTomorrowCartItemsReducer,
    dayAfterTomorrowSubscriptionOrders: dayAfterTomorrowSubscriptionOrdersReducer,
    deliveredSubscriptionOderDetail: deliveredSubscriptionOderDetailReducer,

    fiveDaysFromTodayCartItems: fiveDaysFromTodayCartItemsReducer,
    fiveDaysFromTodaySubscriptionOrders: fiveDaysFromTodaySubscriptionOrdersReducer,
    fourDaysFromTodayCartItems: fourDaysFromTodayCartItemsReducer,
    fourDaysFromTodaySubscriptionOrders: fourDaysFromTodaySubscriptionOrdersReducer,
    frequencyDays: frequencyDaysReducer,
    fundsReceived: fundsReceivedReducer,

    getDeliveredOrders: getDeliveredOrdersReducer,
    getDeliveryOrders: getDeliveryOrdersReducer,
    getDeliveryOrdersStaff: getDeliveryOrdersStaffReducer,
    getpPaymentLinkSentOrders: getpPaymentLinkSentOrdersReducer,
    getDonations: getDonationsReducer,
    getPastActiveOrders: getPastActiveOrdersReducer,
    getNotBroadcastedDonations: getNotBroadcastedDonationsReducer,

    hostPersonalCode: hostPersonalCodeReducer,

    localOrder: localOrderReducer,
    localSubscription: localSubscriptionReducer,

    markets: marketsReducer,

    nextActiveSubscriptionOderDetail: nextActiveSubscriptionOderDetailReducer,
    nextWeekActiveSubscriptionOderId: nextWeekActiveSubscriptionOderIdReducer,
    nextWeekActiveSubscriptionOderDetail: nextWeekActiveSubscriptionOderDetailReducer,
    nextWeekSubscriptionOrders: nextWeekSubscriptionOrdersReducer,
    BlockedDeliveredNotPaidSubscriptionOderDetail: BlockedDeliveredNotPaidSubscriptionOderDetailReducer,

    orderDeliveryIssues: orderDeliveryIssuesReducer,
    orderClientsIssues: orderClientsIssuesReducer,
    orderPaymentResponse: orderPaymentResponseReducer,
    ordersToBeBlocked: ordersToBeBlockedReducer,
    ordersToNotify: ordersToNotifyReducer,

    pastCanceledOrders: pastCanceledOrdersReducer,
    pendingPaymentSubscriptionOderDetail: pendingPaymentSubscriptionOderDetailReducer,
    platformBalanceChange: platformBalanceChangeReducers,
    privacyPolicy: privacyPolicyReducer,
    productCategoryList: productCategoryListReducer,
    productDetail: productDetailReducer,
    productsHistoricInfo: productsHistoricInfoReducer,
    productList: productListReducer,
    productUpdate: productUpdateReducer,
    productsToBuySendEmail: productsToBuySendEmailReducer,

    removeCard: removeCardReducers,
    resetPasswordPersonalCode: resetPasswordPersonalCodeReducer,
    resetPasswordPersonalId: resetPasswordPersonalIdReducer,

    sendPaymentReminder: sendPaymentReminderReducer,
    sevenDaysFromTodayCartItems: sevenDaysFromTodayCartItemsReducer,
    sevenDaysFromTodaySubscriptionOrders: sevenDaysFromTodaySubscriptionOrdersReducer,
    sendOrdersNotifications: sendOrdersNotificationsReducer,

    sixDaysFromTodayCartItems: sixDaysFromTodayCartItemsReducer,
    sixDaysFromTodaySubscriptionOrders: sixDaysFromTodaySubscriptionOrdersReducer,
    subscriptionDetail: subscriptionDetailReducer,
    SubscriptionId: SubscriptionIdReducer,
    subscriptionOrderPaymentDetail: subscriptionOrderPaymentDetailReducer,
    subscriptionUpdate: subscriptionUpdateReducer,
    subscriptionUpdateStatus: subscriptionUpdateStatusReducer,
    subscriptionCancel: cancelSubscriptionReducer,
    subscriptionCheckActiveStatus: subscriptionCheckActiveStatusReducer,
    subscriptionList: subscriptionListReducer,
    subscriptionPause: subscriptionPauseReducer,
    subscriptionModal: subscriptionModalReducer,

    termsSections: termsSectionsReducer,
    termsAndConditions: termsAndConditionsReducer,

    todayCartItems: todayCartItemsReducer,
    todaySubscriptionOrders: todaySubscriptionOrdersReducer,
    todaySubscriptionOrdersMarkDelivered: todaySubscriptionOrdersMarkDeliveredReducer,
    toggleCheckLocalSubscription: toggleCheckLocalSubscriptionReducer,
    tomorrowCartItems: tomorrowCartItemsReducer,
    tomorrowSubscriptionOrders: tomorrowSubscriptionOrdersReducer,
    topUserList: topUserListReducer,
    topProductsList: topProductsListReducer,
    topProductCategoryList: topProductCategoryListReducer,

    updateCreatedSubscriptionOderProduct: updateCreatedSubscriptionOderProductReducer,
    updateCreatedSubscriptionOderStatus: updateCreatedSubscriptionOderStatusReducer,
    updateSubscriptionOderDate: updateSubscriptionOderDateReducer,
    updateFrequency: updateFrequencyReducer,
    updateMarkCompletedStatus: updateMarkCompletedStatusReducer,
    updatePastOrderStatus: updatePastOrderStatusReducer,
    userChangePassword: userChangePasswordReducer,
    userDetail: userDetailReducer,
    userPaymentMethodList: userPaymentMethodListReducer,
    userLastOrder: userLastOrderReducer,
    userLogin: userLoginReducer,
    userMessage: userMessageReducer,
    userOrdersList: userOrdersListReducer,
    userRegister: userRegisterReducer,
    userUpdateAddress: userUpdateAddressReducer,
    userUpdatePersonalInfo: userUpdatePersonalInfoReducer
  })

const cartItemsFromStorage = localStorage.getItem('cartItems') ?
    JSON.parse(localStorage.getItem('cartItems')) : []

const userLoginFromStorage = localStorage.getItem('userLogin') ?
    JSON.parse(localStorage.getItem('userLogin')) : null

// const userDetailFromStorage = localStorage.getItem('userDetail') ?
//     JSON.parse(localStorage.getItem('userDetail')) : null

// const nextWeekActiveSubscriptionOderDetailFromStorage = localStorage.getItem('nextWeekActiveSubscriptionOderDetail') ?
//     JSON.parse(localStorage.getItem('nextWeekActiveSubscriptionOderDetail')) : null

// const nextActiveSubscriptionOderDetailFromStorage = localStorage.getItem('nextActiveSubscriptionOderDetail') ?
//     JSON.parse(localStorage.getItem('nextActiveSubscriptionOderDetail')) : null

const subscriptionDetailFromStorage = localStorage.getItem('subscriptionDetail') ?
    JSON.parse(localStorage.getItem('subscriptionDetail')) : null

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage
    },
    userLogin: {
      user: userLoginFromStorage
    },
    userDetail: {
      // user: userDetailFromStorage,
      loading: false
    },
    nextWeekActiveSubscriptionOderDetail: {
      // nextWeekActiveOrder: nextWeekActiveSubscriptionOderDetailFromStorage,
      // nextWeekActiveOrder: null,
      loading: false
    },
    nextActiveSubscriptionOderDetail: {
      // nextActiveOrder: nextActiveSubscriptionOderDetailFromStorage,
      // nextActiveOrder: null,
      loading: false
    },
    subscriptionDetail: {
      subscription: subscriptionDetailFromStorage,
      loading: false
    },
  }

const middleware = [thunk]

const store = createStore(reducer, initialState,
  composeWithDevTools(applyMiddleware(...middleware)))

export default store