import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Alert, Image, Button } from 'react-bootstrap'
import Loader from '../../../Loader'
import { SubscriptionProducts } from './SubscriptionProducts'
import { CreditCardModalButton } from '../AccountInfo/CreditCardModalButton'
import { FrecuencyForm } from '../FrecuencyForm'
import LogoFiambre from '../../../../images/logoFiambre-Inicio.png'
import ReactPixel from 'react-facebook-pixel';
import { 
    localSubscriptionAction,
    subscriptionUpdateStatusAction,
    subscriptionCheckActiveStatusAction} from '../../../../actions/subscriptionActions'


const { REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE,
    REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT } = process.env

export const PendingTokenSubscriptionSection = () => {

    const dispatch = useDispatch()

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { error:subscriptionDetailError, loading:subscriptionDetailLoading, subscription } = detailSubscription

    const subscriptionLocal = useSelector(state => state.localSubscription)
    const { localProductsItems: localSubscriptionProductsItems, updatedFromServer: updatedSubscriptionFromServer, localSubscriptionStatus, deliverPeriod: localSubscriptionDeliverPeriod } = subscriptionLocal

    const updateStatusSubscription = useSelector(state => state.subscriptionUpdateStatus)
    const { loading: updateStatusLoading, success: updateStatusSuccess } = updateStatusSubscription

    const activateSubscription = () => {
        dispatch(subscriptionUpdateStatusAction(subscription.id, 4))
        dispatch(subscriptionCheckActiveStatusAction())
        if (window.location.origin.includes('localhost') === false && window.location.origin.includes('qa') === false) {
            ReactPixel.track('ActiveSubscription');
        }
    }

    function subscriptionDiscoutValue(cartItemsArray) {
        const itemsCost = SubscriptionCartItemsValue(cartItemsArray)
        const productsDiscount = itemsCost * REACT_APP_FIRSTS_PURCHASES_DISCOUNT_PERCENTAGE
        if (productsDiscount > REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT) {
            return REACT_APP_FIRSTS_PURCHASES_MAX_DISCOUNT
        }
        return productsDiscount
    }

    function SubscriptionCartItemsValue(cartItemsArray) {
        const initialValue = 0;
        const itemsCost = cartItemsArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total_value,
        initialValue,
        );
        return itemsCost
    }

    useEffect(() => {
        if(subscription && subscription.status.name === 'Pending token'){
            if (updatedSubscriptionFromServer === false && subscription.products_items && subscriptionDetailLoading === false) {
                for(var i = 0; i < subscription.products_items.length; i++) {
                    dispatch(localSubscriptionAction(
                        subscription.products_items[i].products,
                        subscription.products_items[i].amount,
                        subscription.products_items[i].total_value,
                        true,
                        subscription.status.name,
                        subscription.frecuency.deliver_period))
                }
            }
        }
    }, [])

    return (
    <div>
        {subscription.products_items.length > 0 ? (
            <div>
                {subscription.frecuency.deliver_period === null ? (
                    <div>
                        <h4 className='mt-1 text-success' style={{ fontSize: '1.4em'}}><i className="far fa-calendar-alt"></i> Frecuencia de entrega (Paso 2 de 3)</h4>
                        <hr className='border border-secondary my-0'></hr>
                        {
                            <Alert variant='primary' className='mt-3'>
                                Descuento: {new Intl.NumberFormat('co-CO').format(Number(subscriptionDiscoutValue(localSubscriptionProductsItems)))} pesos
                            </Alert>
                        }
                        <Row className='p-3'>
                            <Col>
                            <FrecuencyForm create={true}/>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <div>
                        {updateStatusLoading ? (
                            <div>
                                <Loader/>
                            </div>
                        ) : (
                            <div>
                                <h4 className='mt-3 text-success'><i className="fas fa-credit-card mb-0"></i> Elige la forma de pago (Último paso)</h4>
                                <hr className='border border-secondary'></hr>
                                <Row>
                                    <Col>
                                        <Image src={LogoFiambre} className="img-fluid" alt="Responsive image" />
                                        <p className='mb-0'>La Fundación Fiambre da alimentos en los altos de Cazucá <small><a href="https://fundacionfiambre.org/" target="_blank" rel='noreferrer'>Más información</a></small></p>
                                        <p>Verdemi donará el 5% de tus compra a Fiambre para la adquisición de alimentos.</p>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <CreditCardModalButton />
                                    </Col>

                                    <Col xs={12} lg={6} className='mb-3'>
                                        <Button variant='outline-info' className="my-3 btn-block btn-lg h-100" onClick={activateSubscription}>
                                            <p className='m-0 p-0'><i className="fas fa-link"></i> Pago contra entrega manual</p>
                                            <p className='m-n1 p-0'><small><small>Después de entregar cada mercado recibirás un link de pago (PSE, Nequi, tarjeta de crédito/débito)</small></small></p>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </div>
                )}
            </div>
        ) : (
            <div></div>
        )}
        <SubscriptionProducts subscription={subscription}/>
    </div>

    )
}
