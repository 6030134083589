import React, { useState } from 'react';
import { 
  Row,
} from 'react-bootstrap'

const ThreeSpheres = ({step}) => {
  const [numbers] = useState([1, 2, 3]);

  return (
    <div>
      <Row className='d-flex justify-content-center align-items-center'>
      <div
        className="three-spheres-container"
        style={{ display: 'flex', alignItems: 'center'}}
      >
          <div className="sphere" style={{ backgroundColor: step && '#2C971F' }}>
            <div className="number" style={{ color: step ? 'white' : '#B3B3B3' }}>{numbers[0]}</div>
          </div>
          <div className="connector" style={{ backgroundColor: step && (step === 2 || step === 3) && '#2C971F' }}></div>
          <div className="sphere" style={{ backgroundColor: step && (step === 2 || step === 3) && '#2C971F' }}>
            <div className="number" style={{ color: step && (step === 2 || step === 3) ? 'white' :'#B3B3B3' }}>{numbers[1]}</div>
          </div>
          <div className="connector" style={{ backgroundColor: step && step === 3 && '#2C971F' }}></div>
          <div className="sphere" style={{ backgroundColor: step && step === 3 && '#2C971F' }}>
            <div className="number" style={{ color: step && step === 3 ? 'white' :'#B3B3B3' }}>{numbers[2]}</div>
          </div>        
      </div>
      </Row>
    </div>
  );
};

export default ThreeSpheres;
