import React from 'react'
import { DeliveredOrderInfo } from './DeliveredOrderInfo'
import { PendingPaymentOrderInfo } from './PendingPaymentOrderInfo'
import { BlockedForChangesOrderInfo } from './BlockedForChangesOrderInfo'



export const BlockedDeliveredNotPaidOrderSection = ( props ) => {


    return (    
        <div>
            {props.order.status.name == 'Delivered' ? (
                <div>
                    <DeliveredOrderInfo order={props.order}/>
                </div>
            ) : (
                props.order.status.name == 'Payment link sent' || props.order.status.name == 'Payment rejected' ? (
                    <div>
                        <PendingPaymentOrderInfo order={props.order} />
                    </div>
                ) : (
                    <div>
                        <BlockedForChangesOrderInfo order={props.order} />
                    </div>
                )
            )}

        </div>
    )
}
