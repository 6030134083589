import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalVerdemiBalanceChanges } from './ModalVerdemiBalanceChanges';


export const VerdemiBalanceChangesModalButton = () => {

    const [verdemiBalanceChangesShow, setVerdemiBalanceChangesShow] = useState(false);
    const toggleVerdemiBalanceChangesModal = () => {
        setVerdemiBalanceChangesShow(!verdemiBalanceChangesShow)
    } 


    return (
        <div>
            <Button type="button" className="btn-block btn-sm my-1" onClick={toggleVerdemiBalanceChangesModal} style={{ backgroundColor: '#29BC5A', border: '0px' }}>
                <i className="fa fa-percent"></i> Cambios de Balance en Verdemi
            </Button>

            <ModalVerdemiBalanceChanges isOpen={verdemiBalanceChangesShow} toggle={toggleVerdemiBalanceChangesModal}/> 
        </div>
    )
}
