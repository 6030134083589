import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalCancelSubscription } from '../modals/ModalCancelSubscription'


export const CancelSubscriptionModalButton = () => {

    const [cancelSubscriptionShow, setCancelSubscriptionShow] = useState(false);
    const toggleCancelSubscriptionModal = () => {
        setCancelSubscriptionShow(!cancelSubscriptionShow)
    }


    return (
        <div>                                    
            <Button type="button" className="btn btn-danger btn-sm btn-block mt-1" onClick={toggleCancelSubscriptionModal}>
                <i className="far fa-window-close"></i> Cancelar mi subscripción
            </Button>  
            {cancelSubscriptionShow ? (
                <ModalCancelSubscription isOpen={cancelSubscriptionShow} toggle={setCancelSubscriptionShow}/>
            ) : (
                <div></div>
            )}
        </div>
    )
}
