import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Row, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap'
import { ModalCreditCard } from '../../ModalCreditCard'
import { userPaymentMethodListAction, userMessageAction } from '../../../../../actions/userActions'
import { changePaymentMethodAction } from '../../../../../actions/paymentsActions'
import { ModalConfirmDeletePaymentMethod } from './ModalConfirmDeletePaymentMethod'
import { CHANGE_PAYMENT_METHOD_RESET } from '../../../../../constants/paymentsConstants'
import Message from '../../../../Message' 

export const ModalPaymentMethods = (props) => {

    const [lastFourDigits, setLastFourDigits] = useState('')
    const [franchise, setFranchise] = useState('')

    const dispatch = useDispatch()

    const cardCreate = useSelector(state => state.createCard)
    const { success } = cardCreate

    const [changeCardInfoShow, setChangeCardInfoShow] = useState(false);

    const toggleCreditCardInfoModal = () => {
        setChangeCardInfoShow(!changeCardInfoShow)
    }

    const paymentMethodList = useSelector(state => state.userPaymentMethodList)
    const { loading: loadingPayment, paymentMethod, error: errorPaymentMethod } = paymentMethodList

    const cardRemove = useSelector(state => state.removeCard)
    const { success: cardRemovedSucces } = cardRemove

    const messageUser = useSelector(state => state.userMessage)
    const {message: theMessage, display: showMessage } = messageUser

    const selectedPaymentMethodChange = useSelector(state => state.changeSelectedPaymentMethod)
    const {loading: changePaymentMethodLoading, success: changePaymentMethodSuccess  } = selectedPaymentMethodChange

    const[display, setDisplay] = useState(showMessage)

    useEffect(() => {

        if(loadingPayment === false && paymentMethod === null && !errorPaymentMethod) {
            dispatch(userPaymentMethodListAction())
        }

        if (success) {
            setChangeCardInfoShow(false)
        }

        if (cardRemovedSucces) {
            setconfirmDeletePaymenteMethodShow(false)
            dispatch(userMessageAction('Eliminado método de pago seleccionado'))
            if(loadingPayment === false && !errorPaymentMethod) {
                dispatch(userPaymentMethodListAction())
            }
        }

        if (theMessage) {
            setDisplay(showMessage)
            setTimeout(() => setDisplay(false), 6000);
        }

        if (changePaymentMethodSuccess) {
            dispatch(userPaymentMethodListAction())
            dispatch({
                type: CHANGE_PAYMENT_METHOD_RESET
            })
            dispatch(userMessageAction('Cambiado método de pago seleccionado'))
        }

    }, [
        dispatch,
        loadingPayment,
        paymentMethod,
        errorPaymentMethod,
        success,
        cardRemovedSucces,
        theMessage,
        showMessage,
        changePaymentMethodSuccess
    ])

    const [confirmDeletePaymenteMethodShow, setconfirmDeletePaymenteMethodShow] = useState(false);
    const toggleConfirmDeletePaymentMethodModal = (lastFourDigits, franchise) => {
        setLastFourDigits(lastFourDigits)
        setFranchise(franchise)
        setconfirmDeletePaymenteMethodShow(!confirmDeletePaymenteMethodShow)
    }

    
    const tooltipNotChoosed = (
        <Tooltip id="tooltip">
          Seleccionar como método de pago
        </Tooltip>
      );

    const tooltipChoosed = (
    <Tooltip id="tooltip">
        Método de pago elegido
    </Tooltip>
    );

    const changePaymentMethod = (last_four_digits, credit_card_franchise) => {
        dispatch(changePaymentMethodAction(last_four_digits, credit_card_franchise))
    }


    return (
        <div>
            <Modal show={props.isOpen} onHide={props.toggle} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Métodos de pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {display && theMessage && (
                    <Message variant='info'>{theMessage}</Message>
                )}
                {changePaymentMethodLoading || loadingPayment ? (
                    <Spinner
                    animation='border'
                    role='status'
                    style={{
                        height:'25px',
                        width:'25px',
                        margin:'auto',
                        display:'block'
                    }}
                ></Spinner>                                    
                ) : (
                    <div>
                    {paymentMethod !== null ? (
                        paymentMethod.map(item => (
                            <Row>
                                <span className='my-1 ml-3'>**** **** **** {item.last_four_digits} - {item.credit_card_franchise} - {item.selected ? (
                                        <OverlayTrigger placement="right" overlay={tooltipChoosed}>
                                            <i className="fas fa-check ml-3"></i>
                                        </OverlayTrigger>
                                    ) : (
                                        <OverlayTrigger placement="left" overlay={tooltipNotChoosed}>
                                            <Button variant="link" className='px-0' onClick={() =>changePaymentMethod(item.last_four_digits, item.credit_card_franchise)}>
                                                <i className="far fa-check-circle ml-3"></i>
                                            </Button>
                                        </OverlayTrigger>
                                    )}
                                </span>
                                <span className='ml-3'>
                                    <Button variant='link' className='btn-block text-danger' onClick={() => toggleConfirmDeletePaymentMethodModal(item.last_four_digits, item.credit_card_franchise)}><i className="fas fa-trash text-danger"></i> Eliminar medio de pago</Button>
                                </span>
                            </Row>
                        ))
                    ) : (
                        <div></div>
                    )}
                    <span style={{ float: 'left'}}>
                        <Button variant='link' className='btn-block' onClick={toggleCreditCardInfoModal}><i className="fas fa-credit-card"></i> Incluir nuevo método de pago</Button>                                    
                    </span>                        
                    </div>
                )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.toggle}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalConfirmDeletePaymentMethod isOpen={confirmDeletePaymenteMethodShow} toggle={toggleConfirmDeletePaymentMethodModal} lastFourDigits={lastFourDigits} franchise={franchise}/>
            <ModalCreditCard isOpen={changeCardInfoShow} toggle={setChangeCardInfoShow} />                                            
        </div>
    )
}
