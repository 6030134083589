import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tab, Nav } from 'react-bootstrap'
import UserDelivery from '../UserDelivery'

import {
    getDeliveryOrdersAction } from '../../../actions/orderActions'


export const DeliveryOrder = ( props ) => {
    
    const dispatch = useDispatch()

    const detailUser = useSelector(state => state.userDetail)
    const { user } = detailUser

    const ordersDelivery = useSelector(state => state.getDeliveryOrders)
    const { error: errorDeliveryOrders, deliveryOrders, loading: loadingDeliveryOrders } = ordersDelivery

    const [showDeliveryInfoToUser, setShowDeliveryInfoToUser] = useState(false)

    const [activeTab, setActiveTab] = useState('OrderSubscription');

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription


    useEffect(() => {
        if (user && !deliveryOrders && (loadingDeliveryOrders === false || !loadingDeliveryOrders) && !errorDeliveryOrders) {
            dispatch(getDeliveryOrdersAction())
        }
        if (subscription && subscription.status.name == 'Active') {
            if (props && props.isDelivery) {
                if (deliveryOrders && deliveryOrders.length > 0) {
                    props.isDelivery(true)
                }
            }    
        }
    }, [
        deliveryOrders,
        loadingDeliveryOrders,
        errorDeliveryOrders,
        subscription,
    ])

    useEffect(() => {
        if (deliveryOrders && deliveryOrders.length > 0) {
            if (user) {
                const includedInDelivery = deliveryOrders.filter(deliveryOrder => deliveryOrder.subscription.user.validated_address === user.validated_address)
                if (includedInDelivery.length > 0) {
                    setActiveTab("DeliveryInformation")
                    setShowDeliveryInfoToUser(true)
                }
    
            }    
        }
    }, [deliveryOrders, user])

    if (props.type === 'nav') {
        return (
            <div>
            {deliveryOrders && deliveryOrders.length > 0 && showDeliveryInfoToUser &&  (
                <Nav.Item>
                    <Nav.Link eventKey="DeliveryInformation" style={{ fontSize: "13px"}}><i className="fas fa-location-arrow"></i> Seguimiento entrega de hoy</Nav.Link>
                </Nav.Item>
            )}
        </div>
        )
    }

    if (props.type === 'tab') {
        return (
            <div>
            {deliveryOrders && deliveryOrders.length > 0 && showDeliveryInfoToUser && (
                <UserDelivery />
            )}
        </div>
)
    }

}
