import axios from "axios";

import {
    SUBSCRIPTION_ORDER_DETAIL_REQUEST,
    SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    SUBSCRIPTION_ORDER_DETAIL_FAILS,

    SUBSCRIPTION_ORDER_ID_REQUEST,
    SUBSCRIPTION_ORDER_ID_SUCCESS,
    SUBSCRIPTION_ORDER_ID_FAILS,

    SUBSCRIPTION_ORDER_UPDATE_PRODUCT_FAILS,
    SUBSCRIPTION_ORDER_UPDATE_PRODUCT_SUCCESS,
    SUBSCRIPTION_ORDER_UPDATE_PRODUCT_REQUEST ,

    SUBSCRIPTION_ORDER_UPDATE_STATUS_FAILS,
    SUBSCRIPTION_ORDER_UPDATE_STATUS_REQUEST,
    SUBSCRIPTION_ORDER_UPDATE_STATUS_SUCCESS,

    NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_FAILS,
    NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_REQUEST,

    CREATE_DELIVERY_ISSUE_REQUEST,
    CREATE_DELIVERY_ISSUE_SUCCESS,
    CREATE_DELIVERY_ISSUE_FAILS,

    COMPLETE_SUBSCRIPTION_ORDER_REQUEST,
    COMPLETE_SUBSCRIPTION_ORDER_SUCCESS,
    COMPLETE_SUBSCRIPTION_ORDER_FAILS,

    CREATE_CLIENT_ISSUE_REQUEST,
    CREATE_CLIENT_ISSUE_SUCCESS,
    CREATE_CLIENT_ISSUE_FAILS,

    CREATE_DELIVERY_ISSUE_RESPONSE_REQUEST,
    CREATE_DELIVERY_ISSUE_RESPONSE_SUCCESS,
    CREATE_DELIVERY_ISSUE_RESPONSE_FAILS,

    CREATE_CLIENT_ISSUE_RESPONSE_REQUEST,
    CREATE_CLIENT_ISSUE_RESPONSE_SUCCESS,
    CREATE_CLIENT_ISSUE_RESPONSE_FAILS,

    UPDATE_PAST_ORDERS_STATUS_REQUEST,
    UPDATE_PAST_ORDERS_STATUS_SUCCESS,
    UPDATE_PAST_ORDERS_STATUS_FAILS,

    GET_PAST_ACTIVE_ORDERS_REQUEST,
    GET_PAST_ACTIVE_ORDERS_SUCCESS,
    GET_PAST_ACTIVE_ORDERS_FAILS,

    SEND_ORDERS_NOTIFICATIONS_REQUEST, 
    SEND_ORDERS_NOTIFICATIONS_SUCCESS,
    SEND_ORDERS_NOTIFICATIONS_FAILS,

    LOCAL_ORDER_CHANGE_AMOUNT_ITEM,

    REMOVE_LOCAL_ORDER,

    SUBSCRIPTION_ORDER_PAYMENT_DETAIL_REQUEST,
    SUBSCRIPTION_ORDER_PAYMENT_DETAIL_SUCCESS,
    SUBSCRIPTION_ORDER_PAYMENT_DETAIL_FAILS,

    MARK_DELIVERED_TODAY_ORDERS_REQUEST,
    MARK_DELIVERED_TODAY_ORDERS_FAILS,
    MARK_DELIVERED_TODAY_ORDERS_SUCCESS,

    DELIVERED_SUBSCRIPTION_ORDERS_REQUEST,
    DELIVERED_SUBSCRIPTION_ORDERS_SUCCESS,
    DELIVERED_SUBSCRIPTION_ORDERS_FAILS,

    MARK_COMPLETED_ORDERS_STATUS_REQUEST,
    MARK_COMPLETED_ORDERS_STATUS_SUCCESS,
    MARK_COMPLETED_ORDERS_STATUS_FAILS,

    DELIVERED_SUBSCRIPTION_ORDER_DETAIL_REQUEST,
    DELIVERED_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    DELIVERED_SUBSCRIPTION_ORDER_DETAIL_FAILS,

    PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_REQUEST,
    PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_FAILS,

    PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_REQUEST,
    PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_SUCCESS,
    PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_FAILS,

    PAYMENT_REMINDER_REQUEST,
    PAYMENT_REMINDER_SUCCESS,
    PAYMENT_REMINDER_FAILS,

    SUBSCRIPTION_ORDER_UPDATE_DATE_REQUEST,
    SUBSCRIPTION_ORDER_UPDATE_DATE_SUCCESS,
    SUBSCRIPTION_ORDER_UPDATE_DATE_RESET,

    GET_DELIVERY_ORDERS_REQUEST,
    GET_DELIVERY_ORDERS_SUCCESS,
    GET_DELIVERY_ORDERS_FAILS,

    BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_REQUEST,
    BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_FAILS,

    BLOCK_FOR_CHANGES_ORDERS_REQUEST,
    BLOCK_FOR_CHANGES_ORDERS_SUCCESS,
    BLOCK_FOR_CHANGES_ORDERS_FAILS,
    
    GET_DELIVERY_ORDERS_REQUEST_STAFF,
    GET_DELIVERY_ORDERS_SUCCESS_STAFF,
    GET_DELIVERY_ORDERS_FAILS_STAFF,

    BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_REQUEST,
    BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_FAILS,
    BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_RESET

} from "../constants/orderConstants";

export const nextWeekSubscriptionOrderDetailAction = () => async (dispatch, getState) => {

    try {
        dispatch({
            type: SUBSCRIPTION_ORDER_DETAIL_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/next_week_subscription_order/', config)

        const { data } = res
       
        dispatch({
            type: SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
            payload: data
        })

        localStorage.setItem('nextWeekActiveSubscriptionOderDetail', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_ORDER_DETAIL_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                       
    }
}

export const deliveredSubscriptionOrderDetailAction = () => async (dispatch, getState) => {

    try {
        dispatch({
            type: DELIVERED_SUBSCRIPTION_ORDER_DETAIL_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/delivered_subscription_order/', config)

        const { data } = res
       
        dispatch({
            type: DELIVERED_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
            payload: data
        })

        localStorage.setItem('nextWeekActiveSubscriptionOderDetail', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: DELIVERED_SUBSCRIPTION_ORDER_DETAIL_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                       
    }
}


export const BlockedDeliveredNotPaidSubscriptionOrderDetailAction = () => async (dispatch, getState) => {

    try {
        dispatch({
            type: BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/blocked_delivered_not_paid_subscription_order/', config)
        
        const { data } = res
       
        dispatch({
            type: BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
            payload: data
        })

        localStorage.setItem('nextWeekActiveSubscriptionOderDetail', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: BLOCKED_DELIVERED_NOT_PAID_SUBSCRIPTION_ORDER_DETAIL_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                       
    }
}


export const pendingPaymentSubscriptionOrderDetailAction = () => async (dispatch, getState) => {

    try {
        dispatch({
            type: PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/pending_payment_subscription_order/', config)

        const { data } = res
       
        dispatch({
            type: PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
            payload: data
        })

        // localStorage.setItem('nextWeekActiveSubscriptionOderDetail', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: PENDING_PAYMENT_SUBSCRIPTION_ORDER_DETAIL_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                       
    }
}

export const blockedForChangeSubscriptionOrderDetailAction = () => async (dispatch, getState) => {

    try {
        dispatch({
            type: BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/blocked_for_change_subscription_order/', config)

        const { data } = res
       
        dispatch({
            type: BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
            payload: data
        })

        // localStorage.setItem('nextWeekActiveSubscriptionOderDetail', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: BLOCKED_FOR_CHANGE_SUBSCRIPTION_ORDER_DETAIL_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                       
    }
}

export const nextWeekSubscriptionOrderIdAction = () => async (dispatch, getState) => {

    try {
        dispatch({
            type: SUBSCRIPTION_ORDER_ID_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/next_week_subscription_orders_id/', config)

        const { data } = res
       
        dispatch({
            type: SUBSCRIPTION_ORDER_ID_SUCCESS,
            payload: data
        })

        // localStorage.setItem('nextWeekActiveSubscriptionOderDetail', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_ORDER_ID_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                       
    }
}

export const nextActiveSubscriptionOrderDetailAction = () => async (dispatch, getState) => {

    try {
        dispatch({
            type: NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
       
        const res = await axios.get('/api/plans/next_active_subscription_order/', config)

        const { data } = res
       
        dispatch({
            type: NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
            payload: data
        })

        localStorage.setItem('nextActiveSubscriptionOderDetail', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: NEXT_ACTIVE_SUBSCRIPTION_ORDER_DETAIL_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                       
    }
}

export const createdOrderChangeAmountItemAction = (order_pk, product_pk, qty) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SUBSCRIPTION_ORDER_UPDATE_PRODUCT_REQUEST,
            payload: product_pk
        })
        
        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.put('/api/plans/update_order_products/', 
            {'order_pk': order_pk, 'product_pk': product_pk, 'qty': qty}, config)

        const { data } = res

        dispatch({
            type: SUBSCRIPTION_ORDER_UPDATE_PRODUCT_SUCCESS,
            payload: data
        })        


    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_ORDER_UPDATE_PRODUCT_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                       
    }
}

export const updateCreatedSubscriptionOderStatusAction = (order_pk, status) => async (dispatch, getState) => {
    try {
    
        dispatch({
            type: SUBSCRIPTION_ORDER_UPDATE_STATUS_REQUEST
        })
        
        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.put('/api/plans/update_subscription_order_status/', 
        { order_pk, status }, config)

        const { data } = res

        dispatch({
            type: SUBSCRIPTION_ORDER_UPDATE_STATUS_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_ORDER_UPDATE_STATUS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                              
    }
}

export const updateSubscriptionOderDateAction = (order_pk, date) => async (dispatch, getState) => {
    try {

        dispatch({
            type: SUBSCRIPTION_ORDER_UPDATE_DATE_REQUEST
        })
        
        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.put('/api/plans/update_subscription_order_date/', 
        { order_pk, date }, config)

        const { data } = res

        dispatch({
            type: SUBSCRIPTION_ORDER_UPDATE_DATE_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_ORDER_UPDATE_DATE_RESET,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                              
    }
}

export const createOrderDeliveryIssueAction = (subscription_order, description) => async (dispatch, getState) => {
    try {
        dispatch({ type: CREATE_DELIVERY_ISSUE_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.post('/api/plans/create_delivery_issue/', 
        { subscription_order, description }, config)

        const { data } = res

        dispatch({
            type: CREATE_DELIVERY_ISSUE_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: CREATE_DELIVERY_ISSUE_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}

export const completeOrderAction = (pk) => async (dispatch, getState) => {
    try {
        dispatch({ type: COMPLETE_SUBSCRIPTION_ORDER_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.put('/api/plans/complete_order/', 
        { pk }, config)

        const { data } = res

        dispatch({
            type: COMPLETE_SUBSCRIPTION_ORDER_SUCCESS,
            payload: data
        })                
    } catch (error) {
        dispatch({
            type: COMPLETE_SUBSCRIPTION_ORDER_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                              
    }
}

export const createOrderClientIssueAction = (subscription_order, description) => async (dispatch, getState) => {
    try {
        dispatch({ type: CREATE_CLIENT_ISSUE_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
       
        const res = await axios.post('/api/plans/create_client_issue/', 
        { subscription_order, description }, config)

        const { data } = res

        dispatch({
            type: CREATE_CLIENT_ISSUE_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: CREATE_CLIENT_ISSUE_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}

export const createOrderDeliveryIssueResponseAction = (subscription_order_issue, response) => async (dispatch, getState) => {
    try {
        dispatch({ type: CREATE_DELIVERY_ISSUE_RESPONSE_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.post('/api/plans/create_delivery_issue_response/', 
        { subscription_order_issue, response }, config)

        const { data } = res

        dispatch({
            type: CREATE_DELIVERY_ISSUE_RESPONSE_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: CREATE_DELIVERY_ISSUE_RESPONSE_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}

export const createOrderClientIssueResponseAction = (subscription_order_issue, response) => async (dispatch, getState) => {
    try {
        dispatch({ type: CREATE_CLIENT_ISSUE_RESPONSE_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.post('/api/plans/create_client_issue_response/', 
        { subscription_order_issue, response }, config)

        const { data } = res

        dispatch({
            type: CREATE_CLIENT_ISSUE_RESPONSE_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: CREATE_CLIENT_ISSUE_RESPONSE_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}

export const updatePastOrderStatusAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: UPDATE_PAST_ORDERS_STATUS_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/plans/update_past_orders/', config)

        const { data } = res

        dispatch({
            type: UPDATE_PAST_ORDERS_STATUS_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: UPDATE_PAST_ORDERS_STATUS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}

export const todaySubscriptionOrdersMarkCompletedAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: MARK_DELIVERED_TODAY_ORDERS_REQUEST
        })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }

        const res = await axios.get('/api/plans/today_subscription_orders_mark_delivered/', config)

        const { data } = res

        dispatch({
            type: MARK_DELIVERED_TODAY_ORDERS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: MARK_DELIVERED_TODAY_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })        
    }
}

export const updateMarkCompletedStatusAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MARK_COMPLETED_ORDERS_STATUS_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/plans/mark_completed_subscription_orders/', config)

        const { data } = res

        dispatch({
            type: MARK_COMPLETED_ORDERS_STATUS_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: MARK_COMPLETED_ORDERS_STATUS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}


export const getPastActiveOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_PAST_ACTIVE_ORDERS_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/plans/past_active_orders/', config)

        const { data } = res

        dispatch({
            type: GET_PAST_ACTIVE_ORDERS_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: GET_PAST_ACTIVE_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}

export const getDeliveredOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: DELIVERED_SUBSCRIPTION_ORDERS_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/plans/delivered_orders/', config)

        const { data } = res

        dispatch({
            type: DELIVERED_SUBSCRIPTION_ORDERS_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: DELIVERED_SUBSCRIPTION_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}

export const getPaymentLinkSentOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/plans/pending_payment_orders/', config)

        const { data } = res

        dispatch({
            type: PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: PAYMENT_LINK_SENT_SUBSCRIPTION_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}

export const sendOrdersNotificationsAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SEND_ORDERS_NOTIFICATIONS_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/plans/send_notifications_tomorrow_subscriptions/', config)

        const { data } = res

        dispatch({
            type: SEND_ORDERS_NOTIFICATIONS_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: SEND_ORDERS_NOTIFICATIONS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}

export const blockForChangesOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: BLOCK_FOR_CHANGES_ORDERS_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.put('/api/plans/block_for_changes_tomorrow_orders/', {}, config)

        const { data } = res

        dispatch({
            type: BLOCK_FOR_CHANGES_ORDERS_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: BLOCK_FOR_CHANGES_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}


export const localOrderAction = (products, amount, total_value, status) => (dispatch, getState) =>{
    try {
        dispatch({
            type: LOCAL_ORDER_CHANGE_AMOUNT_ITEM,
            payload: {products, amount, total_value, status}
        })        
    } catch (error) {
        console.log(error)
    }
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeLocalOrderAction = () => (dispatch) => {
    try {
        dispatch({
            type: REMOVE_LOCAL_ORDER
        })
    } catch (error) {
        console.log(error)        
    }
    localStorage.removeItem('cartItems')
}

export const checkLocalOrderValueAction = () => (dispatch, getState) => {
    try {

        const {
            localOrder: { localProductsItems }
        } = getState()
        
        const {
            nextWeekActiveSubscriptionOderDetail: { nextWeekActiveOrder }
        } = getState()

        if(localProductsItems && nextWeekActiveOrder){
            const localSumWithInitial = localProductsItems.reduce((acc, item) => 
            acc + (Number(item.total_value)), 0
            )
            const backSumWithInitial = nextWeekActiveOrder.products_items.reduce((acc, item) => 
            acc + (Number(item.total_value)), 0
            )
            if (localSumWithInitial !== backSumWithInitial) {
                dispatch({
                    type: REMOVE_LOCAL_ORDER
                })
            }       
        }
        
    } catch (error) {
        console.log(error)                
    }
}

export const localOrderFromLocalSubscriptionAction = () => (dispatch, getState) => {
    try {

        const {
            localSubscription: { localProductsItems }
        } = getState()

        if (localProductsItems) {
            for(var i = 0; i < localProductsItems.length; i++) {
                dispatch(localOrderAction(localProductsItems[i].products, localProductsItems[i].amount, localProductsItems[i].total_value, true))
            }
        }
        
    } catch (error) {
        console.log(error)        
    }
}

export const subscriptionOrderPaymentDetailAction = (pk) => async (dispatch) => {

    try {
        dispatch({
            type: SUBSCRIPTION_ORDER_PAYMENT_DETAIL_REQUEST
        })

        const res = await axios.get(`/api/plans/subscription_order_payment_detail/${pk}`)

        const { data } = res
       
        dispatch({
            type: SUBSCRIPTION_ORDER_PAYMENT_DETAIL_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_ORDER_PAYMENT_DETAIL_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                       
    }
}

export const sendPaymentReminderAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: PAYMENT_REMINDER_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/plans/send_payment_reminder/', config)

        const { data } = res

        dispatch({
            type: PAYMENT_REMINDER_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: PAYMENT_REMINDER_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}


export const getDeliveryOrdersAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_DELIVERY_ORDERS_REQUEST })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/plans/created_delivered_orders/', config)

        const { data } = res

        dispatch({
            type: GET_DELIVERY_ORDERS_SUCCESS,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: GET_DELIVERY_ORDERS_FAILS,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}


export const getDeliveryOrdersStaffAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_DELIVERY_ORDERS_REQUEST_STAFF })

        const {
            userLogin: { user }
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        
        const res = await axios.get('/api/plans/created_delivered_orders_staff/', config)

        const { data } = res

        dispatch({
            type: GET_DELIVERY_ORDERS_SUCCESS_STAFF,
            payload: data
        })        

    } catch (error) {
        dispatch({
            type: GET_DELIVERY_ORDERS_FAILS_STAFF,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message        
        })                                      
    }
}
