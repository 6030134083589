import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { userOrdersListAction } from '../../../../../actions/userActions'
import Loader from '../../../../Loader'
import Message from '../../../../Message' 
import { FORMER_ORDERS_COLUMNS } from '../../FormerOrdersColumns'
import { DisplayInfoTable } from '../../../../DisplayInfoTable'


export const PastOrders = () => {

    const dispatch = useDispatch()

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    const listUserOrders = useSelector(state => state.userOrdersList)
    const { loading, order_list, error } = listUserOrders

    return (
        <div>
            {loading ? (<Loader />) : (
                <div>
                    {error ? (
                        <div>
                            <Message variant='danger'>{error}</Message>
                        </div>
                    ) : (
                        <div>
                            {order_list && order_list.length > 0 ? (
                                <div>
                                    <DisplayInfoTable rows={order_list} columns={FORMER_ORDERS_COLUMNS} staff_table={false}/>
                                </div>
                            ) : (
                                <p>
                                    No tienes órdenes anteriores
                                </p>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
