import React, { useState, useEffect, lazy, Suspense } from 'react'
import axios from "axios";
import { useSelector } from 'react-redux'
import { Row, Col, Container, Form, Button, Spinner, Image } from 'react-bootstrap'
import Message from '../../../components/Message' 
import VerdemiLogo from '../../../images/logo verdemi.png'
import { WhatsappFloatingButton } from '../../../components/user/WhatsappFloatingButton'

const Footer = lazy(() => import('../../../components/user/prospects/Footer'))
const Header = lazy(() => import('../../../components/user/prospects/Header'))

export const UserRecoverPasswoedScreen = ({history}) => {

    const loginUser = useSelector(state => state.userLogin)
    const { loading, error } = loginUser

    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [sentEmail, setSentEmail] = useState(false)

    const submitHandler = e => {
        e.preventDefault()
        axios
        .get(`${window.location.origin}/api/accounts/user_request_reset_password/${email}/`)
        .then((response) => {
                if (response.status === 200) {
                    setSentEmail(true)
                }
            // console.log(response.status);
        }).catch(error => {
            console.log(error.message, 'error')
        });
        setEmail('')
        setValidEmail(false)
    }

    // user_request_reset_password/

    const handleOnEmailChange = ( email ) => {
        setEmail(email)
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if ( re.test(email) ) {
            setValidEmail(true)
        }
        else {
            setValidEmail(false)
        }
    }


    useEffect(() => {
        if(sentEmail){
            console.log('deberían mandarme a otro lado')
            history.push('/recover_password_email_sent/')
        }
    }, [sentEmail, history])


    return (
        <div>
            <Suspense fallback={"Cargando..."}>
                <Header />
            </Suspense>
            <Container>
                <Row className='mt-3'>
                    <Col className='d-flex justify-content-center mx-3 my-1 mt-5'>
                        <Image className="img-fluid" src={VerdemiLogo} alt="First slide"/>
                    </Col>
                </Row>
                <div className='primary-overlay'>
                    <Row className='justify-content-md-center'>
                        <Col md={6}>
                        <h1 className='mt-1 text-center'>Restablecer Contraseña</h1>
                        <p className='text-center text-dark'>
                            ¿Has olvidado tu contraseña? Ingresa tu correo electrónico y te enviaremos un correo que te permitirá restablecerla.
                        </p>
                        {error ? (
                            <div>
                                {error === 'No active account found with the given credentials' ? (
                                    <Message variant='danger'>No se encontró una cuenta activa con las credenciales dadas</Message>
                                    ) : (
                                    <Message variant='danger'>{error}</Message>
                                )}
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='email'>
                                <Form.Control
                                    required
                                    type='email'
                                    placeholder='Email'
                                    className='rounded-pill text-center'
                                    value={email}
                                    onChange={(e) => handleOnEmailChange(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            {validEmail === false && email.length > 0 && (
                                <Row>
                                    <Col className='mb-n1'>
                                        <p className='text-danger mt-n2' style={{fontSize: '0.9rem'}}>Inserta un email válido</p>
                                    </Col>
                                </Row>
                            )}
                            
                                <Button type='submit' disabled={loading || validEmail === false } variant='success' className='mt-3 align-self-center btn-block btn-lg rounded-pill'>
                                    {loading ? (
                                            <Spinner
                                            animation='border'
                                            role='status'
                                            style={{
                                                height:'25px',
                                                width:'25px',
                                                margin:'auto',
                                                display:'block'
                                            }}
                                            >
                                                <span className='sr-only'>
                                                    Loading...
                                                </span>
                                            </Spinner>
                                    ) : (
                                    <span>
                                        Restablecer mi contraseña
                                    </span>
                                    )}
                                </Button>
                        </Form>
                        <Row className='d-flex justify-content-center mb-3 mt-1'>
                            <Col>
                                <p className='text-center'>Si tiene alguna dificultad para restablecer tu contraseña, por favor contáctanos por Whatsapp</p>
                            </Col>
                        </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
            <WhatsappFloatingButton />
            <Row className='bg-light d-flex justify-content-center p-3 p-md-5'>
                <Col>
                    <Suspense fallback={"Cargando..."}>
                        <Footer />
                    </Suspense>
                </Col>
            </Row>
        </div>
    )
}
