import React, { useEffect, useState, lazy, Suspense } from 'react'
import { Row, Col, Container, Form, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { categoryDetailAction } from '../../../actions/productsActions'
import Product from '../../../components/user/Product'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { Helmet } from "react-helmet";
import { WhatsappFloatingButton } from '../../../components/user/WhatsappFloatingButton'

import { ProductsHorizontalSlider } from '../../../components/user/ProductsHorizontalSlider'

const RightColumn = lazy(() => import('../../../components/user/prospects/RightColumn'))
const Footer = lazy(() => import('../../../components/user/prospects/Footer'))
const Header = lazy(() => import('../../../components/user/prospects/Header'))

export const CategoryDetailScreen = ({history, match}) => {

    console.log(match)

    const dispatch = useDispatch()

    const detailCategory = useSelector(state => state.categoryDetail)
    const { loading, error, category } = detailCategory

    const[productNameFilter, setproductNameFilter] = useState('')
    const[productFilterResults, setProductFilterResults] = useState([])


    useEffect(() =>{
        if ((!category || Object.entries(category).length === 0) && !error && !loading) {
            dispatch(categoryDetailAction(match.params.slug))
        }
        if (category && Object.entries(category).length > 0) {
            if (match.params.slug.toLowerCase() !== category.slug_name.toLowerCase()) {
                setproductNameFilter('')
                dispatch(categoryDetailAction(match.params.slug))            
            }    
        }
        if (category && category.products) {
            if (productNameFilter.length > 0) {
                setProductFilterResults(category.products.filter(product => product.name.toLowerCase().includes(productNameFilter.toLowerCase())))
              }                  
          }
    }, [
        dispatch,
        match.params.slug,
        productNameFilter,
        category,
        error,
        loading
    ])

    return (
        <div style={{overflowX: 'hidden'}}>
            {category && (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{category.name}</title>
                    <link rel="canonical" href="https://verdemi.com/" />
                </Helmet>
            )}
            <Suspense fallback={"Cargando..."}>
                <Header />
            </Suspense>
            <Container className='mt-5'>
            {loading ? <Loader />
            : error ? (
                <Message variant='red'>{error}</Message>
            ) : (
                <Row style={{height:'auto'}}>
                    <Col md={9}>
                        <div id='left'>
                            <Row className='mt-5 mt-md-3'>
                                <Col>
                                    <h2 className='ml-1 ml-md-5' style={{color:'#29bc5a'}}><b>{category.name}</b></h2>
                                </Col>
                            </Row>
                            {category && category.products && (
                            <div>
                                <Row>
                                    <Col className='mx-1 mx-md-5'>
                                        <>
                                            <Form.Control
                                                placeholder="Buscar producto"
                                                type="text"
                                                className='mt-3'
                                                onChange={(e) => setproductNameFilter(e.target.value)}
                                            />
                                        </>
                                    </Col>
                                </Row>
                                {productNameFilter.length !== 0 && productFilterResults.length > 0 ? (
                                    <div>
                                        <div className='d-block d-md-none'>
                                            <ProductsHorizontalSlider categoryProducts={productFilterResults} type={'cart'}/>
                                        </div>
                                        <Row className='mt-3 d-none d-md-block'>
                                            <Col>
                                                <Row className='mb-3'>
                                                {category.products.map(product => product.name.toLowerCase().includes(productNameFilter.toLowerCase()) && (
                                                    <Col key={product.id} xs={6} lg={4} xl={3} className=''>
                                                        <Product product={product} type={'cart'} new_user={false}/>
                                                    </Col>
                                                    ))}                                                            
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : productNameFilter.length !== 0 && productFilterResults.length === 0 ? (
                                    <Row>
                                        <Col className='mx-1 mx-md-5'>
                                            <Alert variant='primary' className='my-3 text-center'>No hay productos con el nombre "{productNameFilter}". Puedes probar con otro nombre</Alert>
                                        </Col>
                                    </Row>
                                ) : (
                                    <div>
                                        <div className='d-block d-md-none'>
                                            <ProductsHorizontalSlider categoryProducts={category.products} type={'cart'}/>
                                        </div>
                                        <Row className='mt-3 d-none d-md-block'>
                                            <Col>
                                                <Row className='mb-3'>
                                                {category.products.map(product =>
                                                    <Col key={product.id} xs={6} lg={4} xl={3}>
                                                        <Product product={product} type={'cart'} new_user={false}/>
                                                    </Col>
                                                )}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </div>
                            )}
                        </div>
                    </Col>
                    <Col md={3} className='boder mt-n5' style={{zIndex:1}}>
                        <Suspense fallback={"Cargando..."}>
                            <RightColumn/>
                        </Suspense>
                    </Col>
                </Row>
            )}
            <WhatsappFloatingButton />
            </Container>
            <Row>
                <Col md={9} className='border bg-light pl-5 pt-3'>
                    <Suspense fallback={"Cargando..."}>
                        <Footer />
                    </Suspense>
                </Col>
            </Row>
        </div>
    )
}
