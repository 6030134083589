import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../../Loader'
import Message from '../../../Message'
import Product from '../../Product'
import { Modal, Row, Col, Container, Button, Form, Alert, CloseButton } from 'react-bootstrap'
import { SubscriptionButton } from './SubscriptionButton'
import { ProductOrderDetailImage } from '../../ProductOrderDetailImage'
import { SUBSCRIPTION_DETAIL_RESET, SUBSCRIPTION_UPDATE_RESET } from '../../../../constants/subscriptionConstants'
import { subscriptionDetailAction } from '../../../../actions/subscriptionActions'
import { productCategoryListAction, productListAction } from '../../../../actions/productsActions'
import { nextWeekSubscriptionOrderDetailAction } from '../../../../actions/orderActions'
import { SUBSCRIPTION_PRODUCTS_MODAL_CLOSE } from '../../../../constants/subscriptionConstants' 
import { ProductsHorizontalSlider } from '../../ProductsHorizontalSlider'

const { 
    REACT_APP_MAXIMUN_NUMBER_OF_PURCHASES_WITH_DISCOUNT } = process.env


export const ModalChangeSubscriptionProducts = (props) => {

    const dispatch = useDispatch()

    const [categoryFilter, setCategoryFilter ] = useState('TODOS')

    const detailSubscription = useSelector(state => state.subscriptionDetail)
    const { subscription } = detailSubscription

    const listProductCategory = useSelector(state => state.productCategoryList)
    const { loading, error, categories } = listProductCategory

    const subscriptionLocal = useSelector(state => state.localSubscription)
    const { localProductsItems: localSubscriptionProductsItems } = subscriptionLocal

    const subscriptionUpdate = useSelector(state => state.subscriptionUpdate)
    const { success, loading: loadingUpdateSubscription } = subscriptionUpdate

    const listProduct = useSelector(state => state.productList)
    const { loading: productsLoading, error: productsError, products } = listProduct

    const[productNameFilter, setproductNameFilter] = useState('')
    const[productFilterResults, setProductFilterResults] = useState([])

    const detailUser = useSelector(state => state.userDetail)
    const { user  } = detailUser

    const [firstPurchasesDiscountApplies, setFirstPurchasesDiscountApplies ] = useState(true)

    const handleCategoryFilterChange = (e) => {
        setCategoryFilter(e.target.id)
    }

    useEffect(() => {
        if (success){
            dispatch({type: SUBSCRIPTION_DETAIL_RESET})
            dispatch({type: SUBSCRIPTION_UPDATE_RESET})
        }

        if (!subscription && loadingUpdateSubscription === false) {
            dispatch(subscriptionDetailAction())
            dispatch(nextWeekSubscriptionOrderDetailAction())
        }
        if (categories) {
            if (categories.length === 0 && (loading === false || !loading)) {
                dispatch(productCategoryListAction())
            }    
        }
        if ((!products || products.length === 0) && !productsError && (productsLoading === false || !productsLoading)) {
            dispatch(productListAction())
          }
        if (products && products.length > 0) {
            if (productNameFilter.length > 0) {
                setProductFilterResults(products.filter(product => product.name
                    .replace('á', 'a')
                    .replace('é', 'e')
                    .replace('í', 'i')
                    .replace('ó', 'o')
                    .replace('ú', 'u')
                    .toLowerCase().includes(productNameFilter
                        .replace('á', 'a')
                        .replace('é', 'e')
                        .replace('í', 'i')
                        .replace('ó', 'o')
                        .replace('ú', 'u')
                        .toLowerCase())))
                }                  
            }
        if (user) {
            if (user.total_purchases) {
                if (user.total_purchases > Number(REACT_APP_MAXIMUN_NUMBER_OF_PURCHASES_WITH_DISCOUNT)) {
                    setFirstPurchasesDiscountApplies(false);
                }        
            }
        }
        if (props.isOpen === false) {
            if (productNameFilter.length > 0) {
                setproductNameFilter('')
            }
        }    
    }, [
        dispatch,
        success,
        subscription,
        loadingUpdateSubscription,
        categories,
        loading,
        productNameFilter,
        productsLoading,
        props.isOpen,
        productsError,
        products,
        user
    ])

    const CloseChangeSubscriptionProductsModal = () => {
        dispatch({type: SUBSCRIPTION_PRODUCTS_MODAL_CLOSE })
    }

    const [disableButtons, setDisableButtons] = useState(false)

    const clickThreshold = 900; // Umbral de tiempo en milisegundos
    const clickTimes = useRef([]);
  
    const DisableButtonFunction = () => {
        clickTimes.current.push(Date.now());

        // Mantén solo los tiempos de clic dentro del umbral
        const now = Date.now();
        clickTimes.current = clickTimes.current.filter(time => now - time < clickThreshold);
    
        if (clickTimes.current.length >= 3) {
          setDisableButtons(true);
    
          // Habilitar el botón después de un tiempo
          setTimeout(() => {
            setDisableButtons(false);
          }, clickThreshold);
        }
    }

    return (
        <div>
            <Modal show={props.isOpen} onHide={props.toggle} animation={false} size="lg">
                <Modal.Header >
                <Modal.Title>
                    Modificar Subscripción
                    {/* <Alert variant='danger' className='my-0 mx-0 text-center px-1' style={{ width: '100%', fontSize: '0.7rem' }}>ESTOS CAMBIOS SE APLICARÁN A TU PRÓXIMA ORDEN <u><b>Y</b></u> A LAS SIGUIENTES</Alert> */}
                    </Modal.Title>
                <CloseButton onClick={CloseChangeSubscriptionProductsModal}/>
                </Modal.Header>
                {/* parte del problema está aquí */}
                <Modal.Body style={{ overflowY: 'auto', overflowX: 'hidden', height: '75vh' }}>
                    <Container>
                        <Row className='d-none'>
                            <Col>
                                <p className='text-center d-none  d-sm-block'>Tu subscripción actual</p>
                                <Row className='d-flex justify-content-around'>
                                    {localSubscriptionProductsItems && localSubscriptionProductsItems.map(item => (
                                        <Col xs={6} md={4} className='pl-3 d-none d-sm-block' key={item.id} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Row className='mt-3'>
                                                <Col xs={12} sm={2} className='pl-0 py-1' style={{ alignItems: 'center', justifyItems: 'center', display: 'grid' }}>
                                                    <ProductOrderDetailImage product={item.products} />
                                                </Col>
                                                <Col sm={10} className='ml-0'>
                                                    {item.products.name}
                                                </Col>                                                 
                                            </Row>
                                            <Row className='pl-1'>
                                                <small>
                                                    (${item.products.price} unidad)
                                                </small>
                                            </Row>
                                            <Row className='pl-3' style={{ marginTop: 'auto' }}>
                                                <SubscriptionButton product={item.products} disableButtons={disableButtons} DisableButtonFunction={DisableButtonFunction}/>
                                            </Row>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='text-center d-none d-md-block'>Filtra los productos por categoría</p>
                                {loading ? (<Loader />) :
                                    error ? (<Message variant='danger'>{error}</Message>):
                                    categories ? (
                                        <div>
                                            <Row className='d-none d-sm-block'>
                                                <Col >
                                                    <Form onChange={handleCategoryFilterChange}>
                                                        <div className="mb-3 form-check">
                                                            {categories && (categories.map(category =>
                                                                <div className='btn-group btn-group-toggle m-1' data-toggle="buttons" key={category.id}>
                                                                    <label className='btn btn-success active'>
                                                                        <Form.Check 
                                                                        name="group1"
                                                                        type="radio"
                                                                        id={category.name}
                                                                        label={category.name}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            ))}
                                                                <div className='btn-group btn-group-toggle' data-toggle="buttons">
                                                                    <label className='btn btn-success active'>
                                                                        <Form.Check
                                                                        name="group1"
                                                                        type="radio"
                                                                        id='TODOS'
                                                                        label='TODOS'
                                                                        />
                                                                    </label>
                                                                </div>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='mx-1 mx-md-5'>
                                                    <p className='text-center d-block d-sm-none'>Filtra por producto</p>
                                                    <p className='text-center d-none d-sm-block'>O por producto</p>
                                                    <>
                                                        <Form.Control
                                                            placeholder="Buscar producto"
                                                            type="text"
                                                            className='mt-3'
                                                            onChange={(e) => setproductNameFilter(e.target.value)}
                                                            style={{ marginBottom: '10px'}}
                                                        />
                                                    </>
                                                </Col>
                                            </Row>
                                        </div>
                                    ) :
                                    (<div></div>)}
                            </Col>
                        </Row>
                        <Row>
                            <Col>{loading ? (<Loader />) :
                                error ? (<Message variant='danger'>{error}</Message>):
                                categories && productNameFilter.length === 0 ? (
                                    <div>
                                        {categories && categoryFilter === 'TODOS' ? (categories.filter((category) => category.products.length > 0).map(category => 
                                        <div key={category.id} className='mt-sm-5'>
                                            <Row className='d-none d-sm-block'>
                                                <h2>{category.name}</h2>
                                            </Row>
                                            <div className='d-none d-sm-block ml-n3'>
                                                <Row>
                                                    {category.products.map(product =>
                                                        <Col key={product.id} xs={6} sm={6} md={6} lg={4} xl={3}>
                                                            <Product
                                                                product={product}
                                                                type={'subscription'}
                                                                new_user={firstPurchasesDiscountApplies}
                                                                horizontalSlider={false}
                                                                disableButtons={disableButtons}
                                                                DisableButtonFunction={DisableButtonFunction}
                                                                categoryName={category.name}
                                                            />
                                                        </Col>
                                                    )}
                                                </Row>
                                            </div>
                                            <div
                                                className="container-fluid border mr-n3 d-block d-sm-none"
                                                style={{
                                                    width: '100vw',
                                                    position: 'relative',
                                                    marginLeft: '-35px',
                                                    overflowY: 'hidden',
                                                    backgroundColor: category.name === 'Verduras' ? '#f6f8f7' :
                                                    (category.name === 'Frutas' ? '#f0eded' :
                                                    (category.name === 'Frutos Secos' ? '#f6f8f7' :
                                                    (category.name === 'Semillas, especias y aromáticas' ? '#f0eded' : '#ffe7cc')))
                                            }}>
                                                <Row>
                                                    <Col>
                                                        <h2 className='ml-3 my-4 text-center'
                                                            style={{
                                                                fontWeight: '500',
                                                                fontSize: '28px',
                                                                lineHeight: '4.7vw',
                                                            }}
                                                        >
                                                            <b>{category.name}</b>
                                                        </h2>
                                                    </Col>
                                                </Row>
                                                <ProductsHorizontalSlider
                                                    categoryProducts={category.products}
                                                    type={'subscription'}
                                                    firstPurchasesDiscountApplies={firstPurchasesDiscountApplies}
                                                    DisableButtonFunction={DisableButtonFunction}
                                                    categoryName={category.name}
                                                />
                                            </div>
                                        </div>
                                        )) : (categories.map(category => category.name === categoryFilter ?
                                            <div key={category.id} className='mt-5'>
                                                <Row className='d-none d-sm-block'>
                                                    <h2>{category.name}</h2>
                                                </Row>
                                                <div className='d-none d-sm-block'>
                                                    <Row>
                                                        {category.products.map(product =>
                                                            <Col key={product.id} xs={6} sm={6} md={6} lg={4} xl={3}>
                                                                <Product
                                                                    product={product}
                                                                    type={'subscription'}
                                                                    new_user={firstPurchasesDiscountApplies} horizontalSlider={false}
                                                                    disableButtons={disableButtons}
                                                                    DisableButtonFunction={DisableButtonFunction}
                                                                    categoryName={category.name}
                                                                />
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </div>
                                                <div className="container-fluid border mr-n3 d-block d-sm-none" style={{ width: '100vw', position: 'relative', marginLeft: '-35px', backgroundColor: category.name === 'Verduras' ? '#f4ffe2' : (category.name === 'Frutas' ? '#ccf1ff' : '#ffe7cc')}}>
                                                    <Row>
                                                        <h2 className='ml-3 mt-3'>{category.name}</h2>
                                                        <p className='ml-auto mt-3 mr-3'><i className="fas fa-chevron-left"></i>  <i className="fas fa-chevron-right"></i></p>
                                                    </Row>
                                                    <div className="scrolling-wrapper row flex-row flex-nowrap pb-4 pt-2">
                                                        {category.products.map(product =>
                                                            <Col key={product.id} xs={5} lg={4} xl={3} className=''>
                                                                <Product
                                                                    product={product}
                                                                    type={'order'}
                                                                    new_user={firstPurchasesDiscountApplies} horizontalSlider={true}
                                                                    disableButtons={disableButtons}
                                                                    DisableButtonFunction={DisableButtonFunction}
                                                                    categoryName={category.name}
                                                                />
                                                            </Col>
                                                        )}
                                                    </div>
                                                </div>
                                            </div> : <div></div>
                                        ))}
                                    </div>
                                    ) : categories && productNameFilter.length !== 0 ? (
                                        <div>
                                            {productFilterResults.length > 0 ? (
                                                <>
                                                    <div>                                               
                                                            <ProductsHorizontalSlider
                                                                categoryProducts={productFilterResults}
                                                                type={'subscription'}
                                                                firstPurchasesDiscountApplies={firstPurchasesDiscountApplies}
                                                                DisableButtonFunction={DisableButtonFunction}
                                                            />
                                                    </div>
                                                    <div className='d-none d-sm-block'>
                                                        <Row>
                                                            {productFilterResults.map(product =>
                                                                <Col key={product.id} xs={6} sm={6} md={6} lg={4} xl={3}>
                                                                    <Product
                                                                        product={product}
                                                                        type={'subscription'}
                                                                        new_user={firstPurchasesDiscountApplies} horizontalSlider={false}
                                                                        disableButtons={disableButtons}
                                                                        DisableButtonFunction={DisableButtonFunction}
                                                                        categoryName={product.category.name}
                                                                    />
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </div>
                                                </>
                                            ) : (<div>
                                                <Row>
                                                    <Col className='mx-1 mx-md-5'>
                                                        <Alert variant='primary' className='my-3 text-center'>No hay productos con el nombre "{productNameFilter}". Puedes probar con otro nombre</Alert>
                                                    </Col>
                                                </Row>
                                            </div>)}
                                        </div>
                                    ) :
                                        (<div></div>
                                    )}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={CloseChangeSubscriptionProductsModal} className='btn-block mx-3'>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>            
        </div>
    )
}
