import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Tab, Nav } from 'react-bootstrap'
import Loader from '../../../Loader'
import Message from '../../../Message'
import { LocalSubscriptionSection } from './LocalSubscriptionSection'
import { SubscriptionInfoSection } from './SubscriptionInfoSection'

import { UserInfo } from './../AccountInfo/UserInfo'
import { DeliveryOrder } from '../DeliveryOrder'
import { BlockedDeliveredNotPaidOrderSection } from './BlockedDeliveredNotPaidOrderSection'
import { userDetailAction, userLogout } from '../../../../actions/userActions'
import { BlockedDeliveredNotPaidSubscriptionOrderDetailAction } from '../../../../actions/orderActions'

export const SubscriptionAndOrderInfo = ({ history }) => {

    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState('OrderSubscription');
    // ESTO HAY QUE CAMBIARLO. PASAR FUNCIÓN AL COMPONENTE

    const handleSelect = (selectedKey) => {
        setActiveTab(selectedKey);
    };

    const detailUser = useSelector(state => state.userDetail)
    const { loading, user, error: userError } = detailUser

    const isDelivery = (delivery) => {
        if (delivery && delivery === true) {
            setActiveTab("DeliveryInformation")
        }
    }

    useEffect(() => {
        if ((user === null || !user) && loading === false) {
            dispatch(userDetailAction())
        }
        if (userError) {
            dispatch(userLogout())
        }
    }, [
        dispatch,
        userError,
        user,
        loading
    ])

    const BlockedDeliveredNotPaid = useSelector(state => state.BlockedDeliveredNotPaidSubscriptionOderDetail)
    const {error: BlockedDeliveredNotPaidError, loading: BlockedDeliveredNotPaidLoading, Order} = BlockedDeliveredNotPaid

    useEffect(() => {
        if (typeof(Order) ==='object' && Order.length === 0 && BlockedDeliveredNotPaidLoading == false && !BlockedDeliveredNotPaidError) {
            dispatch(BlockedDeliveredNotPaidSubscriptionOrderDetailAction())
        }
    }, [
        BlockedDeliveredNotPaidError,
        BlockedDeliveredNotPaidLoading,
        Order
    ])

    const orderLocal = useSelector(state => state.localOrder)
    const { updatedFromServer: updatedOrderFromServer } = orderLocal

    const subscriptionLocal = useSelector(state => state.localSubscription)
    const { updatedFromServer: updatedSubscriptionFromServer } = subscriptionLocal

    // useEffect(() => {}, [])

    return (
        <div className='mt-3'>
            <p className='my-n3 d-none d-sm-block' style={{ color: '#F8F9FA'}}>----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
            <p className='my-n3 d-block d-sm-none' style={{ color: '#F8F9FA'}}>----------------------------------------------------------------------------------------</p>
            {user ? (
                <div>
                    <Tab.Container activeKey={activeTab} id="uncontrolled-tab-example" className="mb-3 mt-3">
                        <Row>
                            <Col className='mt-xs-3'>
                                <Nav variant="pills" className="d-flex" onSelect={handleSelect}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="OrderSubscription" style={{ fontSize: "13px"}}><i className="fas fa-shopping-basket"></i> Tu mercado</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item >
                                        <Nav.Link eventKey="AccountInfo" style={{ fontSize: "13px"}}><i className="fas fa-user-alt"></i> Gestiona tu suscripción</Nav.Link>
                                    </Nav.Item>
                                    <DeliveryOrder type={'nav'} isDelivery={isDelivery}/>
                                </Nav>
                            </Col>
                        </Row>
                        {user ? (
                            <Tab.Content>
                                <Tab.Pane eventKey="AccountInfo" title="Información de la cuenta">
                                    <UserInfo history={history}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="DeliveryInformation" title="Información de entrega">
                                    <DeliveryOrder type={'tab'}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="OrderSubscription" title="Subscripción y próxima orden">
                                    {BlockedDeliveredNotPaidLoading ? (
                                        <div>
                                            <Loader fullWidth={'true'}/>
                                        </div>
                                    ) : (
                                        <div>
                                            {BlockedDeliveredNotPaidError ? (
                                                <div>
                                                    <Message variant='danger'>{BlockedDeliveredNotPaidError}</Message>
                                                </div>
                                            ) : (
                                                <div>
                                                    {Order ? (
                                                        <div>
                                                            <BlockedDeliveredNotPaidOrderSection order={Order} />
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {updatedOrderFromServer || updatedSubscriptionFromServer ? (
                                                                <LocalSubscriptionSection />
                                                            ) : (
                                                                <SubscriptionInfoSection />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {/* <DeliveredOrderSection /> */}
                                </Tab.Pane>
                            </Tab.Content>
                        ) : (
                            <div className='mt-5'>
                                <Loader fullWidth={'true'}/>
                            </div>                        
                        )}
                    </Tab.Container>
                </div>
             ) : ( 
                 <div className='mt-5'> 
                    <p className='my-n3 d-none d-sm-block' style={{ color: '#F8F9FA'}}>----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                     <Loader /> 
                 </div> 
                 )} 
        </div>
    )
}
