import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import {
    removeLocalOrderAction,
} from '../../../../actions/orderActions'


export const LocalActiveSubscriptionPaymentSection = ( props ) => {

    const dispatch = useDispatch()

    const paymentMethodList = useSelector(state => state.userPaymentMethodList)
    const { paymentMethod } = paymentMethodList

    const orderNextWeekActiveSubscription = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
    const { loading: orderLoading, nextWeekActiveOrder  } = orderNextWeekActiveSubscription

    const orderLocal = useSelector(state => state.localOrder)
    const { localProductsItems: localOrderProductsItems, updatedFromServer: updatedOrderFromServer } = orderLocal

    const updateSubscription = useSelector(state => state.subscriptionUpdate)
    const { loading: subscriptionUpdateLoading, success: subscriptionUpdateSuccess } = updateSubscription

    const [refreshOrderShow, setRefreshOrderShow] = useState(false);

    const refreshNextWeekSubscriptionOrderDetailAction = () => {
        dispatch(removeLocalOrderAction())
    }

    useEffect(() => {
        if (updatedOrderFromServer === true && nextWeekActiveOrder.products_items && orderLoading === false && subscriptionUpdateLoading === false) {
            const localSumWithInitial = localOrderProductsItems.reduce((acc, item) => 
            acc + (Number(item.total_value)), 0
            )
            const backSumWithInitial = nextWeekActiveOrder.products_items.reduce((acc, item) => 
            acc + (Number(item.total_value)), 0
            )
            if (localSumWithInitial !== backSumWithInitial) {
                setRefreshOrderShow(true)
            } else {
                setRefreshOrderShow(false)
            }                   
        }
    }, [])

    return (
        <div>
            <p>{paymentMethod && (
                <div>
                    {paymentMethod.length > 0 ? (
                        <div>
                            Se hará un cargo por {nextWeekActiveOrder && orderLoading === false ? (
                            <span>
                                {new Intl.NumberFormat('co-CO').format(Number(nextWeekActiveOrder.products_amont_to_pay) + Number(nextWeekActiveOrder.transport_charge))}
                            </span>) : (
                            <span>
                                Actualizando......
                            </span>)} pesos en tu tarjeta el {nextWeekActiveOrder && orderLoading === false ? (
                                <span>
                                {new Intl.DateTimeFormat('es-GB', { 
                                month: 'long', 
                                day: '2-digit',
                                year: 'numeric', 
                                    }).format(new Date(nextWeekActiveOrder.date))}
                            </span>) : (<span>actualizando...</span>)}, después de entregar tu mercado. <Button variant="link" onClick={refreshNextWeekSubscriptionOrderDetailAction} className='p-0'>{refreshOrderShow && (nextWeekActiveOrder && orderLoading === false) ? (<small className='text-primary'>Refrescar</small>) : (<div></div>)}</Button>
                        </div>
                    ) : (
                        <div>
                            Se te enviará un link de pago por Whatsapp por {nextWeekActiveOrder && orderLoading === false && nextWeekActiveOrder.date !== null ? (
                            <span>
                                <b>{new Intl.NumberFormat('co-CO').format(Number(nextWeekActiveOrder.products_amont_to_pay) + Number(nextWeekActiveOrder.transport_charge))}</b>
                            </span>) : (
                            <span>
                                Actualizando......hará un cargo
                            </span>)} <b>pesos</b> el {nextWeekActiveOrder && orderLoading === false && nextWeekActiveOrder.date !== null ? (
                                <span>
                                    <b>
                                        {new Intl.DateTimeFormat('es-GB', { 
                                        month: 'long', 
                                        day: '2-digit',
                                        year: 'numeric', 
                                            }).format(new Date(nextWeekActiveOrder.date))}
                                    </b>
                                </span>) : (<span>actualizando...</span>)}, después de entregar tu mercado. <Button variant="link" onClick={refreshNextWeekSubscriptionOrderDetailAction} className='p-0'>{refreshOrderShow && (nextWeekActiveOrder && orderLoading === false) ? (<small className='text-primary'>Refrescar</small>) : (<div></div>)}</Button>
                        </div>
                    )}
                </div>
            )}
            </p>
        </div>
    )
}
