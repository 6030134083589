import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Spinner, Row, Col } from 'react-bootstrap'
import { userChangePasswordAction } from '../../../../../actions/userActions'
import { USER_CHANGE_PASSWORD_RESET } from '../../../../../constants/userConstants'
import Message from '../../../../Message'
import { userMessageAction } from '../../../../../actions/userActions'

export const ChangePassword = (props) => {

    const currentPasswordRef = useRef(null)

    const[currentPassword, setCurrentPassword] = useState()
    const[newPassword1, setNewPassword1] = useState()
    const[newPassword2, setNewPassword2] = useState()
    const[newPasswordMatch, setnewPasswordMatch] = useState(false)

    const dispatch = useDispatch()

    const userPasswordChange = useSelector(state => state.userChangePassword)
    const {loading, error, success} = userPasswordChange

    const changePasswordHandler = (e) => {
        e.preventDefault()
        const passwords = {
            currentPassword,
            newPassword1
        }
        dispatch(userChangePasswordAction(passwords))
    }

    useEffect(() => {
        if(newPassword1 && newPassword1.length > 9 && newPassword2 && newPassword1 === newPassword2 && currentPassword){
            setnewPasswordMatch(true)
        } else {
            setnewPasswordMatch(false)
        }
        if(success){
            setCurrentPassword('')
            setNewPassword1('')
            setNewPassword2('')
            dispatch({
                type: USER_CHANGE_PASSWORD_RESET
            })
            dispatch(userMessageAction('Contraseña cambiada.'))
        }
    }, [currentPassword, newPassword1, newPassword2, newPasswordMatch, success, dispatch])

    useEffect(() => {
        if (props && props.active === 'user-change-password') {
            if (currentPasswordRef && currentPasswordRef.current) {
                currentPasswordRef.current.focus();
            }        
        }
    }, [currentPasswordRef, props])


    return (
        <div>
            {error && (<Message variant='danger'>{error}</Message>)}
            <Form onSubmit={changePasswordHandler}>
                <Row className="g-2 my-3">
                    <Col md>
                        <Form.Control
                            ref={currentPasswordRef}
                            type="password"
                            required
                            disabled={loading}
                            placeholder="Contraseña actual"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            style={{ border: '0px', backgroundColor: 'white'}}
                        />
                    </Col>
                </Row>
                <Row className="g-2 my-3">
                    <Col md>
                        <Form.Control
                            type="password"
                            required
                            disabled={loading}
                            placeholder="Nueva contraseña"
                            value={newPassword1}
                            onChange={(e) => setNewPassword1(e.target.value)}
                            style={{ border: '0px', backgroundColor: 'white'}}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {newPassword1 && newPassword1.length < 10 && (
                            <Form.Text className="text-muted">
                                Tu nueva contraseña tiene que tener al menos 10 carácteres
                            </Form.Text>
                        )}
                    </Col>
                </Row>
                <Row className="g-2 mb-3">
                    <Col md>
                        <Form.Control
                            type="password"
                            required
                            disabled={loading}
                            placeholder="Repite nueva contraseña"
                            value={newPassword2}
                            onChange={(e) => setNewPassword2(e.target.value)}
                            style={{ border: '0px', backgroundColor: 'white'}}
                        />
                    </Col>
                </Row>
                <Row className={newPassword1 && newPassword2 && newPassword1 !== newPassword2 && 'mt-n3'}>
                    <Col>
                        {newPassword1 && newPassword2 && newPassword1 !== newPassword2 && (
                            <Form.Text className="text-muted">
                                No coinciden las contraseñas
                            </Form.Text>
                        )}
                    </Col>
                </Row>


                {newPasswordMatch ? (
                    <Button variant="primary" disabled={loading} type="submit" className='btn-block'>
                        {loading ? (
                            <Spinner
                            animation='border'
                            role='status'
                            style={{
                                height:'25px',
                                width:'25px',
                                margin:'auto',
                                display:'block'
                            }}
                            >
                                <span className='sr-only'>
                                    Loading...
                                </span>
                            </Spinner>  
                        ) : (
                            <span>
                                Cambiar contraseña
                            </span>
                        )}
                    </Button>
                ) : (
                    <Button variant="primary" disabled type="submit" className='btn-block'>
                        Cambiar contraseña
                    </Button>
                )}
            </Form>
        </div>
    )
}
