import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { userUpdatePersonalInfoAction, userDetailAction } from '../../../../../actions/userActions'
import Message from '../../../../Message' 
import { USER_UPDATE_PERSONAL_INFO_RESET } from '../../../../../constants/userConstants'
import { userMessageAction } from '../../../../../actions/userActions'


export const ModalCompletePersonalInformation = (props) => {

    const changeNameRef = useRef(null)

    const dispatch = useDispatch()

    const[name, setName] = useState('')
    const[surname, setSurname] = useState('')
    const[address, setAddress] = useState('')
    const[telephone, setTelephone] = useState('')
    const[apartment, setApartment] = useState('')

    const[completeInfo, setCompleteInfo] = useState(true)

    const detailUser = useSelector(state => state.userDetail)
    const { loading: loadingUser, user } = detailUser

    console.log(loadingUser, 'loadingUser')

    console.log(props.isOpen, 'props.isOpen')

    const updateUserInfo = useSelector(state => state.userUpdatePersonalInfo)
    const { loading, success, error } = updateUserInfo

    const completePersonalInformationHandler = (e) => {
        e.preventDefault()
        dispatch(userUpdatePersonalInfoAction(name, surname, address, telephone, apartment))
    }

    const CloseUpdateUserInfoModal = () => {
        props.toggle()
    }


    useEffect(() => {
        if (user.name && name.length === 0) {
            setName(user.name)
        }
        if (user.surname && surname.length === 0) {
            setSurname(user.surname)
        }
        if (user.telephone && telephone.length === 0) {
            setTelephone(user.telephone)
        }
        if (user.validated_address && address.length === 0) {
            setAddress(user.validated_address)
        }
        if (user.provided_address_second_line && apartment.length === 0) {
            setApartment(user.provided_address_second_line)
        }

        if (name && surname && address && telephone) {
            if (name.length > 0 && surname.length > 0 && address.length > 5 && telephone.length > 9) {
                setCompleteInfo(false)
            } else {
                setCompleteInfo(true)
            }    
        }
        if (success && loadingUser === false) {
            console.log(4)
            dispatch({type: USER_UPDATE_PERSONAL_INFO_RESET})
            dispatch(userDetailAction())
            CloseUpdateUserInfoModal()
            dispatch(userMessageAction('Información guardada.'))
        }
    }, [user,
        name,
        surname,
        address,
        telephone,
        completeInfo,
        success,
        CloseUpdateUserInfoModal,
        dispatch,
        loadingUser])

    useEffect(() => {
        if (props && props.isOpen === true) {
            if (changeNameRef && changeNameRef.current) {
                changeNameRef.current.focus();
            }        
        }
    }, [changeNameRef, props])


    return (
        <div>
             <Modal show={props.isOpen} onHide={props.toggle} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Completar información personal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {error && (<Message variant='danger'>{error}</Message>)}
                        <Form onSubmit={completePersonalInformationHandler}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                    ref={changeNameRef}
                                    type="text"
                                    placeholder="Nombre"
                                    value={name}
                                    disabled={loading ? true : false}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Apellidos</Form.Label>
                                <Form.Control 
                                    type="text"
                                    placeholder="Apellidos"
                                    value={surname}
                                    disabled={loading ? true : false}
                                    onChange={(e) => setSurname(e.target.value)}
                                />
                            </Form.Group>

                            {user && user.validated_address ? (
                                <span></span>
                            ) : (
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Dirección</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        placeholder="Dirección (para entregarte tu pedido)"
                                        value={address}
                                        disabled={loading ? true : false}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </Form.Group>
                            )}

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Teléfono</Form.Label>
                                <Form.Control 
                                    type="number"
                                    placeholder="Teléfono (para contactarte si es necesario por tu pedido)"
                                    value={telephone}
                                    disabled={loading ? true : false}
                                    onChange={(e) => setTelephone(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Apartamento/Escalera</Form.Label>
                                <Form.Control 
                                    type="text"
                                    placeholder="Apartamento/Escalera (para entregar tu mercado)"
                                    disabled={loading ? true : false}
                                    value={apartment}
                                    onChange={(e) => setApartment(e.target.value)}
                                />
                            </Form.Group>

                                <Button variant="success" disabled={completeInfo || loading} type="submit" className='btn-block'>
                                    {loading ? (
                                        <Spinner
                                        animation='border'
                                        role='status'
                                        style={{
                                            height:'25px',
                                            width:'25px',
                                            margin:'auto',
                                            display:'block'
                                        }}
                                        >
                                            <span className='sr-only'>
                                                Loading...
                                            </span>
                                        </Spinner>  
                                    ) : (
                                        <span>
                                            Incluir datos
                                        </span>
                                    )}
                                </Button>
                            {/* )} */}
                        </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.toggle}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
