import React, { lazy, Suspense } from 'react'
import { Row, Col, Container, Image } from 'react-bootstrap'
import VerdemiLogo from '../../../images/logo verdemi.png'
import { WhatsappFloatingButton } from '../../../components/user/WhatsappFloatingButton'

const Footer = lazy(() => import('../../../components/user/prospects/Footer'))
const Header = lazy(() => import('../../../components/user/prospects/Header'))

export const UserRecoverPasswoedEmailSentScreen = () => {


    return (
        <div>
            <Suspense fallback={"Cargando..."}>
                <Header />
            </Suspense>
            <Container>
                <Row className='mt-3'>
                    <Col className='d-flex justify-content-center mx-3 my-1 mt-5'>
                        <Image className="img-fluid" src={VerdemiLogo} alt="First slide"/>
                    </Col>
                </Row>
                <div className='primary-overlay'>
                    <Row className='justify-content-md-center'>
                        <Col md={6}>
                            <h1 className='mt-1 text-center'>Restablecer Contraseña</h1>
                            <p className='text-center text-dark'>
                            Te hemos enviado un correo electrónico. Por favor contáctanos si no recibes el correo en unos minutos.
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
            <WhatsappFloatingButton />
            <Row className='bg-light d-flex justify-content-center p-3 p-md-5'>
                <Col>
                    <Suspense fallback={"Cargando..."}>
                        <Footer />
                    </Suspense>
                </Col>
            </Row>
        </div>
    )
}
