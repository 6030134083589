import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { 
    updateCreatedSubscriptionOderStatusAction,
    nextWeekSubscriptionOrderDetailAction,
    removeLocalOrderAction } from '../../../../actions/orderActions'
import { SUBSCRIPTION_ORDER_UPDATE_STATUS_RESET } from '../../../../constants/orderConstants'
import Message from '../../../Message'
import { userMessageAction, userLastOrderAction, userOrdersListAction } from '../../../../actions/userActions'

export const ModalCancelOrder = (props) => {

    const dispatch = useDispatch()

    const orderSubscriptionCreated = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
    const { nextWeekActiveOrder } = orderSubscriptionCreated

    const orderSubscriptionStatusUpdated = useSelector(state => state.updateCreatedSubscriptionOderStatus)
    const { error: orderStatusUpdatedError, loading: orderStatusUpdatedLoading, success } = orderSubscriptionStatusUpdated

    const cancelOrderHandler = (e) => {
        e.preventDefault()
        // 4: the pk id of the Canceled status. Check the pk on production
        dispatch(updateCreatedSubscriptionOderStatusAction(nextWeekActiveOrder.id, 4))
    }

    const CloseCancelOrderModal = () => {
        props.toggle()
    }

    useEffect(() =>{
        if(success){
            dispatch(nextWeekSubscriptionOrderDetailAction())
            dispatch({type: SUBSCRIPTION_ORDER_UPDATE_STATUS_RESET})
            dispatch(userLastOrderAction())
            dispatch(userOrdersListAction())
            CloseCancelOrderModal()
            dispatch(userMessageAction('Orden cancelada'))
            dispatch(removeLocalOrderAction())
        }
    }, [success, dispatch, CloseCancelOrderModal])


    return (
        <div>
            <Modal show={props.isOpen} onHide={props.toggle} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title className='text-success'><i className="fas fa-window-close"></i> Cancelar sólo esta orden</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {nextWeekActiveOrder && (
                        <div>
                            <p>Cancelar envío para el {new Intl.DateTimeFormat('es-GB', { 
                                month: 'long', 
                                day: '2-digit',
                                year: 'numeric', 
                            }).format(new Date(nextWeekActiveOrder.date))}
                            </p>
                            <ul>
                                {nextWeekActiveOrder && nextWeekActiveOrder.products_items && nextWeekActiveOrder.products_items.map(item => (
                                    <li key={item.id}>
                                    {item.products.name} <small>({item.amount} unidades)</small>
                                    </li>
                                ))
                                }    
                            </ul>
                            {orderStatusUpdatedError && (<Message variant='danger'>{orderStatusUpdatedError}</Message>)}
                            <Form onSubmit={cancelOrderHandler}>
                                <Button required={orderStatusUpdatedLoading} disabled={orderStatusUpdatedLoading} variant="danger" type="submit" className='btn-block'>
                                    {orderStatusUpdatedLoading ? (
                                        <Spinner
                                        animation='border'
                                        role='status'
                                        style={{
                                            height:'25px',
                                            width:'25px',
                                            margin:'auto',
                                            display:'block'
                                        }}
                                        >
                                            <span className='sr-only'>
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ) : (
                                        <span>
                                            Cancelar orden
                                        </span>
                                    )}
                                </Button>
                            </Form>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={CloseCancelOrderModal} className='btn-block'>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>                                    
        </div>
    )
}
