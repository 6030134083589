import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { 
    nextWeekSubscriptionOrderDetailAction,
    removeLocalOrderAction,
    updateSubscriptionOderDateAction } from '../../../../actions/orderActions'
import { SUBSCRIPTION_ORDER_UPDATE_DATE_RESET } from '../../../../constants/orderConstants'
import Message from '../../../Message'
import { userMessageAction, userLastOrderAction, userOrdersListAction } from '../../../../actions/userActions'

export const ModalPostponeOrder = (props) => {

    function formatDate(date) {
        return String(date.getDate()) + '/' + String(date.getMonth() +1) + '/' + String(date.getFullYear())
    }

    function CalculateWednesdays(daysToAdd) {
        var today = new Date();
        var proxWednesday = new Date();
        var secondWednesday = new Date();
        var thirdWednesday = new Date();
        var fourthWednesday = new Date();
        proxWednesday.setDate(today.getDate()+daysToAdd);

        secondWednesday.setDate(today.getDate()+(daysToAdd +7));
        var secondWednesdayFormatted = new Intl.DateTimeFormat('es-GB', { 
            month: 'long', 
            day: '2-digit',
            weekday: 'long',
            year: 'numeric', 
                }).format(secondWednesday)
        thirdWednesday.setDate(today.getDate()+(daysToAdd +14));
        var thirdWednesdayFormatted = new Intl.DateTimeFormat('es-GB', { 
            month: 'long', 
            day: '2-digit',
            weekday: 'long',
            year: 'numeric', 
                }).format(thirdWednesday)
        fourthWednesday.setDate(today.getDate()+(daysToAdd +21));
        var fourthWednesdayFormatted = new Intl.DateTimeFormat('es-GB', { 
            month: 'long', 
            day: '2-digit',
            weekday: 'long',
            year: 'numeric', 
                }).format(fourthWednesday)
        return [
            // [formatDate(proxWednesday), proxWednesdayFormatted],
            [formatDate(secondWednesday), secondWednesdayFormatted],
            [formatDate(thirdWednesday), thirdWednesdayFormatted],
            [formatDate(fourthWednesday), fourthWednesdayFormatted]
        ]
    }

    const NextWednesday = currentDay => {
        var daysToAdd = null
        if (currentDay < 3) {
            daysToAdd = 3 - currentDay
        }
        if (currentDay === 3) {
            daysToAdd = 0
        }
        if (currentDay > 3) {
            daysToAdd = currentDay + 6 - 3
        }
        if (daysToAdd || daysToAdd === 0) {
            return CalculateWednesdays(daysToAdd)
        }
        return null
    }

    const [wednesdayOptions, setWednesdayOptions] = useState([]);

    const [newOrderDate, setNewOrderDate] = useState('');

    const dispatch = useDispatch()

    const orderSubscriptionCreated = useSelector(state => state.nextWeekActiveSubscriptionOderDetail)
    const { nextWeekActiveOrder } = orderSubscriptionCreated

    // const orderSubscriptionStatusUpdated = useSelector(state => state.updateCreatedSubscriptionOderStatus)
    // const { error: orderStatusUpdatedError, loading: orderStatusUpdatedLoading, success } = orderSubscriptionStatusUpdated

    const orderSubscriptionDateUpdated = useSelector(state => state.updateSubscriptionOderDate)
    const { error: orderDateUpdatedError, loading: orderDateUpdatedLoading, success } = orderSubscriptionDateUpdated

    const updateDateOrderHandler = (e) => {
        e.preventDefault()
        dispatch(updateSubscriptionOderDateAction(nextWeekActiveOrder.id, newOrderDate))
    }

    const CloseUpdateOrderDateModal = () => {
        props.toggle()
    }

 
    useEffect(() =>{
        if(success){
            dispatch(nextWeekSubscriptionOrderDetailAction())
            dispatch({type: SUBSCRIPTION_ORDER_UPDATE_DATE_RESET})
            dispatch(userLastOrderAction())
            console.log('ModalPostponeOrders')
            dispatch(userOrdersListAction())
            CloseUpdateOrderDateModal()
            dispatch(userMessageAction('Fecha de entrega actualizada'))
            dispatch(removeLocalOrderAction())
        }
        if (wednesdayOptions && wednesdayOptions.length === 0) {
            setWednesdayOptions(NextWednesday(new Date().getDay()))
        }
    }, [
        success,
        dispatch,
        CloseUpdateOrderDateModal,
        wednesdayOptions,
        NextWednesday
    ])

    const onChangeDate = (value) => {
        const NewOrderDate = (value.getDate()).toString() + '/' + (value.getMonth() + 1).toString() + '/' + (value.getFullYear()).toString()
        setNewOrderDate(NewOrderDate)
    }

    const [maxDay, setMaxDay] = useState(new Date());

    useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() + 100);
    setMaxDay(date)
    }, [])


    return (
        <div>
            <Modal show={props.isOpen} onHide={props.toggle} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title style={{ color: '#00baff'}}><i className="fas fa-calendar-alt"></i> Cambiar fecha de entrega de mercado</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <p>Nueva fecha de entrega para de mercado
                        </p>
                        {orderDateUpdatedError && (<Message variant='danger'>{orderDateUpdatedError}</Message>)}
                        {wednesdayOptions && wednesdayOptions.length > 0 && (
                            <Form onSubmit={updateDateOrderHandler}>
                                {orderDateUpdatedLoading ? (
                                    <Spinner
                                    animation='border'
                                    role='status'
                                    style={{
                                        height:'25px',
                                        width:'25px',
                                        margin:'auto',
                                        display:'block'
                                    }}
                                    >
                                        <span className='sr-only'>
                                            Loading...
                                        </span>
                                    </Spinner>
                                ) : (
                                    <div>
                                        <Calendar 
                                            locale="es-ES"
                                            minDate={new Date()}
                                            // activeStartDate={new Date()}
                                            tileDisabled={({ activeStartDate, date, view }) => date.getDay() !== 3}
                                            onChange={(value) => onChangeDate(value)}
                                            className='mx-auto'
                                            maxDate={maxDay}
                                        />
                                    </div>
                                )}
                                <Button required={orderDateUpdatedLoading} disabled={orderDateUpdatedLoading || newOrderDate.length < 3} type="submit" className='btn-block mt-3' style={{ backgroundColor: '#00baff' }}>
                                        {orderDateUpdatedLoading ? (
                                            <Spinner
                                            animation='border'
                                            role='status'
                                            style={{
                                                height:'25px',
                                                width:'25px',
                                                margin:'auto',
                                                display:'block'
                                            }}
                                            >
                                                <span className='sr-only'>
                                                    Loading...
                                                </span>
                                            </Spinner>
                                        ) : (
                                            <span>
                                                Cambiar fecha de entrega
                                            </span>
                                        )}
                                </Button>
                            </Form>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={CloseUpdateOrderDateModal} className='btn-block'>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>                                    
        </div>
    )
}
