import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Row, Button, Col, Image, Form, Alert, Spinner } from 'react-bootstrap'
import Amex from '../../../images/americanexpress.png'
import Visa from '../../../images/visa02.png'
import MasterCard from '../../../images/mastercard.png'
import PayU from '../../../images/Logo PayU.png'
import { createCardAction } from '../../../actions/paymentsActions'
import { 
    subscriptionCheckActiveStatusAction } from '../../../actions/subscriptionActions'
import useGAEventTracker from '../../../hooks/useGAEventTracker';
import ReactPixel from 'react-facebook-pixel';

export const ModalCreditCard = (props) => {

    const placeholderRef = useRef(null)

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Add 1 as months are zero-based
    const currentYear = currentDate.getFullYear();

    const yearOptions = []

    for (let i=0; i<10; i++) {
        yearOptions.push(currentYear+i)
    }

    
    const GAEventsTracker = useGAEventTracker();
    const[cardHolder, setCardHolder] = useState('')
    const[cvv, setCvv] = useState('')
    const[cardNumber, setCardNumber] = useState('')
    const[documentType, setDocumentType] = useState('')
    const[documentNumber, setDocumentNumber] = useState('')
    const[expiracyMonth, setExpiracyMonth] = useState('')
    const[expiracyYear, setExpiracyYear] = useState('')
    const[creditCardType, setcreditCardType] = useState('')
    const[cardExpiracyDateError, setCardExpiracyDateError] = useState(false)

    const cardCreate = useSelector(state => state.createCard)
    const { error: cardCreateError, loading: cardCreateLoading, success: successCardCreate } = cardCreate

    const dispatch = useDispatch()

    const handleCardInfoSubmit = (e) => {
        e.preventDefault()
        const titular = cardHolder
        const cvv_value = cvv
        const numero_de_tarjeta = cardNumber
        const userDocumentType = documentType
        const numero_documento = documentNumber
        const mes_caducidad = expiracyMonth
        const año_caducidad = expiracyYear
        const userCreditCardType = creditCardType
        dispatch(createCardAction(titular, cvv_value, numero_de_tarjeta, userDocumentType, numero_documento, mes_caducidad, año_caducidad, userCreditCardType))
        GAEventsTracker('Credit Cart', 'Credit Cart Button', 'Button')
        dispatch(subscriptionCheckActiveStatusAction())
        if (window.location.origin.includes('localhost') === false && window.location.origin.includes('qa') === false) {
            ReactPixel.track('ActiveSubscription');
        }
    }

    const validateSetMonth = (e) => {
        setCardExpiracyDateError(false)
        if (e.target.value === 'Mes') {
            setExpiracyMonth('Mes')
        } else {
            const expiracyMonthAsInt = parseInt(e.target.value)
            if (expiracyYear === 'Año') {
                setExpiracyMonth(e.target.value)
            } else {
                if (expiracyYear.length > 0) {
                    const expiracyYearAsInt = parseInt(expiracyYear)
                    if (expiracyYearAsInt > currentYear) {
                        setExpiracyMonth(e.target.value)
                    } else if (parseInt(expiracyYearAsInt) === parseInt(currentYear)) {
                        if (parseInt(expiracyMonthAsInt) >= parseInt(currentMonth)) {
                            setExpiracyMonth(e.target.value)
                        } else {
                            setExpiracyMonth(e.target.value)
                            setCardExpiracyDateError(true)
                        }
                    }
                } else {
                    setExpiracyMonth(e.target.value)
                }        
            }
        }
    }

    const validateSetYear = (e) => {
        setCardExpiracyDateError(false)
        if (e.target.value === 'Año') {
            setExpiracyYear("Año")
        } else {
            const expiracyYearAsInt = parseInt(e.target.value)
            if (expiracyMonth === 'Mes') {
                setExpiracyYear(e.target.value)
            } else {
                if (expiracyMonth.length > 0) {
                    const expiracyMonthAsInt = parseInt(expiracyMonth)
                    if (expiracyMonthAsInt > currentMonth) {
                        setExpiracyYear(e.target.value)
                    } else if (parseInt(expiracyMonthAsInt) <= parseInt(currentMonth)) {
                        if (parseInt(expiracyYearAsInt) > parseInt(currentYear)) {
                            setExpiracyYear(e.target.value)
                        } else {
                            setExpiracyYear(e.target.value)
                            setCardExpiracyDateError(true)
                        }
                    }
                } else {
                    setExpiracyYear(e.target.value)
                }        
            }
        }
    } 

    useEffect(() =>{
        if (cardNumber.length > 0){
            const valid_characters = '0123456789 '
            if(valid_characters.includes(cardNumber.slice(-1))){
            } else {
                setCardNumber(cardNumber.substring(0, cardNumber.length - 1))
            }    
        }
        if (cardNumber.length > 19){
            setCardNumber(cardNumber.substring(0, cardNumber.length - 1))
        }
        if (cvv.length > 3){
            setCvv(cvv.substring(0, cvv.length - 1))
        }
        if(cardNumber.slice(0, 1) === '4' && (cardNumber.length === 16 || cardNumber.length === 16)) {
            setcreditCardType('VISA')
        }
        if((cardNumber.slice(0, 2) === '34' || cardNumber.slice(0, 2) === '37') && cardNumber.length === 18) {
            setcreditCardType('AMEX')
        }
        if((cardNumber.slice(0, 2) === '51' || cardNumber.slice(0, 2) === '52' || cardNumber.slice(0, 2) === '53' || cardNumber.slice(0, 2) === '54' || cardNumber.slice(0, 2) === '55') && cardNumber.length === 19) {
            setcreditCardType('MASTERCARD')
        }
        if(cardNumber.length < 16){
            setcreditCardType('')
        }
        if(cardNumber.length === 4 || cardNumber.length === 9 || cardNumber.length === 14){
            setCardNumber(cardNumber + ' ')
        }
        if(successCardCreate) {
            setCardHolder('')
            setCvv('')
            setCardNumber('')
            setDocumentType('')
            setDocumentNumber('')
            setExpiracyMonth('')
            setExpiracyYear('')
            setcreditCardType('')
        }
    }, [
        cardNumber,
        cvv,
        successCardCreate,
        dispatch,
        GAEventsTracker,
        cardExpiracyDateError,
        cardCreateError
    ])

    useEffect(() => {
        if (props.isOpen) {
            if (placeholderRef && placeholderRef.current) {
                placeholderRef.current.focus();
            }    
        }
    }, [placeholderRef, props.isOpen])

    return (
        <div>
             <Modal show={props.isOpen} onHide={props.toggle} animation={false} size="lg">
                <Modal.Header closeButton>
                <Modal.Title className='text-success'><i className="fas fa-credit-card"></i> Incluir datos de pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form className='mb-3' onSubmit={handleCardInfoSubmit}>
                        <Row className='my-1 px-3'>
                            <Col md={7} className='mt-1'>
                                <Form.Control
                                    ref={placeholderRef}
                                    required
                                    placeholder="Titular"
                                    disabled={cardCreateLoading} 
                                    type='text'
                                    value={cardHolder}
                                    onChange={(e) => setCardHolder(e.target.value) }
                                />
                            </Col>
                            <Col md={5} className='mt-1'>
                                <Form.Control
                                    required
                                    disabled={cardCreateLoading}
                                    placeholder="CVV"
                                    type='number'
                                    value={cvv}
                                    onChange={(e) => setCvv(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className='my-1 px-3'>
                            <Col md={7}>
                                <Form.Control
                                    required
                                    placeholder="Número de tarjeta"
                                    disabled={cardCreateLoading} 
                                    type='text'
                                    value={cardNumber}
                                    onChange={(e) => setCardNumber(e.target.value)}
                                />
                            </Col>
                            <Col md={5}>
                                {creditCardType === 'AMEX' ? (
                                    <Image src={Amex} className="img-fluid" alt="Responsive image" />
                                ) : 
                                creditCardType === 'VISA' ? (
                                    <Image src={Visa} className="img-fluid" alt="Responsive image" />
                                ) : 
                                creditCardType === 'MASTERCARD' ? (
                                    <Image src={MasterCard} className="img-fluid" alt="Responsive image" />
                                ) : (
                                    <div></div>
                                )}
                            </Col>
                        </Row>
                        <Row className='my-1 px-3'>
                            <Col md={7} className='mt-1'>
                                <Form.Control
                                    disabled={cardCreateLoading} 
                                    required
                                    as="select"
                                    value={documentType}
                                    onChange={(e) => setDocumentType(e.target.value)}
                                >
                                    <option>Tipo de Documento</option>
                                    <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
                                    <option value="Cédula de extranjería">Cédula de extranjería</option>
                                    <option value="Pasaporte">Pasaporte</option>
                                </Form.Control>
                            </Col>
                            <Col md={5} className='mt-1'>
                                <Form.Control
                                    required
                                    disabled={cardCreateLoading} 
                                    placeholder="Número de documento"
                                    type='number'
                                    value={documentNumber}
                                    onChange={(e) => setDocumentNumber(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center mb-n3 mt-1'>
                                <p>Caducidad de tarjeta</p>
                            </Col>
                        </Row>
                        <Row className='my-1 px-3'>
                            <Col md={7} className='mt-1'>
                                <Form.Control
                                    required
                                    disabled={cardCreateLoading} 
                                    as="select"
                                    value={expiracyMonth}
                                    onChange={(e) => validateSetMonth(e)
                                }>
                                    <option value="Mes">Mes</option>
                                    <option value="01">1</option>
                                    <option value="02">2</option>
                                    <option value="03">3</option>
                                    <option value="04">4</option>
                                    <option value="05">5</option>
                                    <option value="06">6</option>
                                    <option value="07">7</option>
                                    <option value="08">8</option>
                                    <option value="09">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </Form.Control>
                            </Col>
                            <Col className='mt-1'>
                                <Form.Control
                                    required
                                    disabled={cardCreateLoading} 
                                    as="select"
                                    value={expiracyYear}
                                    onChange={(e) => validateSetYear(e) }
                                >
                                    {yearOptions && yearOptions.length > 0 && (
                                        <>
                                        <option value={'Año'}>Año</option>
                                            <>
                                                {yearOptions.map((year) => {
                                                    return (
                                                        <option key={year} value={year.toString()}>{year}</option>
                                                    )
                                                }
                                                )}
                                            </>
                                        </>
                                    )}
                                </Form.Control>
                            </Col>
                        </Row>

                        {cardExpiracyDateError && (
                            <Row className='justify-content-md-center px-3 text-center mt-3'>
                                <Col>
                                    <Alert variant='danger'>Fecha de expiración no válida</Alert>
                                </Col>
                            </Row>
                        )} 

                        {cardCreateError && (
                                <Row className='justify-content-md-center px-3 text-center'>
                                    <Col>
                                        <Alert variant='danger'>{cardCreateError}</Alert>
                                    </Col>
                                </Row>
                            )}

                        <Row className='my-1 px-3'>
                            <Col className='px-0 mt-1'>
                            {cardHolder.length === 0 || cvv.length < 3 || cardNumber.length < 14 || documentNumber.length === 0 || documentType.length === 0 || expiracyMonth.length === 0 || expiracyMonth === 'Mes' || expiracyYear === 'Año' || expiracyYear.length === 0 || cardExpiracyDateError ? (
                                <Col>
                                    <Button variant='success' disabled type='submit' className='btn-block'>Inscribir tarjeta</Button>
                                </Col>
                            ) : (
                                <Col>
                                    <Button
                                        variant='success'
                                        disabled={cardCreateLoading}
                                        type='submit'
                                        className='btn-block'
                                    >
                                    {cardCreateLoading ? (
                                    <Spinner
                                        animation='border'
                                        role='status'
                                        style={{
                                            height:'25px',
                                            width:'25px',
                                            margin:'auto',
                                            display:'block'
                                        }}
                                    ></Spinner>                                    
                                ) : (
                                    <span>
                                        Inscribir tarjeta
                                    </span>
                                    )}
                                    </Button>
                                </Col>
                            )}
                            </Col>
                        </Row>
                        <Row className='my-3 px-3'>
                            <Col>
                                <Alert variant='primary'>
                                    <div className='px-0 d-flex justify-content-center'>
                                    <Image src={PayU} className="img-fluid" alt="Responsive image" />
                                    </div>
                                    El pago se realiza de forma segura a través de la plataforma PayU, especializada en este tipo de transacciones.
                                    Para tu mayor seguridad, no almacenamos ningún dato financiero en nuestros servidores.
                                </Alert>
                            </Col>
                        </Row>
                    </Form>
                    <Row className='justify-content-md-center'>
                        <Button variant="secondary" onClick={props.toggle}>
                            Cerrar
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}
