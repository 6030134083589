import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge';
import { SubscriptionButton } from './SubscriptionButton'
import { ProductHorizontalCardImage } from '../../ProductHorizontalCardImage'

export const ProductHorizontalCard = ({ item, disableButtons, DisableButtonFunction, displayButton }) => {

    const badgeStyle = {
        backgroundColor: item && item.products && item.products.category && item.products.category.name && item.products.category.name.toLowerCase() === 'frutas' ? '#3FE22B' : (
            item && item.products && item.products.category && item.products.category.name && item.products.category.name.toLowerCase() === 'frutos secos' ? '#FF8700' : (
                item && item.products && item.products.category && item.products.category.name && item.products.category.name.toLowerCase() === 'semillas, especias y aromáticas' ? '#CAFF6F' : (
                    item && item.products && item.products.category && item.products.category.name && item.products.category.name.toLowerCase() === 'verduras y hortalizas' ? '#29BC5A' : '#F2C172'
                )
            )),
        color: 'white',
        marginTop: '6px',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        wordBreak: 'break-word',
        WebkitLineClamp: 3, // ajusta según sea necesario para el número deseado de líneas
        maxWidth: '100%',
        whiteSpace: 'normal',
        marginRight: '5px'
    };    

    return (
        <div>
            <div className='rounded bg-white my-1'>
                <Row>
                    <Col xs={4} sm={3}>
                        <ProductHorizontalCardImage product={item.products} type='subscription'/>
                    </Col>
                    <Col xs={8} sm={9} className='pl-3'>
                        <Row>
                            <Col xs={12} md={7}>
                                <p className="card-title mb-n1 pt-3 pl-1">
                                    <b>{item.products.name}</b>
                                </p>
                            </Col>
                            <Col xs={5} className='d-none d-md-block pr-5 pt-1'>
                                {item && item.products && item.products.category && item.products.category.name && 
                                    <Badge style={badgeStyle}>
                                        {item.products.category.name}
                                    </Badge>                            
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='pl-1 mb-n1'><small>Precio por unidad: {new Intl.NumberFormat('co-CO').format(Number(item.products.price))} pesos</small></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="card-text mt-n1 pl-1">
                                    <small className="text-muted">{item.amount} unidades. ${new Intl.NumberFormat('co-CO').format(Number(item.total_value))}</small>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='mb-1 mt-n1'>
                    <Col className='px-5 mb-3'>
                        <SubscriptionButton
                            product={item.products}
                            disableButtons={disableButtons}
                            DisableButtonFunction={DisableButtonFunction}
                            horizontalCard={true}
                        />
                    </Col>
                </Row>
            </div>            
        </div>
    )
}
