import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { userChangePasswordAction } from '../../../../../actions/userActions'
import { USER_CHANGE_PASSWORD_RESET } from '../../../../../constants/userConstants'
import Message from '../../../../Message'
import { userMessageAction } from '../../../../../actions/userActions'

export const ModalChangePassword = (props) => {

    const[currentPassword, setCurrentPassword] = useState()
    const[newPassword1, setNewPassword1] = useState()
    const[newPassword2, setNewPassword2] = useState()
    const[newPasswordMatch, setnewPasswordMatch] = useState(false)

    const dispatch = useDispatch()

    const userPasswordChange = useSelector(state => state.userChangePassword)
    const {loading, error, success} = userPasswordChange

    const changePasswordHandler = (e) => {
        e.preventDefault()
        const passwords = {
            currentPassword,
            newPassword1
        }
        dispatch(userChangePasswordAction(passwords))
    }

    const ClosePasswordModal = () => {
        props.toggle()
    }

    useEffect(() => {
        if(newPassword1 && newPassword1.length > 9 && newPassword2 && newPassword1 === newPassword2 && currentPassword){
            setnewPasswordMatch(true)
        } else {
            setnewPasswordMatch(false)
        }
        if(success){
            setCurrentPassword('')
            setNewPassword1('')
            setNewPassword2('')
            dispatch({
                type: USER_CHANGE_PASSWORD_RESET
            })
            ClosePasswordModal()
            dispatch(userMessageAction('Contraseña cambiada.'))
        }
    }, [currentPassword, newPassword1, newPassword2, newPasswordMatch, success, dispatch, ClosePasswordModal])

    return (
        <div>
             <Modal show={props.isOpen} onHide={props.toggle} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Cambiar contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && (<Message variant='danger'>{error}</Message>)}
                    <Form onSubmit={changePasswordHandler}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Contraseña actual</Form.Label>
                            <Form.Control type="password" placeholder="Contraseña actual" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Nueva contraseña</Form.Label>
                            <Form.Control type="password" placeholder="Nueva contraseña" value={newPassword1} onChange={(e) => setNewPassword1(e.target.value)} />
                            <Form.Text className="text-muted">
                                Tu nueva contraseña tiene que tener al menos 10 carácteres de largo
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Repite nueva contraseña</Form.Label>
                            <Form.Control type="password" placeholder="Repite nueva contraseña" value={newPassword2} onChange={(e) => setNewPassword2(e.target.value)}/>
                        </Form.Group>

                        {newPasswordMatch ? (
                            <Button variant="primary" disabled={loading} type="submit" className='btn-block'>
                                {loading ? (
                                    <Spinner
                                    animation='border'
                                    role='status'
                                    style={{
                                        height:'25px',
                                        width:'25px',
                                        margin:'auto',
                                        display:'block'
                                    }}
                                    >
                                        <span className='sr-only'>
                                            Loading...
                                        </span>
                                    </Spinner>  
                                ) : (
                                    <span>
                                        Cambiar contraseña
                                    </span>
                                )}
                            </Button>
                        ) : (
                            <Button variant="primary" disabled type="submit" className='btn-block'>
                                Cambiar contraseña
                            </Button>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.toggle}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
