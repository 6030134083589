import React from 'react'
import { Spinner } from 'react-bootstrap'

function Loader({fullWidth}){

  return(
    <div>
      {fullWidth && fullWidth === 'true' && (
        <p style={{ color: '#F8F9FA'}} className='my-0'>-------------------------------------------------------------------------------------</p>
      )}
        <Spinner
        animation='border'
        role='status'
        style={{
          height:'100px',
          width:'100px',
          margin:'auto',
          display:'block',
        }}
      >
        <span className='sr-only'>
          Loading...
        </span>
      </Spinner>
    </div>
  )
}

export default Loader